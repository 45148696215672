import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Table, Switch, Select, Button,Space} from 'antd';

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {useNavigate } from "react-router-dom";

import {
  SearchOutlined,
  EditOutlined
} from "@ant-design/icons";

const ETFAssets = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const { TextArea } = Input;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allEtfAsset, setAllEtfAsset] = useState([]);

  //DropList Market
  const [allMarket, setAllMarket] = useState([]);
  //DropList Category
  const [allCategory, setAllCategory] = useState([]);
  //DropList Industry
  const [allFundFamily, setAllFundFamily] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Fields from table to View
  const [etfAssetNameUpdate, setEtfAssetNameUpdate] = useState(null);
  const [yahooTickerUpdate, setYahooTickerUpdate] = useState(null);
  const [bloombergTickerUpdate, setBloombergTickerUpdate] = useState(null);
  const [morningstarTickerUpdate, setMorningstarTickerUpdate] = useState(null);
  const [descriptionUpdate, setDescriptionUpdate] = useState(null);
  const [websiteUpdate, setWebsiteUpdate] = useState(null);
  const [logoURLUpdate, setLogoURLUpdate] = useState(null);
  const [ISINUpdate, setISINUpdate] = useState(null);
  const [fundFamilyNameUpdate, setFundFamilyNameUpdate] = useState(null);
  const [marketNameUpdate, setMarketNameUpdate] = useState(null);
  const [categoryNameUpdate, setCategoryNameUpdate] = useState(null);
  const [expenseRatioUpdate, setExpenseRatioUpdate] = useState(null);
  const [currencyUpdate, setCurrencyUpdate] = useState(null);
  const [statusUpdate, setStatusUpdate] = useState(null);
  const [isFundUpdate, setIsFundUpdate] = useState(null);
  const [isPPRUpdate, setIsPPRUpdate] = useState(null);
  const [isManualPriceUpdate, setIsManualPriceUpdate] = useState(null);

    //Filters
    const [fundFamilyName, setFundFamilyFilter] = useState('all');
    const [isFund, setIsFundFilter] = useState('all');

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "etfAssetName",
      title: "Asset",
      dataIndex: "etfAssetName",
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.etfAssetName.localeCompare(b.etfAssetName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.etfAssetName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "ISIN",
      title: "ISIN",
      dataIndex: "ISIN",
      sorter: (a, b) => a.ISIN.localeCompare(b.ISIN),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.ISIN.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "yahooTicker",
      title: "Yahoo Ticker",
      dataIndex: "yahooTicker",
      sorter: (a, b) => a.yahooTicker.localeCompare(b.yahooTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.yahooTicker.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "bloombergTicker",
      title: "Bloomberg Ticker",
      dataIndex: "bloombergTicker",
      sorter: (a, b) => a.bloombergTicker.localeCompare(b.bloombergTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.bloombergTicker.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "marketName",
      title: "Market",
      dataIndex: ["marketName", "marketName"],
      sorter: (a, b) => a.marketName.marketName.localeCompare(b.marketName.marketName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.marketName.marketName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "fundFamilyName",
      title: "Fund Family",
      dataIndex: ["fundFamilyName", "fundFamilyName"],
      sorter: (a, b) => a.fundFamilyName.fundFamilyName.localeCompare(b.fundFamilyName.fundFamilyName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.fundFamilyName.fundFamilyName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "categoryNameAsset",
      title: "Asset Class",
      dataIndex: ["categoryAssetName", "categoryAssetName"],
      sorter: (a, b) => a.categoryAssetName.categoryAssetName.localeCompare(b.categoryAssetName.categoryAssetName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.categoryAssetName.categoryAssetName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key:"expenseRatio",
      title: "Expense Ratio",
      dataIndex: "expenseRatio",
      sorter: (a, b) => a.expenseRatio - b.expenseRatio,
      render: (value, row, index) => {
        // do something like adding commas to the value or prefix
        return <span>{value.toLocaleString('pt-PT')} %</span>;
      },
    },
    {
      key: "currency",
      title: "Currency",
      dataIndex: ["currency"],
      sorter: (a, b) => a.currency.localeCompare(b.currency),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.currency.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "isFund",
      title: "Fund",
      dataIndex: "isFund",
      render: (value, record) => {
        if(record.isFund === true )
          return (
            <span>Yes</span>
          );

          return (
            <span>No</span>
          );
      },
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.isFund === value;
      },
    },
    {
      key: "isPPR",
      title: "PPR",
      dataIndex: "isPPR",
      render: (value, record) => {
        if(record.isPPR === true )
          return (
            <span>Yes</span>
          );

          return (
            <span>No</span>
          );
      },
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.isPPR === value;
      },
    },
    {
      key: "isManualPrice",
      title: "Manual Price",
      dataIndex: "isManualPrice",
      render: (value, record) => {
        if(record.isManualPrice === true )
          return (
            <span>Yes</span>
          );

          return (
            <span>No</span>
          );
      },
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.isManualPrice === value;
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Actions",
      dataIndex: "type",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
            <EditOutlined
              onClick={() => {
                    setEditable(record);
                    setEtfAssetNameUpdate(record.etfAssetName);
                    setYahooTickerUpdate(record.yahooTicker);
                    setBloombergTickerUpdate(record.bloombergTicker);
                    setMorningstarTickerUpdate(record.morningstarTicker);
                    setDescriptionUpdate(record.description);
                    setWebsiteUpdate(record.website);
                    setLogoURLUpdate(record.logoURL);
                    setISINUpdate(record.ISIN);
                    setFundFamilyNameUpdate(record.fundFamilyName.fundFamilyName);
                    setMarketNameUpdate(record.marketName.marketName);
                    setCategoryNameUpdate(record.categoryAssetName.categoryAssetName);
                    setCurrencyUpdate(record.currency);
                    setExpenseRatioUpdate(record.expenseRatio);
                    setStatusUpdate(record.status);
                    setIsFundUpdate(record.isFund);
                    setIsPPRUpdate(record.isPPR);
                    setIsManualPriceUpdate(record.isManualPriceUpdate);
                    setShowModal(true);
              }}
            />
          </div>
        );
      },
    }
  ];

  const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllETFAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/etfAssets/get-etfAsset',
        {
          fundFamilyName,
          isFund: isFund,
          isPPR: "all",
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllEtfAsset(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        // message.error('You are not Admin User');
      }
    };  
    
    getAllETFAsset();

    // Clean up the interval when the component unmounts
    // return () => 
  },[fundFamilyName,isFund])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitEtfAsset = async (values) => {

  try{
    const promises = [];
  
    const user = JSON.parse(localStorage.getItem('user'))

    if(editable){
      setLoading(true);
      const result = axios.post('/etfAssets/edit-etfAsset', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: editable._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Stock Asset Updated Successfull');

    }else{
      setLoading(true);
      const result = axios.post('/etfAssets/add-etfAsset', {
        ...values, 
        userid:user._id, 
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('ETF Asset Added Successfull');
    }

    setShowModal(false);
    setEditable(null);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  //Handler Markets DropList ****************************************************************************************
  useEffect(() => {
    const getAllMarkets = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/markets/get-market', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllMarket(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        // message.error('You are not Admin User');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllMarkets();
  },[])

  //Handler Fund Family DropList ****************************************************************************************
  useEffect(() => {
    const getAllFundFamlies = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/fundFamilies/get-fundFamily', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllFundFamily(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        // message.error('You are not Admin User');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllFundFamlies();
  },[])

  //Handler Sectors DropList ****************************************************************************************
  useEffect(() => {
    const getAllCategories = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/categoryAssets/get-categoryAsset', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllCategory(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        // message.error('You are not Admin User');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllCategories();
  },[])

  //Handler Test Console Log Values
  const onFinish = (values) => {
    console.log({values});
  };

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setEditable(null);
    setEtfAssetNameUpdate(null);
    setYahooTickerUpdate(null);
    setBloombergTickerUpdate(null);
    setMorningstarTickerUpdate(null);
    setDescriptionUpdate(null);
    setWebsiteUpdate(null);
    setLogoURLUpdate(null);
    setISINUpdate(null);
    setFundFamilyNameUpdate(null);
    setMarketNameUpdate(null);
    setCategoryNameUpdate(null);
    setCurrencyUpdate(null);
    setExpenseRatioUpdate(null);
    setStatusUpdate(null);
    setIsFundUpdate(null);
    setIsPPRUpdate(null);
  };

  /************************************Website DISABLE BUTTON***************************/
  let webSiteInputDisable;

  if (!websiteUpdate) {
    webSiteInputDisable = 
    <Form.Item label="Morningstar Website Stats" name="website">
        <Input 
        type="text"
        />
    </Form.Item>
  } else {
    webSiteInputDisable = 
    <Form.Item label="Morningstar Website Stats" name="website">
      <Space.Compact style={{ width: '100%' }}>
        <Input 
        type="text"
        defaultValue={websiteUpdate}
        />
        <Button type="primary" onClick={() => window.open(websiteUpdate, "Popup","width=600, height=600")}>View</Button>
      </Space.Compact>
    </Form.Item>
  }
  
  
  //Handler Close Modal
  const handleClose = () => {
    setShow(false);
  };

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Definições - ETFs e Fundos de Investimentos</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
          <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
          >+ Novo ETF
        </Button>
        </div>

      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>

        <div className="accountpage">
        <div className="vertical-select">
        <h6>Escolha a Família de Fundos</h6>
          <Select 
            name="accounts"
            style={{
              width: '250px',
            }}  
            value={fundFamilyName} 
            onChange={(values) => setFundFamilyFilter(values)}
            >
                <option value='all'>Todos</option>
                {
                  allFundFamily.map( (getcon, index)=>(
                    <option key={index} value={getcon.id}>{getcon.fundFamilyName} </option>
                  ))
                }
          </Select>
        </div>
        <div></div>
        <div className="vertical-select">
        <h6>Fundo de Investimento?</h6>
          <Select 
          style={{
            width: '150px',
          }} 
          value={isFund} onChange={(values) => setIsFundFilter(values)}>
            <Select.Option value='all'>Todos</Select.Option>
            <Select.Option value='true'>Sim</Select.Option>
            <Select.Option value='false'>Não</Select.Option>
          </Select>
        </div>
      </div>
      <hr></hr>
      <div className="content">
        <Table
        size="small" 
        columns={columns}
        dataSource={allEtfAsset}
        pagination={{ defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
        />
        
      </div>
      {/* <div><pre>{JSON.stringify(allEtfAsset, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(allMarket, null, 2) }</pre></div> */}
      
      <Modal
        destroyOnClose={true} 
        title={editable ? "Edit ETF Asset" : "Add ETF Asset" }
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form 
            layout="vertical" 
            onFinish={handleSubmitEtfAsset} 
            style={{padding:"32px 16px"}}
          >

            <Form.Item label="Asset" name="etfAssetName">
              <Input 
                type="text"
                defaultValue={etfAssetNameUpdate}
                />
            </Form.Item>
            <Space direction="vertical">
            <Form.Item label="Is Fund" name="isFund" valuePropName="checked">
              <Switch 
                defaultChecked={isFundUpdate}
              />
            </Form.Item>
            <Form.Item label="Is PPR" name="isPPR" valuePropName="checked">
              <Switch 
                defaultChecked={isPPRUpdate}
              />
            </Form.Item>
            <Form.Item label="Is Manual Price" name="isManualPrice" valuePropName="checked">
              <Switch 
                defaultChecked={isManualPriceUpdate}
              />
            </Form.Item>
          </Space>
            <Form.Item label="Yahoo Ticker" name="yahooTicker">
              <Input 
                type="text"
                defaultValue={yahooTickerUpdate}
                />
            </Form.Item>

            <Form.Item label="Bloomberg Ticker" name="bloombergTicker">
              <Input
                defaultValue={bloombergTickerUpdate}
                />
            </Form.Item>

            <Form.Item label="Morningstar Ticker" name="morningstarTicker">
              <Input
                defaultValue={morningstarTickerUpdate}
                />
            </Form.Item>

            <Form.Item label="Description" name="description">
              <TextArea
                showCount
                maxLength={2000}
                style={{
                  height: 400,
                  marginBottom: 24,
                }}
                type="text" 
                defaultValue={descriptionUpdate}
                />
            </Form.Item>

            {/* <Form.Item label="Morningstar Website Stats" name="website">
              <Space.Compact style={{ width: '100%' }}>
                <Input 
                type="text"
                defaultValue={websiteUpdate}
                />
                <Button type="primary" onClick={() => window.open(websiteUpdate, "Popup","width=600, height=600")}>View</Button>
              </Space.Compact>
            </Form.Item> */}

            {/* See the button view */}
            {webSiteInputDisable}

            <Form.Item label="LogoURL" name="logoURL">
              <Input 
                type="text"
                defaultValue={logoURLUpdate}
                />
            </Form.Item>

            <Form.Item label="ISIN" name="ISIN">
              <Input 
                type="text"
                defaultValue={ISINUpdate}
                />
            </Form.Item>

            <Form.Item label="Expense Ratio" name="expenseRatio">
              <Input 
                type="text"
                defaultValue={expenseRatioUpdate}
                />
            </Form.Item>

            <Form.Item label="Fund Family" name="fundFamilyName">
              <Select
                defaultValue={fundFamilyNameUpdate}
              >
                  {
                  allFundFamily.map((opts,i)=>
                    <Select.Option key={i} value={opts._id}>
                      {opts.fundFamilyName}
                    </Select.Option>)
                  }
              </Select>
            </Form.Item>

            <Form.Item label="Category" name="categoryAssetName">
              <Select
                defaultValue={categoryNameUpdate}
              >
                  {
                  allCategory.map((opts,i)=>
                    <Select.Option key={i} value={opts._id}>
                      {opts.categoryAssetNamePT}
                    </Select.Option>)
                  }
              </Select>
            </Form.Item>

            <Form.Item label="Market" name="marketName">
              <Select
                defaultValue={marketNameUpdate}
              >
                  {
                  allMarket.map((opts,i)=>
                    <Select.Option key={i} value={opts._id}>
                      {opts.marketNamePT}
                    </Select.Option>)
                  }
              </Select>
            </Form.Item>

            <Form.Item label="Currency" name="currency">
              <Select defaultValue={currencyUpdate}>
                <Select.Option value="EUR">EUR</Select.Option>
                <Select.Option value="USD">USD</Select.Option>
                <Select.Option value="CHF">CHF</Select.Option>
                <Select.Option value="GBP">GBP</Select.Option>
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch 
                defaultChecked={statusUpdate}
              />
            </Form.Item>

            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" onClick={handleClose}>
                {" "}
                SAVE
              </button>
            </div>
            {/* <div><pre>{JSON.stringify(allMarket, null, 2) }</pre></div> */}
            
          </Form>
      </Modal>

    </Layout>
  );
};

export default ETFAssets;