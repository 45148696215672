import React,{useState,useEffect} from "react";
import { message, Button} from 'antd';

import {useNavigate,useLocation} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import  Chart  from "react-apexcharts";

const TransactionsStats = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading,setLoading] = useState(false);

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const user = JSON.parse(localStorage.getItem('user'));
        const response = await axios.post(
          '/transactions/get-categoryexpense',
          { 
            userid: user._id,
            isIncomeOrExpense: false
          },
          { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` } }
        );
        setLoading(false);

        const data = response.data;

        // Group data by _id.categoryNamePT
        const groupedData = data.reduce((acc, item) => {
          const { _id, amount } = item;
          const { categoryNamePT, monthYear } = _id;
          if (!acc[categoryNamePT]) {
            acc[categoryNamePT] = { name: categoryNamePT, data: [] };
          }
          acc[categoryNamePT].data.push({ x: monthYear, y: parseFloat(amount).toFixed(2) }); // Arredondar amount para 2 casas decimais
          return acc;
        }, {});

        // Prepare chart data for each category
        const chartsData = Object.values(groupedData).map(categoryData => ({
          name: categoryData.name,
          data: categoryData.data.sort((a, b) => a.x - b.x) // Ordenando como números
        }));

        setChartData(chartsData);
      } catch (error) {
        setLoading(false);
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  // Função para formatar mês/ano (por exemplo, 202401 para '2024-01')
  const formatMonthYear = (monthYear) => {
    const year = Math.floor(monthYear / 100);
    const month = monthYear % 100;
    return `${year}-${month.toString().padStart(2, '0')}`;
  };


  const [chartDataIncome, setChartDataIncome] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const user = JSON.parse(localStorage.getItem('user'));
        const response = await axios.post(
          '/transactions/get-categoryexpense',
          { 
            userid: user._id,
            isIncomeOrExpense: true
          },
          { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` } }
        );
        setLoading(false);

        const data = response.data;

        // Group data by _id.categoryNamePT
        const groupedData = data.reduce((acc, item) => {
          const { _id, amount } = item;
          const { categoryNamePT, monthYear } = _id;
          if (!acc[categoryNamePT]) {
            acc[categoryNamePT] = { name: categoryNamePT, data: [] };
          }
          acc[categoryNamePT].data.push({ x: monthYear, y: parseFloat(amount).toFixed(2) }); // Arredondar amount para 2 casas decimais
          return acc;
        }, {});

        // Prepare chart data for each category
        const chartsDataIncome = Object.values(groupedData).map(categoryData => ({
          name: categoryData.name,
          data: categoryData.data.sort((a, b) => a.x - b.x) // Ordenando como números
        }));

        setChartDataIncome(chartsDataIncome);
      } catch (error) {
        setLoading(false);
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);
    
  // ACCOUNT TRANSACTIONS
    return (
      <Layout>
        {loading && <Spinner/>}
        <div className="accountpage" style={{ marginBottom: '50px' }}>
        <div></div>
        <div><h1>Dashboard</h1></div>
        <div></div>
      </div>
      <div>
      <h1 align="center" style={{color:"Green"}}>Receitas</h1>
      <hr></hr>
      {loading ? (
        <div>A Carregar Receitas...</div>
      ) : (
        chartDataIncome.map((chart, index) => (
          <div key={index}>
            <h2>{chart.name}</h2>
            <Chart
              style={{ marginTop: '50px' }}
              options={{
                chart: {
                  id: `chart-${index}`,
                  type: 'bar',
                },
                dataLabels: {
                  enabled: true,
                  position: 'top',
                  formatter: function (val) {
                    return val;
                  },
                  offsetY: -20,
                  style: {
                    fontSize: '12px',
                    colors: ['#333']
                  }
                },
                xaxis: {
                  type: 'category',
                  title: {
                    text: 'Ano-Mês'
                  },
                  categories: chart.data.map(point => formatMonthYear(point.x.toString())), // Converte para string para exibição
                  labels: {
                    formatter: val => val
                  }
                },
                yaxis: {
                  title: {
                    text: 'Montantes'
                  },
                  labels: {
                    formatter: val => parseFloat(val).toFixed(2) // Arredondar para zero casas decimais
                  }
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                      position: 'top'
                    },
                    horizontal: false, // Define as barras na vertical
                    columnWidth: '10%', // Define a largura das barras em percentual (80%)
                  },
                }
              }}
              series={[{ name: chart.name, data: chart.data.map(point => parseFloat(point.y)) }]} // Converter de volta para float
              type="bar"
              height={400}
            />
          </div>
        ))
      )}
    </div>
    <hr></hr>
    <h1 align="center" style={{color:"Red"}}>Despesas</h1>
    <hr></hr>
      {loading ? (
        <div>A Carregar Despesas...</div>
      ) : (
        chartData.map((chart, index) => (
          <div key={index}>
            <h2>{chart.name}</h2>
            <Chart
              style={{ marginTop: '50px' }}
              options={{
                chart: {
                  id: `chart-${index}`,
                  type: 'bar',
                },
                dataLabels: {
                  enabled: true,
                  position: 'top',
                  formatter: function (val) {
                    return val;
                  },
                  offsetY: -20,
                  style: {
                    fontSize: '12px',
                    colors: ['#333']
                  }
                },
                xaxis: {
                  type: 'category',
                  title: {
                    text: 'Ano-Mês'
                  },
                  categories: chart.data.map(point => formatMonthYear(point.x.toString())), // Converte para string para exibição
                  labels: {
                    formatter: val => val
                  }
                },
                yaxis: {
                  title: {
                    text: 'Montantes'
                  },
                  labels: {
                    formatter: val => parseFloat(val).toFixed(2) // Arredondar para zero casas decimais
                  }
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                      position: 'top'
                    },
                    horizontal: false, // Define as barras na vertical
                    columnWidth: '10%', // Define a largura das barras em percentual (80%)
                  },
                }
              }}
              series={[{ name: chart.name, data: chart.data.map(point => parseFloat(point.y)) }]} // Converter de volta para float
              type="bar"
              height={400}
            />
          </div>
        ))
      )}
     {/* Final Graphs */}
  
      {/* <div><pre>{JSON.stringify(chartData, null, 2) }</pre></div> */}

      </Layout>
    );
  };

export default TransactionsStats;