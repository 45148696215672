import React,{useState,useEffect} from "react";
import {Progress} from 'antd';
import {useNavigate,useLocation} from "react-router-dom";
import axios from 'axios';
import Spinner from "../components/Spinner";

import Layout from "../components/Layout/Layout";

const ProcessingRefreshExchanges = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const exchangeIdUpdate = data.exchangeID;
  const exchangeNameUpdate = data.exchangeName;

  const [progress, setProgress] = useState(0);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    const getAllRefreshExchangeStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = axios.post('/exchanges/refresh-exchange/'+ user._id +'/'+ exchangeIdUpdate,{
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
  
    // Clean up the interval when the component unmounts
    return () => getAllRefreshExchangeStats();

  },[exchangeIdUpdate])

  useEffect(() => {
    const duration = 60 * 1000; // 60 seconds in milliseconds
    const interval = 100; // Update the progress every 100 milliseconds

    const steps = duration / interval;
    let currentStep = 0;

    const updateProgress = () => {
      currentStep += 1;
      const newProgress = (currentStep / steps) * 100;
      setProgress(newProgress);

      if (currentStep < steps) {
        // Continue updating the progress
        setTimeout(updateProgress, interval);
      }else {
        // Navigate to another page after the progress is complete
        navigate('/Exchanges');
      }
    };

    // Start updating the progress
    updateProgress();

    // Clean up the interval when the component unmounts
    return () => clearTimeout(updateProgress);
  }, [navigate]);

  return (
    <Layout>
      {loading && <Spinner/>}
    <div>
      <h1 align="center">A Actualizar Exchange - {exchangeNameUpdate}</h1>
      <br/>
      <Progress
        percent={parseFloat(progress).toFixed(0)} 
        status="active"
      />
    </div>

    </Layout>
  );
};

export default ProcessingRefreshExchanges;