import React,{useState,useEffect} from "react";
import {Form, Input, message, Select,Switch, Button, Space,Card} from 'antd';
import {useNavigate,useLocation,Link} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import moment from 'moment';

const NewInvestmentsReforces = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const investmentDataUpdate = data;
  const investmentIdUpdate = data.investmentID;
  const investmentNameUpdate = data.investmentName;
  const investmentTypeUpdate = data.investmentType;
  const investmentDescriptionUpdate = data.investmentDescription;
  const brokerNameUpdate = data.brokerName;
  const currencyUpdate = data.currency;
  const investmentRecursiveUpdate = data.investmentRecursive;
  const investmentIndexPercentageUpdate = data.investmentIndexPercentage;
  const investmentStartDateUpdate = data.investmentStartDate;
  const investmentEndDateUpdate = data.investmentEndDate;
  const investmentMobilizationUpdate = data.investmentMobilization;
  const investmentEmissionUpdate = data.investmentEmission;
  const frequencyUpdate = data.frequency;
  const goalNameUpdate = data.goalName;
  const categoryNameUpdate = data.categoryName;
  const investmentObjectUpdate = data.investmentObject;
  const investmentObjectYearUpdate = data.investmentObjectYear;
  // const statusUpdate = data.status;
  const codeSquareUpdate = data.codeSquare;
  const balanceUpdate= data.balance;

  //Handler Test Console Log Values
  const onFinish = (values) => {
    console.log({
      userid:user._id,
      amount: values.amount,
      codeSquare: codeSquareUpdate,
      investmentName:investmentNameUpdate,
      quantity:1,
      currency:currencyUpdate,
      date: values.date,
      monthyear: moment(values.date).format("MMM YYYY"),
      monthyearnumber: moment(values.date).format("YYYYMM"),
      tags: "REFORÇO",
      isReforceOrWithdraw: true,
      typeInvestment:investmentTypeUpdate,
      investmentIndexPercentage:investmentIndexPercentageUpdate,
      goalName:goalNameUpdate,
      categoryName:categoryNameUpdate
    });
  };

  const user = JSON.parse(localStorage.getItem('user'))

  if (balanceUpdate===0){
    var investmentTypeDetail = investmentTypeUpdate;
    var investmentMobilizationDetail = investmentMobilizationUpdate;
    var investmentRecursiveDetail  = investmentRecursiveUpdate;
    var codeSquareDetail  = codeSquareUpdate;
    var investmentNameDetail  = investmentNameUpdate;
    var currencyDetail = currencyUpdate;
    var investmentIndexPercentageDetail   = investmentIndexPercentageUpdate;
    var goalNameDetail   = goalNameUpdate;
    var categoryNameDetail   = categoryNameUpdate;
    var balanceDetail   = balanceUpdate;
    var frequencyDetail = frequencyUpdate;
  }else{
    var investmentTypeDetail = investmentTypeUpdate[0];
    var investmentMobilizationDetail = investmentMobilizationUpdate[0];
    var investmentRecursiveDetail  = investmentRecursiveUpdate[0];
    var codeSquareDetail  = codeSquareUpdate[0];
    var investmentNameDetail  = investmentNameUpdate[0];
    var currencyDetail = currencyUpdate[0];
    var investmentIndexPercentageDetail   = investmentIndexPercentageUpdate[0];
    var goalNameDetail   = goalNameUpdate[0];
    var categoryNameDetail   = categoryNameUpdate[0];
    var balanceDetail   = balanceUpdate;
    var frequencyDetail = frequencyUpdate;
  }
  
  // var statusDetail = statusUpdate;

  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Handler Fund Family DropList ****************************************************************************************
    // useEffect(() => {
    //   const getAllAccounts = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/accounts/get-oneaccount', {
    //         userid:user._id,
    //         _id: accountNameUpdate
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllAccount(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       //message.error('You are not Admin User');
    //     }
    //   };  
    //   getAllAccounts();
    // },[accountNameUpdate])

  
  //Handlers User  **************************************************************************************************************************
  let now = new Date();
  let today = new Date(now.getTime());

  const handleSubmitSubscription = async (values) => {
   
  let tax=0.00000000
  let isPayedReceivedInicial=true

  console.log("---------")
  if(frequencyDetail==="Mensal"){
    tax=parseFloat((((values.investmentIndexPercentage/100)/12)*(1-0.28)))
    console.log("tax/Mensal:" + tax)
  }else if (frequencyDetail==="Trimestral"){
    tax=parseFloat((((values.investmentIndexPercentage/100)/4)*(1-0.28)))
    console.log("tax/Trimestral:" + tax)
  }else if (frequencyDetail==="Semestral"){
    tax=parseFloat((((values.investmentIndexPercentage/100)/2)*(1-0.28)))
    console.log("tax/Semestral:" + tax)
  }else{
    tax=parseFloat((((values.investmentIndexPercentage/100)/1)*(1-0.28)))
    console.log("tax/Anual:" + tax)
  };

  try{
  
    let tagsInicial=["REFORÇO"]
    let tagsSeguintes=["JUROS VENCIDOS"]
    let amountOriginal=values.amount
    let totalAcumulative=0.00000

    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);

      const res = axios.post('/investment-transactions/add-investment-transaction', {
        userid:user._id,
        amount: values.amount,
        codeSquare: codeSquareDetail ,
        squareName: investmentNameDetail ,
        quantity:1,
        currency:currencyDetail ,
        date: values.date,
        monthyear: moment(values.date).format("MMM YYYY"),
        monthyearnumber: moment(values.date).format("YYYYMM"),
        tags: tagsInicial,
        isReforceOrWithdraw: true,
        typeInvestment:investmentTypeDetail ,
        investmentIndexPercentage:investmentIndexPercentageDetail ,
        goalName:goalNameDetail ,
        categoryName:categoryNameDetail,
        isPayedReceived: isPayedReceivedInicial,
        comments: values.comments,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );

    totalAcumulative= parseFloat(amountOriginal*tax)
    console.log("totalAcumulative:" + totalAcumulative)

    values.amount=parseFloat(amountOriginal*tax)

    amountOriginal= parseFloat(amountOriginal)+parseFloat(totalAcumulative)
    console.log("amountOriginal:" + amountOriginal)

    values.months=values.months-1
    tagsInicial=tagsSeguintes
    isPayedReceivedInicial=false

    setLoading(false);
    message.success('Conta Investimento Actualizada com sucesso!');
    navigate('/Investments');
    console.log(values)

    }catch (error){
      setLoading(false);
      message.error('Failed to industry');
      //message.error('You are not Admin User');
    }
  }

  // Nome da Instituição Financeira ou Broker
  let investmenObjectInformationHTML;
  console.log(investmentTypeDetail);
  if (investmentTypeDetail==="Certificados de Aforro" || investmentTypeDetail==="Depósitos a Prazo" || investmentTypeDetail==="Fundos de Investimento"
  || investmentTypeDetail==="Acções" || investmentTypeDetail==="ETFs"
  ) {
    investmenObjectInformationHTML =
    <Space direction="vertical">
      <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da Instituição Financeira ou Broker</h5>

        <Form.Item label="" name="brokerName">
          <Input defaultValue={brokerNameUpdate} 
          type="text"
          style={{
            width: '220px',
          }} 
          />
        </Form.Item>

        <Form.Item label="Moeda-Portfolio" name="currency">
            <Select defaultValue={currencyUpdate} 
                style={{
                  width: '220px',
                }} 
            >
              <Select.Option value="EUR">EUR</Select.Option>
              <Select.Option value="USD">USD</Select.Option>
              {/* <Select.Option value="CHF">CHF</Select.Option>
              <Select.Option value="GBP">GBP</Select.Option>
              <Select.Option value="HKD">HKD</Select.Option>
              <Select.Option value="BRL">BRL</Select.Option>
              <Select.Option value="JPY">JPY</Select.Option>              
              <Select.Option value="Other">Other</Select.Option> */}
            </Select>
        </Form.Item>
      </Space>
        ;
  }
  else {
  }

  // SWITCH Dados do Empréstimo
  let investmentInformationHTML;
  if (investmentTypeDetail==="Certificados de Aforro") {
    investmentInformationHTML =
    <Space direction="vertical">

      </Space>
        ;
  }
  else {
    investmentInformationHTML =
      <br></br>;
      <p>Sem dados</p>;
      <Form.Item hidden="true">
      <Input 
          type="text"
      />
  </Form.Item>;
  }

  // SWITCH Dados do Empréstimo
  if (investmentTypeDetail==="Depósitos a Prazo" ) {
    investmentInformationHTML =
    <Space direction="vertical">

              <Form.Item label="Taxa Anual Nominal Bruta (TANB)" name="investmentIndexPercentage">
                <Input
                disabled="true" 
                type="number"
                defaultValue={investmentIndexPercentageUpdate} 
                suffix="%"
                placeholder='0%'
                size="large" 
                style={{
                  width: '220px',
                  fontSize:"32px"
                }} 
                />
              </Form.Item>

                <Form.Item label="Investimento com renovação automática?" name="investmentRecursive" valuePropName="checked">
                  <Switch disabled="true" checkedChildren="Sim" unCheckedChildren="Não" defaultChecked={investmentRecursiveDetail}/>
                </Form.Item>

                <Form.Item label="Investimento autoriza mobilização antecipada?" name="investmentMobilization" valuePropName="checked">
                  <Switch disabled="true" checkedChildren="Sim" unCheckedChildren="Não" defaultChecked={investmentMobilizationDetail}/>
                </Form.Item>
      </Space>
        ;
  } else if (investmentTypeDetail==="Certificados de Aforro") {
    investmentInformationHTML =
    <Space direction="vertical">
        <Form.Item label="Emissão Série" name="investmentEmission">
          <Select
            disabled="true"
            defaultValue={investmentEmissionUpdate} 
            style={{
              width: '220px',
            }}
          >
            <Select.Option value="EMISSÃO SÉRIE F (2.5%)">EMISSÃO SÉRIE F (2.5%)</Select.Option>
            <Select.Option value="EMISSÃO SÉRIE E (3.5%)">EMISSÃO SÉRIE E (3.5%)</Select.Option>
            <Select.Option value="EMISSÃO SÉRIE D">EMISSÃO SÉRIE D</Select.Option>
            <Select.Option value="EMISSÃO SÉRIE C">EMISSÃO SÉRIE C</Select.Option>
            <Select.Option value="EMISSÃO SÉRIE B">EMISSÃO SÉRIE B</Select.Option>
            <Select.Option value="EMISSÃO SÉRIE A">EMISSÃO SÉRIE A</Select.Option>            
            <Select.Option value="OUTRA">OUTRA</Select.Option>
          </Select>
      </Form.Item>


        <Form.Item label="Taxa Anual Nominal Bruta (TANB)" name="investmentIndexPercentage">
          <Input
          disabled="true" 
          type="number" 
          defaultValue={investmentIndexPercentageUpdate} 
          suffix="%"
          placeholder='0%'
          size="large" 
          style={{
            width: '220px',
            fontSize:"32px"
          }} 
          />
        </Form.Item>

          {/* <Form.Item label="Investimento com renovação automática?" name="investmentRecursive" valuePropName="checked">
            <Switch 
                          style={{
                            width: '100px',
                          }} 
            checkedChildren="Sim" unCheckedChildren="Não" defaultChecked/>
          </Form.Item>

          <Form.Item label="Investimento autoriza mobilização antecipada?" name="investmentmobilization" valuePropName="checked">
            <Switch 
                          style={{
                            width: '100px',
                          }} 
            checkedChildren="Sim" unCheckedChildren="Não" defaultChecked/>
          </Form.Item> */}

    </Space>
    ;
  }
  else if (investmentTypeDetail==="Arte" || investmentTypeDetail==="Outro") {
    investmentInformationHTML =
    investmenObjectInformationHTML =
    <Space direction="vertical">
      <Form.Item label="Nome do Objecto Investido" name="investmentObject">
      <Input defaultValue={investmentObjectUpdate} 
      type="text"
      style={{
        width: '220px',
      }} 
      />
    </Form.Item>
    <Form.Item label="Ano do Objecto Investido" name="investmentObjectYear">
      <Input defaultValue={investmentObjectYearUpdate} 
      type="number"
      style={{
        width: '220px',

      }} 
      />
    </Form.Item>
      </Space>
        ;
  }
  else {
    investmentInformationHTML =
      <br></br>;
      <p>Sem dados</p>;
      <Form.Item hidden="true">
      <Input 
          type="text"
      />
  </Form.Item>;
  }


  let labelFrequency;
  if (investmentTypeDetail==="Certificados de Aforro" || investmentTypeDetail==="Depósitos a Prazo") {
    labelFrequency = "Frequência de pagamento de juros";
  }
  else if (investmentTypeDetail==="Arte" || investmentTypeDetail==="Outro") {
    labelFrequency = "Frequência que quer reavaliar os seus investimentos";
  }
  else {
    labelFrequency = "Frequência de que tenciona investir";
  }

  return (
    <Layout>
      {loading && <Spinner/>}
            <div className="accountpage">
        <div></div>
        <div><h1>Reforço - {investmentNameUpdate}</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={handleSubmitSubscription} 
          >

        <Form.Item 
        style={{ marginTop:10 }} 
        label="Valor do reforço" 
        name="amount"
        rules={[
          { type: 'number',
            min: 0.01,
            message: "Minimo de investimento superior a 0.01",
            transform(value) {
              return Number(value)
            },
          }
        ]}
        >
          <Input
            suffix="€"
            min={0}
            placeholder='0.00'
            type="number" 
            size="large" 
            style={{fontSize:"32px",width:"220px"}}
          />
        </Form.Item>

        <Form.Item 
            label="Data do reforço no seu investimento" 
            name="date" 
            rules={[
              { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                ]}>
            <Input style={{fontSize:"18px",width:"220px"}} type="date"/>
            </Form.Item>

        <Form.Item style={{ marginTop:30 }} label="Comnetários" name="comments">
              <TextArea
                showCount
                maxLength={500}
                style={{
                  width: '220px',
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                />
        </Form.Item>

          {/* <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da conta investimento</h5>

          <Form.Item label="" name="investmentName">
            <Input
            disabled="true" 
            defaultValue={investmentNameUpdate} 
            type="text"
            />
          </Form.Item> */}

          {/* <Form.Item style={{ marginTop:30 }} label="Observações" name="investmentDescription">
              <TextArea
                defaultValue={investmentDescriptionUpdate} 
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                />
            </Form.Item> */}

            {/* <h5 style={{ marginBottom:20, marginTop:20 }}>Tipo de Investimento</h5>

            <Form.Item label="" name="investmentType">
              <Input defaultValue={investmentTypeUpdate}
              disabled="true" 
              type="text"
              style={{
                width: '500px',
              }} 
              />
            </Form.Item> */}

            {/* <Space direction="horizontal">
              {investmenObjectInformationHTML}
            </Space> */}

          {/* <h5 style={{ marginBottom:20, marginTop:20 }}>Objectivo para que Investe</h5>

          <Form.Item label="" name="goalName">
              <Select defaultValue={goalNameUpdate}>
                <Select.Option value="Fundo de Emergência">Fundo de Emergência</Select.Option>
                <Select.Option value="Reforma">Reforma</Select.Option>
                <Select.Option value="Habitação">Habitação</Select.Option>
                <Select.Option value="Educação">Educação</Select.Option>
                <Select.Option value="Saúde">Saúde</Select.Option>
                <Select.Option value="Viagem">Viagem</Select.Option>
                <Select.Option value="Automóvel">Automóvel</Select.Option>
                <Select.Option value="Mota">Mota</Select.Option>
                <Select.Option value="Filantropia">Filantropia</Select.Option>
                <Select.Option value="Outro">Outro</Select.Option>
              </Select> 
            </Form.Item> */}

          {/* {/* <h5 style={{ marginBottom:20, marginTop:20 }}>Dados do Investimento</h5> */}

            {investmentInformationHTML}

          {/* <h5 style={{ marginBottom:20, marginTop:20 }}>Deseja arquivar a conta de Investimento"</h5>

          <Form.Item label="" name="status" valuePropName="checked">
              <Switch 
              style={{
                width: '100px',
              }} 
              checkedChildren="Sim" unCheckedChildren="Não"/>
            </Form.Item>  */}

          {/* <Form.Item 
            label="Data começo do investimento" 
            name="investmentStartDate" >
                <Input 
                defaultValue={investmentStartDateUpdate}  
                style={{fontSize:"18px"}}/>
            </Form.Item> */}

            {/* <Form.Item label={labelFrequency} name="frequency" >
              <Select defaultValue={frequencyUpdate} >
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item> */}

            {/* <Form.Item 
            label="Data que pensa mobilizar totalmente o investimento" 
            name="investmentEndDate">
                <Input style={{fontSize:"18px"}} 
                defaultValue={investmentEndDateUpdate}  
                // defaultValue={date.toLocaleDateString('en-CA')} 
                // onChange={onSetDate}
                />
            </Form.Item> */}

            <Button style={{ width: "100%" , background: "green", color: "white", marginTop: 30, marginBottom: 30}}  htmlType='submit'>
              REFORÇO DO INVESTIMENTO
              </Button>
              <Link to="/Investments">
                <Button style={{ width: "100%" }} type="primary">
                  CANCELAR
                </Button>
                </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(accountDataUpdate, null, 2) }</pre></div> */}
    </Layout>
  );
};

export default NewInvestmentsReforces;