import React,{useState,useEffect} from "react";
import {Form, Input, message,Select, Button,Card} from 'antd';
import {useNavigate,useLocation,Link} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';

const EditInvestmentStocks = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  console.log(data);

  const investmentIdUpdate = data.investmentId;
  const investmentNameUpdate = data.investmentName;
  const investmentTypeUpdate = data.investmentType;
  const investmentDescriptionUpdate = data.investmentDescription;
  const brokerNameUpdate = data.brokerName;
  const currencyUpdate = data.currency;
  const goalNameUpdate = data.goalName;
  const statusUpdate = data.status;

  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Handler Fund Family DropList ****************************************************************************************
    // useEffect(() => {
    //   const getAllAccounts = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/accounts/get-oneaccount', {
    //         userid:user._id,
    //         _id: accountNameUpdate
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllAccount(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       message.error('You are not Admin User');
    //     }
    //   };  
    //   getAllAccounts();
    // },[accountNameUpdate])

  
  //Handlers User  **************************************************************************************************************************
  let now = new Date();
  let today = new Date(now.getTime());

  const handleSubmitSubscription = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);

    if (values.status===true)
    {
      const res = await axios.post('/investments/edit-investment', {
        payload:{
          ...values,
          userid: user._id,
          status : false
        },
        _id: data.investmentId,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
    }else{
      const res = await axios.post('/investments/edit-investment', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: data.investmentId,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
    }

    setLoading(false);
    message.success('Conta Investimento Actualizada com sucesso!');
    navigate('/Investments');
    console.log(values)

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  return (
    <Layout>
      <div className="accountpage">
        <div></div>
        <div><h1>Editar Conta Investimento - {investmentNameUpdate}</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={handleSubmitSubscription} 
          >

          <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da conta investimento</h5>

          <Form.Item label="" name="investmentName">
            <Input 
            defaultValue={investmentNameUpdate} 
            type="text"
            />
          </Form.Item>

          <Form.Item style={{ marginTop:30 }} label="Observações" name="investmentDescription">
              <TextArea
                defaultValue={investmentDescriptionUpdate} 
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                />
            </Form.Item>

      <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da Instituição Financeira ou Broker</h5>

      <Form.Item label="" name="brokerName">
        <Input defaultValue={brokerNameUpdate} 
        type="text"
        style={{
          width: '500px',
        }} 
        />
      </Form.Item>

      <Form.Item label="Currency" name="currency">
          <Select 
          disabled="true"
          defaultValue={currencyUpdate} 
              style={{
                width: '500px',
              }} 
          >
            <Select.Option value="EUR">EUR</Select.Option>
            <Select.Option value="USD">USD</Select.Option>
            {/* <Select.Option value="CHF">CHF</Select.Option>
            <Select.Option value="GBP">GBP</Select.Option>
            <Select.Option value="HKD">HKD</Select.Option>
            <Select.Option value="BRL">BRL</Select.Option>
            <Select.Option value="JPY">JPY</Select.Option>              
            <Select.Option value="Other">Other</Select.Option> */}
          </Select>
      </Form.Item>

            <h5 style={{ marginBottom:20, marginTop:20 }}>Tipo de Investimento</h5>

            <Form.Item label="" name="investmentType">
              <Input defaultValue={investmentTypeUpdate}
              disabled="true" 
              type="text"
              style={{
                width: '500px',
              }} 
              />
            </Form.Item>

            {/* <Space direction="horizontal">
              {investmenObjectInformationHTML}
            </Space> */}

          <h5 style={{ marginBottom:20, marginTop:20 }}>Objectivo para que Investe</h5>

          <Form.Item label="" name="goalName">
              <Select defaultValue={goalNameUpdate}>
                <Select.Option value="Fundo de Emergência">Fundo de Emergência</Select.Option>
                <Select.Option value="Reforma">Reforma</Select.Option>
                <Select.Option value="Habitação">Habitação</Select.Option>
                <Select.Option value="Educação">Educação</Select.Option>
                <Select.Option value="Saúde">Saúde</Select.Option>
                <Select.Option value="Viagem">Viagem</Select.Option>
                <Select.Option value="Automóvel">Automóvel</Select.Option>
                <Select.Option value="Mota">Mota</Select.Option>
                <Select.Option value="Filantropia">Filantropia</Select.Option>
                <Select.Option value="Outro">Outro</Select.Option>
              </Select> 
            </Form.Item>

          {/* <h5 style={{ marginBottom:20, marginTop:20 }}>Deseja arquivar a conta de Investimento</h5>

          <Form.Item label="" name="status" valuePropName="checked">
              <Switch 
              style={{
                width: '100px',
              }} 
              checkedChildren="Sim" unCheckedChildren="Não"/>
            </Form.Item> */}

          {/* <Form.Item 
            label="Data começo do investimento" 
            name="investmentStartDate" >
                <Input 
                defaultValue={investmentStartDateUpdate}  
                style={{fontSize:"18px"}}/>
            </Form.Item> */}

            {/* <Form.Item label={labelFrequency} name="frequency" >
              <Select defaultValue={frequencyUpdate} >
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item> */}

            {/* <Form.Item 
            label="Data que pensa mobilizar totalmente o investimento" 
            name="investmentEndDate">
                <Input style={{fontSize:"18px"}} 
                defaultValue={investmentEndDateUpdate}  
                // defaultValue={date.toLocaleDateString('en-CA')} 
                // onChange={onSetDate}
                />
            </Form.Item> */}

              <Button style={{ width: "100%" , background: "green",color:"white", marginTop: 30, marginBottom: 30}}  htmlType='submit'>
                EDITAR
              </Button>
              <Link to="/Investments">
                <Button style={{ width: "100%" }} type="primary">
                  CANCELAR
                </Button>
                </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(accountDataUpdate, null, 2) }</pre></div> */}
    </Layout>
  );
};

export default EditInvestmentStocks;