import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import {Steps,Form,Input,Button,Space,Radio,message,Row} from 'antd';
import { CheckCircleOutlined,ProfileOutlined, SettingOutlined} from '@ant-design/icons';

import Layout from "../components/Layout/Layout";
import {useNavigate} from "react-router-dom";

import axios from "axios";
import Spinner from '../components/Spinner';

const NewAccounts = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //loading Apis
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState('')

  const { TextArea } = Input;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const [current, setCurrent] = useState(0);
  const [typeDetails, setTypeDetails] = useState(null);
  const [infoDetails, setInfoDetails] = useState(null);

 
  const onFinishTypeForm=(values) => {
    setTypeDetails(values);
    setCurrent(1);
  }

  const onChangeAccount = ({ target: { value } }) => {
    console.log('card choose: ', value);
    setTypeDetails(value);
    setCurrent(1);
  };

  const onFinishInfoForm=(values) => {
    setInfoDetails(values);
    setCurrent(2);
  }

  const forms =[
    <TypeForm 
      onFinish={onFinishTypeForm} 
      initialValues={typeDetails}/>,
    <InfoForm 
      onFinish={onFinishInfoForm} 
      initialValues={infoDetails}/>,
    <FinishForm />
  ]

  const isStepDisabled=(stepnumber)=>{
    if(stepnumber===0){
      return false
    }
    if(stepnumber===1){
      return typeDetails === null
    }
    if(stepnumber===2){
      return typeDetails === null || infoDetails === null
    }
  }

  const user = JSON.parse(localStorage.getItem('user'))

  let range = {min: 1, max: 999999999}
let delta = range.max - range.min

const codeSquare = Math.round(range.min + Math.random() * delta)

  const submitHandler = async (values) => {

    try{
      setLoading(true);
      const res = await axios.post('/accounts/add-account', {
        userid:user._id,
        accountType:typeDetails,
        accountName:infoDetails.accountName,
        accountDescription:infoDetails.accountDescription,
        accountIBAN:infoDetails.accountIBAN,
        codeSquare: codeSquare
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Conta Criada com Sucesso!');
      navigate('/NewBalanceInitial', 
      {state:{
        codeSquare:codeSquare,
        accountName:infoDetails.accountName,
        accountType:typeDetails}
      });

      console.log(res.data);
    }catch (error){
      setLoading(false);
      message.error('Algo deu errado!');
    }
  }

  //Handler Test Console Log Values

  // const onFinish = (values) => {
  //   console.log({
  //     accountType:typeDetails,
  //     accountName:infoDetails.accountName,
  //     accountDescription:infoDetails.accountDescription,
  //     accountIBAN:infoDetails.accountIBAN,
  //   });
  // };

  function TypeForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "100%" }} onFinish={onFinish} initialValues={initialValues}>
        <h4 align="center">Escolha a sua conta</h4>

        <hr/>
        <br/>

        <Radio.Group onChange={onChangeAccount} style={{ display: "flex", justifyContent: "center"}} >
              <Space direction="vertical" align='center' size="middle">
                <Row gutter={[8,24]}>
                <Radio.Button value="Conta-Corrente" style={{ width: "100%", marginTop: 5, textAlign: "center" }}>Conta-Corrente</Radio.Button>
                <Radio.Button value="Conta-Despesas" style={{ width: "100%", marginTop: 5, textAlign: "center"  }}>Conta-Despesas</Radio.Button>
                <Radio.Button value="Cartão Alimentação" style={{ width: "100%", marginTop: 5, textAlign: "center" }}>Cartão Alimentação</Radio.Button>
                {/* <Radio.Button value="Cartão de Crédito" style={{ display: "flex", justifyContent: "center", width:500, marginTop: 5 }}>Cartão de Crédito</Radio.Button> */}
                <Radio.Button value="Outra" style={{ width: "100%", marginTop: 5, textAlign: "center"  }}>Outra</Radio.Button>
                </Row>
              </Space>
            </Radio.Group>

        <Link to="/accounts">
        <Button style={{ width: "100%", marginTop: 30 }} type="primary">
          CANCELAR
        </Button>
        </Link>

    </Form>
    )
  }

  function InfoForm({onFinish, initialValues}){
    return (
      <Form layout="vertical" style={{ width: "100%" }} onFinish={onFinish} initialValues={initialValues}>
        <h4 align="center">Conte-nos mais sobre sua nova conta</h4>
        <hr/>
        <br/>
      <Form.Item label="Nome da Conta ou do seu Banco" name={"accountName"} 
        rules={[{
          required:true, 
          message:"Por favor, insira o nome que quer dar à sua conta"
        }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Descrição" name="accountDescription">
          <TextArea
            showCount
            maxLength={500}
            style={{
              height: "100%",
              marginBottom: 24,
            }}
            type="text"
            />
      </Form.Item>

      <Form.Item label="IBAN" name={"accountIBAN"} 
      >
        <Input />
      </Form.Item>

      <Button style={{ width: "100%" , background: "green", marginTop: 30, marginBottom: 30}} type="primary" htmlType='submit'>
      CRIAR
      </Button>
      <Link to="/accounts">
        <Button style={{ width: "100%" }} type="primary">
          CANCELAR
        </Button>
        </Link>
    </Form>)
  }

  function FinishForm(){
    return (<>
        <div>
          <Space direction="vertical">
            <h2>Parabéns, sua nova conta foi criada com sucesso!</h2>
            <br/>
            <Button style={{ width: "100%" }}  type="primary" onClick={submitHandler}>
              IR PARA A DASHBOARD
          </Button>
          </Space>
        </div>
    </>)
  }

  return (
    <Layout>
          <div aling="center" className='registerAccount'>
            {loading && <Spinner/>}
            <Steps 
              style={{ padding: "10x 16px"}}
              onChange={setCurrent}
              current={current}
              >
              <Steps.Step disabled={isStepDisabled(0)} title='Tipo de Conta' icon={<ProfileOutlined />} />
              <Steps.Step disabled={isStepDisabled(1)} title='Configuração da Conta' icon={<SettingOutlined />} />
              <Steps.Step disabled={isStepDisabled(2)} title='Fim' icon={<CheckCircleOutlined />} />
            </Steps>
            </div>
            <div className="registerAccount-page">
              {forms[current]}
            </div>
            {/* <div><pre>{JSON.stringify(typeDetails, null, 2) }</pre></div>
            <div><pre>{JSON.stringify(infoDetails, null, 2) }</pre></div> */}
    </Layout>
  );
};

export default NewAccounts;