import React,{useState,useEffect} from "react";
import {Input, message,Space,Button} from 'antd';

import Layout from "../components/Layout/Layout";
import Spinner from "../components/Spinner";
import moment from 'moment';
import {useNavigate,useLocation} from "react-router-dom";

import {
  SearchOutlined,
} from "@ant-design/icons";

const InvestorProfilesList = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const data = location.state;
  console.log(data);

  const navigate = useNavigate();

  const result = data.resultSelects.selectPortfolio;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allInvestorProfile, setAllInvestorProfile] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);


  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "userid",
      title: "Client Name",
      dataIndex: ["userid", "name"],
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.userid.name.localeCompare(b.userid.name),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.userid.name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "createdAt",
      title: "Investor Quiz Date",
      dataIndex: "createdAt",
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:MM")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      key: "selectPortfolio",
      title: "Risk Profile",
      dataIndex: "selectPortfolio",
      sorter: (a, b) => a.selectPortfolio.localeCompare(b.selectPortfolio),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.selectPortfolio.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.status === value;
      },
    },
  ];

  const user = JSON.parse(localStorage.getItem('user'))


  //useEffects Hook **************************************************************************************************************************
  //List of Users
  // useEffect(() => {
  //   const getAllInvestorProfiles = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/investorProfiles/get-investorProfile',
  //       {
  //         // userid:user._id,
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllInvestorProfile(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       // message.error('Fetch Issue With Sector');
  //       //message.error('You are not Admin User');
  //     }
  //   };  
  //   getAllInvestorProfiles();
  // },[])

  const submitHandler = async (values) => {
    try{
      navigate('/Investments')
    }catch (error){
      message.error('Something went wrong');
    }
  }

  //Handler Test Console Log Values
  const onFinish = (values) => {
    console.log({values});
  };
  

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>Recomendação para o seu Perfil de Investidor</h1></div>
        <div></div>
      </div>
      <hr></hr>
      <div className="accountpage">
      <div></div>
      <div>
      <Space direction="vertical">
            {result === undefined && (
              <c></c>
            )}
            {result === "Defensivo" && (
              <c><b>O Perfil mais adqueado para si, é o <i>"Defensivo"</i></b>
              <br/><br/>
              Portfolio composto por 80% Obrigações e 20% Ações
              <br/><br/>
              <b>Resultado</b>
              <br/>
              Para investidores cuja principal necessidade é a preservação de rendimentos e capital.</c>
            )}
            {result === "Conservador" && (
              <c><b>O Perfil mais adqueado para si, é o <i>"Conservador"</i></b>
              <br/><br/>
              Portfolio composto por 60% Obrigações e 40% Ações
              <br/><br/>
              <b>Resultado</b>
              <br/> 
              Para investidores que tenham como objetivo principal a preservação do capital com algum crescimento.</c>
            )}
            {result === "Moderado" &&(
              <c><b>O Perfil mais adqueado para si, é o <i>"Moderado"</i></b>
              <br/><br/>
              Portfolio composto por 40% Obrigações e 60% Ações
              <br/><br/>
              <b>Resultado</b>
              <br/> 
              Para investidores que procuram crescimento de capital a longo prazo e estão conscientes da volatilidade. </c>
            )}
            {result === "Agressivo" &&(
              <c><b>O Perfil mais adqueado para si, é o <i>"Agressivo"</i></b>
              <br/><br/>
              Portfolio composto por 20% Obrigações e 80% Ações
              <br/><br/>
              <b>Resultado</b> 
              <br/>
              Para investidores agressivos que procuram maximizar os retornos e podem tolerar uma volatilidade ligeiramente superior. </c>
            )}
            {result === "Arrojado" && (
              <c><b>O Perfil mais adqueado para si, é o <i>"Arrojado"</i></b>
              <br/><br/>
              Portfolio composto por 100% Ações
              <br/><br/>
              <b>Resultado</b> 
              <br/>
              Para investidores agressivos que procuram maximizar retornos e podem tolerar maior volatilidade.</c>
            )}
            <br/>
            <Button style={{ width: "100%" }} type="primary" onClick={submitHandler}>
              Obrigado!
          </Button>
          </Space>
          </div>
        <div></div>
        </div>
      {/* <div><pre>{JSON.stringify(data, null, 2) }</pre></div> */}

    </Layout>
  );

};

export default InvestorProfilesList;