import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Select, Table, Button, Space} from 'antd';
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";

import moment from 'moment';

const ExchangesArchive = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allSubscription, setAllSubscription] = useState([]);

  const [allAccountStats, setAllAccountStats] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Edit Transactions
    const [editable, setEditable] = useState(null);

    //Fields from table to View
    const [exchangeNameUpdate, setExchangeNameUpdate] = useState(null);
    const [brokerNameUpdate, setBrokerNameUpdate] = useState(null);
    const [frequencyUpdate, setFrequencyUpdate] = useState(null);
    const [statusUpdate, setStatusUpdate] = useState(null);
    const [exchangeDescriptionUpdate, setExchangeDescriptionUpdate] = useState(null);
    const [exchangeIdUpdate, setExchangeIdUpdate] = useState(null);
    const [cashExchangeIdUpdate, setCashExchangeIdUpdate] = useState(null);

  //table accounts
  const columns =[
    {
      key: "createdAt",
      title: "Data de criação",
      dataIndex: "createdAt",
      width: "5%",
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      key: "exchangeName",
      title: "Conta Exchange",
      dataIndex: ["exchangeName","exchangeName"],
      width: "5%",
      sorter: (a, b) => a.exchangeName.exchangeName.localeCompare(b.exchangeName.exchangeName),
    },
    {
      key: "brokerName",
      title: "Broker",
      dataIndex: "brokerName",
      width: "5%",
      sorter: (a, b) => a.brokerName.localeCompare(b.brokerName),
    },
    {
      key: "frequency",
      title: "Frequência",
      dataIndex: ["exchangeName","frequency"],
      width: "5%",
      sorter: (a, b) => a.exchangeName.frequency.localeCompare(b.exchangeName.frequency),
    },
    {
      key: "status",
      title: "Estado",
      dataIndex: "status",
      width: "5%",
      sorter: (a, b) => a.archive - b.archive,
      render: (value, record) => {
        if(record.archive === false )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Acções",
      dataIndex: "type",
      width: "5%",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-4">
            <Space size="middle">
            <EditOutlined
              onClick={() => {
                setEditable(record);
                setExchangeNameUpdate(record.exchangeName.exchangeName);
                setBrokerNameUpdate(record.exchangeName.brokerName);
                setFrequencyUpdate(record.exchangeName.frequency);
                setStatusUpdate(record.status);
                setExchangeDescriptionUpdate(record.exchangeName.exchangeDescription);
                setExchangeIdUpdate(record.exchangeName._id);
                setCashExchangeIdUpdate(record._id);
                setShowModal(true);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                // console.log(record);
                // navigate('/Transactions',
                // {
                //   state:{
                //     accountId: record.id,
                //     accountDescription: record.accountName           
                //   }
                // });
              }}
            />
            </Space>
          </div>
        );
      },
    }
  ];

  //useEffect Hook
  useEffect(() => {
    const getAllSubscription = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/cash-exchanges/get-cash-exchange', {
          userid:user._id,
          archive: true,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSubscription(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        // //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllSubscription();
  },[])


  //Handlers User  **************************************************************************************************************************
  const handleSubmitAccount = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/exchanges/edit-exchange', {
      payload:{
        archive: false,
        status: true,
        userid: user._id,
      },
      _id: exchangeIdUpdate,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    const res2 = await axios.post('/cash-exchanges/edit-cash-exchange', {
      payload:{
        archive: false,
        userid: user._id,
      },
      _id: cashExchangeIdUpdate,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Conta Exchange Activada novamente com sucesso!');
    setShowModal(false);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);
    console.log(values)
    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  const reload=()=>window.location.reload();

  const handleClose = () => {
    setShowModal(false)
    setExchangeNameUpdate(null);
    setBrokerNameUpdate(null);
    setFrequencyUpdate(null);
    setStatusUpdate(null);
    setExchangeDescriptionUpdate(null);
    setExchangeIdUpdate(null);
    setCashExchangeIdUpdate(null);
    // reload();
  };

  // const onFinish = (values) => {
  //   console.log({values
  //   });
  // };

  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Arquivo - Contas Exchanges</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      {/* <hr></hr> */}
      <div className="content">
        <br></br>
        <Table 
        columns={columns}
        dataSource={allSubscription}
        pagination={false}
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
      </div>
      {/* <div><pre>{JSON.stringify(allSubscription, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title="Activar Conta Exchange"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleSubmitAccount} style={{padding:"32px 16px"}}>
          <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da Conta Exchange</h5>

          <Form.Item label="Nome da Conta Exchange" name="exchangeName">
            <Input defaultValue={exchangeNameUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Broker</h5>

          <Form.Item label="" name="brokerName">
            <Input defaultValue={brokerNameUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <Form.Item style={{ marginTop:30 }} label="Observações" name="exchangeDescription">
            <TextArea defaultValue={exchangeDescriptionUpdate} 
            disabled="true"
              showCount
              maxLength={500}
              style={{
                height: 100,
                marginBottom: 24,
              }}
              type="text"
              />
          </Form.Item>

          <Form.Item label="Frequência" name="frequency">
            <Select defaultValue={frequencyUpdate} 
            disabled="true">
              <Select.Option value="Mensal">Mensal</Select.Option>
              <Select.Option value="Trimestral">Trimestral</Select.Option>
              <Select.Option value="Semestral">Semestral</Select.Option>
              <Select.Option value="Anual">Anual</Select.Option>
            </Select>
          </Form.Item>

          {/* <div><pre>{JSON.stringify(editable, null, 2) }</pre></div> */}
          
            <div>
              <button 
              type="submit" 
              className="btn btn-primary" 
              onClick={handleClose}
               style={{
                  width: '100%',
                }}>
                {" "}
                ACTIVAR CONTA EXCHANGE NOVAMENTE
              </button>
            </div>
          </Form>
      </Modal>

    </Layout>
  );
};

export default ExchangesArchive;