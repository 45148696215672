import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Table, Switch,Button} from 'antd';

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {useNavigate} from "react-router-dom";

import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";

const Sector = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allCategory, setAllSector] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Fields from table to View
  const [categoryNameUpdate, setCategoryNameUpdate] = useState(null);
  const [categoryNamePTUpdate, setCategoryNamePTUpdate] = useState(null);
  const [isBusinessUpdate, setIsBusinessUpdate] = useState(null);
  const [isIncomeOrExpenseUpdate, setIsIncomeOrExpenseUpdate] = useState(null);
  const [isCreditCardUpdate, setIsCreditCardUpdate] = useState(null);
  const [statusUpdate, setStatusUpdate] = useState(true);
  const [visibleGraphsUpdate, setVisibleGraphsUpdate] = useState(true);
  
  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "categoryName",
      title: "Category Name",
      dataIndex: "categoryName",
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.categoryName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "categoryNamePT",
      title: "Category Name PT",
      dataIndex: "categoryNamePT",
      sorter: (a, b) => a.categoryNamePT.localeCompare(b.categoryNamePT),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.categoryNamePT.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
        key: "isIncomeOrExpense",
        title: "Income/Expense",
        dataIndex: "isIncomeOrExpense",
        sorter: (a, b) => a.isIncomeOrExpense-b.isIncomeOrExpense,
        render: (value, record) => {
          if(record.isIncomeOrExpense === false )
            return (
              <span style={{color: "#39A939"}}>INCOME</span>
            );
  
            return (
              <span style={{color: "#FF3234"}}>EXPENSE</span>
            );
        },
        filters: [
          { text: "EXPENSE", value: true },
          { text: "INCOME", value: false },
        ],
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value, record) => {
          console.log(typeof value)
          return record.isIncomeOrExpense === value;
        },
      },
      {
        key: "isBusiness",
        title: "isBusiness",
        dataIndex: "isBusiness",
        sorter: (a, b) => a.isBusiness-b.isBusiness,
        render: (value, record) => {
          if(record.isBusiness === true )
            return (
              <span style={{color: "#39A939"}}>Sim</span>
            );
  
            return (
              <span style={{color: "#FF3234"}}>Não</span>
            );
        },
        filters: [
          { text: "SIM", value: true },
          { text: "NÃO", value: false },
        ],
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value, record) => {
          console.log(typeof value)
          return record.isBusiness === value;
        },
      },
      {
        key: "visibleGraphs",
        title: "visibleGraphs",
        dataIndex: "visibleGraphs",
        sorter: (a, b) => a.visibleGraphs-b.visibleGraphs,
        render: (value, record) => {
          if(record.visibleGraphs === true )
            return (
              <span style={{color: "#39A939"}}>Sim</span>
            );
  
            return (
              <span style={{color: "#FF3234"}}>Não</span>
            );
        },
        filters: [
          { text: "SIM", value: true },
          { text: "NÃO", value: false },
        ],
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value, record) => {
          console.log(typeof value)
          return record.visibleGraphs === value;
        },
      },
            {
        key: "isCreditCard",
        title: "isCreditCard",
        dataIndex: "isCreditCard",
        sorter: (a, b) => a.isCreditCard-b.isCreditCard,
        render: (value, record) => {
          if(record.isCreditCard === true )
            return (
              <span style={{color: "#39A939"}}>Sim</span>
            );
  
            return (
              <span style={{color: "#FF3234"}}>Não</span>
            );
        },
        filters: [
          { text: "SIM", value: true },
          { text: "NÃO", value: false },
        ],
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value, record) => {
          console.log(typeof value)
          return record.isCreditCard === value;
        },
      },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Actions",
      dataIndex: "type",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
            <EditOutlined
              onClick={() => {
                    setEditable(record);
                    setCategoryNameUpdate(record.categoryName);
                    setCategoryNamePTUpdate(record.categoryNamePT);
                    setIsBusinessUpdate(record.isBusiness);
                    setIsIncomeOrExpenseUpdate(record.isIncomeOrExpense);
                    setStatusUpdate(record.status);
                    setIsCreditCardUpdate(record.isCreditCard);
                    setVisibleGraphsUpdate(record.visibleGraphs);
                    setShowModal(true);
              }}
            />
            <DeleteOutlined
                className="mx-2"
                onClick={() => {
                handleDelete(record);
            }}
          />
          </div>
        );
      },
    }
  ];

  const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllCategory = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/categories/get-category',
        {
          // userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllSector(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        // message.error('Fetch Issue With Sector');
        // message.error('You are not Admin User');
      }
    };  
    
    getAllCategory();

    // Clean up the interval when the component unmounts
    //return () => getAllCategory();
  },[])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitCategory = async (values) => {

  try{
    const promises = [];
  
    const user = JSON.parse(localStorage.getItem('user'))

    if(editable){
      setLoading(true);
      const result = axios.post('/categories/edit-category', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: editable._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Category Updated Successfull');

    }else{
      setLoading(true);
      const result = axios.post('/categories/add-category', {
        ...values, 
        userid:user._id, 
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Category Added Successfull');
    }
    setShowModal(false);
    setEditable(null);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);

    }catch (error){
      setLoading(false);
      // message.error('Failed to sector');
      // message.error('You are not Admin User');
    }
  }

    //delete handler
    const handleDelete = async (record) => {
      try {
          setLoading(true);
          await axios.post("/categories/delete-category", {
          _id: record._id,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          message.success("Category Deleted!");
          setTimeout(function () {
          window.location.reload(true);
          message.success('reload 1 segundo');
          }, 1000);
      } catch (error) {
          setLoading(false);
          console.log(error);
          message.error("unable to delete");
      }
      };

  //Handler Test Console Log Values
  const onFinish = (values) => {
    console.log({values});
  };

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setEditable(null);
    setCategoryNameUpdate(null);
    setCategoryNamePTUpdate(null);
    setIsBusinessUpdate(null);
    setIsIncomeOrExpenseUpdate(null);
    setStatusUpdate(null);
    setIsCreditCardUpdate(null);
    setVisibleGraphsUpdate(null);
  };

  //Handler Close Modal
  const handleClose = () => {
    setShow(false);
  };
  

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Definições - Categorias</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
          <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
          >+ Nova Categoria
        </Button>
        </div>

      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <hr></hr>
      <div className="content">
        <Table 
        columns={columns}
        dataSource={allCategory}
        pagination={false}
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
        
      </div>
      {/* <div><pre>{JSON.stringify(allCategory, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title={editable ? "Edit Category" : "Add Category" }
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form 
            layout="vertical" 
            onFinish={handleSubmitCategory} 
            style={{padding:"32px 16px"}}
          >
            <Form.Item label="Category Name" name="categoryName">
              <Input 
                type="text"
                defaultValue={categoryNameUpdate}
                />
            </Form.Item>
            <Form.Item label="Category Name PT" name="categoryNamePT">
              <Input 
                type="text"
                defaultValue={categoryNamePTUpdate}
                />
            </Form.Item>
            <Form.Item label="Income/Expense" name="isIncomeOrExpense" valuePropName="checked">
              <Switch
                checkedChildren="Expense" 
                unCheckedChildren="Income" 
                defaultChecked={isIncomeOrExpenseUpdate}
              />
            </Form.Item>
            <Form.Item label="Is a Business Category" name="isBusiness" valuePropName="checked">
              <Switch 
                defaultChecked={isBusinessUpdate}
              />
            </Form.Item>
            <Form.Item label="Is a Credit Card Category" name="isCreditCard" valuePropName="checked">
              <Switch 
                defaultChecked={isCreditCardUpdate}
              />
            </Form.Item>
            <Form.Item label="Visible Graphs" name="visibleGraphs" valuePropName="checked">
              <Switch 
                defaultChecked={visibleGraphsUpdate}
              />
            </Form.Item>
            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch 
                defaultChecked={statusUpdate}
              />
            </Form.Item>

            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" onClick={handleClose}>
                {" "}
                SAVE
              </button>
            </div>
            {/* <div><pre>{JSON.stringify(allCategory, null, 2) }</pre></div> */}
            
          </Form>
      </Modal>

    </Layout>
  );

};

export default Sector;