import React,{useState,useEffect} from "react";
import {message, Button, Tooltip,Row,Card} from 'antd';
import MySubscription from "./../components/Assets/subscription.png";
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { CheckCircleFilled,ClockCircleFilled,ExclamationCircleFilled, HddOutlined, SettingOutlined, UnorderedListOutlined } from "@ant-design/icons";

import  Chart  from "react-apexcharts";

const Accounts = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const categoryName= "Subscriptions";
  const [allCategory, setAllCategory] = useState([]);

  const { Meta } = Card;

  // const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  // const [allAccount, setAllAccount] = useState([]);

  const [allSubscriptionStats, setAllSubscriptionStats] = useState([]);
  const [allNoSubscriptionStats, setAllNoSubscriptionStats] = useState([]);

    // //Fields from Graphs to View
    // const [allSubscriptionNameWeights, setAllSubscriptionNameWeights] = useState(null);
    // const [allSubscriptionTypeWeights, setAllSubscriptionTypeWeights] = useState(null);

  // const [show, setShow] = useState(false);

  // const { TextArea } = Input;

  //   //Edit Transactions
  //   const [editable, setEditable] = useState(null);

  //   //Fields from table to View
  //   const [accountNameUpdate, setAccountNameUpdate] = useState(null);
  //   const [accountDescriptionUpdate, setAccountDescriptionUpdate] = useState(null);
  //   const [accountIBANUpdate, setAccountIBANUpdate] = useState(null);
  //   const [accountTypeUpdate, setAccountTypeUpdate] = useState(null);
  //   const [statusUpdate, setStatusUpdate] = useState(null);

  // //table accounts
  // const columns =[
  //   {
  //     key: "createdAt",
  //     title: "Data de criação",
  //     dataIndex: "createdAt",
  //     width: "5%",
  //     render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
  //     sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  //   },
  //   {
  //     key: "accountName",
  //     title: "Conta",
  //     dataIndex: "accountName",
  //     width: "5%",
  //     sorter: (a, b) => a.accountName.localeCompare(b.accountName),
  //   },
  //   {
  //     key: "accountDescription",
  //     title: "Descrição",
  //     dataIndex: "accountDescription",
  //     width: "5%",
  //     sorter: (a, b) => a.accountDescription.localeCompare(b.accountDescription),
  //   },
  //   {
  //     key: "accountType",
  //     title: "Tipo de Conta",
  //     dataIndex: "accountType",
  //     width: "5%",
  //     sorter: (a, b) => a.accountType.localeCompare(b.accountType),
  //   },
  //   {
  //     key: "accountIBAN",
  //     title: "IBAN",
  //     dataIndex: "accountIBAN",
  //     width: "5%"
  //   },
  //   {
  //     key: "status",
  //     title: "Estado",
  //     dataIndex: "status",
  //     width: "5%",
  //     sorter: (a, b) => a.status - b.status,
  //     render: (value, record) => {
  //       if(record.status === true )
  //         return (
  //           <span style={{color: "#39A939"}}>Active</span>
  //         );

  //         return (
  //           <span style={{color: "#FF3234"}}>Inactive</span>
  //         );
  //     },
  //     filters: [
  //       { text: "Active", value: true },
  //       { text: "Inactive", value: false },
  //     ],
  //     filterIcon: () => {
  //       return <SearchOutlined />;
  //     },
  //     onFilter: (value, record) => {
  //       console.log(typeof value)
  //       return record.status === value;
  //     },
  //   },
  //   {
  //     title: "Acções",
  //     dataIndex: "type",
  //     width: "5%",
  //     render: (type, record) => {
  //       // if(type === "Income" )
  //       return (
  //         <div className="mx-4">
  //           <Space size="middle">
  //           <EditOutlined
  //             onClick={() => {
  //               setEditable(record);
  //               setAccountNameUpdate(record.accountName);
  //               setAccountDescriptionUpdate(record.accountDescription);
  //               setAccountIBANUpdate(record.accountIBAN);
  //               setAccountTypeUpdate(record.accountType);
  //               setStatusUpdate(record.status);
  //               setShowModal(true);
  //             }}
  //           />
  //           <UnorderedListOutlined
  //             onClick={() => {
  //               console.log(record);
  //               navigate('/Transactions',
  //               {
  //                 state:{
  //                   accountId: record.id,
  //                   accountDescription: record.accountName           
  //                 }
  //               });
  //             }}
  //           />
  //           </Space>
  //         </div>
  //       );
  //     },
  //   }
  // ];

  useEffect(() => {
    const getAllSubscriptionStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-usersubscriptions', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSubscriptionStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllSubscriptionStats();
  },[])

  useEffect(() => {
    const getAllNoSubscriptionStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-usernosubscriptions', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllNoSubscriptionStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllNoSubscriptionStats();
  },[])

    //Handler Sectors DropList ****************************************************************************************
    useEffect(() => {
      const getAllCategories = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/categories/get-findCategoryName', {
            userid:user._id,
            categoryName: categoryName
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllCategory(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          ////message.error('You are not Admin User');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllCategories();
    },[categoryName])

  var categoryID = allCategory[0];

  const [weightSubject, setWeightSubject]= useState([]);
  const [weightMarks, setWeightMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllSubscriptionNameWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/transactions/get-ytdsubscriptionname',
    {
      userid:user._id,
      categoryName:categoryID
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    // setAllSubscriptionNameWeights(res.data);
    console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].subscriptionName);
    sMarks.push(res.data[i].amount);
    }
    setWeightSubject(sSubject);
    setWeightMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllSubscriptionNameWeights();
  },[categoryID])

  const [weightSubscriptionTypeSubject, setWeightSubscriptionTypeSubject]= useState([]);
  const [weightSubscriptionTypeMarks, setWeightSubscriptionTypeMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllSubscriptionTypeWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/transactions/get-ytdsubscriptiontype',
    {
      userid:user._id,
      categoryName:categoryID
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    // setAllSubscriptionTypeWeights(res.data);
    console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].subscriptionType);
    sMarks.push(res.data[i].amount);
    }
    setWeightSubscriptionTypeSubject(sSubject);
    setWeightSubscriptionTypeMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllSubscriptionTypeWeights();
  },[categoryID])

  //Handlers User  **************************************************************************************************************************
  // const handleSubmitAccount = async (values) => {

  // try{
  
  //   const user = JSON.parse(localStorage.getItem('user'))

  //   setLoading(true);
  //   const res = await axios.post('/accounts/edit-account', {
  //     payload:{
  //       ...values,
  //       userid: user._id,
  //     },
  //     _id: editable._id.accountStats,
  //   },
  //   {
  //     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //   }
  //   );
  //   setLoading(false);
  //   message.success('Conta Actualizada com sucesso!');
  //   setShowModal(false);
  //   setTimeout(function () {
  //     window.location.reload(true);
  //   }, 1000);
  //   console.log(values)

  //   }catch (error){
  //     setLoading(false);
  //     // message.error('Failed to industry');
  //     //message.error('You are not Admin User');
  //   }
  // }

  // const reload=()=>window.location.reload();

  // const handleClose = () => {
  //   setShowModal(false)
  //   setAccountNameUpdate(null);
  //   setAccountDescriptionUpdate(null);
  //   setAccountIBANUpdate(null);
  //   setAccountTypeUpdate(null);
  //   setStatusUpdate(null);
  //   reload();
  // };

  // const onFinish = (values) => {
  //   console.log({values
  //   });
  // };

  return (
    <Layout>
      {loading && <Spinner/>}
                  {/* Start Header */}
                  <div className="accountpage">
        <div></div>
        <div><h1>Minhas Subscrições</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
            <Button
              shape="round"
              className="btn btn-primary btn-block"
              style={{ background: "green", borderColor: "green" }}
              onClick={() => navigate('/NewSubscriptions')}
            >
              + Nova Subscrição
            </Button>
      </div>

      <div className="accountpagebuttonsAll">
        <Button
          shape="round"
          className="btn btn-primary btn-block"
          style={{ background: "blue", borderColor: "blue" }}
          onClick={() => navigate('/TransactionsProductsAll')} 
        >
          Todos os Movimentos
        </Button>
      </div>
        {/* Final Header */}
        <hr></hr>
        {/* Start Graphs */}
      <div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
      <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
        <div className="chart-container">
        <Chart
        align="center" 
        type="donut"
        series={weightMarks}                
        options={{
          responsive: [
            {
              breakpoint: 281, // iPhone SE width
              options: {
                chart: {
                  width: '100%',
                  height: 150 // Adjust height for iPhone SE
                }
              }
            },
            {
              breakpoint: 361, // iPhone SE width
              options: {
                chart: {
                  width: '100%',
                  height: 230 // Adjust height for iPhone SE
                }
              }
            },
            {
              breakpoint: 481, // iPhone SE width
              options: {
                chart: {
                  width: '100%',
                  height: 250 // Adjust height for iPhone SE
                }
              }
            },
            {
              breakpoint: 769, // iPhone 12 Mini width
              options: {
                chart: {
                  width: '100%',
                  height: 275 // Adjust height for iPhone 12 Mini
                }
              }
            },
            {
              breakpoint: 768, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            },
            {
              breakpoint: 1024, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            },
            {
              breakpoint: 1200, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            },
            {
              breakpoint: 2000, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            }
          ],
          labels: weightSubject,
          noData: { text: "No Data" }, 
          plotOptions: {
          pie: {
            donut: {
            labels: {
              show: true,
              name: {
              formatter: function(val) {
                return val
              }
              }
            }
            }
          }
          },
          dataLabels: { enabled: true },
          legend: { show: false } // Hide legends
        }}
        />
        </div>
        <h4 align="center">Subscrições YTD</h4>
        </div>
        <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
        <div className="chart-container">
        <Chart
        align="center" 
        type="donut"
        series={weightSubscriptionTypeMarks}                
        options={{
          responsive: [
            {
              breakpoint: 281, // iPhone SE width
              options: {
                chart: {
                  width: '100%',
                  height: 150 // Adjust height for iPhone SE
                }
              }
            },
            {
              breakpoint: 361, // iPhone SE width
              options: {
                chart: {
                  width: '100%',
                  height: 230 // Adjust height for iPhone SE
                }
              }
            },
            {
              breakpoint: 481, // iPhone SE width
              options: {
                chart: {
                  width: '100%',
                  height: 250 // Adjust height for iPhone SE
                }
              }
            },
            {
              breakpoint: 769, // iPhone 12 Mini width
              options: {
                chart: {
                  width: '100%',
                  height: 275 // Adjust height for iPhone 12 Mini
                }
              }
            },
            {
              breakpoint: 768, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            },
            {
              breakpoint: 1024, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            },
            {
              breakpoint: 1200, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            },
            {
              breakpoint: 2000, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            }
          ],
          labels: weightSubscriptionTypeSubject,
          noData: { text: "No Data" }, 
          plotOptions: {
          pie: {
            donut: {
            labels: {
              show: true,
              name: {
              formatter: function(val) {
                return val
              }
              }
            }
            }
          }
          },
          dataLabels: { enabled: true },
          legend: { show: false } // Hide legends
        }}
        />
        </div>
        <h4 align="center">Tipo de Subscrições</h4>
        </div>
        </div>
      {/* Final Graphs */}
      <hr></hr>
      <div className="content">
        {/* <Table 
        columns={columns}
        dataSource={allAccount}
        pagination={false} 
        /> */}
        
            <Row gutter={[8,24]}     
                style={{
                // height: 200,
                justifyContent: "center"
              }}>
                  {
                  allSubscriptionStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    hoverable
                    style={{ width: 350 , marginTop: 16, marginRight: "10px"}}
                    cover={[
                      <div align="center">
                        <br></br>
                        <div align="right" style={{marginRight: "20px"}}>
                        {opts.isPayedReceived
                          ? 
                          <Tooltip title="Pago">
                            <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
                          </Tooltip>
                          : 
                          <Tooltip title="Pendente">
                          <ExclamationCircleFilled className="mx-2" style={{color: "#FF3234"}}/>
                          </Tooltip>
                        }
                        </div>
                        <div>
                          <br></br>
                          <img alt="example" src={MySubscription}/>
                        </div>
                        <div>
                          <br></br>
                          <h4 className="price">{opts._id.subscriptionName}</h4>
                        </div>
                      </div>
                    ]}
                    actions={[
                      <Tooltip title="Editar">
                      <SettingOutlined key="setting" 
                      onClick={() => {
                        console.log(opts);
                        navigate('/EditSubscriptions',
                        {
                          state:{
                            subscriptionId:opts._id.subscriptionID,
                            subscriptionName:opts._id.subscriptionName,
                            subscriptionType:opts._id.subscriptionType,
                            subscriptionDescription:opts._id.subscriptionDescription,
                            frequency:opts._id.subscriptionFrequency,
                            months: opts._id.subscriptionMonths,
                            accountName:opts._id.accountName,
                            categoryName:opts._id.categoryName,
                            codeSquare: opts._id.codeSquare
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                      <Tooltip title="Transacções">
                      <UnorderedListOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/TransactionsProduct',
                        {
                          state:{
                            accountId:opts._id.accountName,
                            name:opts._id.subscriptionName,
                            codeSquare: opts._id.codeSquare,
                            categoryName: opts._id.categoryName
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                      <Tooltip title="Arquivar">
                      <HddOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/ArchiveSubscriptions',
                        {
                          state:{
                            subscriptionId:opts._id.subscriptionID,
                            subscriptionName:opts._id.subscriptionName,
                            subscriptionType:opts._id.subscriptionType,
                            subscriptionDescription:opts._id.subscriptionDescription,
                            frequency:opts._id.subscriptionFrequency,
                            months: opts._id.subscriptionMonths,
                            accountName:opts._id.accountName,
                            categoryName:opts._id.categoryName,
                            codeSquare: opts._id.codeSquare
                          }
                        });
                      }}
                      />
                      </Tooltip>
                    ]}
                    >
                    <Meta
                      description={[
                            <div align="center">
                                <h4 className="price">{opts._id.subscriptionType}</h4>
                            </div>
                            ]}
                          />
                      <br></br>
                      <div className="additional"  align="center">
                        <h4 className="price">Montante: <span className="quantity">{opts.amount.toLocaleString('pt-BR')} €</span></h4>
                        <hr></hr>
                        <h6 className="price">Data de Pagamento<br></br>{opts.dayMonthYearNumber}</h6>
                        {/* <p>Author: <span className="quantity">John Doe</span></p> */}
                      </div>
                    </Card>)
                  }
                                    {
                  allNoSubscriptionStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    hoverable
                    style={{ width: 350 , marginTop: 16, marginRight: "10px"}}
                    cover={[
                      <div align="center">
                        <br></br>
                        <div align="right" style={{marginRight: "20px"}}>
                        {opts.isPayedReceived
                          ? 
                          <Tooltip title="Pago">
                            <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
                          </Tooltip>
                          : 
                          <Tooltip title="Arquivar">
                          <ClockCircleFilled 
                          className="mx-2" 
                          style={{color: "black"}}
                          onClick={() => {
                            console.log(opts);
                            navigate('/ArchiveSubscriptions',
                            {
                              state:{
                                subscriptionId:opts._id.subscriptionID,
                                subscriptionName:opts._id.subscriptionName,
                                subscriptionType:opts._id.subscriptionType,
                                subscriptionDescription:opts._id.subscriptionDescription,
                                frequency:opts._id.subscriptionFrequency,
                                months: opts._id.subscriptionMonths,
                                accountName:opts._id.accountName,
                                categoryName:opts._id.categoryName,
                                codeSquare: opts._id.codeSquare
                              }
                            });
                          }}
                          />
                          </Tooltip>
                        }
                        </div>
                        <div>
                          <br></br>
                          <img alt="example" src={MySubscription}/>
                        </div>
                        <div>
                          <br></br>
                          <h4 className="price">{opts._id.subscriptionName}</h4>
                        </div>
                      </div>
                    ]}
                    actions={[
                      <SettingOutlined key="setting" 
                      onClick={() => {
                        console.log(opts);
                        navigate('/EditSubscriptions',
                        {
                          state:{
                            subscriptionId:opts._id.subscriptionID,
                            subscriptionName:opts._id.subscriptionName,
                            subscriptionType:opts._id.subscriptionType,
                            subscriptionDescription:opts._id.subscriptionDescription,
                            frequency:opts._id.subscriptionFrequency,
                            months: opts._id.subscriptionMonths,
                            accountName:opts._id.accountName,
                            categoryName:opts._id.categoryName,
                            codeSquare: opts._id.codeSquare
                          }
                        });
                      }}
                      />,
                      <HddOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/ArchiveSubscriptions',
                        {
                          state:{
                            subscriptionId:opts._id.subscriptionID,
                            subscriptionName:opts._id.subscriptionName,
                            subscriptionType:opts._id.subscriptionType,
                            subscriptionDescription:opts._id.subscriptionDescription,
                            frequency:opts._id.subscriptionFrequency,
                            months: opts._id.subscriptionMonths,
                            accountName:opts._id.accountName,
                            categoryName:opts._id.categoryName,
                            codeSquare: opts._id.codeSquare
                          }
                        });
                      }}
                      />
                    ]}
                    >
                    <Meta
                      description={[
                            <div align="center">
                                <h4 className="price">{opts._id.subscriptionType}</h4>
                            </div>
                            ]}
                          />
                      <br></br>
                      <div className="additional"  align="center">
                        <h4 className="price">Montante: <span className="quantity">0 €</span></h4>
                        <h6 className="price">Ultimo Pagamento: Não existe </h6>
                        {/* <p>Author: <span className="quantity">John Doe</span></p> */}
                      </div>
                    </Card>)
                  }
            </Row>

      </div>
      {/* <div><pre>{JSON.stringify(allNoSubscriptionStats, null, 2) }</pre></div> */}

    </Layout>
  );
};

export default Accounts;