import React,{useState,useEffect} from "react";
import {Form, Input, message, Select, Button, Card} from 'antd';
import {useNavigate,useLocation,Link} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';

const ArchiveUtilities = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const accountDataUpdate = data;
  const utilityIdUpdate = data.utilityID;
  const utilityNameUpdate = data.utilityName;
  const utilityTypeUpdate = data.utilityType; 
  const utilityDescriptionUpdate  = data.utilityDescription;
  const frequencyUpdate  = data.frequency;
  const monthsUpdate  = data.months;
  const accountNameUpdate  = data.accountName;
  const codeSquareUpdate  = data.codeSquare;

  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  const [allAccountStats, setAllAccountStats] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

      //Handler Fund Family DropList ****************************************************************************************
      useEffect(() => {
        const getAllAccounts = async (values) => {
          try{
            const user = JSON.parse(localStorage.getItem('user'))
            setLoading(true);
            const res = await axios.post('/accounts/get-oneaccount', {
              userid:user._id,
              _id: accountNameUpdate
            },
            {
              headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
            }
            );
            setLoading(false);
            setAllAccount(res.data);
            console.log(res.data);
          }catch (error){
            setLoading(false);
            //message.error('Fetch Issue With sector');
            // message.error('You are not Admin User');
          }
        };  

        // Clean up the interval when the component unmounts
        return () => getAllAccounts();
      },[accountNameUpdate])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitSubscriptions = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/utilities/edit-utility', {
      payload:{
        status: false,
        userid: user._id,
      },
      _id: data.utilityId,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Conta Arquivada com sucesso!');
    navigate('/Utilities');
    console.log(values)

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  const onFinish = (values) => {
    console.log({values
    });
  };


  return (
    <Layout>
            <div className="accountpage">
        <div></div>
        <div><h1>Arquivar Utility & Comunicação  - {utilityNameUpdate}</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={handleSubmitSubscriptions} 
          >
          <h5 style={{ marginBottom:30, marginTop:30 }}>Nome do Utility & Comunicação</h5>

          <Form.Item label="" name="utilityName">
            <Input defaultValue={utilityNameUpdate} disabled="true"
            type="text"
            />
          </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Tipo de Serviço</h5>

          <Form.Item label="" name="utilityType">
            <Input defaultValue={utilityTypeUpdate} disabled="true"
            type="text"
            />
          </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Conta Utilizada</h5>

          {/* <Form.Item label="" name="accountName">
              <Select defaultValue={accountNameUpdate} disabled="true">
                {
                allAccount.map((opts,i)=>
                  <Select.Option key={i} value={opts._id}>
                    {opts.accountName}
                  </Select.Option>)
                }
              </Select>
          </Form.Item> */}

          <Form.Item style={{ marginTop:30 }} label="Observações" name="utilityDescription">
            <TextArea defaultValue={utilityDescriptionUpdate} disabled="true"
              showCount
              maxLength={500}
              style={{
                height: 100,
                marginBottom: 24,
              }}
              type="text"
              />
          </Form.Item>

          <Form.Item label="Frequência" name="frequency">
            <Select defaultValue={frequencyUpdate} disabled="true">
              <Select.Option value="Mensal">Mensal</Select.Option>
              <Select.Option value="Trimestral">Trimestral</Select.Option>
              <Select.Option value="Semestral">Semestral</Select.Option>
              <Select.Option value="Anual">Anual</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Número de frequências" name="months">
                <Input defaultValue={monthsUpdate} disabled="true"/>
            </Form.Item>

            <Button style={{ width: "100%" , background: "green",color:"white", marginTop: 30, marginBottom: 30}}  htmlType='submit'>
            ARQUIVAR
              </Button>
              <Link to="/Taxes">
                <Button style={{ width: "100%" }} type="primary">
                  CANCELAR
                </Button>
                </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(accountDataUpdate, null, 2) }</pre></div> */}
    </Layout>
  );
};

export default ArchiveUtilities;