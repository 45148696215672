import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Select, Table, Switch, Button, Space,Tooltip,Tag,DatePicker,Col,Typography,Card,Row} from 'antd';

import {useNavigate,useLocation} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { EditOutlined, SearchOutlined, CheckCircleFilled,ExclamationCircleFilled,DeleteOutlined } from "@ant-design/icons";

import moment from 'moment';

const Transactions = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const accountNamePass = data.accountId;
  const accountDescriptionPass = data.accountDescription
  const isCreditCardPass = data.isCreditCard[0]

  console.log(data);

  const { Title, Text } = Typography;

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  const { TextArea } = Input;
  const {RangePicker} = DatePicker;

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Add Tags
  const [tags, setTags] = useState([]);

  //Fields from table to View
  const [amountUpdate, setAmountUpdate] = useState();
  const [dateUpdate, setDateUpdate] = useState();
  const [currencyUpdate, setCurrencyUpdate] = useState();
  const [isPayedReceivedUpdate, setIsPayedReceivedUpdate] = useState(false);
  const [accountNameUpdate, setAccountNameUpdate] = useState();
  const [accountTypeUpdate, setAccountTypeUpdate] = useState();
  const [categoryNameUpdate, setCategoryNameUpdate] = useState();
  const [commentsUpdate, setCommentsUpdate] = useState();
  const [isTravelUpdate, setIsTravelUpdate] = useState(false);
  const [tagsUpdate, setTagsUpdate] = useState();
  const [monthsUpdate, setMonthsUpdate] = useState();
  const [isIncomeOrExpenseUpdate, setIsIncomeOrExpenseUpdate] = useState();
  const [codeSquareUpdate, setCodeSquareUpdate] = useState();
  const [squareNameUpdate, setSquareNameUpdate] = useState();
  const [idUpdate, setIdUpdate] = useState();
  const [deleteOneTransactionUpdate, setDeleteOneTransactionUpdate] = useState(false);

  //Filters
  // const [accountName, setAccountFilter] = useState('all');
  const [isPayedReceived, setIsPayedReceivedFilter] = useState('all');
  const [isIncomeOrExpense, setIsIncomeOrExpenseFilter] = useState('all');
  const [frequency, setFrequency] = useState('month');
  const [selectedDate, setSelectedDate] = useState([]);

  //table accounts
  const columns =[
    //column ispayed
    {
      key:"isPayedReceived",
      title: "",
      dataIndex: "isPayedReceived",
      defaultSortOrder: 'descend',
      align: 'center',
      render: (value, record) => {
        if(record.isPayedReceived === true )
        
          return (
          <Tooltip title="Confirmado">
            <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
          </Tooltip>
          );

          return (
          <Tooltip title="Pendente">
            <ExclamationCircleFilled className="mx-2" style={{color: "#FF3234"}}/>
          </Tooltip>
          );
      },
      filters: [
        { text: "Pagamento Confirmado", value: true },
        { text: "Pagamento Não Confirmado", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.isPayed === value;
      },
    },
    //column date
    {
      key: "date",
      title: "Data",
      dataIndex: "date",
      defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD")}</span>,
      sorter: (a, b) => new Date(a.date) - new Date(b.date)
    },
    //column account
    {
      key:"account",
      title: "Conta",
      dataIndex: ["accountName", "accountName"],
      align: 'center',
      sorter: (a, b) => a.accountName.accountName.localeCompare(b.accountName.accountName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.accountName.accountName.toLowerCase().includes(value.toLowerCase());
      },
    },
      //column account
      {
        key:"accountType",
        title: "Tipo de Conta",
        dataIndex: ["accountName", "accountType"],
        align: 'center',
        sorter: (a, b) => a.accountName.accountType.localeCompare(b.accountName.accountType),
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => {
          return (
            <>
              <Input
                autoFocus
                placeholder="Type text here"
                value={selectedKeys[0]}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                  confirm({ closeDropdown: false });
                }}
                onPressEnter={() => {
                  confirm();
                }}
                onBlur={() => {
                  confirm();
                }}
              ></Input>
              <Button
                onClick={() => {
                  confirm();
                }}
                type="primary"
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters();
                }}
                type="danger"
              >
                Reset
              </Button>
            </>
          );
        },
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value, record) => {
          return record.accountName.accountType.toLowerCase().includes(value.toLowerCase());
        },
      },
       //column category
       {
        key:"category",
        title: "Categoria",
        dataIndex: ["categoryName", "categoryNamePT"],
        align: 'center',
        sorter: (a, b) => a.categoryName.categoryNamePT.localeCompare(b.categoryName.categoryNamePT),
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => {
          return (
            <>
              <Input
                autoFocus
                placeholder="Type text here"
                value={selectedKeys[0]}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                  confirm({ closeDropdown: false });
                }}
                onPressEnter={() => {
                  confirm();
                }}
                onBlur={() => {
                  confirm();
                }}
              ></Input>
              <Button
                onClick={() => {
                  confirm();
                }}
                type="primary"
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters();
                }}
                type="danger"
              >
                Reset
              </Button>
            </>
          );
        },
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value, record) => {
          return record.category.categoryNamePT.toLowerCase().includes(value.toLowerCase());
        },
      },
        //column category
        {
          key:"comments",
          title: "Comentários",
          dataIndex: ["comments"],
          align: 'center',
          sorter: (a, b) => a.comments.localeCompare(b.comments),
        },
        //column category
        // {
        //   key:"comments",
        //   title: "Comentários",
        //   dataIndex: ["comments"],
        //   width: "5%",
        //   align: 'center',
        //   sorter: (a, b) => a.comments.localeCompare(b.comments),
        //   filterDropdown: ({
        //     setSelectedKeys,
        //     selectedKeys,
        //     confirm,
        //     clearFilters,
        //   }) => {
        //     return (
        //       <>
        //         <Input
        //           autoFocus
        //           placeholder="Type text here"
        //           value={selectedKeys[0]}
        //           onChange={(e) => {
        //             setSelectedKeys(e.target.value ? [e.target.value] : []);
        //             confirm({ closeDropdown: false });
        //           }}
        //           onPressEnter={() => {
        //             confirm();
        //           }}
        //           onBlur={() => {
        //             confirm();
        //           }}
        //         ></Input>
        //         <Button
        //           onClick={() => {
        //             confirm();
        //           }}
        //           type="primary"
        //         >
        //           Search
        //         </Button>
        //         <Button
        //           onClick={() => {
        //             clearFilters();
        //           }}
        //           type="danger"
        //         >
        //           Reset
        //         </Button>
        //       </>
        //     );
        //   },
        //   filterIcon: () => {
        //     return <SearchOutlined />;
        //   },
        //   onFilter: (value, record) => {
        //     return record.comments.toLowerCase().includes(value.toLowerCase());
        //   },
        // },
      //column Income/expense
      {
        key:"isIncomeOrExpense",
        title: "",
        dataIndex: "isIncomeOrExpense",
        align: 'center',
        render: (isIncomeOrExpense, record) => {
          if(isIncomeOrExpense === true )
            return (
            <span style={{color: "#39A939"}}>RECEITA</span>
            );
            return (
            <span style={{color: "#FF3234"}}>DESPESA</span>
            );
        },
        filters: [
          { text: "RECEITA", value: false },
          { text: "DESPESA", value: true },
        ],
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value, record) => {
          console.log(typeof value)
          return record.isIncomeOrExpense === value;
        },
      },
    //column amount
    {
        key:"amount",
        title: "Montante",
        dataIndex: "amount",
        align: 'right',
        sorter: (a, b) => a.amount - b.amount,
        render: (amount, record) => {
          if(record.isIncomeOrExpense === true )
            return (
            <span style={{color: "#39A939"}}>{amount.toLocaleString('pt-PT')}  €</span>
            );
            return (
            <span style={{color: "#FF3234"}}>{amount.toLocaleString('pt-PT')}  €</span>
            );
        },
    },
    {
        key:"currency",
        title: "",
        dataIndex: "currency",
        align: 'center',
    },
    //column tags
    {
      key:"tags",
      title: "Tags",
      align: 'center',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 10 ? 'geekblue' : 'green';
            if (tag === 'loser') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Acções",
      dataIndex: "type",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-4">
            <Space size="middle">
            <EditOutlined
              onClick={() => {
                setEditable(record);
                setDateUpdate(record.date);
                setAmountUpdate(record.amount);
                setCurrencyUpdate(record.currency);
                setIsPayedReceivedUpdate(record.isPayedReceived);
                setAccountNameUpdate(record.accountName.accountName);
                setAccountTypeUpdate(record.accountName.accountType);
                setCategoryNameUpdate(record.categoryName.categoryNamePT);
                setCommentsUpdate(record.comments);
                setSquareNameUpdate(record.squareName);
                setIsTravelUpdate(record.isTravel);
                setMonthsUpdate(record.months);
                setTagsUpdate(record.tags);
                setShowModal(true);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                setEditable(record);
                setDateUpdate(record.date);
                setAmountUpdate(record.amount);
                setCurrencyUpdate(record.currency);
                setIsPayedReceivedUpdate(record.isPayedReceived);
                setAccountNameUpdate(record.accountName.accountName);
                setAccountTypeUpdate(record.accountName.accountType);
                setCategoryNameUpdate(record.categoryName.categoryNamePT);
                setIsIncomeOrExpenseUpdate(record.isIncomeOrExpense);
                setCodeSquareUpdate(record.codeSquare);
                setSquareNameUpdate(record.squareName);
                setIdUpdate(record._id);
                setShowDeleteModal(true);
              }}
            />
            </Space>
          </div>
        );
      },
    }
  ];

  useEffect(() => {
    const getAllTransactions = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-transaction',
        {
          userid:user._id,
          accountName: accountNamePass,
          isIncomeOrExpense: isIncomeOrExpense,
          frequency,
          selectedDate,
          isPayedReceived: isPayedReceived,
          isArchive: "all"
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllTransactions(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        //message.error('You are not Admin User');
      }
    };  

    getAllTransactions();
    
    // // Clean up the interval when the component unmounts
    // return () => getAllTransactions();

  },[accountNamePass,isPayedReceived,isIncomeOrExpense,frequency,selectedDate])

  //useEffect Hook
  useEffect(() => {
    const getAllAccount = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/accounts/get-account', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllAccount(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllAccount();
  },[])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitTransaction = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/transactions/edit-transaction', {
      payload:{
        ...values,
        userid: user._id,
      },
      _id: editable._id,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Transacção Actualizada com sucesso!');
    setShowModal(false);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      //message.error('You are not Admin User');
    }
  }

  //delete handler
  const handleDelete = async (record) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'))

        setLoading(true);

        if(record.deleteOnlyOneTransaction===true){
          await axios.post("/transactions/delete-alltransaction", {
            userid:user._id,
            isIncomeOrExpense: isIncomeOrExpenseUpdate,
            selectedDate: dateUpdate,
            codeSquare: codeSquareUpdate
          }
          )
          message.success("Transacções eliminada!");
        }
        else{
          await axios.post("/transactions/delete-transaction", {
            _id: idUpdate,
            });
          message.success("Transacção eliminada!");
        };
        setLoading(false);
        setShowDeleteModal(false);
        setTimeout(function () {
        window.location.reload(true);
        message.success('reload 1 segundo');
        }, 1000);
    } catch (error) {
        setLoading(false);
        console.log(error);
        message.error("unable to delete");
    }
    };

  //tags******************************************************************************************************

  const addTag = (e) => {
    if (e.key === "Tab") {
      if (e.target.value.length > 0) {
        setTags([...tags, e.target.value]);
        e.target.value = "";
      }
    }
  };

  const reload=()=>window.location.reload();

  const handleClose = () => {
    setShowModal(false)
    setEditable(null);
    setDateUpdate(null);
    setAmountUpdate(null);
    setCurrencyUpdate(null);
    setIsPayedReceivedUpdate(null);
    setAccountNameUpdate(null);
    setCategoryNameUpdate(null);
    setCommentsUpdate(null);
    setIsTravelUpdate(null);
    setTagsUpdate(null);
    setMonthsUpdate(null);
    setShowDeleteModal(false);
    setCodeSquareUpdate(null);
    setDeleteOneTransactionUpdate(null);
    setIsIncomeOrExpenseUpdate(null);
    setSquareNameUpdate(null);
    //reload();
  };

  const onChangeAccount = ({ target: { value } }) => {
    console.log('choose: ', value);
    setDeleteOneTransactionUpdate(value);
  };

  // const onFinish = (values) => {
  //   console.log({values
  //   });
  // };

  //Analytics
  const totalIncomesTurnover = allTransactions
  .filter((transaction) => transaction.isIncomeOrExpense === true)
  .reduce((acc, transaction) => acc + transaction.amount, 0);

  const totalExpensesTurnover = allTransactions
  .filter((transaction) => transaction.isIncomeOrExpense === false)
  .reduce((acc, transaction) => acc + transaction.amount, 0);

  // console.log("Confirmed:",totalAllTransactionsPayedTurnover);

  const totalAllTransactionsNotPayedTurnover = allTransactions
  .filter((transaction) => transaction.isPayedReceived === false)
  .reduce((acc, transaction) => acc + transaction.amount, 0);

  // console.log("Pending:",totalAllTransactionsNotPayedTurnover);

  const totalTransactions = totalIncomesTurnover-totalExpensesTurnover;

  const totalSavings = (1-(totalExpensesTurnover/totalIncomesTurnover))*100;

  // console.log("Total:",totalTransactions);

  // const totalNumberTransaction = allTransactions.length;

  // console.log("Transacções:",totalNumberTransaction);

  console.log("isCreditCardPass",isCreditCardPass)
  
  if(isCreditCardPass===true)
  {
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1> {accountDescriptionPass} - Transacções</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            align="right"
            shape="round"
            className="btn btn-primary"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
            >Voltar
          </Button>
        </div>
      <div className="accountpage">
        <div className="vertical-select">
          <h6>Confirmados/Pendentes</h6>
          <Select 
          style={{
            width: '180px',
          }} 
          value={isPayedReceived} onChange={(values) => setIsPayedReceivedFilter(values)}>
            <Select.Option value='all'>Todos</Select.Option>
            <Select.Option value='true'>Confirmado</Select.Option>
            <Select.Option value='false'>Pendente</Select.Option>
          </Select>
        </div>
        <div className="vertical-select">
          <h6>Despesas/Receitas</h6>
          <Select 
          style={{
            width: '180px',
          }} 
          value={isIncomeOrExpense} onChange={(values) => setIsIncomeOrExpenseFilter(values)}>
            <Select.Option value='all'>Todas</Select.Option>
            <Select.Option value='true'>Receitas</Select.Option>
            <Select.Option value='false'>Despesas</Select.Option>
          </Select>
        </div>
        <div className="vertical-select">
        <h6>Escolha a Frequência</h6>
          <Select 
          style={{
            width: '180px',
          }} 
          value={frequency} onChange={(values) => setFrequency(values)}>
            <Select.Option value='7'>Última semana</Select.Option>
            <Select.Option value='month'>Mês Actual</Select.Option>
            <Select.Option value='nextmonth'>Próximo Mês</Select.Option>
            <Select.Option value='lastmonth'>Último Mês</Select.Option>
            <Select.Option value='yeartodate'>YTD</Select.Option>
            <Select.Option value='thisyear'>Este Ano</Select.Option>
            <Select.Option value='custom'>Personalizado</Select.Option>
          </Select>
          {frequency === 'custom' && (
          <RangePicker 
            value={selectedDate} 
            onChange={(values) => { setSelectedDate(values)}}
          />
          )}
        </div>
      </div>
      <div >
      <Row gutter={[16,16]}
            style={{
            justifyContent: "center"
          }}>
            <Col xs={24} sm={12} md={12} lg={6}>
            <Card
            style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
            ><div>
              <Row align="middle" gutter={[24, 0]}>
              <Col 
              >
                {/* <span>{c.today}</span> */}
                <h4>Receitas</h4>
                <Title 
                level={1}
                style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
                >
                  {/* {c.title} <small className={c.bnb}>{c.persent}</small> */}
                  € {totalIncomesTurnover.toLocaleString("pt-BR")} 
                </Title>
              </Col>
                <Col>
                  {/* <div className="icon-box">{c.icon}</div> */}
                  {/* <div className="icon-box">ICON</div> */}
                </Col> 
                </Row>
            </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={6}>
            <Card
            style={{ width: "100%" , marginTop: 16}}
            ><div>
              <Row align="middle" gutter={[24, 0]}>
              <Col 

              >
                {/* <span>{c.today}</span> */}
                <h4>Despesas</h4>
                <Title 
                level={1}
                style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
                >
                  {/* {c.title} <small className={c.bnb}>{c.persent}</small> */}
                  € {totalExpensesTurnover.toLocaleString("pt-BR")} 
                </Title>
              </Col>
              <Col>
                  {/* <div className="icon-box">{c.icon}</div> */}
                  {/* <div className="icon-box">ICON</div> */}
              </Col> 
              </Row>
            </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={6}>
            <Card
            style={{ width: "100%" , marginTop: 16}}
            ><div>
              <Row align="middle" gutter={[24, 0]}>
              <Col 
              >
                {/* <span>{c.today}</span> */}
                <h4>Total Pendente</h4>
                <Title 
                level={1}
                style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
                >
                  {/* {c.title} <small className={c.bnb}>{c.persent}</small> */}
                  € {totalAllTransactionsNotPayedTurnover.toLocaleString("pt-BR")} 
                </Title>
              </Col>
              <Col>
                  {/* <div className="icon-box">{c.icon}</div> */}
                  {/* <div className="icon-box">ICON</div> */}
                </Col> 
                </Row>
            </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={6}>
            <Card
            style={{ width: "100%" , marginTop: 16}}
            ><div>
              <Row align="middle" gutter={[24, 0]}>
              <Col 
              >
                {/* <span>{c.today}</span> */}
                <h4>Saldo</h4>
                <Title 
                level={1}
                style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
                >
                  {/* {c.title} <small className={c.bnb}>{c.persent}</small> */}
                  {totalTransactions.toLocaleString("pt-BR")} €
                </Title>
              </Col>
              <Col>
                  {/* <div className="icon-box">{c.icon}</div> */}
                {/* <div className="icon-box">ICON</div> */}
              </Col> 
              </Row>
            </div>
            </Card>
            </Col>
            </Row>
      </div>
      <hr></hr>
      <div className="content">
          <Table
          size="small"
          columns={columns}
          dataSource={allTransactions}
          pagination={{ 
            position: ['bottomCenter'], 
            defaultPageSize: 25, 
            showSizeChanger: true, 
            pageSizeOptions: ['10', '25', '50', '100', '250'] 
          }}
          //pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50','100','200']}}
          scroll={{ x: 'max-content'}}
          className="custom-table" // Apply custom CSS class for styling
          />
      </div>
      {/* <div><pre>{JSON.stringify(allTransactions, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title="Editar Transacção"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleSubmitTransaction} style={{padding:"32px 16px"}}>
            <Space direction="horizontal">
              <Form.Item style={{ marginTop:10 }} name="amount">
                <Input
                  defaultValue={amountUpdate}
                  placeholder='€ 0.00'
                  type="text" 
                  size="large" 
                  style={{fontSize:"32px", color:"black"}}
                />
              </Form.Item>
              <Form.Item style={{ marginTop:10 }} name="currency">
                  <Input style={{fontSize:"32px", width: "100px"}} defaultValue={currencyUpdate} disabled="true"/>
              </Form.Item>
            </Space>
            <Form.Item label="Pagamento ou Recebimento confirmado" name="isPayedReceived" valuePropName="checked">
              <Switch defaultChecked={isPayedReceivedUpdate} checkedChildren="Sim" unCheckedChildren="Não"/>
            </Form.Item>
            <Form.Item label="Data" name="date">
                <Input
                    type="text"
                    defaultValue={moment(dateUpdate).format("DD/MM/YYYY")}
                    disabled="true"
                />
            </Form.Item>
            <Form.Item label="Conta" name="accountName">
                <Input defaultValue={accountNameUpdate} disabled="true"/>
            </Form.Item>
            <Form.Item label="Tipo de Conta" name="accountType">
                <Input defaultValue={accountTypeUpdate} disabled="true"/>
            </Form.Item>
            <Form.Item label="Categoria" name="categoryName">
              <Input defaultValue={categoryNameUpdate} disabled="true"/>
            </Form.Item>
            <Form.Item label="Obs" name="squareName">
              <Input defaultValue={squareNameUpdate} />
            </Form.Item>
            <Form.Item label="Comentários" name="comments">
              <TextArea
                defaultValue={commentsUpdate}
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                />
            </Form.Item>
            <Form.Item label="Tags" name="tags">
              <Select
                mode="tags"
                style={{
                  width: '100%',
                }}
                placeholder="Tags Mode"
                defaultValue={tagsUpdate}
                onChange={addTag}
                >
              </Select>
            </Form.Item>
            <div>
              <button 
              type="submit" 
              className="btn btn-primary" 
              onClick={handleClose}
               style={{
                  width: '100%',
                }}>
                {" "}
                GRAVAR
              </button>
            </div>
          </Form>
      </Modal>

      <Modal
        destroyOnClose={true} 
        title="Apagar Transacção"
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleDelete} 
          style={{padding:"32px 16px"}}>
            <Space direction="horizontal">
              <Form.Item style={{ marginTop:10 }} name="amount">
                <Input
                  defaultValue={amountUpdate}
                  placeholder='€ 0.00'
                  type="text" 
                  size="large" 
                  style={{fontSize:"32px", color:"black"}}
                  disabled="true"
                />
              </Form.Item>
              <Form.Item style={{ marginTop:10 }} name="currency">
                  <Input style={{fontSize:"32px", width: "100px"}} defaultValue={currencyUpdate} disabled="true"/>
              </Form.Item>
            </Space>
            <Form.Item label="Pagamento ou Recebimento confirmado" name="isPayedReceived" valuePropName="checked">
              <Switch defaultChecked={isPayedReceivedUpdate} checkedChildren="Sim" unCheckedChildren="Não" disabled="true"/>
            </Form.Item>
            <Form.Item label="Data" name="date">
                <Input
                    type="text"
                    defaultValue={moment(dateUpdate).format("DD/MM/YYYY")}
                    disabled="true"
                />
            </Form.Item>
            <Form.Item label="Conta" name="accountName">
                <Input defaultValue={accountNameUpdate} disabled="true"/>
            </Form.Item>
            <Form.Item label="Tipo de Conta" name="accountType">
                <Input defaultValue={accountTypeUpdate} disabled="true"/>
            </Form.Item>
            <Form.Item label="Categoria" name="categoryName">
              <Input defaultValue={categoryNameUpdate} disabled="true"/>
            </Form.Item>
            <Form.Item label="Obs" name="squareName">
              <Input defaultValue={squareNameUpdate} />
            </Form.Item>
            <Form.Item onChange={onChangeAccount} label="Esta despesa/receita e as futuras?" name="deleteOnlyOneTransaction" valuePropName="checked">
              <Switch defaultChecked={deleteOneTransactionUpdate} checkedChildren="Sim" unCheckedChildren="Não"/>
            </Form.Item>
            <div>
              <button 
              type="submit" 
              className="btn btn-primary" 
              onClick={handleClose}
               style={{
                  width: '100%',
                }}>
                {" "}
                APAGAR
              </button>
            </div>
          </Form>
      </Modal>

    </Layout>
  );
  }else{

  // ACCOUNT TRANSACTIONS
    return (
      <Layout>
        {loading && <Spinner/>}
        <div className="accountpage">
          <div>
          </div>
          <div><h1> {accountDescriptionPass} - Transacções</h1></div>
          <div></div>
        </div>
        <div className="accountpagebuttonsAll">
          <Button
            align="right"
            shape="round"
            className="btn btn-primary"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
            >Voltar
          </Button>
        </div>
        <div className="accountpage">
          <div className="vertical-select">
            <h6>Confirmados/Pendentes</h6>
            <Select 
            style={{
              width: '180px',
            }} 
            value={isPayedReceived} onChange={(values) => setIsPayedReceivedFilter(values)}>
              <Select.Option value='all'>Todos</Select.Option>
              <Select.Option value='true'>Confirmado</Select.Option>
              <Select.Option value='false'>Pendente</Select.Option>
            </Select>
          </div>
          <div className="vertical-select">
            <h6>Despesas/Receitas</h6>
            <Select 
            style={{
              width: '180px',
            }} 
            value={isIncomeOrExpense} onChange={(values) => setIsIncomeOrExpenseFilter(values)}>
              <Select.Option value='all'>Todas</Select.Option>
              <Select.Option value='true'>Receitas</Select.Option>
              <Select.Option value='false'>Despesas</Select.Option>
            </Select>
          </div>
          <div className="vertical-select">
          <h6>Escolha a Frequência</h6>
            <Select 
            style={{
              width: '180px',
            }} 
            value={frequency} onChange={(values) => setFrequency(values)}>
              <Select.Option value='7'>Última semana</Select.Option>
              <Select.Option value='month'>Mês Actual</Select.Option>
              <Select.Option value='nextmonth'>Próximo Mês</Select.Option>
              <Select.Option value='lastmonth'>Último Mês</Select.Option>
              <Select.Option value='yeartodate'>YTD</Select.Option>
              <Select.Option value='thisyear'>Este Ano</Select.Option>
              <Select.Option value='custom'>Personalizado</Select.Option>
            </Select>
            {frequency === 'custom' && (
            <RangePicker 
              value={selectedDate} 
              onChange={(values) => { setSelectedDate(values)}}
            />
            )}
          </div>
        </div>
        <div >
        <Row gutter={[16,16]}
              style={{
              justifyContent: "center"
            }}>
              <Col xs={24} sm={12} md={12} lg={6}>
              <Card
              style={{ width: "100%" , marginTop: 16}}
              ><div>
                <Row align="middle" gutter={[24, 0]}>
                <Col
                >
                  {/* <span>{c.today}</span> */}
                  <h4>Receitas</h4>
                  <Title 
                  level={1}
                  style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
                  >
                    {/* {c.title} <small className={c.bnb}>{c.persent}</small> */}
                    € {totalIncomesTurnover.toLocaleString("pt-BR")} 
                  </Title>
                </Col>
                <Col>
                  {/* <div className="icon-box">{c.icon}</div> */}
                  {/* <div className="icon-box">ICON</div> */}
                </Col> 
                </Row>
              </div>
              </Card>
              </Col>

              <Col xs={24} sm={12} md={12} lg={6}>
              <Card
              style={{ width: "100%" , marginTop: 16}}
              ><div>
                <Row align="middle" gutter={[24, 0]}>
                <Col 
                >
                  {/* <span>{c.today}</span> */}
                  <h4>Despesas</h4>
                  <Title 
                  level={1}
                  style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
                  >
                    {/* {c.title} <small className={c.bnb}>{c.persent}</small> */}
                    € {totalExpensesTurnover.toLocaleString("pt-BR")} 
                  </Title>
                </Col>
                <Col>
                  {/* <div className="icon-box">{c.icon}</div> */}
                  {/* <div className="icon-box">ICON</div> */}
                </Col> 
                </Row>
              </div>
              </Card>
              </Col>

              <Col xs={24} sm={12} md={12} lg={6}>
              <Card
              style={{ width: "100%" , marginTop: 16}}
              ><div>
                <Row align="middle" gutter={[24, 0]}>
                <Col
                >
                  {/* <span>{c.today}</span> */}
                  <h4>Total Pendente</h4>
                  <Title 
                  level={1}
                  style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
                  >
                    {/* {c.title} <small className={c.bnb}>{c.persent}</small> */}
                    € {totalAllTransactionsNotPayedTurnover.toLocaleString("pt-BR")} 
                  </Title>
                </Col>
                <Col>
                  {/* <div className="icon-box">{c.icon}</div> */}
                  {/* <div className="icon-box">ICON</div> */}
                </Col> 
                </Row>
              </div>
              </Card>
              </Col>

              <Col xs={24} sm={12} md={12} lg={6}>
              <Card
              style={{ width: "100%" , marginTop: 16}}
              ><div>
                <Row align="middle" gutter={[24, 0]}>
                <Col 
                >
                  {/* <span>{c.today}</span> */}
                  <h4>Saldo</h4>
                  <Title 
                  level={1}
                  style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
                  >
                    {/* {c.title} <small className={c.bnb}>{c.persent}</small> */}
                    {totalTransactions.toLocaleString("pt-BR")} €   <span style={{fontSize: "14px"}}>({totalSavings.toFixed(2).toLocaleString("pt-BR")} %)</span>
                  </Title>
                </Col>
                <Col xs={6}>
                  {/* <div className="icon-box">{c.icon}</div> */}
                  {/* <div className="icon-box">ICON</div> */}
                </Col> 
                </Row>
              </div>
              </Card>
              </Col>
              </Row>
        </div>
        <hr></hr>
        <div className="content">
          <Table
          // size="small"
          columns={columns}
          dataSource={allTransactions}
          pagination={{ 
            position: ['bottomCenter'], 
            defaultPageSize: 25, 
            showSizeChanger: true, 
            pageSizeOptions: ['10', '25', '50', '100', '250'] 
          }}
          scroll={{ x: 'max-content'}}
          className="custom-table" // Apply custom CSS class for styling
          />
        </div>
        {/* <div><pre>{JSON.stringify(allTransactions, null, 2) }</pre></div> */}
  
        <Modal
          destroyOnClose={true} 
          title="Editar Transacção"
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={false}
          style={{padding:"32px 16px"}}
          >
            <Form layout="vertical" onFinish={handleSubmitTransaction} style={{padding:"32px 16px"}}>
              <Space direction="horizontal">
                <Form.Item style={{ marginTop:10 }} name="amount">
                  <Input
                    defaultValue={amountUpdate}
                    placeholder='€ 0.00'
                    type="text" 
                    size="large" 
                    style={{fontSize:"32px", color:"black"}}
                  />
                </Form.Item>
                <Form.Item style={{ marginTop:10 }} name="currency">
                    <Input style={{fontSize:"32px", width: "100px"}} defaultValue={currencyUpdate} disabled="true"/>
                </Form.Item>
              </Space>
              <Form.Item label="Pagamento ou Recebimento confirmado" name="isPayedReceived" valuePropName="checked">
                <Switch defaultChecked={isPayedReceivedUpdate} checkedChildren="Sim" unCheckedChildren="Não"/>
              </Form.Item>
              <Form.Item label="Data" name="date">
                  <Input
                      type="text"
                      defaultValue={moment(dateUpdate).format("DD/MM/YYYY")}
                      disabled="true"
                  />
              </Form.Item>
              <Form.Item label="Conta" name="accountName">
                  <Input defaultValue={accountNameUpdate} disabled="true"/>
              </Form.Item>
              <Form.Item label="Tipo de Conta" name="accountType">
                  <Input defaultValue={accountTypeUpdate} disabled="true"/>
              </Form.Item>
              <Form.Item label="Categoria" name="categoryName">
                <Input defaultValue={categoryNameUpdate} disabled="true"/>
              </Form.Item>
              <Form.Item label="Obs" name="squareName">
                <Input defaultValue={squareNameUpdate} />
              </Form.Item>
              <Form.Item label="Comentários" name="comments">
                <TextArea
                  defaultValue={commentsUpdate}
                  showCount
                  maxLength={500}
                  style={{
                    height: 100,
                    marginBottom: 24,
                  }}
                  type="text"
                  />
              </Form.Item>
              <Form.Item label="Tags" name="tags">
                <Select
                  mode="tags"
                  style={{
                    width: '100%',
                  }}
                  placeholder="Tags Mode"
                  defaultValue={tagsUpdate}
                  onChange={addTag}
                  >
                </Select>
              </Form.Item>
              <div>
                <button 
                type="submit" 
                className="btn btn-primary" 
                onClick={handleClose}
                 style={{
                    width: '100%',
                  }}>
                  {" "}
                  GRAVAR
                </button>
              </div>
            </Form>
        </Modal>
  
        <Modal
          destroyOnClose={true} 
          title="Apagar Transacção"
          open={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          footer={false}
          style={{padding:"32px 16px"}}
          >
            <Form layout="vertical" onFinish={handleDelete} 
            style={{padding:"32px 16px"}}>
              <Space direction="horizontal">
                <Form.Item style={{ marginTop:10 }} name="amount">
                  <Input
                    defaultValue={amountUpdate}
                    placeholder='€ 0.00'
                    type="text" 
                    size="large" 
                    style={{fontSize:"32px", color:"black"}}
                    disabled="true"
                  />
                </Form.Item>
                <Form.Item style={{ marginTop:10 }} name="currency">
                    <Input style={{fontSize:"32px", width: "100px"}} defaultValue={currencyUpdate} disabled="true"/>
                </Form.Item>
              </Space>
              <Form.Item label="Pagamento ou Recebimento confirmado" name="isPayedReceived" valuePropName="checked">
                <Switch defaultChecked={isPayedReceivedUpdate} checkedChildren="Sim" unCheckedChildren="Não" disabled="true"/>
              </Form.Item>
              <Form.Item label="Data" name="date">
                  <Input
                      type="text"
                      defaultValue={moment(dateUpdate).format("DD/MM/YYYY")}
                      disabled="true"
                  />
              </Form.Item>
              <Form.Item label="Conta" name="accountName">
                  <Input defaultValue={accountNameUpdate} disabled="true"/>
              </Form.Item>
              <Form.Item label="Tipo de Conta" name="accountType">
                  <Input defaultValue={accountTypeUpdate} disabled="true"/>
              </Form.Item>
              <Form.Item label="Categoria" name="categoryName">
                <Input defaultValue={categoryNameUpdate} disabled="true"/>
              </Form.Item>
              <Form.Item label="Obs" name="squareName">
                <Input defaultValue={squareNameUpdate} />
              </Form.Item>
              <Form.Item onChange={onChangeAccount} label="Esta despesa/receita e as futuras?" name="deleteOnlyOneTransaction" valuePropName="checked">
                <Switch defaultChecked={deleteOneTransactionUpdate} checkedChildren="Sim" unCheckedChildren="Não"/>
              </Form.Item>
              <div>
                <button 
                type="submit" 
                className="btn btn-primary" 
                onClick={handleClose}
                 style={{
                    width: '100%',
                  }}>
                  {" "}
                  APAGAR
                </button>
              </div>
            </Form>
        </Modal>
  
      </Layout>
    );
  }
};

export default Transactions;