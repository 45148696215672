import React,{useState,useEffect} from 'react';
import {Steps,Form,Button,Space,message,Radio } from 'antd';
import Layout from "../components/Layout/Layout";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import { CheckCircleOutlined } from '@ant-design/icons';

const InvestorProfiles = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  //loading Apis
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState('')

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const [current, setCurrent] = useState(0);
  const [oneDetails, setOneDetails] = useState(null);
  const [twoDetails, setTwoDetails] = useState(null);
  const [threeDetails, setThreeDetails] = useState(null);
  const [fourDetails, setFourDetails] = useState(null);
  const [fiveDetails, setFiveDetails] = useState(null);
  const [sixDetails, setSixDetails] = useState(null);
  const [sevenDetails, setSevenDetails] = useState(null);
  const [eightDetails, setEightDetails] = useState(null);
  const [nineDetails, setNineDetails] = useState(null);
  const [tenDetails, setTenDetails] = useState(null);

  const [resultDetails, setResultDetails] = useState(null);

  const [resultSelects, setResultSelects] = useState({
    selectPortfolio: "0-0"
  });
 
  const onFinishOneForm=(values) => {
    // setOneDetails(values);
    setCurrent(1);
  }

  const onFinishTwoForm=(values) => {
    // setTwoDetails(values);
    setCurrent(2);
  }

  const onFinishThreeForm=(values) => {
    // setTwoDetails(values);
    setCurrent(3);
  }

  const onFinishFourForm=(values) => {
    // setOneDetails(values);
    setCurrent(4);
  }

  const onFinishFiveForm=(values) => {
    // setTwoDetails(values);
    setCurrent(5);
  }

  const onFinishSixForm=(values) => {
    // setTwoDetails(values);
    setCurrent(6);
  }

  const onFinishSevenForm=(values) => {
    // setOneDetails(values);
    setCurrent(7);
  }

  const onFinishEightForm=(values) => {
    // setTwoDetails(values);
    setCurrent(8);
  }

  const onFinishNineForm=(values) => {
    // setTwoDetails(values);
    setCurrent(9);
  }

  const onFinishTenForm=(values) => {
    // setTwoDetails(values);
    setCurrent(10);
  }

  const onFinishSucessForm=(values) => {
    // setLoginDetails(values);
    setCurrent(11);
  }

  const forms =[
    <OneForm 
      onFinish={onFinishOneForm} 
      initialValues={oneDetails}/>,
    <TwoForm 
      onFinish={onFinishTwoForm} 
      initialValues={twoDetails}/>,
    <ThreeForm 
      onFinish={onFinishThreeForm} 
      initialValues={threeDetails}/>,
    <FourForm 
      onFinish={onFinishFourForm} 
      initialValues={fourDetails}/>,
    <FiveForm 
      onFinish={onFinishFiveForm} 
      initialValues={fiveDetails}/>,
    <SixForm 
      onFinish={onFinishSixForm} 
      initialValues={sixDetails}/>,
    <SevenForm 
      onFinish={onFinishSevenForm} 
      initialValues={sevenDetails}/>,
    <EightForm 
      onFinish={onFinishEightForm} 
      initialValues={eightDetails}/>,
    <NineForm 
      onFinish={onFinishNineForm} 
      initialValues={nineDetails}/>,
    <TenForm 
      onFinish={onFinishTenForm} 
      initialValues={tenDetails}/>,
    <FinishForm 
      onFinish={onFinishSucessForm}
    />
  ]

  const isStepDisabled=(stepnumber)=>{
    if(stepnumber===0){
      return false
    }
    if(stepnumber===1){
      return oneDetails === null
    }
    if(stepnumber===2){
      return oneDetails === null || twoDetails === null
    }
    if(stepnumber===3){
      return oneDetails === null || twoDetails === null || threeDetails === null
    }
    if(stepnumber===4){
      return oneDetails === null || twoDetails === null || threeDetails === null || fourDetails === null
    }
    if(stepnumber===5){
      return oneDetails === null || twoDetails === null || threeDetails === null || fourDetails === null || fiveDetails === null
    }
    if(stepnumber===6){
      return oneDetails === null || twoDetails === null || threeDetails === null || fourDetails === null || fiveDetails === null || sixDetails === null
    }
    if(stepnumber===7){
      return oneDetails === null || twoDetails === null || threeDetails === null || fourDetails === null || fiveDetails === null || sixDetails === null || sevenDetails === null || eightDetails === null
    }
    if(stepnumber===8){
      return oneDetails === null || twoDetails === null || threeDetails === null || fourDetails === null || fiveDetails === null || sixDetails === null || sevenDetails === null || eightDetails === null || nineDetails === null
    }
    if(stepnumber===9){
      return oneDetails === null || twoDetails === null || threeDetails === null || fourDetails === null || fiveDetails === null || sixDetails === null || sevenDetails === null || eightDetails === null || nineDetails === null || tenDetails === null
    }
    if(stepnumber===10){
      return oneDetails === null || twoDetails === null || threeDetails === null || fourDetails === null || fiveDetails === null || sixDetails === null || sevenDetails === null || eightDetails === null || nineDetails === null || tenDetails === null
    }
    // if(stepnumber===11){
    //   return oneDetails === null || twoDetails === null
    // }
  }

  const user = JSON.parse(localStorage.getItem('user'))

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );


  const submitHandler = async (values) => {

    let questionPoints1= parseFloat(oneDetails.split('|')[0]);
    let questionPoints2= parseFloat(twoDetails.split('|')[0]);
    let questionPoints3= parseFloat(threeDetails.split('|')[0]);
    let questionPoints4= parseFloat(fourDetails.split('|')[0]);
    let questionPoints5= parseFloat(fiveDetails.split('|')[0]);
    let questionPoints6= parseFloat(sixDetails.split('|')[0]);
    let questionPoints7= parseFloat(sevenDetails.split('|')[0]);
    let questionPoints8= parseFloat(eightDetails.split('|')[0]);
    let questionPoints9= parseFloat(nineDetails.split('|')[0]);
    let questionPoints10 = parseFloat(tenDetails.split('|')[0]);

    let results = questionPoints1+questionPoints2+questionPoints3+questionPoints4+questionPoints5+questionPoints6+questionPoints7+questionPoints8+questionPoints9+questionPoints10
       
    console.log(resultSelects)

    if (results <= 10)
    {
    setResultSelects((resultSelects) => (resultSelects.selectPortfolio = "Defensivo"));
    }
    if (results >= 11 && results <= 30)
    {
    setResultSelects((resultSelects) => (resultSelects.selectPortfolio = "Conservador"));
    }
    if (results >= 31 && results <= 114)
    {
    setResultSelects((resultSelects) => (resultSelects.selectPortfolio = "Moderado"));
    }
    if (results >= 115 && results <= 135)
    {
    setResultSelects((resultSelects) => (resultSelects.selectPortfolio = "Agressivo"));
    }
    if (results >= 136 && results <= 150)
    {
    setResultSelects((resultSelects) => (resultSelects.selectPortfolio = "Arrojado"));
    }
    
    try{
      setLoading(true);
      await axios.post('/investorProfiles/add-investorProfile',
      {
        userid: user._id,
        question1: oneDetails.split('|')[1],
        question2: twoDetails.split('|')[1],
        question3: threeDetails.split('|')[1],
        question4: fourDetails.split('|')[1],
        question5: fiveDetails.split('|')[1],
        question6: sixDetails.split('|')[1],
        question7: sevenDetails.split('|')[1],
        question8: eightDetails.split('|')[1],
        question9: nineDetails.split('|')[1],
        question10: tenDetails.split('|')[1],
        results: results,
        selectPortfolio: resultSelects.selectPortfolio,
        status: true,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      })

      const res2 = await axios.post('/users/edit-user', {
        payload:{
          investorProfile: resultSelects.selectPortfolio,
        },
        _id: user._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );

      localStorage.setItem(
        'investorProfile', 
        JSON.stringify({ investorProfile: resultSelects.selectPortfolio})
      );

      message.success('Investor Profile Successfull');
      setLoading(false);
      navigate('/InvestorProfilesDetail',
      {
        state:{
          resultSelects: resultSelects,                    
        }
      });
    }catch (error){
      setLoading(false);
      message.error('Something went wrong');
    }
  }

  //Handler Test Console Log Values
  // const onFinish = () => {
  //   console.log({
  //   });
  // };

  const onChange = ({ target: { value } }) => {
    console.log('radio1 checked', value);
    setOneDetails(value);
    setCurrent(1);
  };

  const onChange1 = ({ target: { value } }) => {
    console.log('radio1 checked', value);
    setTwoDetails(value);
    setCurrent(2);
  };

  const onChange2 = ({ target: { value } }) => {
    console.log('radio1 checked', value);
    setThreeDetails(value);
    setCurrent(3);
  };

  const onChange3 = ({ target: { value } }) => {
    console.log('radio1 checked', value);
    setFourDetails(value);
    setCurrent(4);
  };

  const onChange4 = ({ target: { value } }) => {
    console.log('radio1 checked', value);
    setFiveDetails(value);
    setCurrent(5);
  };

  const onChange5 = ({ target: { value } }) => {
    console.log('radio1 checked', value);
    setSixDetails(value);
    setCurrent(6);
  };

  const onChange6 = ({ target: { value } }) => {
    console.log('radio1 checked', value);
    setSevenDetails(value);
    setCurrent(7);
  };

  const onChange7 = ({ target: { value } }) => {
    console.log('radio1 checked', value);
    setEightDetails(value);
    setCurrent(8);
  };

  const onChange8 = ({ target: { value } }) => {
    console.log('radio1 checked', value);
    setNineDetails(value);
    setCurrent(9);
  };

  const onChange9 = ({ target: { value } }) => {
    console.log('radio1 checked', value);
    setTenDetails(value);
    setCurrent(10);
  };

  //Handler Test Console Log Values
  const onFinishForm = (values) => {

    let questionPoints1= parseFloat(oneDetails.split('|')[0]);
    let questionPoints2= parseFloat(twoDetails.split('|')[0]);
    let questionPoints3= parseFloat(threeDetails.split('|')[0]);
    let questionPoints4= parseFloat(fourDetails.split('|')[0]);
    let questionPoints5= parseFloat(fiveDetails.split('|')[0]);
    let questionPoints6= parseFloat(sixDetails.split('|')[0]);
    let questionPoints7= parseFloat(sevenDetails.split('|')[0]);
    let questionPoints8= parseFloat(eightDetails.split('|')[0]);
    let questionPoints9= parseFloat(nineDetails.split('|')[0]);
    let questionPoints10 = parseFloat(tenDetails.split('|')[0]);

    let results = questionPoints1+questionPoints2+questionPoints3+questionPoints4+questionPoints5+questionPoints6+questionPoints7+questionPoints8+questionPoints9+questionPoints10
    
    setResultDetails(results);

    console.log({
      "userid": user._id,
      "questionPoints1":oneDetails.split('|')[0],
      "question1":oneDetails.split('|')[1],
      "questionPoints2":twoDetails.split('|')[0],
      "question2":twoDetails.split('|')[1],
      "questionPoints3":threeDetails.split('|')[0],
      "question3":threeDetails.split('|')[1],
      "questionPoints4":fourDetails.split('|')[0],
      "question4":fourDetails.split('|')[1],
      "questionPoints5":fiveDetails.split('|')[0],
      "question5":fiveDetails.split('|')[1],
      "questionPoints6":sixDetails.split('|')[0],
      "question6":sixDetails.split('|')[1],
      "questionPoints7":sevenDetails.split('|')[0],
      "question7":sevenDetails.split('|')[1],
      "questionPoints8":eightDetails.split('|')[0],
      "question8":eightDetails.split('|')[1],
      "questionPoints9":nineDetails.split('|')[0],
      "question9":nineDetails.split('|')[1],
      "questionPoints10":tenDetails.split('|')[0],
      "question10":tenDetails.split('|')[1],
      "results": results,
      "status": true
    });
  };

  function OneForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "60%" }} name="radio-group"  label="Radio.Group" onFinish={onFinish} initialValues={initialValues}>
        <h2 align="center">Situação Pessoal e Financeira</h2>
        <hr/>
        <br/>
        <h4 align="center">1. Qual é a sua idade?</h4>
        <br/>
        <h6 align="center">Em geral, há menos necessidade dos investidores mais velhos assumirem investimentos significativos de
                             risco do que existe para os investidores mais jovens. Embora outras circunstâncias, como a riqueza, possam contrariar esta
                             suposição, o avanço da idade geralmente reduz a tolerância ao risco.
        </h6>
        <br/>
        <Form.Item>
        <Radio.Group value={oneDetails} onChange={onChange} onFinish={onFinish} initialValues={initialValues}
        >
          <Space direction="vertical">
            <Radio value="8|30 anos ou menos">30 anos ou menos</Radio>
            <Radio value="6|31 a 40">31 a 40</Radio>
            <Radio value="4|41 a 50">41 a 50</Radio>
            <Radio value="2|51 a 65">51 a 65</Radio>
            <Radio value="0|Mais de 65 anos">Mais de 65 anos</Radio>
          </Space>
        </Radio.Group>
        </Form.Item>
        <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
          Continue
        </Button>
      </Form>
    )
  }

  function TwoForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "60%" }} name="radio-group"  label="Radio.Group"  onFinish={onFinish} initialValues={initialValues}>
        <h2 align="center">Situação Pessoal e Financeira</h2>
        <hr/>
        <br/>
        <h4 align="center">2. Qual é o seu rendimento familiar anual (antes de impostos)?</h4>
        <br/>
        <h6 align="center">O seu consultor financeiro deve compreender sua situação financeira para lhe fornecer um investimento personalizado
                             estratégia que reflete sua situação atual e permite que você alcance seus objetivos financeiros.
        </h6>
        <br/>
        <Form.Item>
        <Radio.Group value={twoDetails} onChange={onChange1} onFinish={onFinish} initialValues={initialValues}
        >
          <Space direction="vertical">
            <Radio value="0|Menos€40.000">Menos de €40.000</Radio>
            <Radio value="4|€40.001 to €75.000">€40.001 a €75.000</Radio>
            <Radio value="9|€75.001 to €125.000">€75.001 a €125.000</Radio>
            <Radio value="14|€125.001 to €200.000">€125.001 a €200.000</Radio>
            <Radio value="17|Mais de €200.000">Mais de €200.000</Radio>
          </Space>
        </Radio.Group>
        </Form.Item>
        <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
          Continue
        </Button>
      </Form>
    )
  }

  function ThreeForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "60%" }} name="radio-group"  label="Radio.Group"  onFinish={onFinish} initialValues={initialValues}>
        <h2 align="center">Situação Pessoal e Financeira</h2>
        <hr/>
        <br/>
       <h4 align="center">3. Quanto é que o seu agregado familiar tem em activos Investíveis (activos totais menos a sua residência principal,
                             negócios e bens pessoais)?</h4>
        <br/>
        <h6 align="center">Maior riqueza e ativos investíveis normalmente implicam maior risco “financeiro”
                             tolerância. No entanto, alguns investidores ricos podem ter uma baixa tolerância ao risco “psicológico”.
        </h6>
        <br/>
        <Form.Item>
        <Radio.Group value={threeDetails} onChange={onChange2} onFinish={onFinish} initialValues={initialValues}
        >
          <Space direction="vertical">
            <Radio value="0|Menos de €35.000">Menos de €35.000</Radio>
            <Radio value="4|€35.001 a €75.000">€35.001 a €75.000</Radio>
            <Radio value="9|€75.001 a €150.000">€75.001 a €150.000</Radio>
            <Radio value="14|€150.001 a €300.000">€150.001 a €300.000</Radio>
            <Radio value="17|Mais de €300.000">Mais de €300.000</Radio>
          </Space>
        </Radio.Group>
        </Form.Item>
        <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
          Continue
        </Button>
      </Form>
    )
  }

  function FourForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "60%" }} name="radio-group"  label="Radio.Group"  onFinish={onFinish} initialValues={initialValues}>
        <h2 align="center">Objetivos de investimento e tolerância ao risco</h2>
        <hr/>
        <br/>
        <h4 align="center">4. Qual é o seu principal objetivo de investimento?</h4>
        <br/>
        <h6 align="center">Determinar seu objetivo de investimento permite que seu consultor financeiro
                              selecione uma alocação de ativos adequada para maximizar o retorno do seu portfólio e minimizar seu risco.
        </h6>
        <br/>
        <Form.Item>
        <Radio.Group value={fourDetails} onChange={onChange3} onFinish={onFinish} initialValues={initialValues}>
          <Space direction="vertical">
            <Radio value="0|Preservar capital">Preservar capital</Radio>
            <Radio value="4|Gerar rendimentos máximos com crescimento modesto de activos">Gerar rendimentos máximos com crescimento modesto de activos</Radio>
            <Radio value="9|Alcançar crescimento e rendimentos moderados">Alcançar crescimento e rendimentos moderados</Radio>
            <Radio value="14|Alcançar um forte crescimento de activos com rendimentos modestos">Alcançar um forte crescimento de activos com rendimentos modestos</Radio>
            <Radio value="17|Alcance o máximo crescimento de activos">Alcance o máximo crescimento de activos</Radio>
          </Space>
        </Radio.Group>
        </Form.Item>
        <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
          Continue
        </Button>
      </Form>
    )
  }

  function FiveForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "60%" }} name="radio-group"  label="Radio.Group"  onFinish={onFinish} initialValues={initialValues}>
        <h2 align="center">Objetivos de investimento e tolerância ao risco</h2>
        <hr/>
        <br/>
        <h4 align="center">5. Por quanto tempo você planeia investir em fundos antes de começar a levantar uma parte substancial do
                             seu portfólio (por exemplo, um levantamento de mais da metade)?
        </h4>
        <br/>
        <h6 align="center">Se você planeia investir por menos de três anos, poderá enfrentar uma parte decrescente do ciclo de mercado sem
                             a oportunidade de aproveitar as tendências de mercado de longo prazo.
        </h6>
        <br/>
        <Form.Item>
        <Radio.Group value={fiveDetails} onChange={onChange4} onFinish={onFinish} initialValues={initialValues}>
          <Space direction="vertical">
            <Radio value="0|1 a 3 anos">1 a 3 anos</Radio>
            <Radio value="5|4 a 5 anos">4 a 5 anos</Radio>
            <Radio value="11|6 a 10 anos">6 a 10 anos</Radio>
            <Radio value="16|11 a 15 anos">11 a 15 anos</Radio>
            <Radio value="25|Mais de 15 anos">Mais de 15 anos</Radio>
          </Space>
        </Radio.Group>
        </Form.Item>
        <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
          Continue
        </Button>
      </Form>
    )
  }

  function SixForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "60%" }} name="radio-group"  label="Radio.Group"  onFinish={onFinish} initialValues={initialValues}>
        <h2 align="center">Objetivos de investimento e tolerância ao risco</h2>
        <hr/>
        <br/>
        <h4 align="center">6. Dados os seus objetivos financeiros, quanta volatilidade (risco) está disposto a assumir para atingir os objetivos do seu portfólio?
                             retorno esperado?</h4>
        <br/>
        <h6 align="center">Qualquer carteira bem diversificada está sujeita a alguma volatilidade, onde a carteira irá
                             provavelmente terão retornos negativos em determinados anos.
        </h6>
        <br/>
        <Form.Item>
        <Radio.Group value={sixDetails} onChange={onChange5} onFinish={onFinish} initialValues={initialValues}>
          <Space direction="vertical">
            <Radio value="0|Baixa volatilidade, já que exige retornos positivos a cada ano">Baixa volatilidade, já que exige retornos positivos a cada ano</Radio>
            <Radio value="5|Volatilidade baixa a média, como retornos negativos a cada 8 anos">Volatilidade baixa a média, como retornos negativos a cada 8 anos</Radio>
            <Radio value="11|Volatilidade média, como retornos negativos a cada 6 anos">Volatilidade média, como retornos negativos a cada 6 anos</Radio>
            <Radio value="16|Volatilidade média a alta, como retornos negativos a cada 5 anos">Volatilidade média a alta, como retornos negativos a cada 5 anos</Radio>
            <Radio value="25|Alta volatilidade, como retornos negativos a cada 4 anos">Alta volatilidade, como retornos negativos a cada 4 anos</Radio>
          </Space>
        </Radio.Group>
        </Form.Item>
        <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
          Continue
        </Button>
      </Form>
    )
  }

  function SevenForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "60%" }} name="radio-group"  label="Radio.Group"  onFinish={onFinish} initialValues={initialValues}>
        <h2 align="center">Objetivos de investimento e tolerância ao risco</h2>
        <hr/>
        <br/>
        <h4 align="center">7. Quanto retorno temporário negativo na sua carteira de investimentos você poderia tolerar no período de um ano?</h4>
        <br/>
        <h6 align="center">Seu portfólio provavelmente terá um retorno negativo em determinados anos. Uma carteira com maior volatilidade provavelmente terá
                             retornos negativos mais elevados em qualquer ano. Você precisará ter o risco “psicológico” e “financeiro”
                             tolerante para suportar esses retornos negativos.
        </h6>
        <br/>
        <Form.Item>
        <Radio.Group value={sevenDetails} onChange={onChange6} onFinish={onFinish} initialValues={initialValues}>
          <Space direction="vertical">
            <Radio value="0|0%">0%</Radio>
            <Radio value="4|-5%">-5%</Radio>
            <Radio value="9|-10%">-10%</Radio>
            <Radio value="14|-15%">-15%</Radio>
            <Radio value="17|Mais de -15%">Mais de -15%</Radio>
          </Space>
        </Radio.Group>
        </Form.Item>
        <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
          Continue
        </Button>
      </Form>
    )
  }

  function EightForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "60%" }} name="radio-group"  label="Radio.Group"  onFinish={onFinish} initialValues={initialValues}>
        <h2 align="center">Conhecimento e experiência em investimentos</h2>
        <hr/>
        <br/>
        <h4 align="center">8. Quanto espera que seja o retorno médio anual geral de sua carteira de investimentos no longo prazo
                           (mais de 10 anos), antes dos impostos, mas depois da inflação?</h4>
        <br/>
        <h6 align="center">Seu consultor financeiro pode ajudá-lo a alinhar suas expectativas de retorno
                           com sua tolerância ao risco. Idealmente, você deve-se concentrar nos retornos de longo prazo da sua carteira de investimentos
                           após a inflação (ou seja, a taxa real de retorno).
        </h6>
        <br/>
        <Form.Item>
        <Radio.Group value={eightDetails} onChange={onChange7} onFinish={onFinish} initialValues={initialValues}>
          <Space direction="vertical">
            <Radio value="0|0% a 2%">0% a 2%</Radio>
            <Radio value="2|1% a 3%">1% a 3%</Radio>
            <Radio value="4|4% a 7%">4% a 7%</Radio>
            <Radio value="6|5% a 9%">5% a 9%</Radio>
            <Radio value="8|Mais de 9%">Mais de 9%</Radio>
          </Space>
        </Radio.Group>
        </Form.Item>
        <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
          Continue
        </Button>
      </Form>
    )
  }

  function NineForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "60%" }} name="radio-group"  label="Radio.Group"  onFinish={onFinish} initialValues={initialValues}>
        <h2 align="center">Conhecimento e experiência em investimentos</h2>
        <hr/>
        <br/>
        <h4 align="center">9. Qual a afirmação melhor descreve o seu nível de conhecimento sobre investimentos nos mercados financeiros
                             e produtos?</h4>
        <br/>
        <h6 align="center">Conhecer o seu nível de conhecimento sobre investimentos ajuda seu consultor financeiro a se comunicar
                             com você em um nível apropriado. Além disso, seu nível de conhecimento ajuda seu orientador a avaliar o quanto
                             informações básicas são necessárias ao discutir estratégias e produtos de investimento.
        </h6>
        <br/>
        <Form.Item>
        <Radio.Group value={nineDetails} onChange={onChange8} onFinish={onFinish} initialValues={initialValues}>
          <Space direction="vertical">
            <Radio value="0|Conhecimento muito limitado">Conhecimento muito limitado</Radio>
            <Radio value="2|BConhecimento básico e experiência mínima">Conhecimento básico e experiência mínima</Radio>
            <Radio value="4|Bons conhecimentos e alguma experiência em investimentos">Bons conhecimentos e alguma experiência em investimentos</Radio>
            <Radio value="6|Forte conhecimento e experiência">Forte conhecimento e experiência</Radio>
            <Radio value="8|Conhecimento avançado e ampla experiência">Conhecimento avançado e ampla experiência</Radio>
          </Space>
        </Radio.Group>
        </Form.Item>
        <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
          Continue
        </Button>
      </Form>
    )
  }

  function TenForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "60%" }} name="radio-group"  label="Radio.Group"  onFinish={onFinish} initialValues={initialValues}>
        <h2 align="center">Conhecimento e experiência em investimentos</h2>
        <hr/>
        <br/>
        <h4 align="center">Qual das seguintes afirmações melhor descreve a sua situação atual
                             carteira de investimentos?
        </h4>
        <br/>
        <h6 align="center">Informações sobre sua experiência anterior em investimentos ajudam seu consultor financeiro a 
                              avaliar seu conhecimento sobre investimentos e sua atitude em relação ao risco de investimento.
        </h6>
        <br/>
        <Form.Item>
        <Radio.Group value={tenDetails} onChange={onChange9} onFinish={onFinish} initialValues={initialValues}>
          <Space direction="vertical">
            <Radio value="0|Principalmente títulos do mercado monetário português (por exemplo, numerário)">Principalmente títulos do mercado monetário português (por exemplo, numerário)</Radio>
            <Radio value="2|Principalmente títulos de rendimento fixo portugueses (por exemplo, obrigações governamentais e/ou empresariais, certificados de aforro, depósitos a prazo e bilhetes do tesouro)">Principalmente títulos de rendimento fixo portugueses (por exemplo, obrigações governamentais e/ou empresariais, certificados de aforro, depósitos a prazo e bilhetes do tesouro)</Radio>
            <Radio value="4|Quantidades iguais de títulos de renda fixa e de ações">Quantidades iguais de títulos de renda fixa e de ações</Radio>
            <Radio value="6|Principalmente ações blue-chip portuguesas e internacionais">Principalmente ações blue-chip portuguesas e internacionais</Radio>
            <Radio value="8|Principalmente títulos portugueses e internacionais agressivos">Principalmente títulos portugueses e internacionais agressivos</Radio>
          </Space>
        </Radio.Group>
        </Form.Item>
        <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
          Continue
        </Button>
      </Form>
    )
  }

  function FinishForm(){

    return (<>
        <div style={{ width: "30%" }} >
          <Space direction="vertical">
            <h2>Parabéns!</h2>
            <br/>
            <Button style={{ width: "100%" }} type="primary" onClick={submitHandler}>
              A seguir vai saber o seu Perfil de Investidor!
          </Button>
          </Space>
        </div>
    </>)
  }


/*   function ReviewForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "50%" }} onFinish={onFinish} initialValues={initialValues}>
        <h4 align="center">Take a moment to review and </h4>
        <h4 align="center">confirm your information</h4>
        <hr/>
        <h5>Your personal information</h5>
        <br/>
        <a><b>Name:</b> {OneDetails.name}</a>
        <br/>
        <a><b>Fiscal Number:</b> {OneDetails.nif}</a>
        <br/>
        <a><b>Date of birth:</b> {OneDetails.birthdayYear}-{OneDetails.birthdayMonth}-{OneDetails.birthdayDay}</a>
        <br/>
        <a><b>Phone number:</b> {OneDetails.phoneNumber}</a>
        <br/>
        <a><b>Country of Citizenship:</b> {OneDetails.countryCitizenship}</a>
        <br/>
        <a><b>Residential address:</b></a>
        <br/>
        <a>{twoDetails.address}, {twoDetails.city}</a>
        <br/>
        <a>{twoDetails.zipCode}-{twoDetails.state}</a>
        <hr/>
        <h5>Your employment information</h5>
        <br/>
        <a><b>Employment status:</b> {jobDetails.employmentStatus}</a>
        <br/>
        <a><b>Ocuppation:</b> {jobDetails.ocuppationCompany}</a>
        <br/>
        <a><b>Company name</b> {jobDetails.nameCompany}</a>
        <br/>
        <a><b>Company address:</b></a>
        <br/>
        <a>{jobDetails.addressCompany}, {jobDetails.cityCompany}</a>
        <br/>
        <a>{jobDetails.zipCodeCompany}-{jobDetails.stateCompany}</a>
        <br/>
        <hr/>
        {pepDetails.isPEP === true && (
          <a><b>Investment industry association:</b> Yes</a>
        )}
        {pepDetails.isPEP === false && (
          <a><b>Investment industry association:</b> None</a>
        )}
        <br/>
        {pepDetails.isCorporateControl === true && (
          <a><b>Corporate control status:</b> Yes</a>
        )}
        {pepDetails.isCorporateControl === false && (
          <a><b>Corporate control status:</b> None</a>
        )}
        <br/>
        <hr/>
      <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
        Continue
      </Button>
    </Form>)
  } */

/*   function FinishForm(){
    return (<>
        <div style={{ width: "50%" }} >
          <Space direction="vertical">
            <h2>Congratulations your account was created successfully!</h2>
            <br/>
            <h6>We are verifying your account. Please wait our email to access.</h6>
            <br/>
            <Button type="primary" onClick={}>
              Finish
          </Button>
          </Space>
        </div>
    </>)
  } */

  //***************************RENDER**************************************************************************/

  return (
    
    <Layout>
      {/* <div><pre>{JSON.stringify(oneDetails, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(twoDetails, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(threeDetails, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(fourDetails, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(fiveDetails, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(sixDetails, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(sevenDetails, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(eightDetails, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(nineDetails, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(tenDetails, null, 2) }</pre></div> */}
      <div className="App-Investor">
      <Steps 
        style={{ padding: "32px 16px"}}
        onChange={setCurrent}
        current={current}
        >
        <Steps.Step disabled={isStepDisabled(0)} title="" icon={1} />
        <Steps.Step disabled={isStepDisabled(1)} title="" icon={2} />
        <Steps.Step disabled={isStepDisabled(2)} title="" icon={3} />
        <Steps.Step disabled={isStepDisabled(3)} title="" icon={4} />
        <Steps.Step disabled={isStepDisabled(4)} title="" icon={5} />
        <Steps.Step disabled={isStepDisabled(5)} title="" icon={6} />
        <Steps.Step disabled={isStepDisabled(6)} title="" icon={7} />
        <Steps.Step disabled={isStepDisabled(7)} title="" icon={8} />
        <Steps.Step disabled={isStepDisabled(8)} title="" icon={9} />
        <Steps.Step disabled={isStepDisabled(9)} title="" icon={10} />
        <Steps.Step disabled={isStepDisabled(10)} title="" icon={<CheckCircleOutlined />} />
      </Steps>
      </div>
      <div className="registerInvestorProfile-page">
        {forms[current]}
      </div>

    </Layout>
  );
};

export default InvestorProfiles;