// import React,{useState,useEffect} from 'react';
import {Card,Row,Col} from 'antd';
import Layout from "../components/Layout/Layout";
import MyBank from "./../components/Assets/bank.png";
import {useNavigate} from "react-router-dom";
import React,{useEffect} from 'react';

const Settings = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Meta } = Card;

  // const [allAccountStats, setAllAccountStats] = useState([]);
  // const [allNoAccountStats, setAllNoAccountStats] = useState([]);
  // const [allSubscriptionStats, setAllSubscriptionStats] = useState([]);
  // const [allNoSubscriptionStats, setAllNoSubscriptionStats] = useState([]);
  // const [allUtilitiesStats, setAllUtilitiesStats] = useState([]);
  // const [allNoUtilitiesStats, setAllNoUtilitiesStats] = useState([]);
  // const [allInsurancesStats, setAllInsurancesStats] = useState([]);
  // const [allNoInsurancesStats, setAllNoInsurancesStats] = useState([]);
  // const [allLoansStats, setAllLoansStats] = useState([]);
  // const [allNoLoansStats, setAllNoLoansStats] = useState([]);
  // const [allInvestmentsStats, setAllInvestmentsStats] = useState([]);
  // const [allCreditCardsStats, setAllCreditCardsStats] = useState([]);
  // const [allTaxStats, setAllTaxStats] = useState([]);
  // const [allNoTaxStats, setAllNoTaxStats] = useState([]);  
  // const [allWalletsStats, setAllWalletsStats] = useState([]);

  //loading Apis
  // const [loading,setLoading] = useState(false);

  // const [loginUser, setLoginUser] = useState('')
  const navigate = useNavigate();

  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem('user'))
  //   if(user){
  //     setLoginUser(user)
  //   }
  // },[])

  const user = JSON.parse(localStorage.getItem('user'))
  const subscriptionPackage = user.subscriptionPackage

  //***************************RENDER**************************************************************************/

  return (
    
    <Layout>

      {/* {loading && <Spinner/>} */}
      <div className="content">
        <Row gutter={[8,24]}
            style={{
            justifyContent: "center"
          }}>
            
            {/* subscription package view card */}
            {(subscriptionPackage === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Categories');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Categorias</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(subscriptionPackage === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CategoryAssets');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Classe de Activos</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(subscriptionPackage === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/FundFamilies');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Família de Fundos</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(subscriptionPackage === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Industries');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Industrias</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(subscriptionPackage === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/StockAssets');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%",marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Acções</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(subscriptionPackage === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CryptoAssets');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Criptomoedas</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(subscriptionPackage === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/ETFAssets');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%",marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">ETFs e Fundos de Investimento</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(subscriptionPackage === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CurrencyAssets');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Cambios</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(subscriptionPackage === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/FutureAssets');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Futuros</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(subscriptionPackage === 9) && (
             <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Markets');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%",marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Mercados Financeiros</h4>
              </div>
            </Card>
            </Col>
            )}

            {(subscriptionPackage === 9) && (
             <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Countries');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%",marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Países</h4>
              </div>
            </Card>
            </Col>
            )}

            {(subscriptionPackage === 9) && (
             <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Sectors');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%",marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Sectores</h4>
              </div>
            </Card>
            </Col>
            )}

              {(subscriptionPackage === 9) && (
             <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/InvestorRiskTypes');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%",marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Perfis de Investidor</h4>
              </div>
            </Card>
            </Col>
            )}

          </Row>
      </div>

    </Layout>
  );
};

export default Settings;