import React,{useState,useEffect} from 'react';
import {Form,Input,Button,Space,Radio,message,Select,Row,Col} from 'antd';
import {PlusOutlined,MinusCircleOutlined} from '@ant-design/icons';

import Layout from "../components/Layout/Layout";
import {useNavigate,Link} from "react-router-dom";

import axios from "axios";
import Spinner from "../components/Spinner";

import moment from 'moment';

const NewWallets = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // const categoryName= "Savings and Investments";

  // let now=(moment().format('YYYY-MM-DD'));

  //loading Apis
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState('')

  //DropList Category
  // const [allCategory, setAllCategory] = useState([]);

  //DropList Market
  const [allcryptos, setAllCryptos] = useState([]);
  //Passing Category Name
  // const [marketIdAll, setMarketIdAll]= useState([]);

  //Add Tags
  // const [tags, setTags] = useState([]);

  //Recursive Dates Array
  // const [sdates, setsDates]= useState([]);
  // const [sdatesEnd, setsDatesEnd]= useState([]);
  // const [dateUpdate, setDateUpdate] = useState();

  // Define state variables for the two numbers and the result
  // const [num1, setNum1] = useState();
  // const [num2, setNum2] = useState();
  // const [result, setResult] = useState(0);

  // const [getFrequency, setGetFrequency] = useState();
  // const [dateShared, setDateShared] = useState(now);
  // const [dateSharedDate, setDateSharedDate] = useState();
  // const [sharedDetails, setSharedDetails] = useState(false);
  // const [sharedPersonalDetails, setSharedPersonalDetails] = useState(false);
  // const [sharedInformationDetails, setSharedInformationDetails] = useState(false);
  

  const { TextArea } = Input;

  // const [investmentDetails, setinvestmentDetails] = useState(null);
  // const [accountDetails, setAccountDetails] = useState(null);
  const [frequencyDetails, setFrequencyDetails] = useState(null);
  const [goalDetails, setGoalDetails] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user = JSON.parse(localStorage.getItem('user'))

  // const toggleActive = name => event => {
  //   console.log("name: ",name) // prints "name: Active!"
  //   console.log("event: ",event) // event is some data
  //   setMarketIdAll(event)
  // }
    
  //***************************************MONTHS RECURSIVES**************************************************************/
    //Recursive Dates Array
    // const [sMonth, setMonth]= useState('0');

    // const handleMonths=(event)=>{
  
    //     setMonth([]);
    
    //     const getMonth= event.target.value;
    
    //     console.log(getMonth); //22
    //     console.log(sdates); //2023-01-06
    
    //     const dates = [];
    //     const currDate = moment(sdates);
    
    //     if (getMonth === "1" && frequencyDetails==="Mensal" ) {
    //       dates.push(moment(currDate).format('YYYY-MM-DD'));
    //     }
    //     else if (getMonth !== "1" && frequencyDetails==="Mensal" ){
    //       //console.log(currDate); //2023-01-06
        
    //       let lastDate = moment(currDate).add(getMonth-1, 'month').toDate();
    //       //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
    //       //console.log(lastDate); //2028-04-06
    
    //       do {
    //       dates.push(moment(currDate).format('YYYY-MM-DD'));
    //       } while (currDate.add(1, "month").diff(lastDate) < 0);
    //       //dates.push(currDate.clone().toDate());
    //       dates.push(moment(currDate).format('YYYY-MM-DD'));
    //     }
    //     else if (getMonth !== "1" && frequencyDetails==="Trimestral" ){
    //       //console.log(currDate); //2023-01-06
        
    //       let lastDate = moment(currDate).add(getMonth-1, 'quarter').toDate();
    //       //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
    //       //console.log(lastDate); //2028-04-06
    
    //       do {
    //       dates.push(moment(currDate).format('YYYY-MM-DD'));
    //       } while (currDate.add(1, "quarter").diff(lastDate) < 0);
    //       //dates.push(currDate.clone().toDate());
    //       dates.push(moment(currDate).format('YYYY-MM-DD'));
    //     }
    //     else if (getMonth !== "1" && frequencyDetails==="Semestral" ){
    //       //console.log(currDate); //2023-01-06
        
    //       let lastDate = moment(currDate).add((getMonth*2)-2, 'quarter').toDate();
    //       //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
    //       console.log(lastDate); //2028-04-06
    
    //       do {
    //       dates.push(moment(currDate).format('YYYY-MM-DD'));
    //       } while (currDate.add(2, "quarter").diff(lastDate) < 0);
    //       //dates.push(currDate.clone().toDate());
    //       dates.push(moment(currDate).format('YYYY-MM-DD'));
    //     }
    //     else{
    //         //console.log(currDate); //2023-01-06

    //         let lastDate = moment(currDate).add(getMonth-1, 'year').toDate();
    //         //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
      
    //         //console.log(lastDate); //2028-04-06
      
    //         do {
    //         dates.push(moment(currDate).format('YYYY-MM-DD'));
    //         } while (currDate.add(1, "year").diff(lastDate) < 0);
    //         //dates.push(currDate.clone().toDate());
    //         dates.push(moment(currDate).format('YYYY-MM-DD'));
    //     }
    
    //     message.success(dates);
    //     console.log(dates);
    
    //     setMonth(dates);
    //   }

    // const worker = {
    // dates: moment(dateUpdate),
    // };

    //Handler Fund Family DropList ****************************************************************************************
    // // GOALS
    // useEffect(() => {
    //   const getAllAccounts = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/accounts/get-account', {
    //         userid:user._id,
    //         status: true
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllAccount(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       //message.error('You are not Admin User');
    //     }
    //   };  
    //   getAllAccounts();
    // },[])

  // useEffect(() => {
  //   const getAllETFAsset = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/etfAssets/get-etfAsset',
  //       {
  //         userid:user._id,
  //         fundFamilyName: "all",
  //         isFund: false
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllEtfAsset(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With Industry');
  //       //message.error('You are not Admin User');
  //     }
  //   };  
  //   getAllETFAsset();
  // },[])

  // useEffect(() => {
  //   const getAllInvestmentFundAsset = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/etfAssets/get-etfAsset',
  //       {
  //         userid:user._id,
  //         fundFamilyName: "all",
  //         isFund: true
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllInvestmentFundAsset(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With Industry');
  //       //message.error('You are not Admin User');
  //     }
  //   };  
  //   getAllInvestmentFundAsset();
  // },[])

    // useEffect(() => {
    //   const getAllMarkets = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/markets/get-stockmarket', {
    //         userid:user._id,
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllMarkets(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       //message.error('You are not Admin User');
    //     }
    //   };  
    //   getAllMarkets();
    // },[])
  
  
      useEffect(() => {
        const getAllStocks = async (values) => {
          try{
            const user = JSON.parse(localStorage.getItem('user'))
            setLoading(true);
            const res = await axios.post('/cryptoAssets/get-cryptoAsset',
            {
              status: true
            },
            {
              headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
            }
            );
            setLoading(false);
            setAllCryptos(res.data);
            console.log(res.data);
          }catch (error){
            setLoading(false);
            // message.error('Fetch Issue With Sector');
            //message.error('You are not Admin User');
          }
        };  
        
        // Clean up the interval when the component unmounts
        return () => getAllStocks();
      },[])
  

    //Handler Sectors DropList ****************************************************************************************
    // useEffect(() => {
    //   const getAllCategories = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/categories/get-findCategoryName', {
    //         userid:user._id,
    //         categoryName: categoryName
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllCategory(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       //message.error('You are not Admin User');
    //     }
    //   };  
      
    //   // Clean up the interval when the component unmounts
    //   return () => getAllCategories();
    // },[categoryName])

// var categoryID = allCategory[0];

let range = {min: 1, max: 999999999}
let delta = range.max - range.min

const codeSquare = Math.round(range.min + Math.random() * delta)

  const submitHandler = async (values) => {

    // let tax=0.00000000
    // let isPayedReceivedInicial=true

    // console.log("---------")
    // if(frequencyDetails==="Mensal"){
    //   tax=parseFloat((((values.investmentIndexPercentage/100)/12)*(1-0.28)))
    //   console.log("tax/Mensal:" + tax)
    // }else if (frequencyDetails==="Trimestral"){
    //   tax=parseFloat((((values.investmentIndexPercentage/100)/4)*(1-0.28)))
    //   console.log("tax/Trimestral:" + tax)
    // }else if (frequencyDetails==="Semestral"){
    //   tax=parseFloat((((values.investmentIndexPercentage/100)/2)*(1-0.28)))
    //   console.log("tax/Semestral:" + tax)
    // }else{
    //   tax=parseFloat((((values.investmentIndexPercentage/100)/1)*(1-0.28)))
    //   console.log("tax/Anual:" + tax)
    // };

    try{
          let tagsInicial=["AQUISIÇÃO CRIPTOMOEDAS"]
          // let tagsSeguintes=["REFORÇAR CRIPTOMOEDAS"]

          setLoading(true);

          const res2 = axios.post('/wallets/add-wallet', {
            userid:user._id,
            walletName:values.walletName,
            walletDescription:values.walletDescription,
            brokerName:values.brokerName,
            protocol:values.protocol,
            currecy_ctr: user.currency,
            walletStartDate:values.walletStartDate,
            walletEndDate:values.walletEndDate,
            frequency:frequencyDetails,
            initialAssets:values.assets,
            goalName: goalDetails,
            codeSquare: codeSquare,
            months: 1,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
            const res = axios.post('/wallet-transactions/add-wallet-transaction', {
              userid:user._id,
              codeSquare:codeSquare,
              walletName:values.walletName,
              brokerName:values.brokerName,
              amount:0,
              quantity:1,
              protocol:values.protocol,
              currecy_ctr: user.currency,
              date:values.walletStartDate,
              months: 1,
              monthYear: moment(values.walletStartDate).format("MMM YYYY"),
              monthYearNumber: moment(values.walletStartDate).format("YYYYMM"),
              dayMonthYearNumber: moment(values.walletStartDate).format("YYYY-MM-DD"),
              tags: tagsInicial,
              isReforceOrWithdraw: true,
              isPayedReceived: true,
              goalName:goalDetails,
              assets:values.assets,
            },
            {
              headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
            }
            )
            ;
          const res3 = axios.post('/wallets/create-wallet/'+ user._id, {
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          message.success('Conta Wallet Criada com Sucesso!');
          navigate('/ProcessingExchanges',
            {
              state:{
                exchangeName:values.walletName,
                processing:"Portfólio Wallet",
                navigateLink:"/Wallets",
              }
            }
          );
    }catch (error){
      setLoading(false);
      message.error('Algo deu errado!');
    }
  }

  // //Handler Test Console Log Values
  // const onFinish = (values) => {
  //   console.log({
  //     ...values,
  //     userid:user._id,
  //     walletName:values.walletName,
  //     walletDescription:values.walletDescription,
  //     brokerName:values.brokerName,
  //     protocol:values.protocol,
  //     quantity: 1,
  //     walletStartDate:values.walletStartDate,
  //     walletEndDate:values.walletEndDate,
  //     frequency:frequencyDetails,
  //     months: 1,
  //     initialAssets:values.assets,
  //     goalName: goalDetails,
  //     isReforceOrWithdraw:true,
  //     date: values.walletStartDate,
  //     monthyear: moment(values.walletStartDate).format("MMM YYYY"),
  //     monthyearnumber: moment(values.walletStartDate).format("YYYYMM"),
  //     tags: "DEPÓSITO INICIAL",
  //     codeSquare: codeSquare
  //   });
  // };

  // const handleDate=(event)=>{
  //   setsDates([]);
  //   const getDates= moment(event.target.value);
  //   setsDates(getDates);
  //   console.log(getDates);
  // }

  // const handleDateEnd=(event)=>{
  //   setsDatesEnd([]);
  //   const getDates= moment(event.target.value);
  //   setsDatesEnd(getDates);
  //   console.log(getDates);
  // }

  // // Function to handle the subtraction
  // const handleSubtraction = () => {
  //   if ( frequencyDetails === "Trimestral") {
  //     const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'quarter', true)));
  //     setResult(subtractionResult);
  //     console.log(result)
  //   } else if (frequencyDetails === "Anual"){
  //       const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'year', true)));
  //       setResult(subtractionResult);
  //       console.log(result)
  //     } else if (frequencyDetails === "Semestral"){
  //       const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'quarter', true))/2);
  //       setResult(subtractionResult);
  //       console.log(result)
  //     }else {
  //       const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'month', true)));
  //       setResult(subtractionResult);
  //       console.log(result)
  //     }
  //   ;
  // };

  // const onChangeInvestment = ({ target: { value } }) => {
  //   console.log('Investment choose: ', value);
  //   setinvestmentDetails(value);
  // };

  const onChangeGoal = ({ target: { value } }) => {
    console.log('Goal choose: ', value);
    setGoalDetails(value);
  };


  const onChangeFrequency = state => {
    console.log('Frequency choose: ',state);
    setFrequencyDetails(state);
  };

  //DEFAULT DATA FIM DO INVESTIMENTO (FUTURO TRAZER DATA FIM DA META/OBJECTIVO)
  // let defaultDate = new Date()
  // defaultDate.setDate(defaultDate.getDate() + 365)
  // console.log(defaultDate)

  // const [date, setDate] = useState(defaultDate)
  // console.log(date)

  // const onSetDate = (event) => {
  //     setDate(new Date(event.target.value))
  // }

  return (
    <Layout>
    {loading && <Spinner/>}
     <div className="accountpage">
        <div></div>
        <div><h1>Nova Wallet</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={submitHandler} 
          >

          <h5 style={{ marginBottom:30, marginTop:10 }}>Nome da conta Wallet</h5>

          <Form.Item label="" name="walletName">
            <Input 
            type="text"
            />
          </Form.Item>

          <Form.Item style={{ marginTop:30 }} label="Observações" name="walletDescription">
              <TextArea
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                />
            </Form.Item>

              <h5 style={{ marginBottom:30, marginTop:10 }}>Nome do Broker</h5>

                <Form.Item label="" name="brokerName">
                  <Input 
                  type="text"
                  style={{
                    width: '100%',
                  }} 
                  />
                </Form.Item>

                <Form.Item label="Protocolo Blockchain" name="protocol">
                    <Select
                        style={{
                          width: '100%',
                        }} 
                    >
                      <Select.Option value="Ethereum">Ethereum</Select.Option>
                      <Select.Option value="Arbitrum">Arbitrum</Select.Option>
                      <Select.Option value="Optimism">Optimism</Select.Option>
                      <Select.Option value="Polygon">Polygon</Select.Option>
                      <Select.Option value="Base">Base</Select.Option>
                      <Select.Option value="BNB Chain">BNB Chain</Select.Option>
                      <Select.Option value="Avalanche">Avalanche</Select.Option>              
                      <Select.Option value="Celo">Celo</Select.Option>
                      <Select.Option value="Other">Other</Select.Option>
                    </Select>
                </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Objectivo para que Investe</h5>

          <Radio.Group onChange={onChangeGoal} style={{ display: "flex", justifyContent: "left"}} >
            <Space direction="vertical" align='center' size="middle">
              <Row gutter={[8,24]}>
              <Radio.Button value="Fundo de Emergência" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Fundo de Emergência</Radio.Button>
                  <Radio.Button value="Reforma" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Reforma</Radio.Button>
                  <Radio.Button value="Habitação" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Habitação</Radio.Button>
                  <Radio.Button value="Educação" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Educação</Radio.Button>
                  <Radio.Button value="Saúde" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Saúde</Radio.Button>
                  <Radio.Button value="Viagem" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Viagem</Radio.Button>
                  <Radio.Button value="Automóvel" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Automóvel</Radio.Button>
                  <Radio.Button value="Mota" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Mota</Radio.Button>
                  <Radio.Button value="Filantropia" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Filantropia</Radio.Button>
                  <Radio.Button value="Outro" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Outro</Radio.Button>
              </Row>
            </Space>
          </Radio.Group>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Dados da Wallet</h5>

          <Form.List name="assets">
  {(fields, { add, remove }) => (
    <div>
      {fields.map((field, index) => (
        <div key={field.key}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                style={{ width: '100%', marginTop: '20px' }}
                name={[field.name, 'Asset']}
                fieldKey={[field.fieldKey, 'Asset']}
                className="formItemKeyDependency"
                rules={[{ required: true, message: 'Preencha com o Activo Investido' }]}
              >
                <Select placeholder="Activo" style={{ width: '100%' }}>
                  {allcryptos.map(item => (
                    <Select.Option key={item} value={item.yahooTicker}>
                      {item.cryptoAssetName} ({item.yahooTicker})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={[field.name, 'quantity']}
                fieldKey={[field.fieldKey, 'quantity']}
                className="formItemKeyDependency"
                rules={[{ required: true, message: 'Preencha com o Activo Investido' }]}
              >
                <Input style={{ width: '220px' }} placeholder="Quantidade" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={1} lg={1} xl={1} key={3}>
              <MinusCircleOutlined
                className="dynamic-delete-button"
                onClick={() => {
                  remove(field.name);
                }}
              />
            </Col>
          </Row>
        </div>
      ))}
        <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add();
              }}
              style={{ width: "100%" , background: "green", color: "white", marginTop: 30, marginBottom: 30}}
            >
              <PlusOutlined /> Nova Criptomoeda
            </Button>
        </Form.Item>
          </div>
        )}
      </Form.List>

          <Form.Item 
            label="Data começo da conta wallet" 
            name="walletStartDate" 
            onChange={(e)=> {}}
                rules={[
                  { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                    ]}>
                <Input style={{fontSize:"18px"}} type="date"/>
            </Form.Item>

            <Form.Item label="Frequência de que tenciona investir" name="frequency" >
              <Select 
                onChange={onChangeFrequency}
                style={{
                  width: '100%'
                }} >
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item 
            label="Data que pensa mobilizar totalmente a conta Wallet *opcional" 
            name="walletEndDate" 
            // onChange={(e)=> {handleDateEnd(e); handleSubtraction(e)}}
                // rules={[
                //   { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                //     ]}
                >
                <Input style={{fontSize:"18px"}} 
                type="date" 
                // defaultValue={date.toLocaleDateString('en-CA')} 
                // onChange={onSetDate}
                />
            </Form.Item>

            {/* <Form.Item label={labelFrequency} name="frequency" >
              <Select 
              onChange={onChangeFrequency}
                            style={{
                              width: '410px'
                            }} >
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item> */}

              <Button style={{ width: "100%" , background: "green", color: "white", marginTop: 30, marginBottom: 30}}  htmlType='submit'>
              CRIAR
              </Button>
              <Link to="/Wallets">
                <Button style={{ width: "100%" }} type="primary">
                  CANCELAR
                </Button>
                </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(allExpenses, null, 2) }</pre></div> */}
    </Layout>
  )
  };

export default NewWallets;