import React,{useState,useEffect} from "react";
import {Input, message, Button, Col,Tooltip,Row,Card} from 'antd';
import MyLoan from "./../components/Assets/loan.png";
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { CheckCircleFilled, ClockCircleFilled, ExclamationCircleFilled, HddOutlined, SettingOutlined, UnorderedListOutlined } from "@ant-design/icons";


import  Chart  from "react-apexcharts";

const Loans = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const categoryName= "Loans";

  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  const [allCategory, setAllCategory] = useState([]);

  const [allLoansStats, setAllLoansStats] = useState([]);
  const [allNoLoansStats, setAllNoLoansStats] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Edit Transactions
    const [editable, setEditable] = useState(null);

    //Fields from Graphs to View
    const [allLoanNameWeights, setAllLoanNameWeights] = useState(null);
    const [allLoanTypeWeights, setAllLoanTypeWeights] = useState(null);

    //Fields from table to View
    const [accountNameUpdate, setAccountNameUpdate] = useState(null);
    const [accountDescriptionUpdate, setAccountDescriptionUpdate] = useState(null);
    const [accountIBANUpdate, setAccountIBANUpdate] = useState(null);
    const [accountTypeUpdate, setAccountTypeUpdate] = useState(null);
    const [statusUpdate, setStatusUpdate] = useState(null);

  useEffect(() => {
    const getAllLoansStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-userloans', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllLoansStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllLoansStats();
  },[])

  useEffect(() => {
    const getAllNoLoansStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-usernoloans', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllNoLoansStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllNoLoansStats();
  },[])

    //Handler Sectors DropList ****************************************************************************************
    useEffect(() => {
      const getAllCategories = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/categories/get-findCategoryName', {
            userid:user._id,
            categoryName: categoryName,
            isCreditCard: "all"
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllCategory(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          //message.error('You are not Admin User');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllCategories();
    },[categoryName])

  var categoryID = allCategory[0];

  const [weightSubject, setWeightSubject]= useState([]);
  const [weightMarks, setWeightMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllLoanNameWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/transactions/get-ytdloanname',
    {
      userid:user._id,
      categoryName:categoryID
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    setAllLoanNameWeights(res.data);
    console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].loanName);
    sMarks.push(res.data[i].amount);
    }
    setWeightSubject(sSubject);
    setWeightMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllLoanNameWeights();
  },[categoryID])

  const [weightLoanTypeSubject, setWeightLoanTypeSubject]= useState([]);
  const [weightLoanTypeMarks, setWeightLoanTypeMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllLoanTypeWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/transactions/get-ytdloantype',
    {
      userid:user._id,
      categoryName:categoryID
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    setAllLoanTypeWeights(res.data);
    console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].loanType);
    sMarks.push(res.data[i].amount);
    }
    setWeightLoanTypeSubject(sSubject);
    setWeightLoanTypeMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllLoanTypeWeights();
  },[categoryID])

  const reload=()=>window.location.reload();

  const handleClose = () => {
    setShowModal(false)
    setAccountNameUpdate(null);
    setAccountDescriptionUpdate(null);
    setAccountIBANUpdate(null);
    setAccountTypeUpdate(null);
    setStatusUpdate(null);
    reload();
  };

  // const onFinish = (values) => {
  //   console.log({values
  //   });
  // };

  return (
    <Layout>
      {loading && <Spinner/>}
            {/* Start Header */}
            <div className="accountpage">
        <div></div>
        <div><h1>Meus Empréstimos</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
            <Button
              shape="round"
              className="btn btn-primary btn-block"
              style={{ background: "green", borderColor: "green" }}
              onClick={() => navigate('/NewLoans')}
            >
              + Novo Empréstimo
            </Button>
      </div>

      <div className="accountpagebuttonsAll">
        <Button
          shape="round"
          className="btn btn-primary btn-block"
          style={{ background: "blue", borderColor: "blue" }}
          onClick={() => navigate('/TransactionsLoansAll')}
        >
          Todos os Movimentos
        </Button>
      </div>
        {/* Final Header */}

      <hr></hr>
      {/* Start Graphs */}
      <div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
      <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
        <div className="chart-container">
        <Chart
          align="center" 
          type="donut"
          width="100%"
          series={weightMarks}                
          options={{
            responsive: [
              {
                breakpoint: 281, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 150 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 361, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 230 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 481, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 250 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 769, // iPhone 12 Mini width
                options: {
                  chart: {
                    width: '100%',
                    height: 275 // Adjust height for iPhone 12 Mini
                  }
                }
              },
              {
                breakpoint: 768, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1024, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1200, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 2000, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              }
            ],
          labels: weightSubject,
          noData: { text: "No Data" }, 
          plotOptions: {
            pie: {
            donut: {
              labels: {
              show: true,
              name: {
                formatter: function(val) {
                return val
                }
              }
              }
            }
            }
          },
          dataLabels: { enabled: true },
          legend: { show: false } // Hide legends
          }}
        />
        </div>
        <h4 align="center">Empréstimos YTD</h4>
      </div>
      <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
        <div className="chart-container">
        <Chart
          align="center" 
          type="donut"
          width="100%"
          series={weightLoanTypeMarks}                
          options={{
            responsive: [
              {
                breakpoint: 281, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 150 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 361, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 230 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 481, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 250 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 769, // iPhone 12 Mini width
                options: {
                  chart: {
                    width: '100%',
                    height: 275 // Adjust height for iPhone 12 Mini
                  }
                }
              },
              {
                breakpoint: 768, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1024, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1200, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 2000, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              }
            ],
          labels: weightLoanTypeSubject,
          noData: { text: "No Data" }, 
          plotOptions: {
            pie: {
            donut: {
              labels: {
              show: true,
              name: {
                formatter: function(val) {
                return val
                }
              }
              }
            }
            }
          },
          dataLabels: { 
            enabled: true,
          },
          legend: { show: false } // Hide legends
          }}
        />
        </div>
        <h4 align="center">Tipo de Empréstimo YTD</h4>
      </div>
      </div>
      {/* Final Graphs */}
      <hr></hr>
      <div className="content">
            <Row gutter={[8,24]}     
                style={{
                justifyContent: "center"
              }}>
                  {allLoansStats.map((opts, i) => (
                    <Col key={i} xs={24} sm={12} md={12} lg={8}>
                    <Card
                      key={i} value={opts._id}
                      size="small"
                      hoverable
                      style={{marginTop: 16, marginRight: "10px"}}
                      cover={[
                        <div align="center">
                          <br></br>
                          <div align="right" style={{marginRight: "20px"}}>
                          {opts._id.isPayedReceived
                            ? 
                            <Tooltip title="Pago">
                              <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
                            </Tooltip>
                            : 
                            <Tooltip title="Pendente">
                            <ExclamationCircleFilled className="mx-2" style={{color: "#FF3234"}}/>
                            </Tooltip>
                          }
                          </div>
                          <div>
                            <br></br>
                            <img alt="example" src={MyLoan}/>
                          </div>
                          <div>
                            <br></br>
                            <h4 className="price">{opts._id.loanName}</h4>
                          </div>
                        </div>
                      ]}
                      actions={[
                        <Tooltip title="Editar">
                        <SettingOutlined key="setting" 
                        onClick={() => {
                          console.log(opts);
                          navigate('/EditLoans',
                          {
                            state:{
                              loanId:opts._id.loanID,
                              loanName:opts._id.loanName,
                              loanType:opts._id.loanType,
                              loanDescription:opts._id.loanDescription,
                              loanCompany:opts._id.loanCompany,
                              loanNumber:opts._id.loanNumber,
                              loanIndex:opts._id.loanIndex,
                              loanIndexPercentage:opts._id.loanIndexPercentage,
                              loanSpreadPercentage:opts._id.loanSpreadPercentage,
                              loanEndDate:opts._id.loanEndDate,
                              frequency:opts._id.loanFrequency,
                              months: opts.loanMonths,
                              accountName:opts._id.accountName,
                              categoryName:opts._id.categoryName,
                              codeSquare: opts._id.codeSquare,
                              amountDebt:opts._id.amountDebt,
                            }
                          });
                        }}
                        />
                        </Tooltip>,
                        <Tooltip title="Transacções">
                        <UnorderedListOutlined
                        onClick={() => {
                          console.log(opts);
                          navigate('/TransactionsProduct',
                          {
                            state:{
                              accountId:opts._id.accountName,
                              name:opts._id.loanName,
                              codeSquare: opts._id.codeSquare,
                              categoryName: opts._id.categoryName
                            }
                          });
                        }}
                        />
                        </Tooltip>,
                        <Tooltip title="Arquivar">
                        <HddOutlined
                        onClick={() => {
                          console.log(opts);
                          navigate('/ArchiveLoans',
                          {
                            state:{
                              loanId:opts._id.loanID,
                              loanName:opts._id.loanName,
                              loanType:opts._id.loanType,
                              loanDescription:opts._id.loanDescription,
                              loanCompany:opts._id.loanCompany,
                              loanNumber:opts._id.loanNumber,
                              loanIndex:opts._id.loanIndex,
                              loanIndexPercentage:opts._id.loanIndexPercentage,
                              loanSpreadPercentage:opts._id.loanSpreadPercentage,
                              loanEndDate:opts._id.loanEndDate,
                              frequency:opts._id.loanFrequency,
                              months: opts.loanMonths,
                              accountName:opts._id.accountName,
                              categoryName:opts._id.categoryName,
                              codeSquare: opts._id.codeSquare,
                              amount: opts._id.amount,
                            }
                          });
                        }}
                        />
                        </Tooltip>
                      ]}
                      >
                      <Meta
                        description={[
                              <div align="center">
                                  <h4 className="price">{opts._id.loanType}</h4>
                              </div>
                              ]}
                            />
                        <br></br>
                        <div className="additional"  align="center">
                          <h4 className="price">Prestação: <span className="quantity">{opts.amount.toLocaleString('pt-BR')} €</span></h4>
                          <h4 className="price">Montante em Dívida: <span className="quantity">{opts._id.amountDebt.toLocaleString('pt-BR')} €</span></h4>
                          <hr></hr>
                          <h6 className="price">Meses até terminar contracto: {opts.loanMonths}</h6>
                          <hr></hr>
                          <h6 className="price">TANB: {(parseFloat(opts._id.loanIndexPercentage)+parseFloat(opts._id.loanSpreadPercentage)).toLocaleString('pt-BR')} %</h6>
                          <hr></hr>
                          <h6 className="price">Data de Pagamento<br></br>{opts.dayMonthYearNumber}</h6>
                        </div>
                      </Card>
                    </Col>
                  ))}

                    {/* Rendering cards for allNoAccountStats */}
                    {allNoLoansStats.map((opts, i) => (
                      <Col key={i} xs={24} sm={12} md={12} lg={8}>
                      <Card
                        key={i} value={opts._id}
                        size="small"
                        hoverable
                        style={{marginTop: 16, marginRight: "10px"}}
                        cover={[
                          <div align="center">
                            <br></br>
                            <div align="right" style={{marginRight: "20px"}}>
                            {opts.isPayedReceived
                              ? 
                              <Tooltip title="Pago">
                                <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
                              </Tooltip>
                              : 
                              <Tooltip title="Arquivar">
                              <ClockCircleFilled
                              className="mx-2" 
                              style={{color: "black"}}
                              onClick={() => {
                                console.log(opts);
                                navigate('/ArchiveLoans',
                                {
                                  state:{
                                    loanId:opts._id.loanID,
                                    loanName:opts._id.loanName,
                                    loanType:opts._id.loanType,
                                    loanDescription:opts._id.loanDescription,
                                    loanCompany:opts._id.loanCompany,
                                    loanNumber:opts._id.loanNumber,
                                    loanIndex:opts._id.loanIndex,
                                    loanIndexPercentage:opts._id.loanIndexPercentage,
                                    loanSpreadPercentage:opts._id.loanSpreadPercentage,
                                    loanEndDate:opts._id.loanEndDate,
                                    frequency:opts._id.loanFrequency,
                                    months: opts.loanMonths,
                                    accountName:opts._id.accountName,
                                    categoryName:opts._id.categoryName,
                                    codeSquare: opts._id.codeSquare
                                  }
                                });
                              }}
                              />
                              </Tooltip>
                            }
                            </div>
                            <div>
                              <br></br>
                              <img alt="example" src={MyLoan}/>
                            </div>
                            <div>
                              <br></br>
                              <h4 className="price">{opts._id.loanName}</h4>
                            </div>
                          </div>
                        ]}
                        actions={[
                          <SettingOutlined key="setting" 
                          onClick={() => {
                            console.log(opts);
                            navigate('/EditLoans',
                            {
                              state:{
                                loanId:opts._id.loanID,
                                loanName:opts._id.loanName,
                                loanType:opts._id.loanType,
                                loanDescription:opts._id.loanDescription,
                                loanCompany:opts._id.loanCompany,
                                loanNumber:opts._id.loanNumber,
                                loanIndex:opts._id.loanIndex,
                                loanIndexPercentage:opts._id.loanIndexPercentage,
                                loanSpreadPercentage:opts._id.loanSpreadPercentage,
                                loanEndDate:opts._id.loanEndDate,
                                frequency:opts._id.loanFrequency,
                                months: opts.loanMonths,
                                accountName:opts._id.accountName,
                                categoryName:opts._id.categoryName,
                                codeSquare: opts._id.codeSquare
                              }
                            });
                          }}
                          />,
                          <HddOutlined
                          onClick={() => {
                            console.log(opts);
                            navigate('/ArchiveLoans',
                            {
                              state:{
                                loanId:opts._id.loanID,
                                loanName:opts._id.loanName,
                                loanType:opts._id.loanType,
                                loanDescription:opts._id.loanDescription,
                                loanCompany:opts._id.loanCompany,
                                loanNumber:opts._id.loanNumber,
                                loanIndex:opts._id.loanIndex,
                                loanIndexPercentage:opts._id.loanIndexPercentage,
                                loanSpreadPercentage:opts._id.loanSpreadPercentage,
                                loanEndDate:opts._id.loanEndDate,
                                frequency:opts._id.loanFrequency,
                                months: opts.loanMonths,
                                accountName:opts._id.accountName,
                                categoryName:opts._id.categoryName,
                                codeSquare: opts._id.codeSquare,
                                amount: opts._id.amount,
                              }
                            });
                          }}
                          />
                        ]}
                        >
                        <Meta
                          description={[
                                <div align="center">
                                    <h4 className="price">{opts._id.loanType}</h4>
                                </div>
                                ]}
                              />
                          <br></br>
                          <div className="additional"  align="center">
                            <h4 className="price">Prestação: <span className="quantity">0 €</span></h4>
                            <h6 className="price">Ultimo Pagamento: Não existe </h6>
                          </div>
                        </Card>
                      </Col>
                    ))}
            </Row>
      </div>
      {/* <div><pre>{JSON.stringify(allLoansStats, null, 2) }</pre></div> */}

    </Layout>
  );
};

export default Loans;