import React,{useState,useEffect} from "react";
import {Form, Input, message, Select, Button, Space,Card} from 'antd';
import {useNavigate,useLocation,Link} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';


const EditCreditCards = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const creditCardDataUpdate = data;
  const creditCardIdUpdate = data.creditCardsID;
  const amountDebtUpdate = data.amountDebt;
  const creditCardNameUpdate = data.creditCardName;
  const creditCardTypeUpdate = data.creditCardType;
  const creditCardDescriptionUpdate = data.creditCardDescription;
  const creditCardCompanyUpdate = data.creditCardCompany;
  const currencyUpdate = data.currency;
  const TAEGUpdate = data.TAEG;
  const paymentOptionUpdate = data.paymentOption;
  const categoryNameUpdate = data.categoryName;
  const creditCardUpdate = data.codeSquare;

  var creditCardTypeDetail = creditCardTypeUpdate[0];

  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Handler Fund Family DropList ****************************************************************************************
    // useEffect(() => {
    //   const getAllAccounts = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/accounts/get-oneaccount', {
    //         userid:user._id,
    //         _id: accountNameUpdate
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllAccount(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       message.error('You are not Admin User');
    //     }
    //   };  
    //   getAllAccounts();
    // },[accountNameUpdate])

  
  //Handlers User  **************************************************************************************************************************
  let now = new Date();
  let today = new Date(now.getTime());

  const handleSubmitSubscription = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);

    if (values.status===true)
    {
      const res = await axios.post('/creditCards/edit-creditCard', {
        payload:{
          ...values,
          userid: user._id,
          status : false
        },
        _id: data.creditCardsID,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
    }else{
      const res = await axios.post('/creditCards/edit-creditCard', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: data.creditCardsID,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
    }

    setLoading(false);
    message.success('Cartão de Crédito Actualizado com sucesso!');
    navigate('/CreditCards');
    console.log(values)

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  const onChangeFrequency = state => {
    console.log('Frequency choose: ',state);
    setFrequencyDetails(state);
  };

  const [frequencyDetails, setFrequencyDetails] = useState(null);

  return (
    <Layout>
            <div className="accountpage">
        <div></div>
        <div><h1>Editar Cartão de Crédito - {creditCardNameUpdate}</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={handleSubmitSubscription} 
          >

        <Space direction="vertical">

       <Space direction="horizontal"> 

              <Form.Item style={{ marginTop:10 }} label="Plafond do Cartão de Crédito" name="amountDebt">
                <Input
                defaultValue={amountDebtUpdate}
                  suffix="€"
                  placeholder='0.00'
                  type="text"
                  size="large"
                  style={{fontSize:"32px"}}
                />
              </Form.Item>

              <Form.Item style={{ marginTop:10 }} label="Moeda"  name="currency">
                    <Input style={{fontSize:"32px", width: "100px"}} defaultValue={currencyUpdate} disabled="true"/>
              </Form.Item>
              </Space>

              <h5 style={{ marginBottom:30, marginTop:30 }}>Nome do Cartão de Crédito</h5>

              <Form.Item label="" name="creditCardName">
                <Input
                defaultValue={creditCardNameUpdate}
                type="text"
                />
              </Form.Item>

              <h5 style={{ marginBottom:30, marginTop:30 }}>Dados da Instituição Financeira</h5>

              <Form.Item label="Nome da Instituição Financeira" name="creditCardCompany">
                <Input
                defaultValue={creditCardCompanyUpdate}
                type="text"
                />
              </Form.Item>

              <h5 style={{ marginBottom:30, marginTop:30 }}>Dados do Cartão de Crédito</h5>

              <Space>
                <Form.Item label="Taxa Anual Nominal (TAEG)" name="TAEG">
                  <Input
                  type="number"
                  defaultValue={TAEGUpdate}
                  placeholder='0%'
                  suffix="%"
                  style={{
                    width: '200px',
                    fontSize:"32px"
                  }}
                  />
                </Form.Item>
              </Space>

              <Form.Item label="Opção de pagamento" name="paymentOption">
                <Select 
                  defaultValue={paymentOptionUpdate}
                  onChange={onChangeFrequency}>
                  <Select.Option value="5%">5%</Select.Option>
                  <Select.Option value="10%">10%</Select.Option>
                  <Select.Option value="25%">25%</Select.Option>
                  <Select.Option value="50%">50%</Select.Option>
                  <Select.Option value="100%">100%</Select.Option>
                </Select>
              </Form.Item>
              
              <Form.Item style={{ marginTop:30 }} label="Observações" name="creditCardDescription">
                <TextArea
                  defaultValue={creditCardDescriptionUpdate}
                  showCount
                  maxLength={500}
                  style={{
                    height: 100,
                    marginBottom: 24,
                  }}
                  type="text"
                  />
              </Form.Item>

              <Button style={{ width: "100%" , background: "green",color:"white", marginTop: 30, marginBottom: 30}}  htmlType='submit'>
                EDITAR
              </Button>
              <Link to="/CreditCards">
                <Button style={{ width: "100%" }} type="primary">
                  CANCELAR
                </Button>
                </Link>
        </Space>
        </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(accountDataUpdate, null, 2) }</pre></div> */}
    </Layout>
  );
};

export default EditCreditCards;