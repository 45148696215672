import React,{useState,useEffect} from "react";
import { message,Button,Tooltip,Row,Card} from 'antd';
import MyUtilities from "./../components/Assets/utilities.png";
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { CheckCircleFilled, ClockCircleFilled, ExclamationCircleFilled, HddOutlined, SettingOutlined, UnorderedListOutlined } from "@ant-design/icons";

import  Chart  from "react-apexcharts";

const Utilities = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { Meta } = Card;

  // const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  // const [allAccount, setAllAccount] = useState([]);

  const [allCategory, setAllCategory] = useState([]);

  const [allUtilitiesStats, setAllUtilitiesStats] = useState([]);
  
  const [allNoUtilitiesStats, setAllNoUtilitiesStats] = useState([]);

  // const [show, setShow] = useState(false);

  //Fields from Graphs to View
  // const [allYTDUtilityWeights, setAllYTDUtilityNameWeights] = useState(null);
  // const [allMTDUtilityWeights, setAllMTDUtilityWeights] = useState(null);

  // const { TextArea } = Input;

  //   //Edit Transactions
  //   const [editable, setEditable] = useState(null);

  //   //Fields from table to View
  //   const [accountNameUpdate, setAccountNameUpdate] = useState(null);
  //   const [accountDescriptionUpdate, setAccountDescriptionUpdate] = useState(null);
  //   const [accountIBANUpdate, setAccountIBANUpdate] = useState(null);
  //   const [accountTypeUpdate, setAccountTypeUpdate] = useState(null);
  //   const [statusUpdate, setStatusUpdate] = useState(null);

  useEffect(() => {
    const getAllUtilitiesStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-userutilities', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllUtilitiesStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    

    // Clean up the interval when the component unmounts
    return () => getAllUtilitiesStats();
  },[])

  useEffect(() => {
    const getAllNoUtilitiesStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-usernoutilities', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllNoUtilitiesStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    

    // Clean up the interval when the component unmounts
    return () => getAllNoUtilitiesStats();
  },[])

      //Handler Sectors DropList ****************************************************************************************
      useEffect(() => {
        const getAllCategories = async (values) => {
          try{
            const user = JSON.parse(localStorage.getItem('user'))
            const categoryName = user.isBusiness ? "Utilities" : "Housing (Utilities & Cable TV)";
            setLoading(true);
            const res = await axios.post('/categories/get-findCategoryName', {
              userid:user._id,
              categoryName: categoryName,
              isCreditCard: "all"
            },
            {
              headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
            }
            );
            setLoading(false);
            setAllCategory(res.data);
            console.log(res.data);
          }catch (error){
            setLoading(false);
            //message.error('Fetch Issue With sector');
            //message.error('You are not Admin User');
          }
        };  
        
        // Clean up the interval when the component unmounts
        return () => getAllCategories();
      },[])
  
    var categoryID = allCategory[0];
  
    const [weightSubject, setWeightSubject]= useState([]);
    const [weightMarks, setWeightMarks]= useState([]);
  
    useEffect(() => {
  
    const sSubject=[];
    const sMarks=[];
    const getAllLoanNameWeights = async (values) => {
      try{
      setLoading(true);
      const user = JSON.parse(localStorage.getItem('user'))
      const res = await axios.post('/transactions/get-ytdutilityname',
      {
        userid:user._id,
        categoryName:categoryID
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      // setAllYTDUtilityNameWeights(res.data);
      console.log(res.data);
  
      for(let i=0; i< res.data.length; i++)
      {
      sSubject.push(res.data[i].utilityName);
      sMarks.push(res.data[i].amount);
      }
      setWeightSubject(sSubject);
      setWeightMarks(sMarks);
      }catch (error){
      setLoading(false);
      // message.error('Fetch Issue With Sector');
      message.error('You are not Admin User');
      }
    };  
    getAllLoanNameWeights();
    },[categoryID])
  
    const [weightUtilityMTDSubject, setWeightUtilityMTDSubject]= useState([]);
    const [weightUtilityMTDMarks, setWeightUtilityMTDMarks]= useState([]);
  
    useEffect(() => {
  
    const sSubject=[];
    const sMarks=[];
    const getAllLoanTypeWeights = async (values) => {
      try{
      setLoading(true);
      const user = JSON.parse(localStorage.getItem('user'))
      const res = await axios.post('/transactions/get-mtdutilityname',
      {
        userid:user._id,
        categoryName:categoryID
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      // setAllYTDUtilityNameWeights(res.data);
      console.log(res.data);
  
      for(let i=0; i< res.data.length; i++)
      {
      sSubject.push(res.data[i].utilityName);
      sMarks.push(res.data[i].amount);
      }
      setWeightUtilityMTDSubject(sSubject);
      setWeightUtilityMTDMarks(sMarks);
      }catch (error){
      setLoading(false);
      // message.error('Fetch Issue With Sector');
      message.error('You are not Admin User');
      }
    };  
    getAllLoanTypeWeights();
    },[categoryID])

    
  //   //Handler Sectors DropList ****************************************************************************************
  //   useEffect(() => {
  //     const getAllCategories = async (values) => {
  //       try{
  //         const user = JSON.parse(localStorage.getItem('user'))
  //         setLoading(true);
  //         const res = await axios.post('/categories/get-findCategoryName', {
  //           userid:user._id,
  //           categoryName: categoryName
  //         },
  //         {
  //           headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //         }
  //         );
  //         setLoading(false);
  //         setAllCategory(res.data);
  //         console.log(res.data);
  //       }catch (error){
  //         setLoading(false);
  //         //message.error('Fetch Issue With sector');
  //         //message.error('You are not Admin User');
  //       }
  //     };  
  //     getAllCategories();
  //   },[categoryName])

  // var categoryID = allCategory[0];

  // const reload=()=>window.location.reload();

  // const handleClose = () => {
  //   setShowModal(false)
  //   setAccountNameUpdate(null);
  //   setAccountDescriptionUpdate(null);
  //   setAccountIBANUpdate(null);
  //   setAccountTypeUpdate(null);
  //   setStatusUpdate(null);
  //   reload();
  // };

  // const onFinish = (values) => {
  //   console.log({values
  //   });
  // };

  return (
    <Layout>
      {loading && <Spinner/>}
                  {/* Start Header */}
                  <div className="accountpage">
        <div></div>
        <div><h1>Minhas Utilities & Comunicações</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
            <Button
              shape="round"
              className="btn btn-primary btn-block"
              style={{ background: "green", borderColor: "green" }}
              onClick={() => navigate('/NewUtilities')}
            >
              + Novo Serviço
            </Button>
      </div>

      <div className="accountpagebuttonsAll">
        <Button
          shape="round"
          className="btn btn-primary btn-block"
          style={{ background: "blue", borderColor: "blue" }}
          onClick={() => navigate('/TransactionsUtilitiesAll')}
        >
          Todos os Movimentos
        </Button>
      </div>
        {/* Final Header */}
      <hr></hr>
            {/* Start Graphs */}
      <div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
      <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
        <div className="chart-container">
        <Chart
          align="center" 
          type="donut"
          width="100%"
          series={weightMarks}                
          options={{
            responsive: [
              {
                breakpoint: 281, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 150 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 361, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 230 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 481, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 250 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 769, // iPhone 12 Mini width
                options: {
                  chart: {
                    width: '100%',
                    height: 275 // Adjust height for iPhone 12 Mini
                  }
                }
              },
              {
                breakpoint: 768, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1024, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1200, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 2000, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              }
            ],
          labels: weightSubject,
          noData: { text: "No Data" }, 
          plotOptions: {
            pie: {
            donut: {
              labels: {
              show: true,
              name: {
                formatter: function(val) {
                return val
                }
              }
              }
            }
            }
          },
          dataLabels: { enabled: true },
          legend: { show: false } // Hide legends
          }}
        />
        </div>
        <h4 align="center">Utilities YTD</h4>
      </div>
      <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
        <div className="chart-container">
        <Chart
          align="center" 
          type="donut"
          width="100%"
          series={weightUtilityMTDMarks}                
          options={{
            responsive: [
              {
                breakpoint: 281, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 150 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 361, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 230 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 481, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 250 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 769, // iPhone 12 Mini width
                options: {
                  chart: {
                    width: '100%',
                    height: 275 // Adjust height for iPhone 12 Mini
                  }
                }
              },
              {
                breakpoint: 768, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1024, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1200, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 2000, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              }
            ],
          labels: weightUtilityMTDSubject,
          noData: { text: "No Data" }, 
          plotOptions: {
            pie: {
            donut: {
              labels: {
              show: true,
              name: {
                formatter: function(val) {
                return val
                }
              }
              }
            }
            }
          },
          dataLabels: { 
            enabled: true,
          },
          legend: { show: false } // Hide legends
          }}
        />
        </div>
        <h4 align="center">Utilities MTD</h4>
      </div>
      </div>
      {/* Final Graphs */}

      <div className="content">
            <Row gutter={[8,24]}     
                style={{
                // height: 200,
                justifyContent: "center"
              }}>
                  {
                  allUtilitiesStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    hoverable
                    style={{ width: 350 , marginTop: 16, marginRight: "10px"}}
                    cover={[
                      <div align="center">
                        <br></br>
                        <div align="right" style={{marginRight: "20px"}}>
                        {opts.isPayedReceived
                          ? 
                          <Tooltip title="Pago">
                            <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
                          </Tooltip>
                          : 
                          <Tooltip title="Pendente">
                          <ExclamationCircleFilled className="mx-2" style={{color: "#FF3234"}}/>
                          </Tooltip>
                        }
                        </div>
                        <div>
                          <br></br>
                          <img alt="example" src={MyUtilities}/>
                        </div>
                        <div>
                          <br></br>
                          <h4 className="price">{opts._id.utilityName}</h4>
                        </div>
                      </div>
                    ]}
                    actions={[
                      <Tooltip title="Editar">
                      <SettingOutlined key="setting" 
                      onClick={() => {
                        console.log(opts);
                        navigate('/EditUtilities',
                        {
                          state:{
                            utilityId:opts._id.utilityID,
                            utilityName:opts._id.utilityName,
                            utilityType:opts._id.utilityType,
                            utilityDescription:opts._id.utilityDescription,
                            frequency:opts._id.utilityFrequency,
                            months: opts._id.utilityMonths,
                            accountName:opts._id.accountName,
                            categoryName:opts._id.categoryName,
                            codeSquare: opts._id.codeSquare
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                      <Tooltip title="Transacções">
                      <UnorderedListOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/TransactionsProduct',
                        {
                          state:{
                            accountId:opts._id.accountName,
                            name:opts._id.utilityName,
                            codeSquare: opts._id.codeSquare,
                            categoryName: opts._id.categoryName
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                      <Tooltip title="Arquivar">
                      <HddOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/ArchiveUtilities',
                        {
                          state:{
                            utilityId:opts._id.utilityID,
                            utilityName:opts._id.utilityName,
                            utilityType:opts._id.utilityType,
                            utilityDescription:opts._id.utilityDescription,
                            frequency:opts._id.utilityFrequency,
                            months: opts._id.utilityMonths,
                            accountName:opts._id.accountName,
                            categoryName:opts._id.categoryName,
                            codeSquare: opts._id.codeSquare
                          }
                        });
                      }}
                      />
                      </Tooltip>
                    ]}
                    >
                    <Meta
                      description={[
                            <div align="center">
                                <h4 className="price">{opts._id.utilityType}</h4>
                            </div>
                            ]}
                          />
                      <br></br>
                      <div className="additional"  align="center">
                        <h4 className="price">Montante: <span className="quantity">{opts.amount.toLocaleString('pt-BR')} €</span></h4>
                        <hr></hr>
                        <h6 className="price">Data de Pagamento<br></br>{opts.dayMonthYearNumber}</h6>
                        {/* <p>Author: <span className="quantity">John Doe</span></p> */}
                      </div>
                    </Card>)
                  }
                                    {
                  allNoUtilitiesStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    hoverable
                    style={{ width: 350 , marginTop: 16, marginRight: "10px"}}
                    cover={[
                      <div align="center">
                        <br></br>
                        <div align="right" style={{marginRight: "20px"}}>
                        {opts.isPayedReceived
                          ? 
                          <Tooltip title="Pago">
                            <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
                          </Tooltip>
                          : 
                          <Tooltip title="Arquivar">
                          <ClockCircleFilled 
                          className="mx-2" 
                          style={{color: "black"}}
                          onClick={() => {
                            console.log(opts);
                            navigate('/ArchiveUtilities',
                            {
                              state:{
                                utilityId:opts._id.utilityID,
                                utilityName:opts._id.utilityName,
                                utilityType:opts._id.utilityType,
                                utilityDescription:opts._id.utilityDescription,
                                frequency:opts._id.utilityFrequency,
                                months: opts._id.utilityMonths,
                                accountName:opts._id.accountName,
                                categoryName:opts._id.categoryName,
                                codeSquare: opts._id.codeSquare
                              }
                            });
                          }}
                          />
                          </Tooltip>
                        }
                        </div>
                        <div>
                          <br></br>
                          <img alt="example" src={MyUtilities}/>
                        </div>
                        <div>
                          <br></br>
                          <h4 className="price">{opts._id.utilityName}</h4>
                        </div>
                      </div>
                    ]}
                    actions={[
                    <Tooltip title="Arquivar">
                      <HddOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/ArchiveUtilities',
                        {
                          state:{
                            utilityId:opts._id.utilityID,
                            utilityName:opts._id.utilityName,
                            utilityType:opts._id.utilityType,
                            utilityDescription:opts._id.utilityDescription,
                            frequency:opts._id.utilityFrequency,
                            months: opts._id.utilityMonths,
                            accountName:opts._id.accountName,
                            categoryName:opts._id.categoryName,
                            codeSquare: opts._id.codeSquare
                          }
                        });
                      }}
                      />
                      </Tooltip>
                    ]}
                    >
                    <Meta
                      description={[
                            <div align="center">
                                <h4 className="price">{opts._id.utilityType}</h4>
                            </div>
                            ]}
                          />
                      <br></br>
                      <div className="additional"  align="center">
                         <h4 className="price">Montante: <span className="quantity">0 €</span></h4>
                         <hr></hr>
                        <h6 className="price">Ultimo Pagamento<br></br>Não existe </h6>
                        {/* <p>Author: <span className="quantity">John Doe</span></p> */}
                      </div>
                    </Card>)
                  }
            </Row>
      </div>
      {/* <div><pre>{JSON.stringify(allUtilitiesStats, null, 2) }</pre></div> */}

    </Layout>
  );
};

export default Utilities;