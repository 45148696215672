import React,{useState,useEffect} from 'react';
import {Steps,Form,Input,Button,Space,Radio,message,Select,Row} from 'antd';
import { CheckCircleOutlined,ProfileOutlined, SettingOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import Layout from "../components/Layout/Layout";
import {useNavigate} from "react-router-dom";

import axios from "axios";
import Spinner from '../components/Spinner';

const NewCreditCards = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  //loading Apis
  const [loading,setLoading] = useState(false);

  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState('')

  const { TextArea } = Input;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const [current, setCurrent] = useState(0);
  const [typeDetails, setTypeDetails] = useState(null);
  const [infoDetails, setInfoDetails] = useState(null);

  const [frequencyDetails, setFrequencyDetails] = useState(null);

  const onFinishTypeForm=(values) => {
    setTypeDetails(values);
    setCurrent(1);
  }

  const onChangeAccount = ({ target: { value } }) => {
    console.log('card choose: ', value);
    setTypeDetails(value);
    setCurrent(1);
  };

  const onFinishInfoForm=(values) => {
    setInfoDetails(values);
    setCurrent(2);
  }

  const onChangeFrequency = state => {
    console.log('Frequency choose: ',state);
    setFrequencyDetails(state);
  };

  const forms =[
    <TypeForm 
      onFinish={onFinishTypeForm} 
      initialValues={typeDetails}/>,
    <InfoForm 
      onFinish={onFinishInfoForm} 
      initialValues={infoDetails}/>,
    <FinishForm />
  ]

  const isStepDisabled=(stepnumber)=>{
    if(stepnumber===0){
      return false
    }
    if(stepnumber===1){
      return typeDetails === null
    }
    if(stepnumber===2){
      return typeDetails === null || infoDetails === null
    }
  }

  const user = JSON.parse(localStorage.getItem('user'))

  let range = {min: 1, max: 999999999}
let delta = range.max - range.min

const codeSquare = Math.round(range.min + Math.random() * delta)

  const submitHandler = async (values) => {

    try{
      setLoading(true);
      const res = await axios.post('/accounts/add-account', {
        userid:user._id,
        codeSquare: codeSquare,
        accountType:"Cartão de Crédito",
        creditCardType:typeDetails,
        accountName:infoDetails.accountName,
        accountDescription:infoDetails.accountDescription,
        loanIndexPercentage: infoDetails.loanIndexPercentage,
        amountDebt: infoDetails.amountDebt,
        paymentOptions: infoDetails.paymentOptions,
        isCreditCard: true,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Cartão de Crédito criado com Sucesso!');
      navigate('/NewBalanceInitial', 
      {state:{
        codeSquare:codeSquare,
        accountName:infoDetails.accountName,
        accountType:"Cartão de Crédito"}
      });

      console.log(res.data);
    }catch (error){
      setLoading(false);
      message.error('Algo deu errado!');
    }
  }

  function TypeForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "100%" }} onFinish={onFinish} initialValues={initialValues}>
        <h4 align="center">Escolha o Tipo de Cartão de Crédito</h4>

        <hr/>
        <br/>
        <Radio.Group onChange={onChangeAccount} style={{ display: "flex", justifyContent: "center"}} >
              <Space direction="vertical" align='center' size="middle">
                <Row gutter={[8,24]}>
                <Radio.Button value="VISA" style={{ width: "100%", marginTop: 5, textAlign: "center"}}>VISA</Radio.Button>
            <Radio.Button value="MasterCard" style={{ width: "100%", marginTop: 5, textAlign: "center" }}>MasterCard</Radio.Button>
            <Radio.Button value="Maestro" style={{width: "100%", marginTop: 5, textAlign: "center" }}>Maestro</Radio.Button>
            <Radio.Button value="American Express" style={{ width: "100%", marginTop: 5, textAlign: "center" }}>American Express</Radio.Button>
            <Radio.Button value="Outro" style={{ width: "100%", marginTop: 5, textAlign: "center" }}>Outro</Radio.Button>
                </Row>
              </Space>
            </Radio.Group>

        <Link to="/CreditCards">
        <Button style={{ width: "100%", marginTop: 30 }} type="primary">
          CANCELAR
        </Button>
        </Link>

    </Form>   
    )
  }

  function InfoForm({onFinish, initialValues}){
    return (
      <Form layout="vertical" style={{ width: "100%" }} onFinish={onFinish} initialValues={initialValues}>
        <h4 align="center">Conte-nos mais sobre o seu novo Cartão de Crédito</h4>
        <hr/>
        <br/>
      <Form.Item label="Nome do Cartão de Crédito" name={"accountName"} 
        rules={[{
          required:true, 
          message:"Por favor, insira o nome que quer dar ao seu cartão de crédito"
        }]}
      >
        <Input />
      </Form.Item>

        <Form.Item label="Taxa Anual Nominal (TAEG)" name={"loanIndexPercentage"}>
          <Input
          type="number"
          defaultValue={"0"}
          placeholder='0%'
          suffix="%"
          style={{
            width: '180px',
            fontSize:"32px"
          }}
          />
        </Form.Item>

        <Space direction="horizontal"  className="horizontal-space">

          <Form.Item style={{ marginTop:10 }} label="Plafond do Cartão de Crédito" name={"amountDebt"}>
            <Input
              suffix="€"
              placeholder='0.00'
              type="text"
              size="large"
              style={{width: '180px',fontSize:"32px"}}
            />
          </Form.Item>
          <Form.Item style={{ marginTop:10 }} label="Moeda"  name={"currency"}>
                <Input style={{fontSize:"32px", width: "100px"}} defaultValue={"EUR"} disabled="true"/>
          </Form.Item>
          </Space>
          {/* <Form.Item label="Data do cartão de crédito" name="date" 
              rules={[
                { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                  ]}>
              <Input style={{fontSize:"18px"}} type="date"/>
          </Form.Item> */}

        <Form.Item label="Opção de pagamento" name={"paymentOptions"}>
          <Select>
            <Select.Option value="5%">5%</Select.Option>
            <Select.Option value="10%">10%</Select.Option>
            <Select.Option value="25%">25%</Select.Option>
            <Select.Option value="50%">50%</Select.Option>
            <Select.Option value="100%">100%</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Descrição" name={"accountDescription"}>
          <TextArea
            showCount
            maxLength={500}
            style={{
              height: 100,
              marginBottom: 24,
            }}
            type="text"
            />
      </Form.Item>

      <Button style={{ width: "100%" , background: "green", marginTop: 30, marginBottom: 30 }} type="primary" htmlType='submit'>
              CRIAR
              </Button>
              <Link to="/CreditCards">
                <Button style={{ width: "100%"}} type="primary">
                  CANCELAR
                </Button>
                </Link>
    </Form>)
  }

  function FinishForm(){
    return (<>
        <div>
          <Space direction="vertical">
            <h2>Parabéns, seu cartão de crédito foi criado com sucesso!</h2>
            <br/>
            
            <Button style={{ width: "100%" }}  type="primary" onClick={submitHandler}>
              IR PARA A DASHBOARD
          </Button>
          </Space>
        </div>
    </>)
  }

  // const onFinish = (values) => {
  //   console.log({
  //     accountType:typeDetails,
  //     accountName:infoDetails.accountName,
  //     accountDescription:infoDetails.accountDescription,
  //     loanIndexPercentage: infoDetails.loanIndexPercentage,
  //     amountDebt: infoDetails.amountDebt,
  //     amountAvailable: infoDetails.amountAvailable,
  //     paymentOptions: infoDetails.paymentOptions,
  //   });
  // };

  return (
    <Layout>
      {loading && <Spinner/>}
          <div aling="center" className='registerAccount'>
            {loading && <Spinner/>}
            <Steps 
              style={{ padding: "10x 16px"}}
              onChange={setCurrent}
              current={current}
              >
              <Steps.Step disabled={isStepDisabled(0)} title='Tipo de Cartão de Crédito' icon={<ProfileOutlined />} />
              <Steps.Step disabled={isStepDisabled(1)} title='Configuração do Cartão de Crédito' icon={<SettingOutlined />} />
              <Steps.Step disabled={isStepDisabled(2)} title='Fim' icon={<CheckCircleOutlined />} />
            </Steps>
            </div>
            <div className="registerAccount-page">
              {forms[current]}
            </div>
            {/* <div><pre>{JSON.stringify(typeDetails, null, 2) }</pre></div>
            <div><pre>{JSON.stringify(infoDetails, null, 2) }</pre></div> */}
    </Layout>
  );
};

export default NewCreditCards;