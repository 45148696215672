import React,{useState,useEffect} from 'react';
import {Form,Input,Button,Space,Radio,message,Select,Row} from 'antd';
import { Link } from 'react-router-dom';

import Layout from "../components/Layout/Layout";
import {useNavigate} from "react-router-dom";

import axios from "axios";
import Spinner from '../components/Spinner';

import moment from 'moment';

const NewExchanges = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const categoryName= "Savings and Investments";

  let now=(moment().format('YYYY-MM-DD'));

  //loading Apis
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState('')

  //DropList Category
  const [allCategory, setAllCategory] = useState([]);

  //DropList Market
  const [allcryptos, setAllCryptos] = useState([]);
  //Passing Category Name
  const [marketIdAll, setMarketIdAll]= useState([]);

  //Add Tags
  const [tags, setTags] = useState([]);

  //Recursive Dates Array
  const [sdates, setsDates]= useState([]);
  const [sdatesEnd, setsDatesEnd]= useState([]);
  const [dateUpdate, setDateUpdate] = useState();

  // Define state variables for the two numbers and the result
  const [num1, setNum1] = useState();
  const [num2, setNum2] = useState();
  const [result, setResult] = useState(0);

  const [getFrequency, setGetFrequency] = useState();
  const [dateShared, setDateShared] = useState(now);
  const [dateSharedDate, setDateSharedDate] = useState();
  const [sharedDetails, setSharedDetails] = useState(false);
  const [sharedPersonalDetails, setSharedPersonalDetails] = useState(false);
  const [sharedInformationDetails, setSharedInformationDetails] = useState(false);
  

  const { TextArea } = Input;

  const [investmentDetails, setinvestmentDetails] = useState(null);
  const [accountDetails, setAccountDetails] = useState(null);
  const [frequencyDetails, setFrequencyDetails] = useState(null);
  const [goalDetails, setGoalDetails] = useState(null);
  const [brokerDetails, setBrokerDetails] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user = JSON.parse(localStorage.getItem('user'))

  const toggleActive = name => event => {
    console.log("name: ",name) // prints "name: Active!"
    console.log("event: ",event) // event is some data
    setMarketIdAll(event)
  }
    
  //***************************************MONTHS RECURSIVES**************************************************************/
    //Recursive Dates Array
    const [sMonth, setMonth]= useState('0');

    const handleMonths=(event)=>{
  
        setMonth([]);
    
        const getMonth= event.target.value;
    
        console.log(getMonth); //22
        console.log(sdates); //2023-01-06
    
        const dates = [];
        const currDate = moment(sdates);
    
        if (getMonth === "1" && frequencyDetails==="Mensal" ) {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else if (getMonth !== "1" && frequencyDetails==="Mensal" ){
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add(getMonth-1, 'month').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          //console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(1, "month").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else if (getMonth !== "1" && frequencyDetails==="Trimestral" ){
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add(getMonth-1, 'quarter').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          //console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(1, "quarter").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else if (getMonth !== "1" && frequencyDetails==="Semestral" ){
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add((getMonth*2)-2, 'quarter').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(2, "quarter").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else{
            //console.log(currDate); //2023-01-06

            let lastDate = moment(currDate).add(getMonth-1, 'year').toDate();
            //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
      
            //console.log(lastDate); //2028-04-06
      
            do {
            dates.push(moment(currDate).format('YYYY-MM-DD'));
            } while (currDate.add(1, "year").diff(lastDate) < 0);
            //dates.push(currDate.clone().toDate());
            dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
    
        message.success(dates);
        console.log(dates);
    
        setMonth(dates);
      }

    const worker = {
    dates: moment(dateUpdate),
    };

    //Handler Fund Family DropList ****************************************************************************************
    // // GOALS
    // useEffect(() => {
    //   const getAllAccounts = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/accounts/get-account', {
    //         userid:user._id,
    //         status: true
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllAccount(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       ////message.error('You are not Admin User');
    //     }
    //   };  
    //   getAllAccounts();
    // },[])

  // useEffect(() => {
  //   const getAllETFAsset = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/etfAssets/get-etfAsset',
  //       {
  //         userid:user._id,
  //         fundFamilyName: "all",
  //         isFund: false
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllEtfAsset(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With Industry');
  //       ////message.error('You are not Admin User');
  //     }
  //   };  
  //   getAllETFAsset();
  // },[])

  // useEffect(() => {
  //   const getAllInvestmentFundAsset = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/etfAssets/get-etfAsset',
  //       {
  //         userid:user._id,
  //         fundFamilyName: "all",
  //         isFund: true
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllInvestmentFundAsset(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With Industry');
  //       ////message.error('You are not Admin User');
  //     }
  //   };  
  //   getAllInvestmentFundAsset();
  // },[])

    // useEffect(() => {
    //   const getAllMarkets = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/markets/get-stockmarket', {
    //         userid:user._id,
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllMarkets(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       ////message.error('You are not Admin User');
    //     }
    //   };  
    //   getAllMarkets();
    // },[])
  
  
    //Handler Sectors DropList ****************************************************************************************
    useEffect(() => {
      const getAllCategories = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/categories/get-findCategoryName', {
            userid:user._id,
            categoryName: categoryName
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllCategory(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          ////message.error('You are not Admin User');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllCategories();

    },[categoryName])

var categoryID = allCategory[0];

let range = {min: 1, max: 999999999}
let delta = range.max - range.min

const codeSquare = Math.round(range.min + Math.random() * delta)

  const submitHandler = async (values) => {

    try{
          setLoading(true);
          const res = axios.post('/exchanges/add-exchange', {
            userid:user._id,
            exchangeName:values.exchangeName,
            exchangeDescription:values.exchangeDescription,
            brokerName:brokerDetails,
            currency:values.currency,
            assetType:values.assetType,
            quantity: 1,
            exchangeStartDate:values.exchangeStartDate,
            exchangeEndDate:values.exchangeEndDate,
            frequency:frequencyDetails,
            goalName: goalDetails,
            date: values.exchangeStartDate,
            codeSquare: codeSquare,
            apiKey: values.apiKey,
            keySecret: values.keySecret
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          const res2 = axios.post('/exchanges/create-exchange/'+ user._id, {
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
      ;
      message.success('Conta Exchange Criada com Sucesso!');
      navigate('/ProcessingExchanges',
        {
          state:{
            exchangeName:values.exchangeName,
            processing:"Portfólio de Exchange",
            navigateLink:"/Exchanges",
          }
        }
      );
      setLoading(false);
    }catch (error){
      setLoading(false);
      message.error('Algo deu errado!');
    }
  }

  // //Handler Test Console Log Values
  const onFinish = (values) => {
    console.log({
      ...values,
      userid:user._id,
      exchangeName:values.exchangeName,
      exchangeDescription:values.exchangeDescription,
      brokerName:brokerDetails,
      currency:values.currency,
      assetType:values.assetType,
      quantity: 1,
      exchangeStartDate:values.exchangeStartDate,
      exchangeEndDate:values.exchangeEndDate,
      frequency:frequencyDetails,
      goalName: goalDetails,
      date: values.exchangeStartDate,
      codeSquare: codeSquare,
      apiKey: values.apiKey,
      keySecret: values.keySecret
    });
  };

  const handleDate=(event)=>{
    setsDates([]);
    const getDates= moment(event.target.value);
    setsDates(getDates);
    console.log(getDates);
  }

  // let brokerInformationHTML;
  // if (brokerDetails==="Binance") {
  //   brokerInformationHTML =
  //   <Space direction="vertical"  align='center' size="middle">
  //           <Form.Item label="Api Key" name="apiKey">
  //             <Input
  //                 style={{
  //                   width: '100%'
  //                 }}  
  //             type="text"
  //             />
  //           </Form.Item>
  //           <Form.Item label="Key Secret" name="keySecret">
  //             <Input 
  //             type="text"
  //             style={{
  //               width: '100%'
  //             }} 
  //             />
  //           </Form.Item>
  //   </Space>
  //       ;
  // } else {
  //   brokerInformationHTML =
  //     <br></br>;
  //     // <p>Sem dados</p>;
  //     <Form.Item hidden="true">
  //     <Input 
  //         type="text"
  //     />
  // </Form.Item>;
  // }

  // // Function to handle the subtraction
  // const handleSubtraction = () => {
  //   if ( frequencyDetails === "Trimestral") {
  //     const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'quarter', true)));
  //     setResult(subtractionResult);
  //     console.log(result)
  //   } else if (frequencyDetails === "Anual"){
  //       const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'year', true)));
  //       setResult(subtractionResult);
  //       console.log(result)
  //     } else if (frequencyDetails === "Semestral"){
  //       const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'quarter', true))/2);
  //       setResult(subtractionResult);
  //       console.log(result)
  //     }else {
  //       const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'month', true)));
  //       setResult(subtractionResult);
  //       console.log(result)
  //     }
  //   ;
  // };

  // const onChangeInvestment = ({ target: { value } }) => {
  //   console.log('Investment choose: ', value);
  //   setinvestmentDetails(value);
  // };

  const onChangeGoal = ({ target: { value } }) => {
    console.log('Goal choose: ', value);
    setGoalDetails(value);
  };

  const onChangeBroker = ({ target: { value } }) => {
    console.log('Broker choose: ', value);
    setBrokerDetails(value);
  };

  const onChangeFrequency = state => {
    console.log('Frequency choose: ',state);
    setFrequencyDetails(state);
  };

  //DEFAULT DATA FIM DO INVESTIMENTO (FUTURO TRAZER DATA FIM DA META/OBJECTIVO)
  let defaultDate = new Date()
  defaultDate.setDate(defaultDate.getDate() + 365)
  console.log(defaultDate)

  const [date, setDate] = useState(defaultDate)
  console.log(date)

  // const onSetDate = (event) => {
  //     setDate(new Date(event.target.value))
  // }

  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Nova Exchange</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={submitHandler} 
          >
          <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da Exchange</h5>

          <Form.Item label="" name="exchangeName">
            <Input 
            type="text"
            />
          </Form.Item>

          <Form.Item style={{ marginTop:30 }} label="Observações" name="exchangeDescription">
              <TextArea
                showCount
                maxLength={550}
                style={{
                  height: 100,
                  width: "100%",
                  marginBottom: 24,
                }}
                type="text"
                />
            </Form.Item>
              
            <h5 style={{ marginBottom:30, marginTop:30 }}>Nome do Broker</h5>

            <Radio.Group onChange={onChangeBroker} style={{ marginBottom:"50px", display: "flex", justifyContent: "left"}} required>
            <Space direction="vertical" align='center' size="middle">
              <Row gutter={[4,12]}>
                  <Radio.Button value="Binance" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Binance</Radio.Button>
                  <Radio.Button disabled="true" value="XTB" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>XTB</Radio.Button>
              </Row>
            </Space>
          </Radio.Group>
          {setBrokerDetails ? null : <span style={{ color: 'red' }}>* Campo de preenchimento obrigatório</span>}

          {/* {brokerInformationHTML} */}

          <Form.Item label="Api Key" name="apiKey">
              <Input
                  style={{
                    width: '100%'
                  }}  
              type="text"
              />
            </Form.Item>
            <Form.Item label="Key Secret" name="keySecret">
              <Input 
              type="text"
              style={{
                width: '100%'
              }} 
              />
            </Form.Item>


            <Form.Item label="Tipo de Activos" name="assetType">
                  <Select
                      style={{
                        width: '100%',
                      }} 
                  >
                    <Select.Option value="Acções, ETFs, Fundos de Investimento">Acções, ETFs, Fundos de Investimento</Select.Option>
                    <Select.Option value="FOREX">FOREX</Select.Option>
                    <Select.Option value="Criptomoedas">Criptomoedas</Select.Option>            
                    <Select.Option value="Outros">Outros</Select.Option>
                  </Select>
              </Form.Item>

              <Form.Item style={{ marginBottom:30}} label="Moeda" name="currency">
                  <Select
                      style={{
                        width: '100%',
                      }} 
                  >
                    {/* <Select.Option value="EUR">EUR</Select.Option> */}
                    <Select.Option value="USD">USD</Select.Option>
                    {/* <Select.Option value="CHF">CHF</Select.Option>
                    <Select.Option value="GBP">GBP</Select.Option>
                    <Select.Option value="HKD">HKD</Select.Option>
                    <Select.Option value="BRL">BRL</Select.Option>
                    <Select.Option value="JPY">JPY</Select.Option>              
                    <Select.Option value="Other">Other</Select.Option> */}
                  </Select>
              </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Objectivo para que Investe</h5>

          <Radio.Group onChange={onChangeGoal} style={{ display: "flex", justifyContent: "left"}} required>
            <Space direction="vertical" align='center' size="middle">
              <Row gutter={[8,24]}>
              <Radio.Button value="Fundo de Emergência" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Fundo de Emergência</Radio.Button>
                  <Radio.Button value="Reforma" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>Reforma</Radio.Button>
                  <Radio.Button value="Habitação" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>Habitação</Radio.Button>
                  <Radio.Button value="Educação" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Educação</Radio.Button>
                  <Radio.Button value="Saúde" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Saúde</Radio.Button>
                  <Radio.Button value="Viagem" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Viagem</Radio.Button>
                  <Radio.Button value="Automóvel" style={{display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Automóvel</Radio.Button>
                  <Radio.Button value="Mota" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Mota</Radio.Button>
                  <Radio.Button value="Filantropia" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Filantropia</Radio.Button>
                  <Radio.Button value="Outro" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>Outro</Radio.Button>
              </Row>
            </Space>
          </Radio.Group>
          {setGoalDetails ? null : <span style={{ color: 'red' }}>* Campo de preenchimento obrigatório</span>}

          <h5 style={{ marginBottom:30, marginTop:30 }}>Dados da Wallet</h5>

          <Form.Item 
            label="Data começo da conta exchange" 
            name="exchangeStartDate" 
            onChange={(e)=> {handleDate(e)}}
                rules={[
                  { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                    ]}
                    >
                <Input style={{fontSize:"18px"}} type="date"/>
            </Form.Item>

            <Form.Item label="Frequência de que tenciona investir" name="frequency" >
              <Select 
                onChange={onChangeFrequency}
                style={{
                  width: '220px'
                }} >
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item 
            label="Data que pensa mobilizar totalmente a conta exchange *opcional" 
            name="exchangeEndDate" 
            // onChange={(e)=> {handleDateEnd(e); handleSubtraction(e)}}
                // rules={[
                //   { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                //     ]}
                >
                <Input style={{fontSize:"18px"}} 
                type="date" 
                // defaultValue={date.toLocaleDateString('en-CA')} 
                // onChange={onSetDate}
                />
            </Form.Item>

            {/* <Form.Item label={labelFrequency} name="frequency" >
              <Select 
              onChange={onChangeFrequency}
                            style={{
                              width: '410px'
                            }} >
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item> */}

              <Button style={{ width: "100%" , background: "green", color:"white", marginTop: 30, marginBottom: 30}}  htmlType='submit'>
              CRIAR
              </Button>
              <Link to="/Exchanges">
                <Button style={{ width: "100%" }} type="primary">
                  CANCELAR
                </Button>
                </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(allExpenses, null, 2) }</pre></div> */}
    </Layout>
  )
  };

export default NewExchanges;