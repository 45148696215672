import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Table, Switch, Select, Button} from 'antd';

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {useNavigate } from "react-router-dom";

import {
  SearchOutlined,
  EditOutlined
} from "@ant-design/icons";

const Market = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allMarket, setAllMarket] = useState([]);

  //DropList Sectors
  const [allCountry, setAllCountry] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Fields from table to View
  const [marketNameUpdate, setMarketNameUpdate] = useState(null);
  const [marketNamePTUpdate, setMarketNamePTUpdate] = useState(null);
  const [yahooIndexUpdate, setYahooIndexUpdate] = useState(null);
  const [countryNameUpdate, setCountryNameUpdate] = useState(null);
  const [currencyUpdate, setCurrencyUpdate] = useState(null);
  const [statusUpdate, setStatusUpdate] = useState(null);
  const [isStockUpdate, setIsStockUpdate] = useState(null);
  const [ibPrimaryMarketUpdate, setIbPrimaryMarketUpdate] = useState(null);
  const [ibExchangeUpdate, setIbExchangeUpdateUpdate] = useState(null);
  
  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "marketName",
      title: "Market Name",
      dataIndex: "marketName",
      sorter: (a, b) => a.marketName.length - b.marketName.length,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.marketName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "marketNamePT",
      title: "Market Name PT",
      dataIndex: "marketNamePT",
      sorter: (a, b) => a.marketNamePT.length - b.marketNamePT.length,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.marketNamePT.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "yahooIndex",
      title: "Index Yahoo",
      dataIndex: "yahooIndex",
      sorter: (a, b) => a.yahooIndex.length - b.yahooIndex.length,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.yahooIndex.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "countryName",
      title: "Country Name",
      dataIndex: ["countryName", "countryName"],
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.countryName.countryName.length - b.countryName.countryName.length,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.countryName.countryName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "currency",
      title: "Currency",
      dataIndex: "currency",
      sorter: (a, b) => a.currency.length - b.currency.length,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.currency.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "isStock",
      title: "isStock",
      dataIndex: "isStock",
      render: (value, record) => {
        if(record.isStock === true )
          return (
            <span>Stock</span>
          );

          return (
            <span>Other</span>
          );
      },
      filters: [
        { text: "Stock", value: true },
        { text: "Other", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.isStock === value;
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Actions",
      dataIndex: "type",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
            <EditOutlined
              onClick={() => {
                    setEditable(record);
                    setMarketNameUpdate(record.marketName);
                    setMarketNamePTUpdate(record.marketNamePT);
                    setYahooIndexUpdate(record.yahooIndex);
                    setCountryNameUpdate(record.countryName.countryName);
                    setYahooIndexUpdate(record.yahooIndex);
                    setCurrencyUpdate(record.currency);
                    setStatusUpdate(record.status);
                    setIsStockUpdate(record.isStock);
                    setIbPrimaryMarketUpdate(record.ibPrimaryMarket);
                    setIbExchangeUpdateUpdate(record.ibExchange);
                    setShowModal(true);
              }}
            />
          </div>
        );
      },
    }
  ];

  const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllMarket = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/markets/get-market',
        {
          // userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllMarket(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        //message.error('You are not Admin User');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllMarket();
  },[])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitMarket = async (values) => {

  try{
    const promises = [];
  
    const user = JSON.parse(localStorage.getItem('user'))

    if(editable){
      setLoading(true);
      const result = axios.post('/markets/edit-market', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: editable._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Market Updated Successfull');

    }else{
      setLoading(true);
      const result = axios.post('/markets/add-market', {
        ...values, 
        userid:user._id, 
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Market Added Successfull');
    }

    setShowModal(false);
    setEditable(null);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      //message.error('You are not Admin User');
    }
  }

  //Handler Country DropList ****************************************************************************************
  useEffect(() => {
    const getAllCountries = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/countries/get-country', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllCountry(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        //message.error('You are not Admin User');
      }
    };  
    getAllCountries();
  },[])

  //Handler Test Console Log Values
  const onFinish = (values) => {
    console.log({values});
  };

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setEditable(null);
    setMarketNameUpdate(null);
    setMarketNamePTUpdate(null);
    setYahooIndexUpdate(null);
    setCountryNameUpdate(null);
    setCurrencyUpdate(null);
    setStatusUpdate(null);
    setIsStockUpdate(null);
    setIbPrimaryMarketUpdate(null);
    setIbExchangeUpdateUpdate(null);
  };

  //Handler Close Modal
  const handleClose = () => {
    setShow(false);
  };
  

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Definições - Mercados Financeiros</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
          <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
          >+ Novo Mercado Financeiro
        </Button>
        </div>

      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <hr></hr>

      <div className="content">
        <Table 
        columns={columns}
        dataSource={allMarket}
        pagination={false}
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
        
      </div>
      {/* <div><pre>{JSON.stringify(allMarket, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title={editable ? "Edit Market" : "Add Market" }
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form 
            layout="vertical" 
            onFinish={handleSubmitMarket} 
            style={{padding:"32px 16px"}}
          >

            <Form.Item label="Market Name" name="marketName">
              <Input 
                type="text"
                defaultValue={marketNameUpdate}
                />
            </Form.Item>
            <Form.Item label="Market Name PT" name="marketNamePT">
              <Input 
                type="text"
                defaultValue={marketNamePTUpdate}
                />
            </Form.Item>
            <Form.Item label="Index Yahoo" name="yahooIndex">
              <Input 
                type="text"
                defaultValue={yahooIndexUpdate}
                />
            </Form.Item>
            <Form.Item label="Country Name" name="countryName">
              <Select
                defaultValue={countryNameUpdate}
              >
                  {
                  allCountry.map((opts,i)=>
                    <Select.Option key={i} value={opts._id}>
                      {opts.countryName}
                    </Select.Option>)
                  }
              </Select>
            </Form.Item>
            <Form.Item label="Currency" name="currency">
              <Select defaultValue={currencyUpdate}>
                <Select.Option value="EUR">EUR</Select.Option>
                <Select.Option value="USD">USD</Select.Option>
                <Select.Option value="CHF">CHF</Select.Option>
                <Select.Option value="GBP">GBP</Select.Option>
                <Select.Option value="HKD">HKD</Select.Option>
                <Select.Option value="BRL">BRL</Select.Option>
                <Select.Option value="JPY">JPY</Select.Option>              
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>
            <hr></hr>
            <h5>Interactive Brokers - Config</h5>
            <br></br>
            <Form.Item label="Primary Market" name="ibPrimaryMarket">
              <Input 
                type="text"
                defaultValue={ibPrimaryMarketUpdate}
                />
            </Form.Item>
            <Form.Item label="Exchange" name="ibExchange">
              <Input 
                type="text"
                defaultValue={ibExchangeUpdate}
                />
            </Form.Item>
            <br></br>
            <hr></hr>
            <Form.Item label="Is Stock" name="isStock" valuePropName="checked">
              <Switch 
                defaultChecked={isStockUpdate}
              />
            </Form.Item>
            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch 
                defaultChecked={statusUpdate}
              />
            </Form.Item>
            
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" onClick={handleClose}>
                {" "}
                SAVE
              </button>
            </div>
            {/* <div><pre>{JSON.stringify(allMarket, null, 2) }</pre></div> */}
            
          </Form>
      </Modal>

    </Layout>
  );
};

export default Market;