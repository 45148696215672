import React,{useState,useEffect} from 'react';
import {Card,Row,Col} from 'antd';
import Layout from "./../components/Layout/Layout";
import MyBank from "./../components/Assets/bank.png";
import MyWallet from "./../components/Assets/wallet.png";
import MyTax from "./../components/Assets/tax.png";
import MyInvestment from "./../components/Assets/investment.png";
import MyLoan from "./../components/Assets/loan.png";
import MyUtilities from "./../components/Assets/utilities.png";
import MyInsurance from "./../components/Assets/insurance.png";
import MySubscription from "./../components/Assets/subscription.png";
import MyCreditCard from "./../components/Assets/credit-card.png";
import MyExchange from "./../components/Assets/binance.png";
import Spinner from "../components/Spinner";
import {useNavigate} from "react-router-dom";

import axios from 'axios';

const HomePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { Meta } = Card;

  const [allAccountStats, setAllAccountStats] = useState([]);
  const [allNoAccountStats, setAllNoAccountStats] = useState([]);
  const [allSubscriptionStats, setAllSubscriptionStats] = useState([]);
  const [allNoSubscriptionStats, setAllNoSubscriptionStats] = useState([]);
  const [allUtilitiesStats, setAllUtilitiesStats] = useState([]);
  const [allNoUtilitiesStats, setAllNoUtilitiesStats] = useState([]);
  const [allInsurancesStats, setAllInsurancesStats] = useState([]);
  const [allNoInsurancesStats, setAllNoInsurancesStats] = useState([]);
  const [allLoansStats, setAllLoansStats] = useState([]);
  const [allNoLoansStats, setAllNoLoansStats] = useState([]);
  const [allInvestmentsStats, setAllInvestmentsStats] = useState([]);
  const [allInvestmentsStocksStats, setAllInvestmentsStocksStats] = useState([]);
  const [allInvestmentsEtfsStats, setAllInvestmentsEtfsStats] = useState([]);
  const [allInvestmentsFundFamilyStats, setAllInvestmentsFundFamilyStats] = useState([]);
  const [allCreditCardsStats, setAllCreditCardsStats] = useState([]);
  const [allExchangesStats, setAllExchangesStats] = useState([]);
  const [allTaxStats, setAllTaxStats] = useState([]);
  const [allNoTaxStats, setAllNoTaxStats] = useState([]);  
  const [allWalletsStats, setAllWalletsStats] = useState([]);

  //loading Apis
  const [loading,setLoading] = useState(false);

  const [loginUser, setLoginUser] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user_currency = loginUser.currency

  // Accounts -------------------------------------------------------------------------------------

  useEffect(() => {
    const getAllAccountStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-userbalance', {
          userid:user._id,
          isCreditCard: false,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllAccountStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllAccountStats();
  },[])

  //Analytics
  const totalAccountBalance= allAccountStats
  .reduce((acc, transaction) => acc + transaction.balance, 0);

  const CountAccountBalance= allAccountStats.length;

  useEffect(() => {
    const getAllNoAccountStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-usernobalance', {
          userid:user._id,
          isCreditCard: false,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllNoAccountStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllNoAccountStats();
  },[])

  //Analytics
  const CountNoAccountBalance= allNoAccountStats.length;

  const CountAllAccountBalance = CountAccountBalance+CountNoAccountBalance

  // Subscritpions -------------------------------------------------------------------------------------

  useEffect(() => {
    const getAllSubscriptionStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-usersubscriptions', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSubscriptionStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllSubscriptionStats();
  },[])

  //Analytics
  const totalSubscriptionBalance= allSubscriptionStats
  .reduce((acc, transaction) => acc + transaction.amount, 0);

  const CountSubscriptionBalance= allSubscriptionStats.length;

  useEffect(() => {
    const getAllNoSubscriptionStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-usernosubscriptions', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllNoSubscriptionStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllNoSubscriptionStats();
  },[])

  //Analytics
  const CountNoSubscriptionBalance= allNoSubscriptionStats.length;

  const CountAllSubscriptionBalance = CountSubscriptionBalance+CountNoSubscriptionBalance

  // Utilities -------------------------------------------------------------------------------------
  useEffect(() => {
    const getAllUtilitiesStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-userutilities', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllUtilitiesStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllUtilitiesStats();
  },[])

  //Analytics
  const totalUtilitiesBalance= allUtilitiesStats
  .reduce((acc, transaction) => acc + transaction.amount, 0);

  const CountUtilitiesBalance= allUtilitiesStats.length;

  useEffect(() => {
    const getAllNoUtilitiesStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-usernoutilities', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllNoUtilitiesStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllNoUtilitiesStats();
  },[])

  //Analytics
  const CountNoUtilitiesBalance= allNoUtilitiesStats.length;

  const CountAllUtilitiesBalance = CountUtilitiesBalance+CountNoUtilitiesBalance

  // Insurances -------------------------------------------------------------------------------------
  useEffect(() => {
    const getAllInsuranceStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-userinsurances', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllInsurancesStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllInsuranceStats();
  },[])

  //Analytics
  const totalInsurancesBalance= allInsurancesStats
  .reduce((acc, transaction) => acc + transaction.amount, 0);

  const CountInsurancesBalance= allInsurancesStats.length;

  useEffect(() => {
    const getAllNoInsuranceStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-usernoinsurances', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllNoInsurancesStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllNoInsuranceStats();
  },[])

  //Analytics
  const CountNoInsurancesBalance= allNoInsurancesStats.length;

  const CountAllInsurancesBalance = CountInsurancesBalance+CountNoInsurancesBalance
  // Loans -------------------------------------------------------------------------------------
  useEffect(() => {
    const getAllLoansStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-userloans', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllLoansStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllLoansStats();
  },[])

  //Analytics
  const totalLoansBalance= allLoansStats
  .reduce((acc, transaction) => acc + transaction.amount, 0);

  const CountLoansBalance= allLoansStats.length;

  useEffect(() => {
    const getAllNoLoansStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-usernoloans', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllNoLoansStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllNoLoansStats();
  },[])

  //Analytics
  const CountNoLoansBalance= allNoLoansStats.length;

  const CountAllNoLoansBalance = CountLoansBalance+CountNoLoansBalance

  // Savings & Investments -------------------------------------------------------------------------------------
  useEffect(() => {
    const getAllInvestmetsStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/investment-transactions/get-userinvestmentbalance', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllInvestmentsStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllInvestmetsStats();
  },[])

  useEffect(() => {
    const getAllInvestmetsStocksStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/investment-transactions/get-userinvestmenstockbalance', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllInvestmentsStocksStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllInvestmetsStocksStats();
  },[])

  useEffect(() => {
    const getAllInvestmetsEtfStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/investment-transactions/get-userinvestmenetfbalance', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllInvestmentsEtfsStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllInvestmetsEtfStats();
  },[])

  useEffect(() => {
    const getAllInvestmetsFundFamilyStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/investment-transactions/get-userinvestmentfundfamilybalance', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllInvestmentsFundFamilyStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllInvestmetsFundFamilyStats();
  },[])

  //Analytics
  const totalInvestmentsBalance= allInvestmentsStats
  .reduce((acc, transaction) => acc + transaction.balance, 0);

  const totalInvestmentsStocksBalance= allInvestmentsStocksStats
  .reduce((acc, transaction) => acc + transaction.balance, 0);

  const totalInvestmentsEtfsBalance= allInvestmentsEtfsStats
  .reduce((acc, transaction) => acc + transaction.balance, 0);

  const totalInvestmentsFundFamilyBalance= allInvestmentsFundFamilyStats
  .reduce((acc, transaction) => acc + transaction.balance, 0);

  const totalInvestmentsBalances = totalInvestmentsBalance+totalInvestmentsStocksBalance+totalInvestmentsEtfsBalance+totalInvestmentsFundFamilyBalance
  const CountAllInvestmentsBalance= allInvestmentsStats.length+allInvestmentsStocksStats.length+allInvestmentsEtfsStats.length+allInvestmentsFundFamilyStats.length;

    // Wallets -------------------------------------------------------------------------------------
    useEffect(() => {
      const getAllWalletsStats = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/wallet-transactions/get-userwalletbalance', {
            userid:user._id,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }       
          );
          setLoading(false);
          setAllWalletsStats(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With account');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllWalletsStats();
    },[])
  
    //Analytics
    const totalWalletsBalance= allWalletsStats
    .reduce((acc, transaction) => acc + transaction.balance_ctr, 0);
  
    const CountAllWalletssBalance= allWalletsStats.length;

  // Credit Cards -------------------------------------------------------------------------------------
  useEffect(() => {
    const getAllCreditCardsStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-userbalance', {
          userid:user._id,
          isCreditCard: true,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllCreditCardsStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllCreditCardsStats();
  },[])

  //Analytics
  const totalCreditCardsBalance= allCreditCardsStats
  .reduce((acc, transaction) => acc + transaction.balance, 0);

  const CountAllCreditCardsBalance= allCreditCardsStats.length;

    // Taxes -------------------------------------------------------------------------------------

    useEffect(() => {
      const getAllTaxStats = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/transactions/get-user-dashboard-taxes', {
            userid:user._id,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }       
          );
          setLoading(false);
          setAllTaxStats(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With account');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllTaxStats();
    },[])
  
    //Analytics
    const totalTaxBalance= allTaxStats
    .reduce((acc, transaction) => acc + transaction.amount, 0);
  
    const CountTaxBalance= allTaxStats.length;
  
    useEffect(() => {
      const getAllNoTaxStats = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/transactions/get-usernotaxes', {
            userid:user._id,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }       
          );
          setLoading(false);
          setAllNoTaxStats(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With account');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllNoTaxStats();
    },[])
  
    //Analytics
    const CountNoTaxBalance= allNoTaxStats.length;
  
    const CountAllTaxBalance = CountTaxBalance+CountNoTaxBalance

  // Exchanges & Cash -------------------------------------------------------------------------------------
  useEffect(() => {
    const getAllExchangesStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/exchanges/get-userexchanges', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllExchangesStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllExchangesStats();
  },[])

  //Analytics
  const totalExchangeBalance= allExchangesStats
  .reduce((acc, transaction) => acc + transaction.investingBalance, 0);

  const totalCashExchangeBalance= allExchangesStats
  .reduce((acc, transaction) => acc + transaction.investingCash, 0);

  const CountAllExchangeBalance= allExchangesStats
  .reduce((acc, transaction) => acc + transaction.countExchanges, 0);
  
  // useEffect(() => {
  //   const getAllNoLoansStats = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/transactions/get-userloans', {
  //         userid:user._id,
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }       
  //       );
  //       setLoading(false);
  //       setAllLoansStats(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With account');
  //     }
  //   };  
  //   getAllNoLoansStats();
  // },[])

  // //Analytics
  // const CountNoLoansBalance= allNoLoansStats.length;

  // const CountAllNoLoansBalance = CountLoansBalance+CountNoLoansBalance

  //***************************RENDER**************************************************************************/

  return (
    
    <Layout visible={false}>

      {loading && <Spinner/>}
      <div className="content">
        <Row gutter={[8,24]}
            style={{
            justifyContent: "center"
          }}>
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Accounts');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price">Contas</h4>
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    <div align="center">
                        <h4 className="price">{CountAllAccountBalance} contas</h4>
                    </div>
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Saldo: <span className="quantity">{totalAccountBalance.toLocaleString('pt-BR')} {user_currency}</span></h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Loans');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyLoan} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price">Empréstimos</h4>
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    <div align="center">
                        <h4 className="price">{CountAllNoLoansBalance} contas</h4>
                    </div>
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Saldo: <span className="quantity">{totalLoansBalance.toLocaleString('pt-BR')} {user_currency}</span></h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Insurances');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyInsurance} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price">Seguros</h4>
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    <div align="center">
                        <h4 className="price">{CountAllInsurancesBalance} contas</h4>
                    </div>
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Saldo: <span className="quantity">{totalInsurancesBalance.toLocaleString('pt-BR')} {user_currency}</span></h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Utilities');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyUtilities} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price">Utilities & Comunicações</h4>
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    <div align="center">
                        <h4 className="price">{CountAllUtilitiesBalance} contas</h4>
                    </div>
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Saldo: <span className="quantity">{totalUtilitiesBalance.toLocaleString('pt-BR')} {user_currency}</span></h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Subscriptions');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MySubscription} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price">Subscrições</h4>
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    <div align="center">
                        <h4 className="price">{CountAllSubscriptionBalance} contas</h4>
                    </div>
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Saldo: <span className="quantity">{totalSubscriptionBalance.toLocaleString('pt-BR')} {user_currency}</span></h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Investments');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyInvestment} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price">Poupanças & Investimentos</h4>
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    <div align="center">
                        <h4 className="price">{CountAllInvestmentsBalance} contas</h4>
                    </div>
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Saldo: <span className="quantity">{totalInvestmentsBalances.toLocaleString('pt-BR')} {user_currency}</span></h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CreditCards');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyCreditCard} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price">Cartões de Crédito</h4>
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    <div align="center">
                        <h4 className="price">{CountAllCreditCardsBalance} contas</h4>
                    </div>
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
              <h4 className="price">Saldo: <span className="quantity">{totalCreditCardsBalance.toLocaleString('pt-BR')} {user_currency}</span></h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Taxes');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyTax} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price">Contribuições Fiscais</h4>
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    <div align="center">
                        <h4 className="price">{CountAllTaxBalance} contas</h4>
                    </div>
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Saldo: <span className="quantity">{totalTaxBalance.toLocaleString('pt-BR')} {user_currency}</span></h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Exchanges');
            }} 
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyExchange} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price">Exchanges</h4>
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    <div align="center">
                        <h4 className="price">{CountAllExchangeBalance} contas</h4>
                    </div>
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
              <h4 className="price">Saldo: <span className="quantity">{totalExchangeBalance.toLocaleString('pt-BR')} {user_currency}</span></h4>
              <h4 className="price">Dinheiro Disponível: <span className="quantity">{totalCashExchangeBalance.toLocaleString('pt-BR')} {user_currency}</span></h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Wallets');
            }} 
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyWallet} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price">Wallets</h4>
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    <div align="center">
                        <h4 className="price">{CountAllWalletssBalance} contas</h4>
                    </div>
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
              <h4 className="price">Saldo: <span className="quantity">{totalWalletsBalance.toLocaleString('pt-BR')} {user_currency}</span></h4>
              </div>
            </Card>
            </Col>
          </Row>
      </div>

      {/* <div><pre>{JSON.stringify(allAccountStats, null, 2) }</pre></div> */}


    </Layout>
  );
};

export default HomePage;