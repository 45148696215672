import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Table, Switch, Space, Tooltip, Button} from 'antd';

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import moment from 'moment';

import {
  SearchOutlined,
  CheckCircleFilled,
  StopOutlined,
  EditOutlined,
  EyeOutlined
} from "@ant-design/icons";

const Users = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [showModal, setShowModal] = useState(false);
  const [showModalUser, setShowModalUser] = useState(false);
  // const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allUser, setAllUser] = useState([]);

  // const [packageDetails, setPackageDetails] = useState(null);

  // const onChangePackage = state => {
  //   console.log('Package choose: ',state);
  //   setPackageDetails(state);
  // };


  // //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Fields from table to View
  const [nameUpdate, setNameUpdate] = useState(null);
  const [emailUpdate, setEmailUpdate] = useState(null);
  const [isAdminUpdate, setIsAdminUpdate] = useState(null);
  const [isBlockedUpdate, setIsBlockedUpdate] = useState(null);

  // const [subscriptionPackageUpdate,setSubscriptionPackageUpdate] = useState(null);
  const [nifUpdate,setNifUpdate] = useState(null);
  const [birthdayDateUpdate,setBirthdayDateUpdate] = useState(null);
  const [countryCitizenshipUpdate,setCountryCitizenshipUpdate] = useState(null);
  const [phoneNumberUpdate,setPhoneNumberUpdate] = useState(null);
  // const [isMobileUpdate,setIsMobileUpdate] = useState(null);

  // const [addressUpdate,setAddressUpdate] = useState(null);
  // const [cityUpdate,setCityUpdate] = useState(null);
  // const [stateUpdate,setStateUpdate] = useState(null);
  // const [zipCodeUpdate,setZipCodeUpdate] = useState(null);

  // const [employmentStatusUpdate,setEmploymentStatusUpdate] = useState(null);
  // const [ocuppationCompanyUpdate,setOcuppationCompanyUpdate] = useState(null);
  // const [nameCompanyUpdate,setNameCompanyUpdate] = useState(null);
  // const [addressCompanyUpdate,setAddressCompanyUpdate] = useState(null);
  // const [cityCompanyUpdate,setCityCompanyUpdate] = useState(null);
  // const [stateCompanyUpdate,setStateCompanyUpdate] = useState(null);
  // const [zipCodeCompanyUpdate,setZipCodeCompanyUpdate] = useState(null);

  const [isPEPUpdate,setIsPEPUpdate] = useState(null);
  const [isCorporateControlUpdate,setIsCorporateControlUpdate] = useState(null);

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      // defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "countryCitizenship",
      title: "Country Citizenship",
      dataIndex: "countryCitizenship",
      sorter: (a, b) => a.countryCitizenship.localeCompare(b.countryCitizenship),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.countryCitizenship.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.email.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "isAdmin",
      title: "Administrator",
      dataIndex: "isAdmin",
      align: "center",
      render: (value, record) => {
        if(record.isAdmin === true )
          return (
          <Tooltip title="Yes">
            <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
          </Tooltip>
          );

          return (
          <Tooltip title="No">
            <StopOutlined className="mx-2" style={{color: "#FF3234"}}/>
          </Tooltip>
          );
      },
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.isAdmin === value;
      },
    },
    {
      key: "subscriptionPackage",
      title: "Subscription Package",
      dataIndex: ["subscriptionPackage"],
      align: "center",
      sorter: (a, b) => a.subscriptionPackage-b.subscriptionPackage,
      render: (value, record) => {
      if(record.subscriptionPackage === 0 )
      return (
      <span>Basic Package</span>
      );
      if(record.subscriptionPackage === 1 )
      return (
      <span>Investor Package</span>
      );
      return (
      <span>Super Administrator</span>
      );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "isBusiness",
      title: "Business Account",
      dataIndex: "isManager",
      align: "center",
      render: (value, record) => {
        if(record.isBusiness === true )
          return (
            <span style={{color: "#39A939"}}>Yes</span>
          );

          return (
            <span style={{color: "#FF3234"}}>No</span>
          );
      },
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.isBusiness === value;
      },
    },
    {
      key: "isBlocked",
      title: "Status",
      dataIndex: "isBlocked",
      align: "center",
      render: (value, record) => {
        if(record.isBlocked === false )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: false },
        { text: "Inactive", value: true },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.isBlocked === value;
      },
    },
    {
      title: "Actions",
      dataIndex: "type",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
            <Space direction="horizontal">
            <EditOutlined
              onClick={() => {
                    setEditable(record);
                    setNameUpdate(record.name);
                    setEmailUpdate(record.email);
                    setIsAdminUpdate(record.isAdmin);
                    setPhoneNumberUpdate(record.phoneNumber);
                    // setSubscriptionPackageUpdate(record.subscriptionPackage);
                    setIsBlockedUpdate(record.isBlocked);
                    setShowModal(true);
              }}
            />
            <EyeOutlined
              onClick={() => {
                console.log(record);
                setEditable(record);
                setNameUpdate(record.name);
                setEmailUpdate(record.email);
                setNifUpdate(record.nif);
                setBirthdayDateUpdate(record.birthdayDate);
                setCountryCitizenshipUpdate(record.countryCitizenship);
                setPhoneNumberUpdate(record.phoneNumber);
                // setIsMobileUpdate(record.isMobile);
                // setAddressUpdate(record.address);
                // setCityUpdate(record.city);
                // setStateUpdate(record.state);
                // setZipCodeUpdate(record.zipCode);
                // setEmploymentStatusUpdate(record.employmentStatus);
                // setOcuppationCompanyUpdate(record.ocuppationCompany);
                // setNameCompanyUpdate(record.nameCompany);
                // setAddressCompanyUpdate(record.addressCompany);
                // setCityCompanyUpdate(record.cityCompany);
                // setStateCompanyUpdate(record.stateCompany);
                // setZipCodeCompanyUpdate(record.zipCodeCompany);
                setIsPEPUpdate(record.isPEP);
                setIsCorporateControlUpdate(record.isCorporateControl);
                // setManagerIdUpdate(record.managerId.name);
                setShowModalUser(true);
              }}
            />
            </Space>
          </div>
        );
      },
    }
  ];

  // const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllUser = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/users/all-users',
        {
          // userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllUser(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        // message.error('Fetch Issue With Users');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllUser();
  },[])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitUser = async (values) => {

  try{
    // const promises = [];
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const result = axios.post('/users/edit-user', {
      payload:{
        ...values,
        userid: user._id,
      },
      _id: editable._id,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('User Updated Successfull');

    setShowModal(false);
    setEditable(null);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);
    }catch (error){
      setLoading(false);
      // message.error('Failed to edit User');
    }
  }

  // //Handler Test Console Log Values
  // const onFinish = (values) => {
  //   console.log({values});
  // };

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setEditable(null);
    setNameUpdate(null);
    setEmailUpdate(null);
    setIsAdminUpdate(null);
    setIsBlockedUpdate(null);
  };

  const handleCancelModalUser = () => {
    setShowModalUser(false);
    setEditable(null);
    setNameUpdate(null);
    setEmailUpdate(null);
  };

  //Handler Close Modal
  const handleClose = () => {
    setShow(false);
  };
  

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>Settings - Users</h1></div>
        <div></div>
      </div>
      <hr></hr>
      <div className="content">
        <Table 
        columns={columns}
        dataSource={allUser}
        pagination={{ defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100']}}
        scroll={{ x: 'max-content'}}
        className="custom-table" 
        />
        
      </div>
      {/* <div><pre>{JSON.stringify(allUser, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title="Edit User"
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form 
            layout="vertical" 
            onFinish={handleSubmitUser} 
            style={{padding:"32px 16px"}}
          >
            <Form.Item label="Name" name="name">
              <Input 
                type="text"
                defaultValue={nameUpdate}
                />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input 
                type="text"
                defaultValue={emailUpdate}
                />
            </Form.Item>
            <Form.Item label="Phone Number" name="phoneNumber">
              <Input 
                type="text"
                defaultValue={phoneNumberUpdate}
                />
            </Form.Item>

            <Form.Item label="Administrator" name="isAdmin" valuePropName="checked">
              <Switch 
                defaultChecked={isAdminUpdate}
              />
            </Form.Item>
            {/* <Form.Item label="Opção de pagamento" name="paymentOption">
                <Select 
                  defaultValue={subscriptionPackageUpdate}
                  onChange={onChangePackage}>
                  <Select.Option value="0">Basic Package</Select.Option>
                  <Select.Option value="1">Investor Package</Select.Option>
                  <Select.Option value="2">RoboAdvisor Package</Select.Option>
                  <Select.Option value="9">Super Adminsitrator</Select.Option>
                </Select>
              </Form.Item> */}
            <Form.Item label="Blocked" name="isBlocked" valuePropName="checked">
              <Switch 
                defaultChecked={isBlockedUpdate}
              />
            </Form.Item>

            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" onClick={handleClose}>
                {" "}
                SAVE
              </button>
            </div>
            {/* <div><pre>{JSON.stringify(allUser, null, 2) }</pre></div> */}
            
          </Form>
      </Modal>

      <Modal
        destroyOnClose={true} 
        // title="User Profile"
        open={showModalUser}
        onCancel={handleCancelModalUser}
        footer={false}
        style={{padding:"32px 16px"}}
        >
        <h5>Your personal information</h5>
        <br/>
        <p><b>Name:</b> {nameUpdate}</p>
        <br/>
        <p><b>Fiscal Number:</b> {nifUpdate}</p>
        <br/>
        <p><b>Date of birth:</b> {moment(birthdayDateUpdate).format("DD/MM/YYYY")}</p>
        <br/>
        <p><b>Phone number:</b> {phoneNumberUpdate}</p>
        <br/>
        <p><b>Country of Citizenship:</b> {countryCitizenshipUpdate}</p>
        <br/>
        {/* <p><b>Residential address:</b></p>
        <br/>
        <p>{addressUpdate}, {cityUpdate}</p>
        <br/>
        <p>{zipCodeUpdate}-{stateUpdate}</p> */}
        <hr/>
        <h5>Associations</h5>
        <br/>
        {isPEPUpdate === true && (
          <p><b>Investment industry association:</b> Yes</p>
        )}
        {isPEPUpdate === false && (
          <p><b>Investment industry association:</b> None</p>
        )}
        <br/>
        {isCorporateControlUpdate === true && (
          <p><b>Corporate control status:</b> Yes</p>
        )}
        {isCorporateControlUpdate === false && (
          <p><b>Corporate control status:</b> None</p>
        )}
        <br/>
        {/* <div><pre>{JSON.stringify(editable, null, 2) }</pre></div>   */}
      </Modal>

    </Layout>
  );
};

export default Users;