import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Select, Switch, Button, Space,Tooltip,Row,Card} from 'antd';
import MyWallet from "./../components/Assets/binance.png";
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { CheckCircleFilled, ExclamationCircleFilled, HddOutlined, MinusOutlined, PlusOutlined, ReloadOutlined, SettingOutlined, TableOutlined, UnorderedListOutlined } from "@ant-design/icons";

import  Chart  from "react-apexcharts";

const Exchanges = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = JSON.parse(localStorage.getItem('user'))
  const investor = JSON.parse(localStorage.getItem('investorProfile'))
  console.log(investor)

  const { Meta } = Card;

  //Fields from Graphs to View
  const [allBalanceExchangeWeights, setAllBalanceExchangeWeights] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  const [allInvestmentStats, setAllInvestmentStats] = useState([]);
  const [allNoInvestmentStats, setAllNoInvestmentStats] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Edit Transactions
    const [editable, setEditable] = useState(null);

    //Fields from table to View
    const [accountNameUpdate, setAccountNameUpdate] = useState(null);
    const [accountDescriptionUpdate, setAccountDescriptionUpdate] = useState(null);
    const [accountIBANUpdate, setAccountIBANUpdate] = useState(null);
    const [accountTypeUpdate, setAccountTypeUpdate] = useState(null);
    const [statusUpdate, setStatusUpdate] = useState(null);

  // //table accounts
  // const columns =[
  //   {
  //     key: "createdAt",
  //     title: "Data de criação",
  //     dataIndex: "createdAt",
  //     width: "5%",
  //     render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
  //     sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  //   },
  //   {
  //     key: "accountName",
  //     title: "Conta",
  //     dataIndex: "accountName",
  //     width: "5%",
  //     sorter: (a, b) => a.accountName.localeCompare(b.accountName),
  //   },
  //   {
  //     key: "accountDescription",
  //     title: "Descrição",
  //     dataIndex: "accountDescription",
  //     width: "5%",
  //     sorter: (a, b) => a.accountDescription.localeCompare(b.accountDescription),
  //   },
  //   {
  //     key: "accountType",
  //     title: "Tipo de Conta",
  //     dataIndex: "accountType",
  //     width: "5%",
  //     sorter: (a, b) => a.accountType.localeCompare(b.accountType),
  //   },
  //   {
  //     key: "accountIBAN",
  //     title: "IBAN",
  //     dataIndex: "accountIBAN",
  //     width: "5%"
  //   },
  //   {
  //     key: "status",
  //     title: "Estado",
  //     dataIndex: "status",
  //     width: "5%",
  //     sorter: (a, b) => a.status - b.status,
  //     render: (value, record) => {
  //       if(record.status === true )
  //         return (
  //           <span style={{color: "#39A939"}}>Active</span>
  //         );

  //         return (
  //           <span style={{color: "#FF3234"}}>Inactive</span>
  //         );
  //     },
  //     filters: [
  //       { text: "Active", value: true },
  //       { text: "Inactive", value: false },
  //     ],
  //     filterIcon: () => {
  //       return <SearchOutlined />;
  //     },
  //     onFilter: (value, record) => {
  //       console.log(typeof value)
  //       return record.status === value;
  //     },
  //   },
  //   {
  //     title: "Acções",
  //     dataIndex: "type",
  //     width: "5%",
  //     render: (type, record) => {
  //       // if(type === "Income" )
  //       return (
  //         <div className="mx-4">
  //           <Space size="middle">
  //           <EditOutlined
  //             onClick={() => {
  //               setEditable(record);
  //               setAccountNameUpdate(record.accountName);
  //               setAccountDescriptionUpdate(record.accountDescription);
  //               setAccountIBANUpdate(record.accountIBAN);
  //               setAccountTypeUpdate(record.accountType);
  //               setStatusUpdate(record.status);
  //               setShowModal(true);
  //             }}
  //           />
  //           <UnorderedListOutlined
  //             onClick={() => {
  //               console.log(record);
  //               navigate('/Transactions',
  //               {
  //                 state:{
  //                   accountId: record.id,
  //                   accountDescription: record.accountName           
  //                 }
  //               });
  //             }}
  //           />
  //           </Space>
  //         </div>
  //       );
  //     },
  //   }
  // ];

  // useEffect(() => {
  //   const getInvestorProfileStats = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/investorProfiles/get-youinvestorprofile', {
  //         userid:user._id,
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }       
  //       );
  //       setLoading(false);
  //       setAllInvestorProfileStats(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With account');
  //     }
  //   };  
  //   getInvestorProfileStats();
  // },[])


  useEffect(() => {
    const getallInvestmentStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/cash-exchanges/get-cash-exchange', {
          userid:user._id,
          archive: false,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllInvestmentStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        // //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getallInvestmentStats();
  },[])

  const [weightSubject, setWeightSubject]= useState([]);
  const [weightMarks, setWeightMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllLoanNameWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/transactions/get-balanceexchange',
    {
      userid:user._id,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    setAllBalanceExchangeWeights(res.data);
    console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].exchangeName);
    sMarks.push(res.data[i].amount);
    }
    setWeightSubject(sSubject);
    setWeightMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllLoanNameWeights();
  },[])

  const [weightAssetSubject, setWeightAssetSubject]= useState([]);
  const [weightAssetMarks, setWeightAssetMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllAssetWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/transactions/get-assetexchangeaccount',
    {
      userid:user._id,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].symbol);
    sMarks.push(res.data[i].amount);
    }
    setWeightAssetSubject(sSubject);
    setWeightAssetMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllAssetWeights();
  },[])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitAccount = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/wallets/edit-wallet', {
      payload:{
        ...values,
        userid: user._id,
      },
      _id: editable._id.accountStats,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Wallet Actualizada com sucesso!');
    setShowModal(false);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);
    console.log(values)

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  const reload=()=>window.location.reload();

  const handleClose = () => {
    setShowModal(false)
    setAccountNameUpdate(null);
    setAccountDescriptionUpdate(null);
    setAccountIBANUpdate(null);
    setAccountTypeUpdate(null);
    setStatusUpdate(null);
    reload();
  };

  let labelInvestorProfile;
  if (user.investorProfile==="null" && investor===null) {
    labelInvestorProfile = "";
  }
  else if (investor!==null){
    labelInvestorProfile = 
    <div className="accountpage">
      <div></div>
      <div>
      <h3>O seu perfil de investidor é : {investor.investorProfile}</h3>
      </div>
      <div></div>
    </div>;
  }
  else{
    labelInvestorProfile = 
      <div className="accountpage">
        <div></div>
        <div>
        <h3>O seu perfil de investidor é : {user.investorProfile}</h3>
        </div>
        <div></div>
      </div>;
  }

  console.log("user.investorProfile: ",user.investorProfile);
  console.log("investor: ",investor);

    if((user.investorProfile===null && investor===null) || (user.investorProfile==="null" && investor===null)){
    //if(user.investorProfile==="null"){
    return (
      <Layout>
        {loading && <Spinner/>}
              {/* Start Header */}
      <div className="accountpage">
        <div></div>
        <div><h1>Minhas Exchanges</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
            <Button
              shape="round"
              className="btn btn-primary btn-block"
              style={{ background: "green", borderColor: "green" }}
              onClick={() => navigate('/NewSubscriptions')}
            >
              + Nova Subscrição
            </Button>
      </div>
      <div>
      </div>
        <div>
          <h3><i>Para que comece a utilizar o modulo de "Poupanças e Investimentos", preencha o questionário para identificar o seu perfil de investidor</i></h3>
          <br/>
          <Button
          onClick={() => navigate('/InvestorProfiles')} 
          style={{ width: "100%" }}  
          type="primary">
            COMEÇA JÁ O QUESTIONÁRIO
          </Button>
        </div>
        <div>
        </div>
        {/* Final Header */}
      </Layout>
    );
  }

  return (
    <Layout>
      {loading && <Spinner/>}
            {/* Start Header */}
            <div className="accountpage">
        <div></div>
        <div><h1>Minhas Exchanges</h1></div>
        <div></div>
      </div>

      {labelInvestorProfile}

      <div className="accountpagebuttonsAll">
            <Button
              shape="round"
              className="btn btn-primary btn-block"
              style={{ background: "green", borderColor: "green" }}
              onClick={() => navigate('/NewExchanges')}
            >
              + Nova Exchange
            </Button>
      </div>

      <div className="accountpagebuttonsAll">
        <Button
          shape="round"
          className="btn btn-primary btn-block"
          style={{ background: "blue", borderColor: "blue" }}
          onClick={() => navigate('/OrderAllExchanges')}
        >
          Todos os Movimentos
        </Button>
      </div>
      {/* Final Header */}
      <hr></hr>
      {/* Start Graphs */}
      <div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
      <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
        <div className="chart-container">
        <Chart
          align="center" 
          type="donut"
          width="100%"
          series={weightMarks}                
          options={{
            responsive: [
              {
                breakpoint: 281, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 150 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 361, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 230 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 481, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 250 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 769, // iPhone 12 Mini width
                options: {
                  chart: {
                    width: '100%',
                    height: 275 // Adjust height for iPhone 12 Mini
                  }
                }
              },
              {
                breakpoint: 768, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1024, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1200, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 2000, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              }
            ],
          labels: weightSubject,
          noData: { text: "No Data" }, 
          plotOptions: {
            pie: {
            donut: {
              labels: {
              show: true,
              name: {
                formatter: function(val) {
                return val
                }
              }
              }
            }
            }
          },
          dataLabels: { enabled: true },
          legend: { show: false } // Hide legends
          }}
        />
        </div>
        <h4 align="center">Valor das Exchanges</h4>
      </div>
      <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
        <div className="chart-container">
        <Chart
          align="center" 
          type="donut"
          width="100%"
          series={weightAssetMarks}                
          options={{
            responsive: [
              {
                breakpoint: 281, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 150 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 361, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 230 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 481, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 250 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 769, // iPhone 12 Mini width
                options: {
                  chart: {
                    width: '100%',
                    height: 275 // Adjust height for iPhone 12 Mini
                  }
                }
              },
              {
                breakpoint: 768, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1024, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1200, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 2000, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              }
            ],
          labels: weightAssetSubject,
          noData: { text: "No Data" }, 
          plotOptions: {
            pie: {
            donut: {
              labels: {
              show: true,
              name: {
                formatter: function(val) {
                return val
                }
              }
              }
            }
            }
          },
          dataLabels: { enabled: true },
          legend: { show: false } // Hide legends
          }}
        />
        </div>
        <h4 align="center">Valor dos Activos</h4>
      </div>
      </div>
      {/* Final Graphs */}

      {/* <hr></hr> */}
      <div className="content" align="center">
            <Row gutter={[8,24]}     
                style={{
                // height: 200,
                justifyContent: "center"
              }}>
                  {
                  allInvestmentStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    hoverable
                    style={{ width: 400 , marginTop: 16, marginRight: "10px"}}
                    cover={[
                      <div align="center">
                        <br></br>
                        <div>
                          <div align="right" style={{marginRight: "20px"}}>
                              {(() => {
                                    return (
                                      <div>
                                        <Space>
                                        <Tooltip title="Comprar">
                                      <PlusOutlined
                                      style={{color: "green"}}
                                        onClick={() => {
                                          console.log(opts);
                                          // navigate('/NewWalletsReforces',
                                          // {
                                          //   state:{
                                          //     userID: opts._id.userid,
                                          //     walletId:opts._id.walletID,
                                          //     walletName:opts._id.walletName,
                                          //     walletDescription:opts._id.walletDescription,
                                          //     brokerName:opts._id.brokerName,
                                          //     currency:opts._id.currency,
                                          //     walletStartDate:opts._id.walletStartDate,
                                          //     walletEndDate:opts._id.walletEndDate,
                                          //     frequency:opts._id.frequency,
                                          //     goalName:opts._id.goalName,
                                          //     codeSquare:opts._id.codeSquare,
                                          //     status:opts._id.status,
                                          //     balance:opts.balance
                                          //   }
                                          // });
                                        }}
                                        />
                                        </Tooltip>,
                                        <Tooltip title="Vender">
                                     <MinusOutlined
                                      style={{color: "red" }}
                                        onClick={() => {
                                          console.log(opts);
                                          // navigate('/NewWalletsWithdraws',
                                          // {
                                          //   state:{
                                          //     userID: opts._id.userid,
                                          //     walletId:opts._id.walletID,
                                          //     walletName:opts._id.walletName,
                                          //     walletDescription:opts._id.walletDescription,
                                          //     brokerName:opts._id.brokerName,
                                          //     currency:opts._id.currency,
                                          //     walletStartDate:opts._id.walletStartDate,
                                          //     walletEndDate:opts._id.walletEndDate,
                                          //     frequency:opts._id.frequency,
                                          //     goalName:opts._id.goalName,
                                          //     codeSquare:opts._id.codeSquare,
                                          //     status:opts._id.status,
                                          //     balance:opts.balance
                                          //   }
                                          // });
                                        }}
                                        />
                                        </Tooltip>
                                        </Space>
                                        </div>   
                                    )
                              })()}
                          </div>
                        </div>
                        <div>
                          <br></br>
                          <img alt="example" src={MyWallet}/>
                        </div>
                        <div>
                          <br></br>
                          <h4 className="price">{opts.exchangeName.exchangeName}</h4>
                        </div>
                      </div>
                    ]}
                    actions={[
                      <Tooltip title="Editar">
                      <SettingOutlined
                      key="setting" 
                      onClick={() => {
                        console.log(opts);
                        navigate('/EditExchanges',
                        {
                          state:{
                            userID: opts.userid,
                            exchangeID:opts.exchangeName._id,
                            exchangeName:opts.exchangeName.exchangeName,
                            exchangeDescription:opts.exchangeName.exchangeDescription,
                            brokerName:opts.exchangeName.brokerName,
                            currency:opts.exchangeName.currency,
                            frequency:opts.exchangeName.frequency,
                            goalName:opts.exchangeName.goalName,
                            codeSquare:opts.exchangeName.codeSquare,
                            status:opts.exchangeName.status,
                            apiKey:opts.exchangeName.apiKey,
                            keySecret:opts.exchangeName.keySecret,
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                      <Tooltip title="Transacções">
                      <UnorderedListOutlined
                        onClick={() => {
                          navigate('/OrderExchanges',
                          {
                            state:{
                              userID: opts.userid,
                              exchangeID:opts.exchangeName._id,
                              exchangeName:opts.exchangeName.exchangeName,
                              currency:opts.exchangeName.currency,
                            }
                          });
                        }}
                      />
                      </Tooltip>,
                      <Tooltip title="Detalhe Portfolio">
                      <TableOutlined
                        onClick={() => {
                          navigate('/PortfolioExchanges',
                          {
                            state:{
                              userID: opts.userid,
                              exchangeID:opts.exchangeName._id,
                              exchangeName:opts.exchangeName.exchangeName,
                              currency:opts.exchangeName.currency,
                            }
                          });
                        }}
                      />
                      </Tooltip>,
                      <Tooltip title="Refrescar Portfolio">
                      <ReloadOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/ProcessingRefreshExchanges',
                        {
                          state:{
                            exchangeID:opts.exchangeName._id,
                            exchangeName:opts.exchangeName.exchangeName,
                          }
                        }
                      );
                      }}
                      />
                      </Tooltip>,
                      <Tooltip title="Arquivar">
                      <HddOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/ArchiveExchanges',
                        {
                          state:{
                            userID: opts.userid,
                            cashExchangeID:opts._id,
                            exchangeID:opts.exchangeName._id,
                            exchangeName:opts.exchangeName.exchangeName,
                            exchangeDescription:opts.exchangeName.exchangeDescription,
                            brokerName:opts.exchangeName.brokerName,
                            currency:opts.exchangeName.currency,
                            frequency:opts.exchangeName.frequency,
                            goalName:opts.exchangeName.goalName,
                            codeSquare:opts.exchangeName.codeSquare,
                            status:opts.exchangeName.status,
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                    ]}
                    >
                    <Meta
                      description={[
                            <div align="center" marginRight="500px">
                                {opts.exchangeName.statusApi
                                  ? 
                                  <Tooltip title="API Negociação Activa">
                                    <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
                                  </Tooltip>
                                  : 
                                  <Tooltip title="API Negociação Desactiva">
                                  <ExclamationCircleFilled className="mx-2" style={{color: "#FF3234"}}/>
                                  </Tooltip>
                                }
                                <div>
                                  <h4 className="price">{opts.brokerName} </h4>
                                </div>
                                <span className="price">({opts.exchangeName.assetType})</span>
                            </div>
                            ]}
                          />
                      <br></br>
                      <div className="additional"  align="center">
                      <h6 className="price" >Valor da carteira: <span className="quantity">{opts.portfolio_exchange_value.toLocaleString('pt-BR')} {opts.currency} / {opts.portfolio_exchange_value_ctr.toLocaleString('pt-BR')} {opts.currency_ctr}</span></h6>
                      <hr></hr>
                      <h6 className="price" >Dinheiro disponível: <span className="quantity">{opts.cash.toLocaleString('pt-BR')} {opts.currency} / {opts.cash_ctr.toLocaleString('pt-BR')} {opts.currency_ctr}</span></h6>
                      <h6 className="price" >Dinheiro cativo: <span className="quantity">{opts.locked.toLocaleString('pt-BR')} {opts.currency} / {opts.locked_ctr.toLocaleString('pt-BR')} {opts.currency_ctr}</span></h6>
                      <hr></hr>
                      <h5>Porfolio actualizado<br></br><span className="quantity">{opts.date_refreshed}</span></h5>
                      </div>
                    </Card>)
                  }
            </Row>
      </div>
      {/* <div><pre>{JSON.stringify(allNoInvestmentStats, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title="Editar Conta"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleSubmitAccount} style={{padding:"32px 16px"}}>
            <Form.Item label="Nome da Conta ou do seu Banco" name="accountName">
              <Input 
              type="text"
              defaultValue={accountNameUpdate}
              />
            </Form.Item>
            <Form.Item label="Descrição" name="accountDescription">
              <TextArea
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                defaultValue={accountDescriptionUpdate}
                />
            </Form.Item>
            <Form.Item label="IBAN" name="accountIBAN">
              <Input 
              type="text"
              defaultValue={accountIBANUpdate}
              />
            </Form.Item>
            <Form.Item label="Account Type" name="accountType">
              <Select defaultValue={accountTypeUpdate}>
                <Select.Option value="Conta-Corrente">Conta-Corrente</Select.Option>
                <Select.Option value="Conta-Despesas">Conta-Despesas</Select.Option>
                <Select.Option value="Cartão Alimentação">Cartão Alimentação</Select.Option>
                <Select.Option value="Cartão de Crédito">Cartão de Crédito</Select.Option>
                <Select.Option value="Conta Pagamentos">Outra</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Estado da Conta" name="status" valuePropName="checked">
              <Switch defaultChecked={statusUpdate}/>
            </Form.Item>
            <div>
              <button 
              type="submit" 
              className="btn btn-primary" 
              onClick={handleClose}
               style={{
                  width: '100%',
                }}>
                {" "}
                GRAVAR
              </button>
            </div>
          </Form>

          {/* <div><pre>{JSON.stringify(allInvestmentStats, null, 2) }</pre></div> */}
          
      </Modal>

    </Layout>
  );
};

export default Exchanges;