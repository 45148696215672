import React,{useState,useEffect} from "react";
import {Input,Table, Select, Button,Tag} from 'antd';
import {useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import moment from 'moment';

const StockNews = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  // const { TextArea } = Input;

  // const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allStockAsset, setAllStockAsset] = useState([]);

  //DropList Market
  const [allStocks, setAllStocks] = useState([]);
  //DropList Market
  const [allMarket, setAllMarket] = useState([]);
  //DropList Sectors
  const [allSector, setAllSector] = useState([]);
  //DropList Industry
  const [allIndustry, setAllIndustry] = useState([]);

  //Filters
  const [marketName, setMarketNameFilter] = useState('all');
  const [sectorName, setSectorNameFilter] = useState('all');
  const [industryName, setIndustryNameFilter] = useState('all');
  const [stockAssetName, setStockAssetNameFilter] = useState('all');
  const [title, setTitleFilter] = useState("");

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "providerPublishTime",
      title: "Data Publicação",
      dataIndex: "providerPublishTime",
      width: '1%',
      defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.providerPublishTime) - new Date(b.providerPublishTime)
    },
    {
      key: "stockAssetName",
      title: "Activo",
      dataIndex: ["stockAssetName","stockAssetName"],
      width: '3%',
      // defaultSortOrder: 'ascend',
      sorter: (a, b) => a.stockAssetName.stockAssetName.localeCompare(b.stockAssetName.stockAssetName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.stockAssetName.stockAssetName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "yahooTicker",
      title: "Yahoo Ticker",
      dataIndex: "yahooTicker",
      width: '1%',
      sorter: (a, b) => a.yahooTicker.localeCompare(b.yahooTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.yahooTicker.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "publisher",
      title: "Publicado",
      dataIndex: ["publisher"],
      width: '1%',
      sorter: (a, b) => a.publisher.localeCompare(b.publisher),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.publisher.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "title",
      title: "Título",
      dataIndex: ["title"],
      width: '5%',
      sorter: (a, b) => a.title.localeCompare(b.title),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.title.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "marketName",
      title: "Mercado",
      dataIndex: ["marketName", "marketNamePT"],
      width: '1%',
      sorter: (a, b) => a.marketName.marketNamePT.localeCompare(b.marketName.marketNamePT),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.marketName.marketNamePT.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "sectorName",
      title: "Sector",
      dataIndex: ["sectorName", "sectorNamePT"],
      width: '1%',
      sorter: (a, b) => a.sectorName.sectorNamePT.localeCompare(b.sectorName.sectorNamePT),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.sectorName.sectorNamePT.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "industryName",
      title: "Industria",
      dataIndex: ["industryName", "industryNamePT"],
      width: '1%',
      sorter: (a, b) => a.industryName.industryNamePT.localeCompare(b.industryName.industryNamePT),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.industryName.industryNamePT.toLowerCase().includes(value.toLowerCase());
      },
    },
    // {
    //   key: "currency",
    //   title: "",
    //   dataIndex: ["currency"],
    //   width: '1%',
    //   sorter: (a, b) => a.currency.localeCompare(b.currency),
    //   filterDropdown: ({
    //     setSelectedKeys,
    //     selectedKeys,
    //     confirm,
    //     clearFilters,
    //   }) => {
    //     return (
    //       <>
    //         <Input
    //           autoFocus
    //           placeholder="Type text here"
    //           value={selectedKeys[0]}
    //           onChange={(e) => {
    //             setSelectedKeys(e.target.value ? [e.target.value] : []);
    //             confirm({ closeDropdown: false });
    //           }}
    //           onPressEnter={() => {
    //             confirm();
    //           }}
    //           onBlur={() => {
    //             confirm();
    //           }}
    //         ></Input>
    //         <Button
    //           onClick={() => {
    //             confirm();
    //           }}
    //           type="primary"
    //         >
    //           Search
    //         </Button>
    //         <Button
    //           onClick={() => {
    //             clearFilters();
    //           }}
    //           type="danger"
    //         >
    //           Reset
    //         </Button>
    //       </>
    //     );
    //   },
    //   filterIcon: () => {
    //     return <SearchOutlined />;
    //   },
    //   onFilter: (value, record) => {
    //     return record.currency.toLowerCase().includes(value.toLowerCase());
    //   },
    // },
        // //column tags
        // {
        //   key:"relatedTickers",
        //   title: "relatedTickers",
        //   align: 'center',
        //   width: '1%',
        //   render: (_, { relatedTickers }) => (
        //     <>
        //       {relatedTickers.map((tag) => {
        //         let color = tag.length > 3 ? 'geekblue' : 'green';
        //         if (tag === 'loser') {
        //           color = 'volcano';
        //         }
        //         return (
        //           <Tag color={color} key={tag}>
        //             {tag.toUpperCase()}
        //           </Tag>
        //         );
        //       })}
        //     </>
        //   ),
        // },
    {
      title: "Acções",
      dataIndex: "type",
      width: '1%',
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
            <EyeOutlined
              onClick={() => {
                    window.open(record.link, "Popup","width=1000, height=1000");
              }}
            />
          </div>
        );
      },
    }
  ];

  // const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllStockAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/newsstockassets/get-newsstockAsset',
        {
          stockAssetName: stockAssetName,
          marketName: marketName,
          sectorName: sectorName,
          industryName: industryName,
          title:title,
          yahooTicker:""
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllStockAsset(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllStockAsset();

    // // Clean up the interval when the component unmounts
    // return () => getAllStockAsset();
  },[marketName,sectorName,industryName,stockAssetName,title])

    //Handler Markets DropList ****************************************************************************************
    useEffect(() => {
      const getAllStocks = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/stockAssets/get-onestockAsset', {
            marketName:"all"
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllStocks(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          //message.error('You are not Admin User');
        }
      };  
      
      getAllStocks();
  
      // Clean up the interval when the component unmounts
      //return () => 
    },[])

  //Handler Markets DropList ****************************************************************************************
  useEffect(() => {
    const getAllMarkets = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/markets/get-stockmarket', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllMarket(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllMarkets();

    // Clean up the interval when the component unmounts
    //return () => 
  },[])

  //Handler Sectors DropList ****************************************************************************************
  useEffect(() => {
    const getAllSectors = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/sectors/get-sector', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllSector(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllSectors();

    // Clean up the interval when the component unmounts
    //return () => 
  },[])

  //Handler Sectors DropList ****************************************************************************************
  useEffect(() => {
    const getAllIndustries = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/industries/get-industry', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllIndustry(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllIndustries();

    // Clean up the interval when the component unmounts
    //return () => 
  },[])

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>Notícias - Acções</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          {/* <Button
            align="right"
            shape="round"
            className="btn btn-primary"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
            >Voltar
          </Button> */}
        </div>
      <div className="accountpage">
      <div  className="vertical-select">
                    {/* <Form.Item> */}
          <h6>Escolha Activo Financeiro</h6>
            <Select
              placeholder="Please select Stock"
              name="asset"
              style={{
                width: '100%',
              }}  
              defaultValue={stockAssetName} 
              onChange={(values) => setStockAssetNameFilter(values)}
              >
                  <option value='all'>Todos</option>
                  {
                    allStocks.map( (getcon, index)=>(
                      <option key={index} value={getcon.id}>{getcon.stockAssetName} </option>
                    ))
                  }
            </Select>
          {/* </Form.Item> */}
          </div>

        <div  className="vertical-select">
                    {/* <Form.Item> */}
          <h6>Escolha Mercado Financeiro</h6>
            <Select
              placeholder="Please select Stock Markets"
              name="accounts"
              style={{
                width: '100%',
              }}  
              defaultValue={marketName} 
              onChange={(values) => setMarketNameFilter(values)}
              >
                  <option value='all'>Todos</option>
                  {
                    allMarket.map( (getcon, index)=>(
                      <option key={index} value={getcon.id}>{getcon.marketName} </option>
                    ))
                  }
            </Select>
          {/* </Form.Item> */}
          </div>

        <div  className="vertical-select">
            <h6>Escolha Sector Financeiro</h6>
              <Select
                // mode="multiple"
                placeholder="Please select Sector"
                name="accounts"
                // size="small"
                style={{
                  width: '100%',
                }}  
                defaultValue={sectorName} 
                onChange={(values) => setSectorNameFilter(values)}
                >
                    <option value='all'>Todos</option>
                    {
                      allSector.map( (getcon, index)=>(
                        <option key={index} value={getcon.id}>{getcon.sectorNamePT} </option>
                      ))
                    }
              </Select>
            {/* </Form.Item> */}
        </div>

        <div  className="vertical-select">
            <h6><h6>Escolha Industria Financeiro</h6></h6>
              <Select
                // mode="multiple"
                placeholder="Please select Sector"
                name="accounts"
                // size="small"
                style={{
                  width: '100%',
                }}  
                defaultValue={industryName} 
                onChange={(values) => setIndustryNameFilter(values)}
                >
                    <option value='all'>Todas</option>
                    {
                      allIndustry.map( (getcon, index)=>(
                        <option key={index} value={getcon.id}>{getcon.industryNamePT} </option>
                      ))
                    }
              </Select>
            {/* </Form.Item> */}
        </div>
        {/* <div className="vertical-select">
          <h6><h6>Procura por Título</h6></h6>
          <Input
              name="title"
              style={{
                  width: '100%',
              }}  
              onChange={(values) => setTitleFilter(values)}
          />
      </div> */}
      </div>
      <hr></hr>
      <div className="content">
          <Table
            size="small"
            columns={columns}
            dataSource={allStockAsset}
            pagination={{ position: ['bottomCenter'], defaultPageSize: 50, showSizeChanger: true, pageSizeOptions: ['10', '25', '50','100']}}
            scroll={{ x: 'max-content'}}
            className="custom-table" // Apply custom CSS class for styling
          />
      </div>
      {/* <div><pre>{JSON.stringify(allStockAsset, null, 2) }</pre></div> */}
      
    </Layout>
  );
};

export default StockNews;