import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Select, Table, Switch, Button, Space} from 'antd';
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import {DeleteOutlined, EditOutlined,SearchOutlined} from "@ant-design/icons";

import moment from 'moment';

const InvestmentsArchive = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allInvestments, setallInvestments] = useState([]);

  const [allAccountStats, setAllAccountStats] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Edit Transactions
    const [editable, setEditable] = useState(null);

    //Fields from table to View
    const [investmentNameUpdate, setInvestmentNameUpdate] = useState(null);
    const [investmentTypeUpdate, setInvestmentTypeUpdate] = useState(null);
    const [investmentDescriptionUpdate, setInvestmentDescriptionUpdate] = useState(null);
    const [brokerNameUpdate, setBrokerNameCompanyUpdate] = useState(null);
    const [frequencyUpdate, setFrequencyUpdate] = useState(null);
    const [statusUpdate, setStatusUpdate] = useState(null);
  

  //table accounts
  const columns =[
    {
      key: "createdAt",
      title: "Data de criação",
      dataIndex: "createdAt",
      width: "5%",
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      key: "updatedAt",
      title: "Ultima Actualização",
      dataIndex: "updatedAt",
      width: "5%",
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
    },
    {
      key: "goalName",
      title: "Objectivo Investimento",
      dataIndex: "goalName",
      width: "5%",
      sorter: (a, b) => a.goalName.localeCompare(b.goalName),
    },
    {
      key: "investmentType",
      title: "Tipo Investimento",
      dataIndex: "investmentType",
      width: "5%",
      sorter: (a, b) => a.investmentType.localeCompare(b.investmentType),
    },
    {
      key: "investmentName",
      title: "Nome Investimento",
      dataIndex: "investmentName",
      width: "5%",
      sorter: (a, b) => a.investmentName.localeCompare(b.investmentName),
    },
    {
      key: "brokerName",
      title: "Casa de Investimentos",
      dataIndex: "brokerName",
      width: "5%",
      sorter: (a, b) => a.loanCompany.localeCompare(b.loanCompany),
    },
    {
      key: "investmentEmission",
      title: "Série Certificados",
      dataIndex: "investmentEmission",
      width: "5%",
      sorter: (a, b) => a.investmentEmission.localeCompare(b.investmentEmission),
    },
    {
      key: "investmentIndexPercentage",
      title: "TANB",
      dataIndex: "investmentIndexPercentage",
      width: "5%",
      sorter: (a, b) => a.investmentIndexPercentage-b.investmentIndexPercentage,
    },
    {
      key: "investmentObject",
      title: "Nome do Objecto Investido",
      dataIndex: "investmentObject",
      width: "5%",
      sorter: (a, b) => a.investmentObject.localeCompare(b.investmentObject),
    },
    {
      key: "investmentObjectYear",
      title: "Ano que adquiriu o Investimento",
      dataIndex: "investmentObjectYear",
      width: "5%",
      sorter: (a, b) => a.investmentObjectYear.localeCompare(b.investmentObjectYear),
    },
    {
      key: "status",
      title: "Estado",
      dataIndex: "status",
      width: "5%",
      sorter: (a, b) => a.status - b.status,
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Acções",
      dataIndex: "type",
      width: "5%",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-4">
            <Space size="middle">
            <EditOutlined
              onClick={() => {
                setEditable(record);
                setInvestmentNameUpdate(record.investmentName);
                setInvestmentTypeUpdate(record.investmentType);
                setInvestmentDescriptionUpdate(record.investmentDescription);
                setBrokerNameCompanyUpdate(record.brokerName);
                setFrequencyUpdate(record.frequency);
                setStatusUpdate(record.status);
                setShowModal(true);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                // console.log(record);
                // navigate('/Transactions',
                // {
                //   state:{
                //     accountId: record.id,
                //     accountDescription: record.accountName           
                //   }
                // });
              }}
            />
            </Space>
          </div>
        );
      },
    }
  ];

  //useEffect Hook
  useEffect(() => {
    const getAllUtility = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/investments/get-investment', {
          userid:user._id,
          status: false
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setallInvestments(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllUtility();
  },[])


  //Handlers User  **************************************************************************************************************************
  const handleSubmitAccount = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/investments/edit-investment', {
      payload:{
        ...values,
        userid: user._id,
      },
      _id: editable._id,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Investimento Activado novamente com sucesso!');
    setShowModal(false);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);
    console.log(values)

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      //////message.error('You are not Admin User');
    }
  }

  const reload=()=>window.location.reload();

  const handleClose = () => {
    setShowModal(false);
    setInvestmentNameUpdate(null);
    setInvestmentTypeUpdate(null);
    setFrequencyUpdate(null);
    setBrokerNameCompanyUpdate(null);
    setStatusUpdate(null);
    setInvestmentDescriptionUpdate(null);
    setStatusUpdate(null);
    reload();
  };

  const onFinish = (values) => {
    console.log({values
    });
  };

  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Arquivo - Contas de Investimento</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      {/* <hr></hr> */}
      <div className="content">
        <br></br>
        <Table 
        columns={columns}
        dataSource={allInvestments}
        pagination={false}
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
      </div>
      {/* <div><pre>{JSON.stringify(allInvestments, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title="Activar Conta de Investimento"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleSubmitAccount} style={{padding:"32px 16px"}}>
          <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da Conta de Investimento</h5>

          <Form.Item label="" name="investmentName">
            <Input defaultValue={investmentNameUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Casa de Investimento</h5>

          <Form.Item label="" name="brokerName">
            <Input defaultValue={brokerNameUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Dados da Conta Investimento</h5>

          <Form.Item label="" name="investmentType">
            <Input defaultValue={investmentTypeUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <Form.Item style={{ marginTop:30 }} label="Observações" name="investmentDescription">
            <TextArea defaultValue={investmentDescriptionUpdate} 
            disabled="true"
              showCount
              maxLength={500}
              style={{
                height: 100,
                marginBottom: 24,
              }}
              type="text"
              />
          </Form.Item>

          <Form.Item label="Frequência" name="frequency">
            <Select defaultValue={frequencyUpdate} 
            disabled="true">
              <Select.Option value="Mensal">Mensal</Select.Option>
              <Select.Option value="Trimestral">Trimestral</Select.Option>
              <Select.Option value="Semestral">Semestral</Select.Option>
              <Select.Option value="Anual">Anual</Select.Option>
            </Select>
          </Form.Item>


            <Form.Item label="Activar novamente a conta" name="status" valuePropName="checked">
              <Switch 
                  style={{
                    width: "100px",
                  }}
              defaultChecked={statusUpdate} checkedChildren="Sim" unCheckedChildren="Não"/>
            </Form.Item>

            <div>
              <button 
              type="submit" 
              className="btn btn-primary" 
              onClick={handleClose}
               style={{
                  width: '100%',
                }}>
                {" "}
                ACTIVAR CONTA INVESTIMENTO NOVAMENTE
              </button>
            </div>
          </Form>
      </Modal>

    </Layout>
  );
};

export default InvestmentsArchive;