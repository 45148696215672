import React,{useState,useEffect} from "react";
import {Table,Button} from 'antd';
import {useNavigate,useLocation} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import moment from 'moment';

const PortfolioWallets = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // const { Meta } = Card;

  // const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const walletNameIdUpdate = data.walletNameId;
  const walletNameUpdate = data.walletName;
  // const currencyUpdate = data.currency;

  const [loading,setLoading] = useState(false);

  const [allSubscription, setAllSubscription] = useState([]);

  // const [allAccountStats, setAllAccountStats] = useState([]);

  // const [show, setShow] = useState(false);

  // const { TextArea } = Input;

    // //Edit Transactions
    // const [editable, setEditable] = useState(null);

  //table accounts
  const columns =[
    {
      key: "date_refreshed",
      title: "Data de Actualização",
      dataIndex: "date_refreshed",
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.date_refreshed) - new Date(b.date_refreshed)
    },
    {
      key: "symbol",
      title: "Símbolo",
      dataIndex: ["symbol"],
      sorter: (a, b) => a.symbol.localeCompare(b.symbol),
    },
    {
      key: "cryptoAssetName",
      title: "Título",
      dataIndex: ["cryptoAssetName","cryptoAssetName"],
      sorter: (a, b) => a.cryptoAssetName.cryptoAssetName.localeCompare(b.cryptoAssetName),
    },
    {
      key: "protocol",
      title: "Protocolo",
      dataIndex: ["walletName","protocol"],
      sorter: (a, b) => a.walletName.protocol.localeCompare(b.walletName),
    },
    {
      key: "qty",
      title: "Quantidade",
      dataIndex: "qty",
      align: "right",
      sorter: (a, b) => a.qty-b.qty,
      render: (qty, record) => {
        if(record.qty >= 0 )
          return (
          <span style={{color: "black"}}>{qty}</span>
          );
      },
    },
    {
      key: "today_price",
      title: "Cotação Base",
      dataIndex: "today_price",
      align: "right",
      sorter: (a, b) => a.today_price -b.today_price,
    },
    {
      key: "currency",
      title: "Moeda Base",
      dataIndex: ["currency"],
    },
    {
      key: "today_value",
      title: "Valor Base",
      dataIndex: "today_value",
      align: "right",
      sorter: (a, b) => a.today_value-b.today_value,
      render: (today_value, record) => {
        if(record.today_value >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(today_value).toFixed(2)}</span>
          );
    },
    },
    {
      key: "currency",
      title: "Moeda Base",
      dataIndex: ["currency"],
    },
    {
      key: "today_price_ctr",
      title: "Cotação",
      dataIndex: "today_price_ctr",
      align: "right",
      sorter: (a, b) => a.today_price_ctr -b.today_price_ctr,
      render: (today_price_ctr, record) => {
        if(record.today_price_ctr >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(today_price_ctr).toFixed(4)}</span>
          );
    },
    },
    {
      key: "currency_ctr",
      title: "Moeda",
      dataIndex: ["currency_ctr"],
    },
    {
      key: "today_value_ctr",
      title: "Valor",
      dataIndex: "today_value_ctr",
      width: "3%",
      align: "right",
      sorter: (a, b) => a.today_value_ctr-b.today_value_ctr,
      render: (today_value_ctr, record) => {
        if(record.today_value_ctr >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(today_value_ctr).toFixed(2)}</span>
          );
    },
    },
    {
      key: "currency_ctr",
      title: "Moeda",
      dataIndex: ["currency_ctr"],
    },
    {
      key:"weights_pct",
      title: "Peso%",
      dataIndex: "weights_pct",
      defaultSortOrder: 'descend',
      align: 'right',
      sorter: (a, b) => a.weights_pct - b.weights_pct,
      render: (weights_pct, record) => {
        if(record.weights_pct >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(weights_pct).toFixed(2)} %</span>
          );
      },
  },
    // {
    //   title: "Acções",
    //   dataIndex: "type",
    //   width: "5%",
    //   render: (type, record) => {
    //     // if(type === "Income" )
    //     return (
    //       <div className="mx-4">
    //         <Space size="middle">
    //         <EditOutlined
    //           onClick={() => {
    //             setEditable(record);
    //             setExchangeNameUpdate(record.exchangeName.exchangeName);
    //             setBrokerNameUpdate(record.exchangeName.brokerName);
    //             setFrequencyUpdate(record.exchangeName.frequency);
    //             setStatusUpdate(record.status);
    //             setExchangeDescriptionUpdate(record.exchangeName.exchangeDescription);
    //             setExchangeIdUpdate(record.exchangeName._id);
    //             setCashExchangeIdUpdate(record._id);
    //             setShowModal(true);
    //           }}
    //         />
    //         <DeleteOutlined
    //           onClick={() => {
    //             // console.log(record);
    //             // navigate('/Transactions',
    //             // {
    //             //   state:{
    //             //     accountId: record.id,
    //             //     accountDescription: record.accountName           
    //             //   }
    //             // });
    //           }}
    //         />
    //         </Space>
    //       </div>
    //     );
    //   },
    // }
  ];

  //useEffect Hook
  useEffect(() => {
    const getAllSubscription = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/portfolio-wallets/get-portfolio-wallet', {
          userid:user._id,
          walletName: walletNameIdUpdate
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSubscription(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllSubscription();
  },[walletNameIdUpdate])


  // //Handlers User  **************************************************************************************************************************
  // const handleSubmitAccount = async (values) => {

  // try{
  
  //   const user = JSON.parse(localStorage.getItem('user'))

  //   setLoading(true);
  //   const res = await axios.post('/exchanges/edit-exchange', {
  //     payload:{
  //       archive: false,
  //       status: true,
  //       userid: user._id,
  //     },
  //     _id: exchangeIdUpdate,
  //   },
  //   {
  //     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //   }
  //   );
  //   const res2 = await axios.post('/cash-exchanges/edit-cash-exchange', {
  //     payload:{
  //       archive: false,
  //       userid: user._id,
  //     },
  //     _id: cashExchangeIdUpdate,
  //   },
  //   {
  //     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //   }
  //   );
  //   setLoading(false);
  //   message.success('Conta Wallet Activada novamente com sucesso!');
  //   setShowModal(false);
  //   setTimeout(function () {
  //     window.location.reload(true);
  //   }, 1000);
  //   console.log(values)
  //   }catch (error){
  //     setLoading(false);
  //     // message.error('Failed to industry');
  //     //message.error('You are not Admin User');
  //   }
  // }

  return (
    <Layout>
      {loading && <Spinner/>}
    
      <div className="accountpage">
          <div>
          </div>
          {/* <div><h1> Portfolio ETFs- {investmentNameUpdate} - {currencyUpdate}</h1></div> */}
          <div><h1> Portfolio Wallet- {walletNameUpdate}</h1></div>
          <div></div>
        </div>
        <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <hr></hr>
      <div className="content">
        <br></br>
        <Table
            // size="small"
            columns={columns}
            dataSource={allSubscription}
            pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
            scroll={{ x: 'max-content'}}
            className="custom-table" // Apply custom CSS class for styling
        />
      </div>

    </Layout>
  );
};

export default PortfolioWallets;