import React,{useState,useEffect} from 'react';
import {Form,Input,Button,Space,Radio,message,Select,Row,Switch} from 'antd';
import { Link } from 'react-router-dom';

import Layout from "../components/Layout/Layout";
import {useNavigate} from "react-router-dom";

import moment from 'moment';

import axios from "axios";
import Spinner from "../components/Spinner";

const NewIncomes = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [showField, setShowField] = useState(false);

  const handleSwitchChange = (checked) => {
    setShowField(checked);
  };

  //loading Apis
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState('')

  //DropList Category
  const [allCategory, setAllCategory] = useState([]);
  //DropList Account
  const [allAccount, setAllAccount] = useState([]);

  //Add Tags
  const [tags, setTags] = useState([]);

  //Recursive Dates Array
  const [sdates, setsDates]= useState([]);
  const [dateUpdate, setDateUpdate] = useState();

  const { TextArea } = Input;

  const [categoryDetails, setCategoryDetails] = useState(null);
  const [accountDetails, setAccountDetails] = useState(null);
  const [isCreditCardDetails, setIsCreditCardDetails] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user = JSON.parse(localStorage.getItem('user'))

  const addTag = (e) => {
    if (e.key === "Tab") {
      if (e.target.value.length > 0) {
        setTags([...tags, e.target.value]);
        e.target.value = "";
      }
    }
  };

       //***************************************MONTHS RECURSIVES**************************************************************/
    //Recursive Dates Array
    const [sMonth, setMonth]= useState('0');

    const handleMonths=(event)=>{
  
        setMonth([]);
    
        const getMonth= event.target.value;
    
        console.log(getMonth); //22
        console.log(sdates); //2023-01-06
    
        const dates = [];
        const currDate = moment(sdates);
    
        if (getMonth === "1") {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }else{
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add(getMonth-1, 'month').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          //console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(1, "month").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
    
        message.success(dates);
        console.log(dates);
    
        setMonth(dates);
      }

    const worker = {
    dates: moment(dateUpdate),
    };

    //Handler Fund Family DropList ****************************************************************************************
    useEffect(() => {
      const getAllAccounts = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/accounts/get-account', {
            userid:user._id,
            status: true,
            isCreditCard: "all"
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllAccount(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          //message.error('You are not Admin User');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllAccounts();
    },[])
  
    //Handler Sectors DropList ****************************************************************************************
    useEffect(() => {
      const getAllCategories = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/categories/get-categoryName', {
            userid:user._id,
            isIncomeOrExpense: false,
            isBusiness: user.isBusiness,
            isCreditCard: "all"
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllCategory(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          //message.error('You are not Admin User');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllCategories();

    },[isCreditCardDetails])

let range = {min: 1, max: 999999999}
let delta = range.max - range.min

const codeSquare = Math.round(range.min + Math.random() * delta)

  const submitHandler = async (values) => {

    try{
      setLoading(true);

      if(isCreditCardDetails!==true)
      {
        for (const sdate of sMonth) {
          const res = axios.post('/transactions/add-transaction', {
            ...values, 
            isIncomeOrExpense:true,
            date: sdate,
            userid:user._id, 
            monthYear: moment(sdate).format("MMM YYYY"),
            monthYearNumber: moment(sdate).format("YYYYMM"),
            dayMonthYearNumber: moment(sdate).format("YYYY-MM-DD"),
            accountName:accountDetails.split('|')[0],
            categoryName:categoryDetails,
            codeSquare: codeSquare,
            tags: values.tags,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
        }
      }else{
        for (const sdate of sMonth) {
          const res = axios.post('/transactions/add-transaction', {
            ...values, 
            isIncomeOrExpense:true,
            date: sdate,
            userid:user._id, 
            monthYear: moment(sdate).format("MMM YYYY"),
            monthYearNumber: moment(sdate).format("YYYYMM"),
            dayMonthYearNumber: moment(sdate).format("YYYY-MM-DD"),
            accountName:accountDetails.split('|')[0],
            categoryName:categoryDetails,
            codeSquare: codeSquare,
            tags: values.tags,
            isCreditCard: true
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
        }
      }
      setLoading(false);
      message.success('Receita Criada com Sucesso!');
      navigate('/Accounts');
    }catch (error){
      setLoading(false);
      message.error('Algo deu errado!');
    }
  }

  //Handler Test Console Log Values
  // const onFinish = (values) => {
  //   console.log({
  //     ...values, 
  //     isIncomeOrExpense:true,
  //     date: values.date,
  //     userid:user._id,
  //     monthyear: moment(values.date).format("MMM YYYY"),
  //     monthyearnumber: moment(values.date).format("YYYYMM"),
  //     accountName:accountDetails,
  //     categoryName:categoryDetails
  //   });
  // };

  const handleDate=(event)=>{
    setsDates([]);
    const getDates= moment(event.target.value);
    setsDates(getDates);
    console.log(getDates);

    // novo
    setMonth([]);
    const dates = [];
    dates.push(moment(getDates).format('YYYY-MM-DD'));
    message.success(dates);
    setMonth(dates);
  }

  const onChangeAccount = ({ target: { value } }) => {
    console.log('Account choose: ', value);
    setAccountDetails(value);
    if(value.split('|')[1]==="true"){
      setIsCreditCardDetails(true);
    }else{
      setIsCreditCardDetails(false);
    }
  };

  const onChangeCategory = ({ target: { value } }) => {
    console.log('Category choose: ', value);
    setCategoryDetails(value);
  };

  const totalAccounts = allAccount.length;

  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Nova Receita</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate("/Accounts")}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={submitHandler} 
          >
            <Space direction="horizontal" className="horizontal-space">
            <Form.Item style={{ marginTop:10 }} label="Valor da Receita" name="amount">
              <Input
                placeholder='0.00'
                // suffix="€"
                type="text" 
                size="large" 
                style={{fontSize:"32px"}}
              />
            </Form.Item>
            <Form.Item style={{ marginTop:10 }} label="Moeda"  name="currency">
                  <Input style={{fontSize:"32px", width: "100px"}} defaultValue={user.currency} disabled="true"/>
            </Form.Item>
            </Space>
            <Form.Item label="Data" name="date" onChange={(e)=>handleDate(e)}
                rules={[
                  { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                    ]}>
                <Input style={{fontSize:"18px"}} type="date"/>
            </Form.Item>

            <Form.Item label="Já recebida?" name="isPayedReceived" valuePropName="checked">
              <Switch checkedChildren="Sim" unCheckedChildren="Não"/>
            </Form.Item>

            <h5 style={{ marginBottom:30, marginTop:30 }}>Conta</h5>

            <Radio.Group onChange={onChangeAccount} style={{ display: "flex", justifyContent: "left"}} required>
              <Space direction="vertical" align='center' size="middle">
              <Row gutter={[8,24]}>
              {
                      allAccount.map((opts,i)=>
                        <Radio.Button style={{ display: "flex", justifyContent: "center", width:"100%", marginTop: 5 }} 
                        key={i} value={`${opts._id}|${opts.isCreditCard}`}>
                          {opts.accountName}
                        </Radio.Button>)
                      }
              </Row>
              </Space>
            </Radio.Group>
            {accountDetails ? null : <span style={{ color: 'red' }}>* Campo de preenchimento obrigatório</span>}

            <h5 style={{ marginBottom:30, marginTop:30 }}>Tipo de Receita</h5>

            
            <Radio.Group onChange={onChangeCategory} style={{ display: "flex", justifyContent: "left"}} required>
              <Space direction="vertical" align='center' size="middle">
              <Row gutter={[8,24]}>
                      {
                      allCategory.map((opts,i)=>
                        <Radio.Button style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%", marginTop: 5 }} key={i} value={opts._id}>
                          {opts.categoryNamePT}
                        </Radio.Button>)
                      }
              </Row>
              </Space>
            </Radio.Group>
            {accountDetails ? null : <span style={{ color: 'red' }}>* Campo de preenchimento obrigatório</span>}

            <Form.Item style={{ marginTop:30 }} label="Comentários" name="comments">
              <TextArea
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                />
            </Form.Item>

            <Form.Item label="Tags" name="tags">
              <Select
                mode="tags"
                style={{
                  width: '100%',
                }}
                placeholder="Tags Mode"
                onChange={addTag}
                >
              </Select>
            </Form.Item>

            <Form.Item label="Receita Recorrente">
              <Switch onChange={handleSwitchChange} />
            </Form.Item>

            {showField && (
            <Form.Item label="Número de meses de receita recorrente" name="months" onChange={(e)=>handleMonths(e)} 
                rules={[
                  {
                    required: false, message: "Por favor, insira o número de meses que vai receber esta receita recorrente!",
                    min: 1,
                    max: 999,
                  }
                    ]}>
                <Input min={1} defaultValue={1}/>
            </Form.Item>
            )}

            {/* <Form.Item label="Viagem?" name="isTravel" valuePropName="checked">
              <Switch checkedChildren="Sim" unCheckedChildren="Não" />
            </Form.Item> */}

          <Button style={{ width: "100%", background:"green" }} type="primary" htmlType='submit'>
                CRIAR
                </Button>
            <Link to="/Accounts">
            <Button style={{ width: "100%", marginTop: 30 }} type="primary">
              CANCELAR
            </Button>
            </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(allExpenses, null, 2) }</pre></div> */}
    </Layout>
  )
  };

export default NewIncomes;