import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Table, Switch, Select, Button,Space} from 'antd';
import {useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {
  SearchOutlined,
  EditOutlined
} from "@ant-design/icons";

const StockAssets = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const { TextArea } = Input;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allStockAsset, setAllStockAsset] = useState([]);

  //DropList Market
  const [allMarket, setAllMarket] = useState([]);
  //DropList Sectors
  const [allSector, setAllSector] = useState([]);
  //DropList Industry
  const [allIndustry, setAllIndustry] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Fields from table to View
  const [stockAssetNameUpdate, setStockAssetNameUpdate] = useState(null);
  const [yahooTickerUpdate, setYahooTickerUpdate] = useState(null);
  const [bloombergTickerUpdate, setBloombergTickerUpdate] = useState(null);
  const [descriptionUpdate, setDescriptionUpdate] = useState(null);
  const [websiteUpdate, setWebsiteUpdate] = useState(null);
  const [logoURLUpdate, setLogoURLUpdate] = useState(null);
  const [ISINUpdate, setISINUpdate] = useState(null);
  const [marketNameUpdate, setMarketNameUpdate] = useState(null);
  const [sectorNameUpdate, setSectorNameUpdate] = useState(null);
  const [industryNameUpdate, setIndustryNameUpdate] = useState(null);
  const [currencyUpdate, setCurrencyUpdate] = useState(null);
  const [statusUpdate, setStatusUpdate] = useState(null);

  //Filters
  const [marketName, setMarketNameFilter] = useState('all');

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "stockAssetName",
      title: "Asset",
      dataIndex: "stockAssetName",
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.stockAssetName.localeCompare(b.stockAssetName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.stockAssetName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "yahooTicker",
      title: "Yahoo Ticker",
      dataIndex: "yahooTicker",
      sorter: (a, b) => a.yahooTicker.localeCompare(b.yahooTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        const ticker = record.yahooTicker;
        if (ticker && typeof ticker === 'string') {
          return ticker.toUpperCase().includes(value.toUpperCase());
        }
        return false; // or handle the case where record.yahooTicker is not a string
      },
    },
    {
      key: "bloombergTicker",
      title: "Bloomberg Ticker",
      dataIndex: "bloombergTicker",
      sorter: (a, b) => a.bloombergTicker.localeCompare(b.bloombergTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        const ticker = record.bloombergTicker;
        if (ticker && typeof ticker === 'string') {
          return ticker.toUpperCase().includes(value.toUpperCase());
        }
        return false; // or handle the case where record.yahooTicker is not a string
      },
    },
    {
      key: "marketName",
      title: "Market",
      dataIndex: ["marketName", "marketName"],
      sorter: (a, b) => a.marketName.marketName.localeCompare(b.marketName.marketName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        const ticker = record.marketName?.marketName;
        if (ticker && typeof ticker === 'string') {
          return ticker.toLowerCase().includes(value.toLowerCase());
        }
        return false; // or handle the case where record.marketName.marketName is not a string
      },
    },
    {
      key: "sectorName",
      title: "Sector",
      dataIndex: ["sectorName", "sectorName"],
      sorter: (a, b) => a.sectorName.sectorName.localeCompare(b.sectorName.sectorName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.sectorName.sectorName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "industryName",
      title: "Industry",
      dataIndex: ["industryName", "industryName"],
      sorter: (a, b) => a.industryName.industryName.localeCompare(b.industryName.industryName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.industryName.industryName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "currency",
      title: "Currency",
      dataIndex: ["currency"],
      sorter: (a, b) => a.currency.localeCompare(b.currency),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.currency.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Actions",
      dataIndex: "type",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
            <EditOutlined
              onClick={() => {
                    setEditable(record);
                    setStockAssetNameUpdate(record.stockAssetName);
                    setYahooTickerUpdate(record.yahooTicker);
                    setBloombergTickerUpdate(record.bloombergTicker);
                    setDescriptionUpdate(record.description);
                    setWebsiteUpdate(record.website);
                    setLogoURLUpdate(record.logoURL);
                    setISINUpdate(record.ISIN);
                    setMarketNameUpdate(record.marketName.marketName);
                    setSectorNameUpdate(record.sectorName.sectorName);
                    setIndustryNameUpdate(record.industryName.industryName);
                    setCurrencyUpdate(record.currency);
                    setStatusUpdate(record.status);
                    //navigate('/ImportFund', { state: record });
                    setShowModal(true);
              }}
            />
          </div>
        );
      },
    }
  ];

  // const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllStockAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/stockAssets/get-stockAsset',
        {
          // userid:user._id,
          marketName
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllStockAsset(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        //message.error('You are not Admin User');
      }
    };  
    getAllStockAsset()

    // Clean up the interval when the component unmounts
    //return () => getAllStockAsset();
  },[marketName])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitStockAsset = async (values) => {

  try{
    // const promises = [];
  
    const user = JSON.parse(localStorage.getItem('user'))

    if(editable){
      setLoading(true);
      const result = axios.post('/stockAssets/edit-stockAsset', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: editable._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Stock Asset Updated Successfull');

    }else{
      setLoading(true);
      const result = axios.post('/stockAssets/add-stockAsset', {
        ...values, 
        userid:user._id, 
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Stock Asset Added Successfull');
    }

    setShowModal(false);
    setEditable(null);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      //message.error('You are not Admin User');
    }
  }

  //Handler Markets DropList ****************************************************************************************
  useEffect(() => {
    const getAllMarkets = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/markets/get-stockmarket', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllMarket(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        //message.error('You are not Admin User');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllMarkets();
  },[])

  //Handler Sectors DropList ****************************************************************************************
  useEffect(() => {
    const getAllSectors = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/sectors/get-sector', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllSector(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        //message.error('You are not Admin User');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllSectors();
  },[])

  //Handler Sectors DropList ****************************************************************************************
  useEffect(() => {
    const getAllIndustries = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/industries/get-industry', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllIndustry(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        //message.error('You are not Admin User');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllIndustries();
  },[])

  // //Handler Test Console Log Values
  // const onFinish = (values) => {
  //   console.log({values});
  // };

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setEditable(null);
    setStockAssetNameUpdate(null);
    setYahooTickerUpdate(null);
    setBloombergTickerUpdate(null);
    setDescriptionUpdate(null);
    setWebsiteUpdate(null);
    setLogoURLUpdate(null);
    setISINUpdate(null);
    setMarketNameUpdate(null);
    setSectorNameUpdate(null);
    setIndustryNameUpdate(null);
    setCurrencyUpdate(null);
    setStatusUpdate(null);
  };

    /************************************Website DISABLE BUTTON***************************/
    let webSiteInputDisable;

    if (!websiteUpdate) {
      webSiteInputDisable = 
      <Form.Item label="Website" name="website">
          <Input 
          type="text"
          />
      </Form.Item>
    } else {
      webSiteInputDisable = 
      <Form.Item label="Website" name="website">
        <Space.Compact style={{ width: '100%' }}>
          <Input 
          type="text"
          defaultValue={websiteUpdate}
          />
          <Button type="primary" onClick={() => window.open(websiteUpdate, "Popup","width=600, height=600")}>View</Button>
        </Space.Compact>
      </Form.Item>
    }

    let logoURLUDisable;

    if (logoURLUpdate) {
      logoURLUDisable = 
      <div>
        <img src={logoURLUpdate} alt={yahooTickerUpdate} style={{ width: '150px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}}/>
      </div>
    } 


  // //Handler Close Modal
  const handleClose = () => {
    setShow(false);
  };
  
  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Definições - Industrias</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
          <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
          >+ Nova Industria
        </Button>
        </div>

      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>

        <div className="accountpage">
        <div></div>
        <div className="vertical-select">
        <h6>Escolha Mercado Financeiro</h6>
          <Select
            placeholder="Please select Stock Markets"
            name="accounts"
            style={{
              width: '250px',
            }}  
            defaultValue={marketName} 
            onChange={(values) => setMarketNameFilter(values)}
            >
                <option value='all'>All</option>
                {
                  allMarket.map( (getcon, index)=>(
                    <option key={index} value={getcon.id}>{getcon.marketName} </option>
                  ))
                }
          </Select>
        </div>
        <div></div>
      </div>
      <hr></hr>
      <div className="content">
        <Table
        // size="small" 
        columns={columns}
        dataSource={allStockAsset}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
        
      </div>
      {/* <div><pre>{JSON.stringify(allStockAsset, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(allMarket, null, 2) }</pre></div> */}
      
      <Modal
        destroyOnClose={true} 
        title={editable ? "Edit Stock Asset" : "Add Stock Asset" }
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          {/* See the button view */}
          {logoURLUDisable}

          <Form 
            layout="vertical" 
            onFinish={handleSubmitStockAsset} 
            style={{padding:"32px 16px"}}
          >

            <Form.Item label="Asset" name="stockAssetName">
              <Input 
                type="text"
                defaultValue={stockAssetNameUpdate}
                />
            </Form.Item>
            <Form.Item label="Yahoo Ticker" name="yahooTicker">
              <Input 
                type="text"
                defaultValue={yahooTickerUpdate}
                />
            </Form.Item>

            <Form.Item label="Bloomberg Ticker" name="bloombergTicker">
              <Input
                defaultValue={bloombergTickerUpdate}
                />
            </Form.Item>

            <Form.Item label="Description" name="description">
              <TextArea
                showCount
                maxLength={2000}
                style={{
                  height: 400,
                  marginBottom: 24,
                }}
                type="text" 
                defaultValue={descriptionUpdate}
                />
            </Form.Item>

            {/* <Form.Item label="Website" name="website">
              <Input 
                type="text"
                defaultValue={websiteUpdate}
                />
            </Form.Item> */}

            {/* See the button view */}
            {webSiteInputDisable}

            <Form.Item label="LogoURL" name="logoURL">
              <Input 
                type="text"
                defaultValue={logoURLUpdate}
                />
            </Form.Item>

            <Form.Item label="ISIN" name="ISIN">
              <Input 
                type="text"
                defaultValue={ISINUpdate}
                />
            </Form.Item>

            <Form.Item label="Market Name" name="marketName">
              <Select
                defaultValue={marketNameUpdate}
              >
                  {
                  allMarket.map((opts,i)=>
                    <Select.Option key={i} value={opts._id}>
                      {opts.marketName}
                    </Select.Option>)
                  }
              </Select>
            </Form.Item>

            <Form.Item label="Sector Name" name="sectorName">
              <Select
                defaultValue={sectorNameUpdate}
              >
                  {
                  allSector.map((opts,i)=>
                    <Select.Option key={i} value={opts._id}>
                      {opts.sectorName}
                    </Select.Option>)
                  }
              </Select>
            </Form.Item>

            <Form.Item label="Industry Name" name="industryName">
              <Select
                defaultValue={industryNameUpdate}
              >
                  {
                  allIndustry.map((opts,i)=>
                    <Select.Option key={i} value={opts._id}>
                      {opts.industryName}
                    </Select.Option>)
                  }
              </Select>
            </Form.Item>

            <Form.Item label="Currency" name="currency">
              <Select defaultValue={currencyUpdate}>
                <Select.Option value="EUR">EUR</Select.Option>
                <Select.Option value="USD">USD</Select.Option>
                <Select.Option value="CHF">CHF</Select.Option>
                <Select.Option value="GBP">GBP</Select.Option>
                <Select.Option value="HKD">HKD</Select.Option>
                <Select.Option value="BRL">BRL</Select.Option>
                <Select.Option value="JPY">JPY</Select.Option>    
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>
            
            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch 
                defaultChecked={statusUpdate}
              />
            </Form.Item>

            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" onClick={handleClose}>
                {" "}
                SAVE
              </button>
            </div>
            {/* <div><pre>{JSON.stringify(allMarket, null, 2) }</pre></div> */}
            
          </Form>
      </Modal>

    </Layout>
  );
};

export default StockAssets;