import React,{useState,useEffect} from 'react'
import {Link,useNavigate} from "react-router-dom";
import { Button,message,Space,Row} from "antd";

import axios from "axios";

const Navbar = () => {

  const [loginUser, setLoginUser] = useState('')
  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

    //Handler Fund Family DropList ****************************************************************************************
    useEffect(() => {
      const getAllAccounts = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/accounts/get-account', {
            userid:user._id,
            status: true,
            isCreditCard: "all"
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllAccount(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          // message.error('You are not Admin User');
        }
      };  
      
    // Clean up the interval when the component unmounts
    return () => getAllAccounts();
    
    },[])
  
      
  // const logoutHandler = () => {
  //   localStorage.removeItem('user');
  //   message.success("Logout Successfully");
  //   navigate('/login');
  // }
  
  const NewExpenseHandler = () => {
    navigate('/NewExpenses');
  }

  const NewIncomeHandler = () => {
    navigate('/NewIncomes');
  }

  const totalAccounts = allAccount.length;

      
  return (
    <>
    <nav className="navbar navbar-expand-lg" style={{ marginTop: 20 }}>
        <div className="container-fluid">
            <div className="row align-items-center">
              {/*<div className="col">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                Dashboard
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/Explorer">
                                Explorer
                            </a>
                        </li>
                    </ul>
                </div> */}
                {totalAccounts > 0 && (
                    <div className="col d-flex justify-content-end">
                        <button
                            className="btn btn-primary mx-2"
                            style={{ background: "#C30101", borderColor: "#C30101" }}
                            onClick={NewExpenseHandler}
                        >
                            Despesa
                        </button>
                        <button
                            className="btn btn-primary mx-2"
                            style={{ background: "green", borderColor: "green" }}
                            onClick={NewIncomeHandler}
                        >
                            Receita
                        </button>
                    </div>
                )}
            </div>
        </div>
    </nav>
    </>
  )
}

export default Navbar;