import React,{useState,useEffect} from "react";
import {Form, Input, message, Select, Button, Card} from 'antd';
import {useNavigate,useLocation,Link} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';

import moment from 'moment';

const ArchiveWallets = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const walletDataUpdate = data;
  const walletIdUpdate = data.walletId;
  const walletNameUpdate = data.walletName;
  const walletDescriptionUpdate = data.walletDescription;
  const brokerNameUpdate = data.brokerName;
  const currencyUpdate = data.currency_ctr;
  const protocolUpdate = data.protocol;
  const walletStartDateUpdate = data.walletStartDate;
  const walletEndDateUpdate = data.walletEndDate;
  const frequencyUpdate = data.frequency;
  const goalNameUpdate = data.goalName;
  // const statusUpdate = data.status;
  const walletUpdate = data.codeSquare;


  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  const [allAccountStats, setAllAccountStats] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

  //Handlers User  **************************************************************************************************************************
  const handleSubmitAccount = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/wallets/edit-wallet', {
      payload:{
        status: false,
        userid: user._id,
      },
      _id: data.walletId,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    const res2 = await axios.post('/wallet-transactions/archive-allwallet-transaction', {
      payload:{
        isArchive: true,
      },
      walletName: walletNameUpdate,
      userid: user._id,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Conta Wallet Arquivada com sucesso!');
    navigate('/Wallets');
    console.log(values)

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  return (
    <Layout>
      <div className="accountpage">
        <div></div>
        <div><h1>Arquivar Conta - {walletNameUpdate}</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>

      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={handleSubmitAccount} 
          >

          <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da conta Wallet</h5>

          <Form.Item label="" name="walletName">
            <Input
            disabled="true"
            defaultValue={walletNameUpdate} 
            type="text"
            />
          </Form.Item>

          <Form.Item style={{ marginTop:30 }} label="Observações" name="walletDescription">
              <TextArea
              disabled="true"
              defaultValue={walletDescriptionUpdate}
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                />
            </Form.Item>

              <h5 style={{ marginBottom:30, marginTop:10 }}>Nome do Broker</h5>

                <Form.Item label="" name="brokerName">
                  <Input
                  disabled="true"
                  defaultValue={brokerNameUpdate} 
                  type="text"
                  style={{
                    width: '100%',
                  }} 
                  />
                </Form.Item>

                <Form.Item label="Protocolo" name="protocol">
                    <Select
                    disabled="true"
                    defaultValue={protocolUpdate}
                        style={{
                          width: '100%',
                        }} 
                    >
                      <Select.Option value="Ethereum">Ethereum</Select.Option>
                      <Select.Option value="Arbitrum">Arbitrum</Select.Option>
                      <Select.Option value="Optimism">Optimism</Select.Option>
                      <Select.Option value="Polygon">Polygon</Select.Option>
                      <Select.Option value="Base">Base</Select.Option>
                      <Select.Option value="BNB Chain">BNB Chain</Select.Option>
                      <Select.Option value="Avalanche">Avalanche</Select.Option>              
                      <Select.Option value="Celo">Celo</Select.Option>
                      <Select.Option value="Other">Other</Select.Option>
                    </Select>
                </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Objectivo para que Investe</h5>


          <Form.Item label="" name="goalName">
             <Select 
             disabled="true"
              defaultValue={goalNameUpdate}
                style={{
                  width: '100%'
                }} >
                <Select.Option value="Fundo de Emergência">Fundo de Emergência</Select.Option>
                <Select.Option value="Reforma">Reforma</Select.Option>
                <Select.Option value="Habitação">Habitação</Select.Option>
                <Select.Option value="Educação">Educação</Select.Option>
                <Select.Option value="Saúde">Saúde</Select.Option>
                <Select.Option value="Viagem">Viagem</Select.Option>
                <Select.Option value="Automóvel">Automóvel</Select.Option>
                <Select.Option value="Mota">Mota</Select.Option>
                <Select.Option value="Filantropia">Filantropia</Select.Option>
                <Select.Option value="Outro">Outro</Select.Option>
              </Select>
          </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Dados da Wallet</h5>

          <Form.Item 
            label="Data começo da conta wallet" 
            name="walletStartDate" 
          >
            <Input
            disabled="true" 
            defaultValue={moment(walletStartDateUpdate).format("DD/MM/YYYY")}
            style={{fontSize:"18px"}}/>
            </Form.Item>

            <Form.Item label="Frequência de que tenciona investir" name="frequency" >
              <Select
              disabled="true" 
              defaultValue={frequencyUpdate}
                style={{
                  width: '100%'
                }} >
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item>

            <Button style={{ width: "100%" , background: "green",color:"white", marginTop: 30, marginBottom: 30}}  htmlType='submit'>
            ARQUIVAR
              </Button>
              <Link to="/Wallets">
                <Button style={{ width: "100%" }} type="primary">
                  CANCELAR
                </Button>
                </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(accountDataUpdate, null, 2) }</pre></div> */}
    </Layout>
  );
};

export default ArchiveWallets;