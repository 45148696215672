import React,{useState,useEffect} from "react";
import {Input, message, Table,Button, Space} from 'antd';
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";

import moment from 'moment';

const AccountsArchive = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  // const [allAccountStats, setAllAccountStats] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Edit Transactions
    const [editable, setEditable] = useState(null);

    //Fields from table to View
    const [accountNameUpdate, setAccountNameUpdate] = useState(null);
    const [accountDescriptionUpdate, setAccountDescriptionUpdate] = useState(null);
    const [accountIBANUpdate, setAccountIBANUpdate] = useState(null);
    const [accountTypeUpdate, setAccountTypeUpdate] = useState(null);
    const [statusUpdate, setStatusUpdate] = useState(null);

  //table accounts
  const columns =[
    {
      key: "createdAt",
      title: "Data de criação",
      dataIndex: "createdAt",
      width: "5%",
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      key: "accountName",
      title: "Conta",
      dataIndex: "accountName",
      width: "5%",
      sorter: (a, b) => a.accountName.localeCompare(b.accountName),
    },
    {
      key: "accountDescription",
      title: "Descrição",
      dataIndex: "accountDescription",
      width: "5%",
      sorter: (a, b) => a.accountDescription.localeCompare(b.accountDescription),
    },
    {
      key: "accountType",
      title: "Tipo de Conta",
      dataIndex: "accountType",
      width: "5%",
      sorter: (a, b) => a.accountType.localeCompare(b.accountType),
    },
    {
      key: "accountIBAN",
      title: "IBAN",
      dataIndex: "accountIBAN",
      width: "5%"
    },
    {
      key: "status",
      title: "Estado",
      dataIndex: "status",
      width: "5%",
      sorter: (a, b) => a.status - b.status,
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Acções",
      dataIndex: "type",
      width: "5%",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-4">
            <Space size="middle">
            <EditOutlined
              // onClick={() => {
              //   setEditable(record);
              //   setAccountNameUpdate(record.accountName);
              //   setAccountDescriptionUpdate(record.accountDescription);
              //   setAccountIBANUpdate(record.accountIBAN);
              //   setAccountTypeUpdate(record.accountType);
              //   setStatusUpdate(record.status);
              //   setShowModal(true);
              // }}
              onClick={() => {
                navigate('/ArchiveAccountsActive',
                {
                  state:{
                    accountID: record._id ,
                    accountName: record.accountName,
                    accountDescription:  record.accountDescription,
                    accountIBAN:  record.accountIBAN,   
                    accountType:  record.accountType,
                    userID: record.userid
                  }
                });
              }}
            />
            {/* <UnorderedListOutlined
              onClick={() => {
                console.log(record);
                navigate('/Transactions',
                {
                  state:{
                    accountId: record.id,
                    accountDescription: record.accountName           
                  }
                });
              }}
            /> */}
            </Space>
          </div>
        );
      },
    }
  ];

  //useEffect Hook
  useEffect(() => {
    const getAllAccount = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/accounts/get-account', {
          userid:user._id,
          status: false,
          isCreditCard: false
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllAccount(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        // //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllAccount();
  },[])


  //Handlers User  **************************************************************************************************************************
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

  const handleSubmitAccount = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/accounts/edit-account', {
      payload:{
        ...values,
        userid: user._id,
      },
      _id: editable._id,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    const res2 = await axios.post('/transactions/archive-alltransaction', {
      payload:{
        isArchive: false,
      },
      accountName: editable._id,
      userid: user._id,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Conta Activa com sucesso!');
    navigate('/Accounts');
    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  const reload=()=>window.location.reload();

  const handleClose = () => {
    setShowModal(false)
    setAccountNameUpdate(null);
    setAccountDescriptionUpdate(null);
    setAccountIBANUpdate(null);
    setAccountTypeUpdate(null);
    setStatusUpdate(null);
    reload();
  };

  // const onFinish = (values) => {
  //   console.log({values
  //   });
  // };

  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Arquivo - Contas</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <hr></hr>
      <div className="content">
        <br></br>
        <Table 
        columns={columns}
        dataSource={allAccount}
        pagination={false} 
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
      </div>
      {/* <div><pre>{JSON.stringify(allAccountStats, null, 2) }</pre></div> */}

      {/* <Modal
        destroyOnClose={true} 
        title="Activar Conta"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleSubmitAccount} style={{padding:"32px 16px"}}>
            <Form.Item label="Nome da Conta ou do seu Banco" name="accountName">
              <Input 
              type="text"
              defaultValue={accountNameUpdate}
              disabled="true"
              />
            </Form.Item>
            <Form.Item label="Descrição" name="accountDescription">
              <TextArea
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                defaultValue={accountDescriptionUpdate}
                disabled="true"
                />
            </Form.Item>
            <Form.Item label="IBAN" name="accountIBAN">
              <Input 
              type="text"
              defaultValue={accountIBANUpdate}
              disabled="true"
              />
            </Form.Item>
            <Form.Item label="Account Type" name="accountType">
              <Select defaultValue={accountTypeUpdate} disabled="true">
                <Select.Option value="Conta-Corrente">Conta-Corrente</Select.Option>
                <Select.Option value="Conta-Despesas">Conta-Despesas</Select.Option>
                <Select.Option value="Cartão Alimentação">Cartão Alimentação</Select.Option>
                <Select.Option value="Cartão de Crédito">Cartão de Crédito</Select.Option>
                <Select.Option value="Conta Pagamentos">Outra</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Activar novamente a conta" name="status" valuePropName="checked">
              <Switch defaultChecked={statusUpdate} checkedChildren="Sim" unCheckedChildren="Não"/>
            </Form.Item>
            <div>
              <button 
              type="submit" 
              className="btn btn-primary" 
              onClick={handleClose}
               style={{
                  width: '100%',
                }}>
                {" "}
                ACTIVAR CONTA NOVAMENTE
              </button>
            </div>
          </Form>
      </Modal> */}

    </Layout>
  );
};

export default AccountsArchive;