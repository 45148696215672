import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Table, Switch, Select, Button} from 'antd';

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {useNavigate } from "react-router-dom";

import {
  SearchOutlined,
  EditOutlined
} from "@ant-design/icons";

const Countries = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [showModalView, setShowModalView] = useState(false);  

  const [loading,setLoading] = useState(false);

  const [allCountry, setAllCountry] = useState([]);

  //DropList Sectors
  const [allSector, setAllSector] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Fields from table to View
  const [countryNameUpdate, setCountryNameUpdate] = useState(null);
  const [countryNamePTUpdate, setCountryNamePTUpdate] = useState(null);
  const [regionUpdate, setRegionUpdate] = useState(null);
  const [statusUpdate, setStatusUpdate] = useState(null);

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "countryName",
      title: "Country Name",
      dataIndex: ["countryName"],
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.countryName.length - b.countryName.length,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.countryName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "countryNamePT",
      title: "Country Name PT",
      dataIndex: "countryNamePT",
      sorter: (a, b) => a.countryNamePT.length - b.countryNamePT.length,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.countryNamePT.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "region",
      title: "Region",
      dataIndex: "region",
      sorter: (a, b) => a.region.length - b.region.length,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.region.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Actions",
      dataIndex: "type",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
            <EditOutlined
              onClick={() => {
                    setEditable(record);
                    setCountryNameUpdate(record.countryName);
                    setCountryNamePTUpdate(record.countryNamePT);
                    setRegionUpdate(record.region);
                    setStatusUpdate(record.status);
                    setShowModal(true);
              }}
            />
            {/* <EyeOutlined className="mx-2"
                onClick={() => {
                  setEditable(record);
                  setCountryNameUpdate(record.countryName);
                  setCountryNamePTUpdate(record.countryNamePT);
                  setRegionUpdate(record.region);
                  setStatusUpdate(record.status);
                  setShowModalView(true);
            }}
            /> */}
          </div>
        );
      },
    }
  ];

  const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllCountry = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/countries/get-country',
        {
          // userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllCountry(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        // message.error('You are not Admin User');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllCountry();
  },[])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitCountry = async (values) => {

  try{
    const promises = [];
  
    const user = JSON.parse(localStorage.getItem('user'))

    if(editable){
      setLoading(true);
      const result = axios.post('/countries/edit-country', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: editable._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Country Updated Successfull');

    }else{
      setLoading(true);
      const result = axios.post('/countries/add-country', {
        ...values, 
        userid:user._id, 
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Country Added Successfull');
    }

    setShowModal(false);
    setEditable(null);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  //Handler Test Console Log Values
  const onFinish = (values) => {
    console.log({values});
  };

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setShowModalView(false);
    setEditable(null);
    setCountryNameUpdate(null);
    setCountryNamePTUpdate(null);
    setRegionUpdate(null);
    setStatusUpdate(null);
  };

  //Handler Close Modal
  const handleClose = () => {
    setShow(false);
  };
  

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Definições - Países</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
          <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
          >+ Novo País
        </Button>
        </div>

      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <hr></hr>
      <div className="content">
        <Table 
        columns={columns}
        dataSource={allCountry}
        pagination={false}
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
        
      </div>
      {/* <div><pre>{JSON.stringify(allCountry, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title={editable ? "Edit Country" : "Add Country" }
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form 
            layout="vertical" 
            onFinish={handleSubmitCountry} 
            style={{padding:"32px 16px"}}
          >

            <Form.Item label="Country Name" name="countryName">
              <Input 
                type="text"
                defaultValue={countryNameUpdate}
                />
            </Form.Item>
            <Form.Item label="Country Name PT" name="countryNamePT">
              <Input 
                type="text"
                defaultValue={countryNamePTUpdate}
                />
            </Form.Item>

            <Form.Item label="Region" name="region">
              <Select defaultValue={regionUpdate}>
                <Select.Option value="Europe">Europe</Select.Option>
                <Select.Option value="Americas">Americas</Select.Option>
                <Select.Option value="Asia & Middle East">Asia & Middle East</Select.Option>
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch 
                defaultChecked={statusUpdate}
              />
            </Form.Item>

            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" onClick={handleClose}>
                {" "}
                SAVE
              </button>
            </div>
            {/* <div><pre>{JSON.stringify(allCountry, null, 2) }</pre></div> */}
            
          </Form>
      </Modal>

    </Layout>
  );
};

export default Countries;