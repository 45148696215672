import React,{useState,useEffect} from "react";
import {Input, Table, Button} from 'antd';

import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { SearchOutlined} from "@ant-design/icons";

import moment from 'moment';

const OrderAllExchanges = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // const { Meta } = Card;

  // const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allSubscription, setAllSubscription] = useState([]);

  // const [allAccountStats, setAllAccountStats] = useState([]);

  // const [show, setShow] = useState(false);

  // const { TextArea } = Input;

    //Edit Transactions
    // const [editable, setEditable] = useState(null);

  //table accounts
  const columns =[
    {
      key: "date_traded",
      title: "Data de Negociação",
      dataIndex: "date_traded",
      defaultSortOrder: 'descend',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.date_traded) - new Date(b.date_traded)
    },
    {
      key: "brokerName",
      title: "Broker",
      dataIndex: "brokerName",
      sorter: (a, b) => a.brokerName.localeCompare(b.brokerName),
    },
    {
      key: "exchangeName",
      title: "Nome",
      dataIndex: ["exchangeName","exchangeName"],
      sorter: (a, b) => a.exchangeName.exchangeName.localeCompare(b.exchangeName.exchangeName),
    },
    {
      key: "orderId",
      title: "Ordem ID",
      dataIndex: "orderId",
      sorter: (a, b) => a.orderId-b.orderId,
    },
    {
      key: "symbol",
      title: "Símbolo",
      dataIndex: ["symbol"],
      sorter: (a, b) => a.symbol.localeCompare(b.symbol),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.symbol.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "",
      dataIndex: ["status"],
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status, record) => {
        if(record.status === "BUY" )
          return (
          <span style={{color: "#39A939"}}>{status}</span>
          );
          return (
          <span style={{color: "#FF3234"}}>{status}</span>
          );
      },
    },
    {
      key: "qty",
      title: "Quantidade",
      dataIndex: "qty",
      sorter: (a, b) => a.qty-b.qty,
    },
    // {
    //   key: "locked",
    //   title: "Quantidade Cativa",
    //   dataIndex: "locked",
    //   width: "2%",
    //   sorter: (a, b) => a.locked-b.locked,
    // },
    {
      key: "today_price",
      title: "Cotação Base",
      dataIndex: "today_price",
      align: "right",
      sorter: (a, b) => a.today_price -b.today_price,
      render: (today_price, record) => {
        if(record.today_price >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(today_price).toFixed(4)}</span>
          );
      },
    },
    {
      key: "currency",
      title: "Moeda Base",
      dataIndex: ["currency"],
    },
    {
      key: "today_value",
      title: "Valor Base",
      dataIndex: "today_value",
      align: "right",
      sorter: (a, b) => a.today_value-b.today_value,
      render: (today_value, record) => {
          if(record.today_value >= 0 )
            return (
            <span style={{color: "#39A939"}}>{parseFloat(today_value.toLocaleString('pt-PT')).toFixed(2)}</span>
            );
            return (
            <span style={{color: "#FF3234"}}>{parseFloat(today_value.toLocaleString('pt-PT')).toFixed(2)}</span>
            );
        },
    },
    {
      key: "currency",
      title: "Moeda Base",
      dataIndex: ["currency"],
    },
    {
      key: "today_price_ctr",
      title: "Cotação",
      dataIndex: "today_price_ctr",
      align: "right",
      sorter: (a, b) => a.today_price_ctr -b.today_price_ctr,
      render: (today_price_ctr, record) => {
        if(record.today_price_ctr >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(today_price_ctr).toFixed(4)}</span>
          );
      },
    },
    {
      key: "currency_ctr",
      title: "Moeda",
      dataIndex: ["currency_ctr"],
    },
    {
      key: "today_value_ctr",
      title: "Valor",
      dataIndex: "today_value_ctr",
      align: "right",
      sorter: (a, b) => a.today_value_ctr-b.today_value_ctr,
      render: (today_value_ctr, record) => {
        if(record.today_value_ctr >= 0 )
          return (
          <span style={{color: "#39A939"}}>{parseFloat(today_value_ctr.toLocaleString('pt-PT')).toFixed(2)}</span>
          );
          return (
          <span style={{color: "#FF3234"}}>{parseFloat(today_value_ctr.toLocaleString('pt-PT')).toFixed(2)}</span>
          );
      },
    },
    {
      key: "currency_ctr",
      title: "Moeda",
      dataIndex: ["currency_ctr"],
    },
  //   {
  //     key:"weights_pct",
  //     title: "Peso%",
  //     dataIndex: "weights_pct",
  //     width: "10%",
  //     defaultSortOrder: 'descend',
  //     align: 'right',
  //     sorter: (a, b) => a.weights_pct - b.weights_pct,
  //     render: (weights_pct, record) => {
  //       if(record.weights_pct >= 0 )
  //         return (
  //         // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
  //         <span> {parseFloat(weights_pct).toFixed(2)} %</span>
  //         );
  //     },
  // },
    // {
    //   title: "Acções",
    //   dataIndex: "type",
    //   width: "5%",
    //   render: (type, record) => {
    //     // if(type === "Income" )
    //     return (
    //       <div className="mx-4">
    //         <Space size="middle">
    //         <EditOutlined
    //           onClick={() => {
    //             setEditable(record);
    //             setExchangeNameUpdate(record.exchangeName.exchangeName);
    //             setBrokerNameUpdate(record.exchangeName.brokerName);
    //             setFrequencyUpdate(record.exchangeName.frequency);
    //             setStatusUpdate(record.status);
    //             setExchangeDescriptionUpdate(record.exchangeName.exchangeDescription);
    //             setExchangeIdUpdate(record.exchangeName._id);
    //             setCashExchangeIdUpdate(record._id);
    //             setShowModal(true);
    //           }}
    //         />
    //         <DeleteOutlined
    //           onClick={() => {
    //             // console.log(record);
    //             // navigate('/Transactions',
    //             // {
    //             //   state:{
    //             //     accountId: record.id,
    //             //     accountDescription: record.accountName           
    //             //   }
    //             // });
    //           }}
    //         />
    //         </Space>
    //       </div>
    //     );
    //   },
    // }
  ];

  //useEffect Hook
  useEffect(() => {
    const getAllSubscription = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/order-exchanges/get-portfolio-exchange', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSubscription(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllSubscription();
  },[])


  // //Handlers User  **************************************************************************************************************************
  // const handleSubmitAccount = async (values) => {

  // try{
  
  //   const user = JSON.parse(localStorage.getItem('user'))

  //   setLoading(true);
  //   const res = await axios.post('/exchanges/edit-exchange', {
  //     payload:{
  //       archive: false,
  //       status: true,
  //       userid: user._id,
  //     },
  //     _id: exchangeIdUpdate,
  //   },
  //   {
  //     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //   }
  //   );
  //   const res2 = await axios.post('/cash-exchanges/edit-cash-exchange', {
  //     payload:{
  //       archive: false,
  //       userid: user._id,
  //     },
  //     _id: cashExchangeIdUpdate,
  //   },
  //   {
  //     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //   }
  //   );
  //   setLoading(false);
  //   message.success('Conta Wallet Activada novamente com sucesso!');
  //   setShowModal(false);
  //   setTimeout(function () {
  //     window.location.reload(true);
  //   }, 1000);
  //   console.log(values)
  //   }catch (error){
  //     setLoading(false);
  //     // message.error('Failed to industry');
  //     //message.error('You are not Admin User');
  //   }
  // }

  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>Todas as Ordens - Portfolios</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            align="right"
            shape="round"
            className="btn btn-primary"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
            >Voltar
          </Button>
        </div>
      <hr></hr>
      <div className="content">
        <br></br>
        <Table
          // size="small"
          columns={columns}
          dataSource={allSubscription}
          pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
          scroll={{ x: 'max-content'}}
          className="custom-table" // Apply custom CSS class for styling
        />
      </div>

      {/* <div><pre>{JSON.stringify(allSubscription, null, 2) }</pre></div> */}

      {/* <Modal
        destroyOnClose={true} 
        title="Activar Conta Exchange"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleSubmitAccount} style={{padding:"32px 16px"}}>
          <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da Conta Exchange</h5>

          <Form.Item label="Nome da Conta Exchange" name="exchangeName">
            <Input defaultValue={exchangeNameUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Broker</h5>

          <Form.Item label="" name="brokerName">
            <Input defaultValue={brokerNameUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <Form.Item style={{ marginTop:30 }} label="Observações" name="exchangeDescription">
            <TextArea defaultValue={exchangeDescriptionUpdate} 
            disabled="true"
              showCount
              maxLength={500}
              style={{
                height: 100,
                marginBottom: 24,
              }}
              type="text"
              />
          </Form.Item>

          <Form.Item label="Frequência" name="frequency">
            <Select defaultValue={frequencyUpdate} 
            disabled="true">
              <Select.Option value="Mensal">Mensal</Select.Option>
              <Select.Option value="Trimestral">Trimestral</Select.Option>
              <Select.Option value="Semestral">Semestral</Select.Option>
              <Select.Option value="Anual">Anual</Select.Option>
            </Select>
          </Form.Item>
          
            <div>
              <button 
              type="submit" 
              className="btn btn-primary" 
              onClick={handleClose}
               style={{
                  width: '100%',
                }}>
                {" "}
                ACTIVAR CONTA EXCHANGE NOVAMENTE
              </button>
            </div>
          </Form>
      </Modal> */}

    </Layout>
  );
};

export default OrderAllExchanges;