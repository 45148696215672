import React,{useState,useEffect} from 'react';
import {Form,Input,Button,Space,Radio,message,Select,Row,Switch,Col} from 'antd';
import {PlusOutlined,MinusCircleOutlined} from '@ant-design/icons';

import Layout from "../components/Layout/Layout";
import {useNavigate,Link} from "react-router-dom";

import axios from "axios";
import Spinner from "../components/Spinner";

import moment from 'moment';

const NewInvestments = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const categoryName= "Savings and Investments";

  let now=(moment().format('YYYY-MM-DD'));

  //loading Apis
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState('')

  //DropList Category
  const [allCategory, setAllCategory] = useState([]);
  //DropList Account
  const [allAccount, setAllAccount] = useState([]);
  //DropList Account
  const [allEtfAsset, setAllEtfAsset] = useState([]);
  //DropList Account
  const [allInvestmentFundAsset, setAllInvestmentFundAsset] = useState([]);
    //DropList Account
    const [allInvestmentPPRAsset, setAllInvestmentPPRAsset] = useState([]);
  //DropList Account  
  const [getUser, setGetUser] = useState([]);

  //DropList Market
  const [allMarkets, setAllMarkets] = useState([]);
  const [allstocks, setAllStocks] = useState([]);
  //Passing Category Name
  const [marketIdAll, setMarketIdAll]= useState([]);

  //Add Tags
  const [tags, setTags] = useState([]);

  //Recursive Dates Array
  const [sdates, setsDates]= useState([]);
  const [sdatesEnd, setsDatesEnd]= useState([]);
  const [dateUpdate, setDateUpdate] = useState();

  // Define state variables for the two numbers and the result
  const [num1, setNum1] = useState();
  const [num2, setNum2] = useState();
  const [result, setResult] = useState(0);

  const [getFrequency, setGetFrequency] = useState();
  const [dateShared, setDateShared] = useState(now);
  const [dateSharedDate, setDateSharedDate] = useState();
  const [sharedDetails, setSharedDetails] = useState(false);
  const [sharedPersonalDetails, setSharedPersonalDetails] = useState(false);
  const [sharedInformationDetails, setSharedInformationDetails] = useState(false);
  

  const { TextArea } = Input;

  const [investmentDetails, setinvestmentDetails] = useState(null);
  const [accountDetails, setAccountDetails] = useState(null);
  const [frequencyDetails, setFrequencyDetails] = useState(null);
  const [goalDetails, setGoalDetails] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user = JSON.parse(localStorage.getItem('user'))

  const toggleActive = name => event => {
    console.log("name: ",name) // prints "name: Active!"
    console.log("event: ",event) // event is some data
    setMarketIdAll(event)
  }
       //***************************************MONTHS RECURSIVES**************************************************************/
    //Recursive Dates Array
    const [sMonth, setMonth]= useState('0');

    const handleMonths=(event)=>{
  
        setMonth([]);
    
        const getMonth= event.target.value;
    
        console.log(getMonth); //22
        console.log(sdates); //2023-01-06
    
        const dates = [];
        const currDate = moment(sdates);
    
        if (getMonth === "1" && frequencyDetails==="Mensal" ) {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else if (getMonth !== "1" && frequencyDetails==="Mensal" ){
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add(getMonth-1, 'month').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          //console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(1, "month").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else if (getMonth !== "1" && frequencyDetails==="Trimestral" ){
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add(getMonth-1, 'quarter').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          //console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(1, "quarter").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else if (getMonth !== "1" && frequencyDetails==="Semestral" ){
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add((getMonth*2)-2, 'quarter').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(2, "quarter").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else{
            //console.log(currDate); //2023-01-06

            let lastDate = moment(currDate).add(getMonth-1, 'year').toDate();
            //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
      
            //console.log(lastDate); //2028-04-06
      
            do {
            dates.push(moment(currDate).format('YYYY-MM-DD'));
            } while (currDate.add(1, "year").diff(lastDate) < 0);
            //dates.push(currDate.clone().toDate());
            dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
    
        message.success(dates);
        console.log(dates);
    
        setMonth(dates);
      }

    const worker = {
    dates: moment(dateUpdate),
    };

    //Handler Fund Family DropList ****************************************************************************************
    // // GOALS
    // useEffect(() => {
    //   const getAllAccounts = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/accounts/get-account', {
    //         userid:user._id,
    //         status: true
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllAccount(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       //message.error('You are not Admin User');
    //     }
    //   };  
    //   getAllAccounts();
    // },[])

  useEffect(() => {
    const getAllETFAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/etfAssets/get-etfAsset',
        {
          userid:user._id,
          fundFamilyName: "all",
          isFund: false,
          isPPR: false
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllEtfAsset(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllETFAsset();

    // Clean up the interval when the component unmounts
    // return () => getAllETFAsset();
  },[])

  useEffect(() => {
    const getAllInvestmentFundAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/etfAssets/get-etfAsset',
        {
          userid:user._id,
          fundFamilyName: "all",
          isFund: true,
          isPPR: false
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllInvestmentFundAsset(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllInvestmentFundAsset();

    // Clean up the interval when the component unmounts
    // return () => getAllInvestmentFundAsset();
  },[])
  
  useEffect(() => {
    const getAllInvestmentPPRAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/etfAssets/get-etfAsset',
        {
          userid:user._id,
          fundFamilyName: "all",
          isFund: true,
          isPPR: true
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllInvestmentPPRAsset(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllInvestmentPPRAsset();

    // Clean up the interval when the component unmounts
    // return () => getAllInvestmentFundAsset();
  },[])

    useEffect(() => {
      const getAllMarkets = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/markets/get-stockmarket', {
            userid:user._id,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllMarkets(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          //message.error('You are not Admin User');
        }
      };  
      
      getAllMarkets();

      // Clean up the interval when the component unmounts
      // return () => getAllMarkets();
    },[])
  
  
      useEffect(() => {
        const getAllStocks = async (values) => {
          try{
            const user = JSON.parse(localStorage.getItem('user'))
            setLoading(true);
            const res = await axios.post('/stockAssets/get-stockAsset',
            {
              marketName:marketIdAll
            },
            {
              headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
            }
            );
            setLoading(false);
            setAllStocks(res.data);
            console.log(res.data);
          }catch (error){
            setLoading(false);
            // message.error('Fetch Issue With Sector');
            //message.error('You are not Admin User');
          }
        };  
        
        getAllStocks();

        // Clean up the interval when the component unmounts
        // return () => getAllStocks();
      },[marketIdAll])
  
    useEffect(() => {
      const getUser = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          // const userIdString = user._id.toString();
          setLoading(true);
          const res = await axios.post('/users/get-openfinanceoneuser', {
            _id:user._id,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setGetUser(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          //message.error('You are not Admin User');
        }
      };  
      
      getUser();

      // Clean up the interval when the component unmounts
      // return () => getUser();
    },[])

    //Handler Sectors DropList ****************************************************************************************
    useEffect(() => {
      const getAllCategories = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/categories/get-findCategoryName', {
            userid:user._id,
            categoryName: categoryName
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllCategory(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          //message.error('You are not Admin User');
        }
      };  
      
      getAllCategories();

      // Clean up the interval when the component unmounts
      // return () => getAllCategories();
    },[categoryName])

var categoryID = allCategory[0];

let range = {min: 1, max: 999999999}
let delta = range.max - range.min

const codeSquare = Math.round(range.min + Math.random() * delta)

// Nome da Instituição Financeira ou Broker
let investmenObjectInformationHTML;
if (investmentDetails==="Certificados de Aforro" || investmentDetails==="Depósitos a Prazo" || investmentDetails==="Fundos de Investimento"
|| investmentDetails==="Acções" || investmentDetails==="ETFs" || investmentDetails==="Plano Poupança Reforma (PPR)"
) {
  investmenObjectInformationHTML =
  <Space direction="vertical">
    <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da Instituição Financeira ou Broker</h5>

      <Form.Item label="" name="brokerName">
        <Input 
        type="text"
        style={{
          width: '220px',
        }} 
        />
      </Form.Item>

      {/* <Form.Item label="Moeda-Portfolio" name="currency">
          <Select
              style={{
                width: '220px',
              }} 
          >
            <Select.Option value="EUR">EUR</Select.Option>
            <Select.Option value="USD">USD</Select.Option>
            <Select.Option value="CHF">CHF</Select.Option>
            <Select.Option value="GBP">GBP</Select.Option>
            <Select.Option value="HKD">HKD</Select.Option>
            <Select.Option value="BRL">BRL</Select.Option>
            <Select.Option value="JPY">JPY</Select.Option>              
            <Select.Option value="Other">Other</Select.Option>
          </Select>
      </Form.Item> */}
    </Space>
      ;
}
else {
  investmenObjectInformationHTML =
  <Space direction="vertical">
    <h5 style={{ marginBottom:30, marginTop:30 }}>Objecto investido</h5>

    <Form.Item label="Nome do Objecto Investido" name="investmentObject">
    <Input 
    type="text"
    defaultValue={""}
    style={{
      width: '220px',
    }} 
    />
  </Form.Item>
  <Form.Item label="Ano do Objecto Investido" name="investmentObjectYear">
    <Input 
    type="number"
    defaultValue={""}
    style={{
      width: '220px',

    }} 
    />
  </Form.Item>
    </Space>
      ;
;
}

// SWITCH Dados do Empréstimo
let investmentInformationHTML;
if (investmentDetails==="Certificados de Aforro") {
  investmentInformationHTML =
  <Space direction="vertical">
    </Space>
      ;
}
else {
  investmentInformationHTML =
    <br></br>;
    <p>Sem dados</p>;
    <Form.Item hidden="true">
    <Input 
        type="text"
    />
</Form.Item>;
}

// SWITCH Dados do Empréstimo
if (investmentDetails==="Depósitos a Prazo" ) {
  investmentInformationHTML =
  <Space direction="vertical">
           <Space direction="vertical">
            <Form.Item style={{ marginTop:10 }} label="Valor investido no depósito a prazo" name="amount">
              <Input
                suffix="€"
                placeholder='0.00'
                type="text" 
                size="large" 
                style={{width: '220px',fontSize:"32px"}}
              />
            </Form.Item>
            </Space>

            <Form.Item label="Taxa Anual Nominal Bruta (TANB)" name="investmentIndexPercentage">
              <Input 
              type="number" 
              defaultValue={"0"}
              suffix="%"
              placeholder='0%'
              size="large" 
              style={{
                width: '220px',
                fontSize:"32px"
              }} 
              />
            </Form.Item>

              <Form.Item label="Investimento com renovação automática?" name="investmentRecursive" valuePropName="checked">
                <Switch checkedChildren="Sim" unCheckedChildren="Não" defaultChecked/>
              </Form.Item>

              <Form.Item label="Investimento autoriza mobilização antecipada?" name="investmentmobilization" valuePropName="checked">
                <Switch checkedChildren="Sim" unCheckedChildren="Não" defaultChecked/>
              </Form.Item>

    </Space>
      ;
} else if (investmentDetails==="Certificados de Aforro") {
  investmentInformationHTML =
  <Space direction="vertical">
      <Form.Item label="Emissão Série" name="investmentEmission">
        <Select
          style={{
            width: '220px',
          }}
        >
          <Select.Option value="EMISSÃO SÉRIE F (2.5%)">EMISSÃO SÉRIE F (2.5%)</Select.Option>
          <Select.Option value="EMISSÃO SÉRIE E (3.5%)">EMISSÃO SÉRIE E (3.5%)</Select.Option>
          <Select.Option value="EMISSÃO SÉRIE D">EMISSÃO SÉRIE D</Select.Option>
          <Select.Option value="EMISSÃO SÉRIE C">EMISSÃO SÉRIE C</Select.Option>
          <Select.Option value="EMISSÃO SÉRIE B">EMISSÃO SÉRIE B</Select.Option>
          <Select.Option value="EMISSÃO SÉRIE A">EMISSÃO SÉRIE A</Select.Option>            
          <Select.Option value="OUTRA">OUTRA</Select.Option>
        </Select>
    </Form.Item>

    <Space direction="vertical">
      <Form.Item style={{ marginTop:10 }} label="Valor investido inicialmente nos certificados de aforro" name="amount">
        <Input
          suffix="€"
          placeholder='0.00'
          type="text" 
          size="large" 
          style={{fontSize:"32px",width:"220px"}}
        />
      </Form.Item>
      </Space>

      <Form.Item label="Taxa Anual Nominal Bruta (TANB)" name="investmentIndexPercentage">
        <Input 
        type="number" 
        defaultValue={"0"}
        suffix="%"
        placeholder='0%'
        size="large" 
        style={{
          width: '220px',
          fontSize:"32px"
        }} 
        />
      </Form.Item>

        <Form.Item label="Investimento com renovação automática?" name="investmentRecursive" valuePropName="checked">
          <Switch checkedChildren="Sim" unCheckedChildren="Não" defaultChecked/>
        </Form.Item>

        <Form.Item label="Investimento autoriza mobilização antecipada?" name="investmentmobilization" valuePropName="checked">
          <Switch checkedChildren="Sim" unCheckedChildren="Não" defaultChecked/>
        </Form.Item>

  </Space>
  ;
}else if (investmentDetails==="Acções") {
  investmentInformationHTML =
      <Form.List name="assets" >
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item
                      style={{ width: '100%', marginTop: '20px' }}
                      name={[field.name, 'Market']}
                      fieldKey={[field.fieldKey, 'Market']}
                      rules={[{ required: true, message: 'Preencha com o Mercado Investido' }]}
                    >
                      <Select
                         onChange={toggleActive("Active!")}
                        placeholder="Mercado Financeiro"
                        style={{ width: '100%' }}
                      >
                        {allMarkets.map((getcon, index) => (
                          <Select.Option key={index} value={getcon.id}>
                            {getcon.marketNamePT}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={[field.name, 'Asset']}
                      fieldKey={[field.fieldKey, 'Asset']}
                      className="formItemKeyDependency"
                      rules={[{ required: true, message: 'Preencha com o Activo Investido' }]}
                    >
                      <Select
                        placeholder="Activo"
                        style={{ width: '100%' }}
                      >
                        {allstocks.map(item => (
                          <Select.Option key={item} value={item.yahooTicker}>
                            {item.stockAssetName} {"("+item.yahooTicker+")"} 
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={[field.name, 'quantity']}
                      fieldKey={[field.fieldKey, 'quantity']}
                      className="formItemKeyDependency"
                      rules={[{ required: true, message: 'Preencha com o Activo Investido' }]}
                    >
                      <Input style={{ width: '220px' }} placeholder="Quantidade" />
                    </Form.Item>
                  </Col>
                </Row>
                <Col xs={24} sm={24} md={1} lg={1} xl={1} key={3}>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </Col>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                style={{ width: "50%" , background: "green", color: "white", marginTop: 30, marginBottom: 30}}
              >
                <PlusOutlined /> Novo Investimento Acção
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
  ;
}else if (investmentDetails==="ETFs") {
  investmentInformationHTML =
  <Form.List name="assets">
  {(fields, { add, remove }) => (
    <div>
      {fields.map((field, index) => (
        <div key={field.key}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                style={{ width: '100%', marginTop: '20px' }}
                name={[field.name, 'Asset']}
                fieldKey={[field.fieldKey, 'Asset']}
                className="formItemKeyDependency"
                rules={[{ required: true, message: 'Preencha com o Activo Investido' }]}
              >
                <Select placeholder="Activo" style={{ width: '100%' }}>
                  {allEtfAsset.map(item => (
                    <Select.Option key={item} value={item.yahooTicker}>
                      {item.etfAssetName} ({item.currency})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={[field.name, 'quantity']}
                fieldKey={[field.fieldKey, 'quantity']}
                className="formItemKeyDependency"
                rules={[{ required: true, message: 'Preencha com o Activo Investido' }]}
              >
                <Input style={{ width: '220px' }} placeholder="Quantidade" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={1} lg={1} xl={1} key={3}>
              <MinusCircleOutlined
                className="dynamic-delete-button"
                onClick={() => {
                  remove(field.name);
                }}
              />
            </Col>
          </Row>
        </div>
      ))}
        <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add();
              }}
              style={{ width: "50%" , background: "green", color: "white", marginTop: 30, marginBottom: 30}}
            >
              <PlusOutlined /> Novo Investimento ETF
            </Button>
        </Form.Item>
    </div>
  )}
</Form.List>
  ;
}
else if (investmentDetails==="Fundos de Investimento") {
  investmentInformationHTML =
  <Form.List name="assets">
  {(fields, { add, remove }) => (
    <div>
      {fields.map((field, index) => (
        <div key={field.key}>
          <Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form.Item
                  name={[field.name, 'Asset']}
                  fieldKey={[field.fieldKey, 'Asset']}
                  className="formItemKeyDependency"
                  rules={[{ required: true, message: 'Preencha com o Activo Investido' }]}
                >
                  <Select placeholder="Activo" style={{ width: '50%' }}>
                    {allInvestmentFundAsset.map(item => (
                      <Select.Option key={item} value={item.ISIN}>
                        {item.etfAssetName} ({item.currency})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={[field.name, 'quantity']}
                  fieldKey={[field.fieldKey, 'quantity']}
                  className="formItemKeyDependency"
                  rules={[{ required: true, message: 'Preencha com o Activo Investido' }]}
                >
                  <Input style={{ width: '220px' }} placeholder="Quantidade" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={1} lg={1} xl={1} key={3}>
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => {
                    remove(field.name);
                  }}
                />
              </Col>
            </Row>
          </Form.Item>
        </div>
      ))}
      <Form.Item>
          <Button
            type="dashed"
            onClick={() => {
              add();
            }}
            style={{ width: "50%" , background: "green", color: "white", marginTop: 30, marginBottom: 30}}
          >
            <PlusOutlined /> Novo Fundo Investimento
          </Button>
      </Form.Item>
    </div>
  )}
</Form.List>
  ;
}
else if (investmentDetails==="Plano Poupança Reforma (PPR)") {
  investmentInformationHTML =
  <Form.List name="assets">
  {(fields, { add, remove }) => (
    <div>
      {fields.map((field, index) => (
        <div key={field.key}>
          <Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form.Item
                  name={[field.name, 'Asset']}
                  fieldKey={[field.fieldKey, 'Asset']}
                  className="formItemKeyDependency"
                  rules={[{ required: true, message: 'Preencha com o Activo Investido' }]}
                  >
                <Select placeholder="Activo" style={{ width: '100%' }}>
                  {allInvestmentPPRAsset.map(item => (
                    <Select.Option key={item} value={item.ISIN}>
                      {item.etfAssetName} ({item.currency})
                    </Select.Option>
                  ))}
                </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={[field.name, 'quantity']}
                  fieldKey={[field.fieldKey, 'quantity']}
                  className="formItemKeyDependency"
                  rules={[{ required: true, message: 'Preencha com o Activo Investido' }]}
                >
                  <Input style={{ width: '220px' }} placeholder="Quantidade" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={2} lg={2} xl={2} key={3}>
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => {
                    remove(field.name);
                  }}
                />
              </Col>
            </Row>
          </Form.Item>
        </div>
      ))}
          <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                style={{ width: "50%" , background: "green", color: "white", marginTop: 30, marginBottom: 30}}
              >
                <PlusOutlined /> Novo Fundo PPR
              </Button>
          </Form.Item>
    </div>
  )}
</Form.List>
  ;
}
else if (investmentDetails==="Arte" || investmentDetails==="Outro") {
  investmentInformationHTML =
  <Space direction="vertical">
      <Form.Item style={{ marginTop:10 }} label="Valor do investimento" name="amount">
        <Input
          suffix="€"
          placeholder='0.00'
          type="text" 
          size="large" 
          style={{fontSize:"32px",width:"220px"}}
        />
      </Form.Item>
  </Space>
  ;
}
else {
  investmentInformationHTML =
    <br></br>;
    <p>Sem dados</p>;
    <Form.Item hidden="true">
    <Input 
        type="text"
    />
</Form.Item>;
}

let investmentMonthsInformationHTML;
if (investmentDetails==="Certificados de Aforro"  || investmentDetails==="Depósitos a Prazo" || investmentDetails==="Arte" || investmentDetails==="Outro" ) {
  investmentMonthsInformationHTML =
  <Space direction="vertical">
            <Form.Item
                style={{
                  width: '100%'
                }}  
              label="Número de frequências até ao término do investimento" 
              defaultValue="0"
            >
              <b>{result}</b>
            </Form.Item>

            <Form.Item 
            label="Confirme e insira abaixo o número acima exposto!" 
            name="months" 
            onChange={(e)=>handleMonths(e)}
                rules={[
                  {
                    required: true, message: "Por favor, insira o número de meses que vai ter até terminar o investimento!",
                    min: 1,
                    max: 999,
                  }
                  
                    ]}
                    >
                <Input min={1} 
                defaultValue={0} 
                style={{
                  width: '100%',
                }}
                type="number" />
            </Form.Item>
    </Space>
      ;
} else {
  investmentMonthsInformationHTML =
    <br></br>;
    <p>Sem dados</p>;
    <Form.Item hidden="true">
    <Input 
        type="text"
    />
</Form.Item>;
}

// SWITCH Shared
let sharedHTML;
if (sharedDetails===true ) {
  sharedHTML =
  <Space direction='vertical'>
    
      <br></br>

      <Form.Item label="Dados Pessoais?" name="isSharedPersonalProfile" valuePropName="checked">
        <Switch checkedChildren="Sim" unCheckedChildren="Não"/>
      </Form.Item>
      <p><b><i>*Partilhe o seu Nome Completo, NIF e Data de Nascimento</i></b></p>

      <br></br>

      <Form.Item label="Dados do Investimento?" name="isSharedInformation" valuePropName="checked">
        <Switch checkedChildren="Sim" unCheckedChildren="Não" />
      </Form.Item>
      <p><b><i>*Partilhe os dados do seu investimento</i></b></p>

      <br></br>

      <Form.Item label="Prazo máximo de Partilha em número de meses?" name="monthsShared" onChange={(e)=>{handleDateShared(e)}}>
        <Input min={1} defaultValue={0} type="number" style={{width:"220px"}}/>
      </Form.Item>
      <br></br>

      <b style={{marginBottom:"50px"}}>Prazo de compartilhamento até: 
      <br/>
      <br/>
      {dateShared}</b>

  </Space>
      ;
} 
else {
  sharedHTML =
    <Form.Item hidden="true">
    <Input 
        type="text"
    />
</Form.Item>;
}

let labelFrequency;
if (investmentDetails==="Certificados de Aforro" || investmentDetails==="Depósitos a Prazo") {
  labelFrequency = "Frequência de pagamento de juros";
}
else if (investmentDetails==="Arte" || investmentDetails==="Outro") {
  labelFrequency = "Frequência que quer reavaliar os seus investimentos";
}
else {
  labelFrequency = "Frequência de que tenciona investir";
}

  const submitHandler = async (values) => {

    let tax=0.00000000
    let isPayedReceivedInicial=true

    console.log("---------")
    if(frequencyDetails==="Mensal"){
      tax=parseFloat((((values.investmentIndexPercentage/100)/12)*(1-0.28)))
      console.log("tax/Mensal:" + tax)
    }else if (frequencyDetails==="Trimestral"){
      tax=parseFloat((((values.investmentIndexPercentage/100)/4)*(1-0.28)))
      console.log("tax/Trimestral:" + tax)
    }else if (frequencyDetails==="Semestral"){
      tax=parseFloat((((values.investmentIndexPercentage/100)/2)*(1-0.28)))
      console.log("tax/Semestral:" + tax)
    }else{
      tax=parseFloat((((values.investmentIndexPercentage/100)/1)*(1-0.28)))
      console.log("tax/Anual:" + tax)
    };

    try{

      if (values.isShared===true){
        if (values.isSharedPersonalProfile===true & values.isSharedInformation=== true)
        {
          const res3 = axios.post('/openFinance/add-openfinanceinvestment', {
            userid:user._id,
            isShared: values.isShared,
            isSharedInformation: values.isSharedInformation,
            isSharedPersonalProfile: values.isSharedPersonalProfile,
            amount: values.amount,
            codeSquare: codeSquare,
            investmentType:investmentDetails,
            brokerName:values.brokerName,
            currency:values.currency,
            investmentRecursive: values.investmentRecursive,
            investmentIndexPercentage: values.investmentIndexPercentage,
            investmentEndDate:values.investmentEndDate,
            investmentmobilization:values.investmentmobilization,
            investmentEmission:values.investmentEmission,
            name: getUser[0].name,
            nif: getUser[0].nif,
            birthdayDate: getUser[0].birthdayDate,
            countryCitizenship: getUser[0].countryCitizenship,
            phoneNumber: getUser[0].phoneNumber,
            cityLiving: getUser[0].cityLiving,
            stateLiving: getUser[0].stateLiving,
            countryLiving: getUser[0].countryLiving,
            employmentStatus: getUser[0].employmentStatus,
            ocuppationWork: getUser[0].ocuppationWork,
            isPEP: getUser[0].isPEP,
            isCorporateControl: getUser[0].isCorporateControl,
            email: getUser[0].email,
            frequency: frequencyDetails,
            months: values.months,
            dateEndShared: dateSharedDate,
            monthsShared: values.monthsShared,
            contador: 1
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
        } 
        if (values.isSharedInformation=== true & (values.isSharedPersonalProfile===false || values.isSharedPersonalProfile===undefined))
        {
          const res4 = axios.post('/openFinance/add-openfinanceinvestment', {
          userid:user._id,
          isShared: values.isShared,
          isSharedInformation: values.isSharedInformation,
          isSharedPersonalProfile: values.isSharedPersonalProfile,
          amount: values.amount,
          codeSquare: codeSquare,
          investmentType:investmentDetails,
          brokerName:values.brokerName,
          currency:values.currency,
          investmentRecursive: values.investmentRecursive,
          investmentIndexPercentage: values.investmentIndexPercentage,
          investmentEndDate:values.investmentEndDate,
          investmentmobilization:values.investmentmobilization,
          investmentEmission:values.investmentEmission,
          frequency: frequencyDetails,
          months: values.months,
          dateEndShared: dateSharedDate,
          monthsShared: values.monthsShared,
          contador: 1
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
      }if (values.isSharedPersonalProfile=== true & (values.isSharedInformation===false || values.isSharedInformation===undefined))
      {
        const res5 = axios.post('/openFinance/add-openfinanceinvestment', {
          userid:user._id,
          isShared: values.isShared,
          isSharedInformation: values.isSharedInformation,
          isSharedPersonalProfile: values.isSharedPersonalProfile,
          amount: values.amount,
          codeSquare: codeSquare,
          investmentType:investmentDetails,
          name: getUser[0].name,
          nif: getUser[0].nif,
          birthdayDate: getUser[0].birthdayDate,
          countryCitizenship: getUser[0].countryCitizenship,
          phoneNumber: getUser[0].phoneNumber,
          cityLiving: getUser[0].cityLiving,
          stateLiving: getUser[0].stateLiving,
          countryLiving: getUser[0].countryLiving,
          employmentStatus: getUser[0].employmentStatus,
          ocuppationWork: getUser[0].ocuppationWork,
          isPEP: getUser[0].isPEP,
          isCorporateControl: getUser[0].isCorporateControl,
          email: getUser[0].email,
          frequency: frequencyDetails,
          months: values.months,
          dateEndShared: dateSharedDate,
          monthsShared: values.monthsShared,
          contador: 1
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
      }
      }else{
        const res6 = axios.post('/openFinance/add-openfinanceinvestment', {
          userid:user._id,
          isShared: values.isShared,
          isSharedInformation: values.isSharedInformation,
          isSharedPersonalProfile: values.isSharedPersonalProfile,
          amount: values.amount,
          codeSquare: codeSquare,
          investmentType:investmentDetails,
          contador: 1
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
      }
      
        if (investmentDetails==="Certificados de Aforro" || investmentDetails==="Depósitos a Prazo") {

          let tagsInicial=["DEPÓSITO INICIAL"]
          let tagsSeguintes=["JUROS VENCIDOS"]
          let amountOriginal=values.amount
          let totalAcumulative=0.00000

          setLoading(true);

          const res2 = axios.post('/investments/add-investment', {
            userid:user._id,
            investmentName:values.investmentName,
            investmentDescription:values.investmentDescription,
            investmentType:investmentDetails,
            brokerName:values.brokerName,
            currency:values.currency,
            currecy_ctr: user.currency,
            investmentRecursive:values.investmentRecursive,
            investmentIndexPercentage:values.investmentIndexPercentage,
            investmentStartDate:values.investmentStartDate,
            investmentEndDate:values.investmentEndDate,
            investmentMobilization:values.investmentmobilization,
            investmentEmission:values.investmentEmission,
            frequency:frequencyDetails,
            months: values.months,
            goalName:goalDetails,
            categoryName:categoryID.id,
            codeSquare:codeSquare,
            initialAssets: null,
            investmentObject:values.investmentObject,
            investmentObjectYear:values.investmentObjectYear,
            isProtectedCapital:true,
            status:true
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          for (const sdate of sMonth) {
            const res = axios.post('/investment-transactions/add-investment-transaction', {
              userid:user._id,
              codeSquare:codeSquare,
              squareName:values.investmentName,
              amount:values.amount,
              quantity:1,
              currency:values.currency,
              currecy_ctr: user.currency,
              date:sdate,
              months: values.months, 
              monthYear: moment(sdate).format("MMM YYYY"),
              monthYearNumber: moment(sdate).format("YYYYMM"),
              dayMonthYearNumber: moment(sdate).format("YYYY-MM-DD"),
              tags: tagsInicial,
              isReforceOrWithdraw: true,
              typeInvestment:investmentDetails,
              investmentIndexPercentage:values.investmentIndexPercentage,
              goalName:goalDetails,
              categoryName:categoryID.id,
              isPayedReceived:isPayedReceivedInicial,
              status:true,
            },
            {
              headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
            }
            )

            totalAcumulative= parseFloat(amountOriginal*tax)
            console.log("totalAcumulative:" + totalAcumulative)

            values.amount=parseFloat(amountOriginal*tax)

            amountOriginal= parseFloat(amountOriginal)+parseFloat(totalAcumulative)
            console.log("amountOriginal:" + amountOriginal)

            values.months=values.months-1
            tagsInicial=tagsSeguintes
            isPayedReceivedInicial=false
            ;
          }
          message.success('Conta Investimento Criado com Sucesso!');
          if(values.months >= 100){
            setTimeout(function () {
              window.location.reload(true);
              message.success('reload 30 segundos');
            }, 30000);
          }else if (values.months >= 23 || values.months < 100){
            setTimeout(function () {
              window.location.reload(true);
              message.success('reload 10 segundos');
            }, 10000);
          }else if (values.months < 23){
            setTimeout(function () {
              window.location.reload(true);
              message.success('reload 3 segundos');
            }, 3000);
          }
          navigate('/Investments');
        }

        else if (investmentDetails==="Arte" || investmentDetails==="Outro") {

          let tagsInicial=["AQUISIÇÃO INVESTIMENTO"]
          let tagsSeguintes=["REAVALIAR"]

          setLoading(true);

          const res2 = axios.post('/investments/add-investment', {
            userid:user._id,
            investmentName:values.investmentName,
            investmentDescription:values.investmentDescription,
            investmentType:investmentDetails,
            brokerName:values.brokerName,
            currency:values.currency,
            currecy_ctr: user.currency,
            investmentRecursive:values.investmentRecursive,
            investmentIndexPercentage:values.investmentIndexPercentage,
            investmentStartDate:values.investmentStartDate,
            investmentEndDate:values.investmentEndDate,
            investmentMobilization:values.investmentmobilization,
            investmentEmission:values.investmentEmission,
            frequency:frequencyDetails,
            months: values.months,
            goalName:goalDetails,
            categoryName:categoryID.id,
            codeSquare:codeSquare,
            initialAssets: null,
            investmentObject:values.investmentObject,
            investmentObjectYear:values.investmentObjectYear,
            isProtectedCapital:false,
            status:true
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          for (const sdate of sMonth) {
            const res = axios.post('/investment-transactions/add-investment-transaction', {
              userid:user._id,
              codeSquare:codeSquare,
              squareName:values.investmentName,
              amount:values.amount,
              quantity:1,
              currency:values.currency,
              currecy_ctr: user.currency,
              date:sdate,
              months: 1, 
              monthYear: moment(sdate).format("MMM YYYY"),
              monthYearNumber: moment(sdate).format("YYYYMM"),
              dayMonthYearNumber: moment(sdate).format("YYYY-MM-DD"),
              tags: tagsInicial,
              isReforceOrWithdraw: true,
              typeInvestment:investmentDetails,
              investmentIndexPercentage:values.investmentIndexPercentage,
              goalName:goalDetails,
              categoryName:categoryID.id,
              isPayedReceived:isPayedReceivedInicial,
              status:true
            },
            {
              headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
            }
            )
            values.amount=0
            tagsInicial=tagsSeguintes
            isPayedReceivedInicial=false
            ;
          }
          message.success('Conta Investimento Criado com Sucesso!');
          if(values.months >= 100){
            setTimeout(function () {
              window.location.reload(true);
              message.success('reload 30 segundos');
            }, 30000);
          }else if (values.months > 23 || values.months < 100){
            setTimeout(function () {
              window.location.reload(true);
              message.success('reload 10 segundos');
            }, 10000);
          }else{
            setTimeout(function () {
              window.location.reload(true);
              message.success('reload 3 segundos');
            }, 3000);
          }
          navigate('/Investments');
        }else if (investmentDetails==="Acções") {

          let tagsInicial=["AQUISIÇÃO INVESTIMENTO"]
          let tagsSeguintes=["REFORÇAR INVESTIMENTO"]

          setLoading(true);

          const res2 = axios.post('/investments/add-investment', {
            userid:user._id,
            investmentName:values.investmentName,
            investmentDescription:values.investmentDescription,
            investmentType:investmentDetails,
            brokerName:values.brokerName,
            currency:values.currency,
            currecy_ctr: user.currency,
            investmentRecursive:values.investmentRecursive,
            investmentIndexPercentage:values.investmentIndexPercentage,
            investmentStartDate:values.investmentStartDate,
            investmentEndDate:values.investmentEndDate,
            investmentMobilization:values.investmentmobilization,
            investmentEmission:values.investmentEmission,
            frequency:frequencyDetails,
            months: values.months,
            goalName:goalDetails,
            categoryName:categoryID.id,
            codeSquare:codeSquare,
            initialAssets: values.assets,
            investmentObject:values.investmentObject,
            investmentObjectYear:values.investmentObjectYear,
            isProtectedCapital:false,
            // status: false,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          for (const sdate of sMonth) {
            const res = axios.post('/investment-transactions/add-investment-transaction', {
              userid:user._id,
              codeSquare:codeSquare,
              squareName:values.investmentName,
              amount:0,
              quantity:1,
              currency:values.currency,
              currecy_ctr: user.currency,
              date:values.investmentStartDate,
              months: 1, 
              monthYear: moment(values.investmentStartDate).format("MMM YYYY"),
              monthYearNumber: moment(values.investmentStartDate).format("YYYYMM"),
              dayMonthYearNumber: moment(values.investmentStartDate).format("YYYY-MM-DD"),
              tags: tagsInicial,
              isReforceOrWithdraw: true,
              typeInvestment:investmentDetails,
              investmentIndexPercentage:values.investmentIndexPercentage,
              goalName:goalDetails,
              categoryName:categoryID.id,
              isPayedReceived:isPayedReceivedInicial,
              status: false,
            },
            {
              headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
            }
            )
            values.amount=0
            tagsInicial=tagsSeguintes
            isPayedReceivedInicial=false
            ;
          }
          const res3 = axios.post('/investments/create-investment-stock/'+ user._id, {
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          message.success('Conta Investimento Criada com Sucesso!');
          navigate('/ProcessingExchanges',
            {
              state:{
                exchangeName:values.investmentName,
                processing:"Portfólio de Acções",
                navigateLink:"/Investments",
              }
            }
          );
        }else if (investmentDetails==="ETFs") {

          let tagsInicial=["AQUISIÇÃO INVESTIMENTO"]
          let tagsSeguintes=["REFORÇAR INVESTIMENTO"]

          setLoading(true);

          const res2 = axios.post('/investments/add-investment', {
            userid:user._id,
            investmentName:values.investmentName,
            investmentDescription:values.investmentDescription,
            investmentType:investmentDetails,
            brokerName:values.brokerName,
            currency:values.currency,
            currecy_ctr: user.currency,
            investmentRecursive:values.investmentRecursive,
            investmentIndexPercentage:values.investmentIndexPercentage,
            investmentStartDate:values.investmentStartDate,
            investmentEndDate:values.investmentEndDate,
            investmentMobilization:values.investmentmobilization,
            investmentEmission:values.investmentEmission,
            frequency:frequencyDetails,
            months: values.months,
            goalName:goalDetails,
            categoryName:categoryID.id,
            codeSquare:codeSquare,
            initialAssets: values.assets,
            investmentObject:values.investmentObject,
            investmentObjectYear:values.investmentObjectYear,
            isProtectedCapital:false,
            // status: false,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          for (const sdate of sMonth) {
            const res = axios.post('/investment-transactions/add-investment-transaction', {
              userid:user._id,
              codeSquare:codeSquare,
              squareName:values.investmentName,
              amount:0,
              quantity:1,
              currency:values.currency,
              currecy_ctr: user.currency,
              date:values.investmentStartDate,
              months: 1, 
              monthYear: moment(values.investmentStartDate).format("MMM YYYY"),
              monthYearNumber: moment(values.investmentStartDate).format("YYYYMM"),
              dayMonthYearNumber: moment(values.investmentStartDate).format("YYYY-MM-DD"),
              tags: tagsInicial,
              isReforceOrWithdraw: true,
              typeInvestment:investmentDetails,
              investmentIndexPercentage:values.investmentIndexPercentage,
              goalName:goalDetails,
              categoryName:categoryID.id,
              isPayedReceived:isPayedReceivedInicial,
              status: false,
            },
            {
              headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
            }
            )
            values.amount=0
            tagsInicial=tagsSeguintes
            isPayedReceivedInicial=false
            ;
          }
          const res3 = axios.post('/investments/create-investment-etf/'+ user._id, {
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          message.success('Conta Investimento Criada com Sucesso!');
          navigate('/ProcessingExchanges',
            {
              state:{
                exchangeName:values.investmentName,
                processing:"Portfólio de ETFs",
                navigateLink:"/Investments",
              }
            }
          );
        }
        else if (investmentDetails==="Fundos de Investimento" || investmentDetails==="Plano Poupança Reforma (PPR)") {

          let tagsInicial=["AQUISIÇÃO INVESTIMENTO"]
          let tagsSeguintes=["REFORÇAR INVESTIMENTO"]

          setLoading(true);

          const res2 = axios.post('/investments/add-investment', {
            userid:user._id,
            investmentName:values.investmentName,
            investmentDescription:values.investmentDescription,
            investmentType:investmentDetails,
            brokerName:values.brokerName,
            currency:values.currency,
            currecy_ctr: user.currency,
            investmentRecursive:values.investmentRecursive,
            investmentIndexPercentage:values.investmentIndexPercentage,
            investmentStartDate:values.investmentStartDate,
            investmentEndDate:values.investmentEndDate,
            investmentMobilization:values.investmentmobilization,
            investmentEmission:values.investmentEmission,
            frequency:frequencyDetails,
            months: values.months,
            goalName:goalDetails,
            categoryName:categoryID.id,
            codeSquare:codeSquare,
            initialAssets: values.assets,
            investmentObject:values.investmentObject,
            investmentObjectYear:values.investmentObjectYear,
            isProtectedCapital:false,
            // status: false,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          for (const sdate of sMonth) {
            const res = axios.post('/investment-transactions/add-investment-transaction', {
              userid:user._id,
              codeSquare:codeSquare,
              squareName:values.investmentName,
              amount:0,
              quantity:1,
              currency:values.currency,
              currecy_ctr: user.currency,
              date:values.investmentStartDate,
              months: 1, 
              monthYear: moment(values.investmentStartDate).format("MMM YYYY"),
              monthYearNumber: moment(values.investmentStartDate).format("YYYYMM"),
              dayMonthYearNumber: moment(values.investmentStartDate).format("YYYY-MM-DD"),
              tags: tagsInicial,
              isReforceOrWithdraw: true,
              typeInvestment:investmentDetails,
              investmentIndexPercentage:values.investmentIndexPercentage,
              goalName:goalDetails,
              categoryName:categoryID.id,
              isPayedReceived:isPayedReceivedInicial,
              status: false,
            },
            {
              headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
            }
            )
            values.amount=0
            tagsInicial=tagsSeguintes
            isPayedReceivedInicial=false
            ;
          }
          const res3 = axios.post('/investments/create-investment-fundinvestment/'+ user._id, {
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          message.success('Conta Investimento Criada com Sucesso!');
          if (investmentDetails==="Fundos de Investimento")
          {
            navigate('/ProcessingExchanges',
            {
              state:{
                exchangeName:values.investmentName,
                processing:"Portfólio de Fundos de Investimento",
                navigateLink:"/Investments",
              }
            }
          );
          }else{
            navigate('/ProcessingExchanges',
            {
              state:{
                exchangeName:values.investmentName,
                processing:"Portfólio Plano Poupança Reforma (PPR)",
                navigateLink:"/Investments",
              }
            }
          );
          }
        }
      ;

      setLoading(false);
    }catch (error){
      setLoading(false);
      message.error('Algo deu errado!');
    }
  }

  const onChangeShared = state => {
    console.log('Shared choose: ',state);
    setSharedDetails(state);
  };

  // Function to handle the subtraction
  const handleDateShared = (event) => {

    const getMonthShared= event.target.value;
    const todayShared = (moment().add(getMonthShared, 'month').format('YYYY-MM-DD'));
    setDateShared(todayShared);
    const todaySharedDate = (moment().add(getMonthShared, 'month').toDate());
    setDateSharedDate(todaySharedDate);
    console.log(dateShared)
  };

  // const onFinish2 = (values) => {
  //   if (values.isShared===true){
  //     if (values.isSharedPersonalProfile===true & values.isSharedInformation=== true){
  //     console.log({
  //       userid:user._id,
  //       isShared: values.isShared,
  //       isSharedInformation: values.isSharedInformation,
  //       isSharedPersonalProfile: values.isSharedPersonalProfile,
  //       amount: values.amount,
  //       codeSquare: codeSquare,
  //       investmentType:investmentDetails,
  //       brokerName:values.brokerName,
  //       currency:values.currency,
  //       currecy_ctr: user.currency,
  //       investmentRecursive: values.investmentRecursive,
  //       investmentIndexPercentage: values.investmentIndexPercentage,
  //       investmentEndDate:values.investmentEndDate,
  //       investmentmobilization:values.investmentmobilization,
  //       investmentEmission:values.investmentEmission,
  //       name: getUser[0].name,
  //       nif: getUser[0].nif,
  //       birthdayDate: getUser[0].birthdayDate,
  //       countryCitizenship: getUser[0].countryCitizenship,
  //       phoneNumber: getUser[0].phoneNumber,
  //       cityLiving: getUser[0].cityLiving,
  //       stateLiving: getUser[0].stateLiving,
  //       countryLiving: getUser[0].countryLiving,
  //       employmentStatus: getUser[0].employmentStatus,
  //       ocuppationWork: getUser[0].ocuppationWork,
  //       isPEP: getUser[0].isPEP,
  //       isCorporateControl: getUser[0].isCorporateControl,
  //       email: getUser[0].email,
  //       frequency: frequencyDetails,
  //       months: values.months,
  //       dateEndShared: dateShared,
  //       monthsShared: values.monthsShared,
  //     });
  //   }
  //   if (values.isSharedInformation=== true & (values.isSharedPersonalProfile===false || values.isSharedPersonalProfile===undefined)){
  //     console.log({
  //       userid:user._id,
  //       isShared: values.isShared,
  //       isSharedInformation: values.isSharedInformation,
  //       isSharedPersonalProfile: values.isSharedPersonalProfile,
  //       amount: values.amount,
  //       codeSquare: codeSquare,
  //       investmentType:investmentDetails,
  //       brokerName:values.brokerName,
  //       currency:values.currency,
  //       currecy_ctr: user.currency,
  //       investmentRecursive: values.investmentRecursive,
  //       investmentIndexPercentage: values.investmentIndexPercentage,
  //       investmentEndDate:values.investmentEndDate,
  //       investmentmobilization:values.investmentmobilization,
  //       investmentEmission:values.investmentEmission,
  //       frequency: frequencyDetails,
  //       months: values.months,
  //       dateEndShared: dateShared,
  //       monthsShared: values.monthsShared,
  //     });
  //   }
  //   if (values.isSharedPersonalProfile=== true & (values.isSharedInformation===false || values.isSharedInformation===undefined)){
  //     console.log({
  //       userid:user._id,
  //       isShared: values.isShared,
  //       isSharedInformation: values.isSharedInformation,
  //       isSharedPersonalProfile: values.isSharedPersonalProfile,
  //       amount: values.amount,
  //       codeSquare: codeSquare,
  //       investmentType: investmentDetails,
  //       name: getUser[0].name,
  //       nif: getUser[0].nif,
  //       birthdayDate: getUser[0].birthdayDate,
  //       countryCitizenship: getUser[0].countryCitizenship,
  //       phoneNumber: getUser[0].phoneNumber,
  //       cityLiving: getUser[0].cityLiving,
  //       stateLiving: getUser[0].stateLiving,
  //       countryLiving: getUser[0].countryLiving,
  //       employmentStatus: getUser[0].employmentStatus,
  //       ocuppationWork: getUser[0].ocuppationWork,
  //       isPEP: getUser[0].isPEP,
  //       isCorporateControl: getUser[0].isCorporateControl,
  //       email: getUser[0].email,
  //       frequency: frequencyDetails,
  //       months: values.months,
  //       dateEndShared: dateShared,
  //       monthsShared: values.monthsShared,
  //     });
  //   }
  //   }else{
  //     console.log({
  //       userid:user._id,
  //       isShared: values.isShared,
  //       isSharedInformation: values.isSharedInformation,
  //       isSharedPersonalProfile: values.isSharedPersonalProfile,
  //       amount: values.amount,
  //       codeSquare: codeSquare,
  //       loanType: investmentDetails,
  //     });
  //   }
  // };

  // //Handler Test Console Log Values
  // const onFinish = (values) => {
  //   console.log({
  //     ...values,
  //     assets: values.assets
  //   });
  // };

  const handleDate=(event)=>{
    setsDates([]);
    const getDates= moment(event.target.value);
    setsDates(getDates);
    console.log(getDates);
  }

  const handleDateEnd=(event)=>{
    setsDatesEnd([]);
    const getDates= moment(event.target.value);
    setsDatesEnd(getDates);
    console.log(getDates);
  }

  // Function to handle the subtraction
  const handleSubtraction = () => {
    if ( frequencyDetails === "Trimestral") {
      const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'quarter', true)));
      setResult(subtractionResult);
      console.log(result)
    } else if (frequencyDetails === "Anual"){
        const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'year', true)));
        setResult(subtractionResult);
        console.log(result)
      } else if (frequencyDetails === "Semestral"){
        const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'quarter', true))/2);
        setResult(subtractionResult);
        console.log(result)
      }else {
        const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'month', true)));
        setResult(subtractionResult);
        console.log(result)
      }
    ;
  };

  // const onChangeAccount = ({ target: { value } }) => {
  //   console.log('Account choose: ', value);
  //   setAccountDetails(value);
  // };

  const onChangeInvestment = ({ target: { value } }) => {
    console.log('Investment choose: ', value);
    setinvestmentDetails(value);
  };

  const onChangeGoal = ({ target: { value } }) => {
    console.log('Goal choose: ', value);
    setGoalDetails(value);
  };


  const onChangeFrequency = state => {
    console.log('Frequency choose: ',state);
    setFrequencyDetails(state);
  };

  //DEFAULT DATA FIM DO INVESTIMENTO (FUTURO TRAZER DATA FIM DA META/OBJECTIVO)
  let defaultDate = new Date()
  defaultDate.setDate(defaultDate.getDate() + 365)
  console.log(defaultDate)

  const [date, setDate] = useState(defaultDate)
  console.log(date)

  const onSetDate = (event) => {
      setDate(new Date(event.target.value))
  }

  // START RETURN
  return (
    <Layout>
      {loading && <Spinner/>}
        <div className="accountpage">
        <div></div>
        <div><h1>Nova Conta Investimento</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={submitHandler} 
          >

          <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da conta investimento</h5>

          <Form.Item label="" name="investmentName">
            <Input 
            type="text"
            />
          </Form.Item>

          <Form.Item style={{ marginTop:30 }} label="Observações" name="investmentDescription">
              <TextArea
                showCount
                maxLength={500}
                style={{
                  height: 220,
                  marginBottom: 24,
                }}
                type="text"
                />
            </Form.Item>

            <h5 style={{ marginBottom:30, marginTop:30 }}>Tipo de Investimento</h5>

            <Radio.Group onChange={onChangeInvestment} style={{ display: "flex", justifyContent: "left"}} >
            <Space direction="vertical" align='center' size="middle">
              <Row gutter={[8,24]}>
              <Radio.Button value="Acções" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>Acções</Radio.Button>
                  <Radio.Button value="ETFs" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>ETFs</Radio.Button>
                  <Radio.Button value="Fundos de Investimento" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Fundos de Investimento</Radio.Button>
                  <Radio.Button value="Plano Poupança Reforma (PPR)" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Plano Poupança Reforma (PPR)</Radio.Button>
                  <Radio.Button value="Certificados de Aforro" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>Certificados de Aforro</Radio.Button>
                  <Radio.Button value="Depósitos a Prazo" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Depósitos a Prazo</Radio.Button>
                  <Radio.Button disabled="true" value="P2P" style={{display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>P2P</Radio.Button>
                  <Radio.Button value="Arte" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Arte</Radio.Button>
                  <Radio.Button value="Outro" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Outro</Radio.Button>
              </Row>
            </Space>
          </Radio.Group>

            <Space direction="horizontal">
              {investmenObjectInformationHTML}
            </Space>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Objectivo para que Investe</h5>

          <Radio.Group onChange={onChangeGoal} style={{ display: "flex", justifyContent: "left"}} >
            <Space direction="vertical" align='center' size="middle">
              <Row gutter={[8,24]}>
              <Radio.Button value="Fundo de Emergência" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Fundo de Emergência</Radio.Button>
                  <Radio.Button value="Reforma" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Reforma</Radio.Button>
                  <Radio.Button value="Habitação" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Habitação</Radio.Button>
                  <Radio.Button value="Educação" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Educação</Radio.Button>
                  <Radio.Button value="Saúde" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Saúde</Radio.Button>
                  <Radio.Button value="Viagem" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Viagem</Radio.Button>
                  <Radio.Button value="Automóvel" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Automóvel</Radio.Button>
                  <Radio.Button value="Mota" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Mota</Radio.Button>
                  <Radio.Button value="Filantropia" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Filantropia</Radio.Button>
                  <Radio.Button value="Outro" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Outro</Radio.Button>
              </Row>
            </Space>
          </Radio.Group>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Dados do Investimento</h5>

            {investmentInformationHTML}

          <Form.Item label={labelFrequency} name="frequency" >
              <Select 
                onChange={onChangeFrequency}
                style={{
                  width: '220px'
                }} >
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item>

          <Form.Item 
            label="Data começo do investimento" 
            name="investmentStartDate"
            style={{
              width: '220px',
            }}  
            onChange={(e)=> {handleDate(e); handleSubtraction(e)}}
                rules={[
                  { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                    ]}>
                <Input style={{fontSize:"18px"}} type="date"/>
            </Form.Item>

            <Form.Item 
            label="Data que pensa mobilizar totalmente o investimento *opcional" 
            name="investmentEndDate" 
            style={{
              width: '220px',
            }}  
            onChange={(e)=> {handleDateEnd(e); handleSubtraction(e)}}
                // rules={[
                //   { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                //     ]}
                >
                <Input style={{fontSize:"18px"}} 
                type="date" 
                // defaultValue={date.toLocaleDateString('en-CA')} 
                // onChange={onSetDate}
                />
            </Form.Item>

            {/* <Form.Item label={labelFrequency} name="frequency" >
              <Select 
              onChange={onChangeFrequency}
                            style={{
                              width: '410px'
                            }} >
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item> */}

            <Space direction="horizontal">
              {investmentMonthsInformationHTML}
            </Space>

            <br></br>
            <hr/>
            <br></br>
            <h5 style={{ marginBottom:30, marginTop:30 }}>Partilhe os dados do seu Investimento e receba ofertas mais atractivas que neste momento tem, será contactado pelos nossos Financial Advisors ou Avaliadores de Arte!</h5>

            <b style={{marginBottom:"100px"}}>A avaliação das suas obras de arte, serão anuais, e terá um custo de 10,00€ por peça! </b>

            <Form.Item style={{marginTop:"50px"}} label="Quer Partilhar os dados do investimento?" name="isShared" valuePropName="checked" >
            <Switch 
            checkedChildren="Sim" 
            unCheckedChildren="Não" 
            //onChange={onChangeShared("Active!")}
            onChange={onChangeShared}
            />
            </Form.Item>

            <Space direction="vertical">
            {sharedHTML}
            </Space>


            <Button style={{ width: "100%" , background: "green", color: "white", marginTop: 30, marginBottom: 30}}  htmlType='submit'>
              CRIAR
              </Button>
              <Link to="/Investments">
                <Button style={{ width: "100%" }} type="primary">
                  CANCELAR
                </Button>
                </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(allExpenses, null, 2) }</pre></div> */}
    </Layout>
  )
  };

export default NewInvestments;