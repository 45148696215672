import React,{useState,useEffect} from "react";
import {Form, Input, message, Select, Button, Card} from 'antd';
import {useNavigate,useLocation,Link} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';

const EditExchanges = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const exchangeDataUpdate = data;
  const exchangeIdUpdate = data.exchangeID;
  const exchangeNameUpdate = data.exchangeName;
  const exchangeDescriptionUpdate = data.exchangeDescription;
  const brokerNameUpdate = data.brokerName;
  const apiKeyUpdate = data.apiKey;
  const keySecretUpdate = data.keySecret;
  const currencyUpdate = data.currency;
  const frequencyUpdate = data.frequency;
  const goalNameUpdate = data.goalName;
  // const statusUpdate = data.status;
  const exchangeUpdate = data.codeSquare;

  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Handler Fund Family DropList ****************************************************************************************
    // useEffect(() => {
    //   const getAllAccounts = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/accounts/get-oneaccount', {
    //         userid:user._id,
    //         _id: accountNameUpdate
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllAccount(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       message.error('You are not Admin User');
    //     }
    //   };  
    //   getAllAccounts();
    // },[accountNameUpdate])

  
  //Handlers User  **************************************************************************************************************************
  let now = new Date();
  let today = new Date(now.getTime());

  const handleSubmitSubscription = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);

    const res = await axios.post('/exchanges/edit-exchange', {
      payload:{
        ...values,
        userid: user._id,
      },
      _id: data.exchangeID,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );

    setLoading(false);
    message.success('Conta Exchange Actualizada com sucesso!');
    navigate('/Exchanges');
    console.log(values)

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  let brokerInformationHTML;
  if (brokerNameUpdate==="Binance") {
    brokerInformationHTML =
    <div className="vertical-select">
            <Form.Item label="Api Key" name="apiKey">
              <Input
              defaultValue={apiKeyUpdate} 
                  style={{
                    width: '100%'
                  }}  
              type="text"
              />
            </Form.Item>
            <Form.Item label="Key Secret" name="keySecret">
              <Input
              defaultValue={keySecretUpdate}
              style={{
                width: '100%'
              }}  
              type="text"
              />
            </Form.Item>
    </div>
        ;
  } else {
    brokerInformationHTML =
      <br></br>;
      // <p>Sem dados</p>;
      <Form.Item hidden="true">
      <Input 
          type="text"
      />
  </Form.Item>;
  }

  return (
    <Layout>
       <div className="accountpage">
        <div></div>
        <div><h1>Editar Exchange - {exchangeNameUpdate}</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={handleSubmitSubscription}
          >

          <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da conta Exchange</h5>

          <Form.Item label="" name="exchangeName">
            <Input
            defaultValue={exchangeNameUpdate} 
            type="text"
            />
          </Form.Item>

          <Form.Item style={{ marginBottom:30, marginTop:30}} label="Observações" name="exchangeDescription">
              <TextArea
              defaultValue={exchangeDescriptionUpdate}
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                />
            </Form.Item>

              <h5 style={{ marginBottom:30, marginTop:10 }}>Nome do Broker</h5>

                <Form.Item label="" name="brokerName">
                  <Input
                  defaultValue={brokerNameUpdate} 
                  type="text"
                  style={{
                    width: '100%',
                  }} 
                  />
                </Form.Item>

                {brokerInformationHTML}

                <Form.Item label="Currency" name="currency">
                    <Select
                    defaultValue={currencyUpdate}
                        style={{
                          width: '220px',
                        }} 
                    >
                      <Select.Option value="EUR">EUR</Select.Option>
                      <Select.Option value="USD">USD</Select.Option>
                      <Select.Option value="CHF">CHF</Select.Option>
                      <Select.Option value="GBP">GBP</Select.Option>
                      <Select.Option value="HKD">HKD</Select.Option>
                      <Select.Option value="BRL">BRL</Select.Option>
                      <Select.Option value="JPY">JPY</Select.Option>              
                      <Select.Option value="Other">Other</Select.Option>
                    </Select>
                </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Objectivo para que Investe</h5>


          <Form.Item label="" name="goalName">
             <Select 
              defaultValue={goalNameUpdate}
                style={{
                  width: '220px',
                }} >
                <Select.Option value="Fundo de Emergência">Fundo de Emergência</Select.Option>
                <Select.Option value="Reforma">Reforma</Select.Option>
                <Select.Option value="Habitação">Habitação</Select.Option>
                <Select.Option value="Educação">Educação</Select.Option>
                <Select.Option value="Saúde">Saúde</Select.Option>
                <Select.Option value="Viagem">Viagem</Select.Option>
                <Select.Option value="Automóvel">Automóvel</Select.Option>
                <Select.Option value="Mota">Mota</Select.Option>
                <Select.Option value="Filantropia">Filantropia</Select.Option>
                <Select.Option value="Outro">Outro</Select.Option>
              </Select>
          </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Dados da Exchange</h5>

            <Form.Item label="Frequência de que tenciona investir" name="frequency" >
              <Select 
              defaultValue={frequencyUpdate}
                style={{
                  width: '220px',
                }} >
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item>

            <Button style={{ width: "100%", background:"green", color:"white" }} type="primary" htmlType='submit'>
                    EDITAR
                    </Button>
              <Link to="/Exchanges">
              <Button style={{ width: "100%", marginTop: 30 }} type="primary">
                CANCELAR
              </Button>
              </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(allExpenses, null, 2) }</pre></div> */}
    </Layout>
  );
};

export default EditExchanges;