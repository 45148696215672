import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Select, Table, Switch, Button, Space} from 'antd';
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { EditOutlined, SearchOutlined, UnorderedListOutlined } from "@ant-design/icons";

import moment from 'moment';

const InsurancesArchive = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allInsurance, setAllInsurance] = useState([]);

  const [allAccountStats, setAllAccountStats] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Edit Transactions
    const [editable, setEditable] = useState(null);

    //Fields from table to View
    const [insuranceNameUpdate, setInsuranceNameUpdate] = useState(null);
    const [insuranceTypeUpdate, setInsuranceTypeUpdate] = useState(null);
    const [insuranceDescriptionUpdate, setInsuranceDescriptionUpdate] = useState(null);
    const [insuranceCompanyUpdate, setInsuranceCompanyUpdate] = useState(null);
    const [insuranceObjectUpdate, setInsuranceObjectUpdate] = useState(null);
    const [frequencyUpdate, setFrequencyUpdate] = useState(null);
    const [monthsUpdate, setMonthsUpdate] = useState(null);
    const [statusUpdate, setStatusUpdate] = useState(null);
  

  //table accounts
  const columns =[
    {
      key: "createdAt",
      title: "Data de criação",
      dataIndex: "createdAt",
      width: "5%",
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      key: "insuranceName",
      title: "Serviço",
      dataIndex: "insuranceName",
      width: "5%",
      sorter: (a, b) => a.insuranceName.localeCompare(b.insuranceName),
    },
    {
      key: "insuranceCompany",
      title: "Seguradora",
      dataIndex: "insuranceCompany",
      width: "5%",
      sorter: (a, b) => a.insuranceCompany.localeCompare(b.insuranceCompany),
    },
    {
      key: "insuranceType",
      title: "Tipo",
      dataIndex: "insuranceType",
      width: "5%",
      sorter: (a, b) => a.insuranceType.localeCompare(b.insuranceType),
    },
    {
      key: "insuranceObject",
      title: "Objecto Segurado",
      dataIndex: "insuranceObject",
      width: "5%",
      sorter: (a, b) => a.insuranceObject.localeCompare(b.insuranceObject),
    },
    {
      key: "frequency",
      title: "Frequência",
      dataIndex: "frequency",
      width: "5%",
      sorter: (a, b) => a.frequency.localeCompare(b.frequency),
    },
    {
      key: "months",
      title: "Número de Frequências",
      dataIndex: "months",
      width: "5%"
    },
    {
      key: "status",
      title: "Estado",
      dataIndex: "status",
      width: "5%",
      sorter: (a, b) => a.status - b.status,
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Acções",
      dataIndex: "type",
      width: "5%",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-4">
            <Space size="middle">
            <EditOutlined
              onClick={() => {
                setEditable(record);
                setInsuranceNameUpdate(record.insuranceName);
                setInsuranceTypeUpdate(record.insuranceType);
                setInsuranceDescriptionUpdate(record.insuranceDescription);
                setInsuranceCompanyUpdate(record.insuranceCompany);
                setInsuranceObjectUpdate(record.insuranceObject);
                setFrequencyUpdate(record.frequency);
                setMonthsUpdate(record.months);
                setStatusUpdate(record.status);
                setShowModal(true);
              }}
            />
            <UnorderedListOutlined
              onClick={() => {
                // console.log(record);
                // navigate('/Transactions',
                // {
                //   state:{
                //     accountId: record.id,
                //     accountDescription: record.accountName           
                //   }
                // });
              }}
            />
            </Space>
          </div>
        );
      },
    }
  ];

  //useEffect Hook
  useEffect(() => {
    const getAllUtility = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/insurances/get-insurance', {
          userid:user._id,
          status: false
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllInsurance(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllUtility();
  },[])


  //Handlers User  **************************************************************************************************************************
  const handleSubmitAccount = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/insurances/edit-insurance', {
      payload:{
        ...values,
        userid: user._id,
      },
      _id: editable._id,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Seguro Activado novamente com sucesso!');
    setShowModal(false);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);
    console.log(values)

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      ////message.error('You are not Admin User');
    }
  }

  const reload=()=>window.location.reload();

  const handleClose = () => {
    setShowModal(false)
    setInsuranceNameUpdate(null);
    setInsuranceTypeUpdate(null);
    setFrequencyUpdate(null);
    setMonthsUpdate(null);
    setStatusUpdate(null);
    setInsuranceDescriptionUpdate(null);
    reload();
  };

  // const onFinish = (values) => {
  //   console.log({values
  //   });
  // };

  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Arquivo - Seguros</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      {/* <hr></hr> */}
      <div className="content">
        <br></br>
        <Table 
        columns={columns}
        dataSource={allInsurance}
        pagination={false}
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
      </div>
      {/* <div><pre>{JSON.stringify(allInsurance, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title="Activar Seguro"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleSubmitAccount} style={{padding:"32px 16px"}}>
          <h5 style={{ marginBottom:30, marginTop:30 }}>Nome do Seguro</h5>

          <Form.Item label="" name="insuranceName">
            <Input defaultValue={insuranceNameUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Seguradora</h5>

          <Form.Item label="" name="insuranceCompany">
            <Input defaultValue={insuranceCompanyUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Tipo de Seguro</h5>

          <Form.Item label="" name="insuranceType">
            <Input defaultValue={insuranceTypeUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Objecto Seguro</h5>

          <Form.Item label="" name="insuranceObject">
            <Input defaultValue={insuranceObjectUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <Form.Item style={{ marginTop:30 }} label="Observações" name="insuranceDescription">
            <TextArea defaultValue={insuranceDescriptionUpdate} 
            disabled="true"
              showCount
              maxLength={500}
              style={{
                height: 100,
                marginBottom: 24,
              }}
              type="text"
              />
          </Form.Item>

          <Form.Item label="Frequência" name="frequency">
            <Select defaultValue={frequencyUpdate} 
            disabled="true">
              <Select.Option value="Mensal">Mensal</Select.Option>
              <Select.Option value="Trimestral">Trimestral</Select.Option>
              <Select.Option value="Semestral">Semestral</Select.Option>
              <Select.Option value="Anual">Anual</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Número de frequências" name="months">
                <Input defaultValue={monthsUpdate} 
                disabled="true"/>
            </Form.Item>

            <Form.Item label="Activar novamente a conta" name="status" valuePropName="checked">
              <Switch defaultChecked={statusUpdate} checkedChildren="Sim" unCheckedChildren="Não"/>
            </Form.Item>
            <div>
              <button 
              type="submit" 
              className="btn btn-primary" 
              onClick={handleClose}
               style={{
                  width: '100%',
                }}>
                {" "}
                ACTIVAR SEGURO NOVAMENTE
              </button>
            </div>
          </Form>
      </Modal>

    </Layout>
  );
};

export default InsurancesArchive;