import React,{useState,useEffect} from "react";
import { message,Button, Space,Tooltip,Row,Card} from 'antd';
import MyWallet from "./../components/Assets/wallet.png";
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import {HddOutlined, MinusOutlined, PlusOutlined, ReloadOutlined, SettingOutlined, TableOutlined, UnorderedListOutlined } from "@ant-design/icons";

import  Chart  from "react-apexcharts";

const Investments = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = JSON.parse(localStorage.getItem('user'))
  const investor = JSON.parse(localStorage.getItem('investorProfile'))
  console.log(investor)

  const { Meta } = Card;

  // const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  // const [allAccount, setAllAccount] = useState([]);

  const [allInvestmentStats, setAllInvestmentStats] = useState([]);
  // const [allNoInvestmentStats, setAllNoInvestmentStats] = useState([]);

  //Fields from Graphs to View
  // const [allWalletsWeights, setAllWalletsWeights] = useState(null);
  // const [allWalletsAssetsWeights, setAllWalletsAssetsWeights] = useState(null);

  // const [show, setShow] = useState(false);

  // const { TextArea } = Input;

    //Edit Transactions
    // const [editable, setEditable] = useState(null);

    //Fields from table to View
    // const [accountNameUpdate, setAccountNameUpdate] = useState(null);
    // const [accountDescriptionUpdate, setAccountDescriptionUpdate] = useState(null);
    // const [accountIBANUpdate, setAccountIBANUpdate] = useState(null);
    // const [accountTypeUpdate, setAccountTypeUpdate] = useState(null);
    // const [statusUpdate, setStatusUpdate] = useState(null);

  // //table accounts
  // const columns =[
  //   {
  //     key: "createdAt",
  //     title: "Data de criação",
  //     dataIndex: "createdAt",
  //     width: "5%",
  //     render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
  //     sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  //   },
  //   {
  //     key: "accountName",
  //     title: "Conta",
  //     dataIndex: "accountName",
  //     width: "5%",
  //     sorter: (a, b) => a.accountName.localeCompare(b.accountName),
  //   },
  //   {
  //     key: "accountDescription",
  //     title: "Descrição",
  //     dataIndex: "accountDescription",
  //     width: "5%",
  //     sorter: (a, b) => a.accountDescription.localeCompare(b.accountDescription),
  //   },
  //   {
  //     key: "accountType",
  //     title: "Tipo de Conta",
  //     dataIndex: "accountType",
  //     width: "5%",
  //     sorter: (a, b) => a.accountType.localeCompare(b.accountType),
  //   },
  //   {
  //     key: "accountIBAN",
  //     title: "IBAN",
  //     dataIndex: "accountIBAN",
  //     width: "5%"
  //   },
  //   {
  //     key: "status",
  //     title: "Estado",
  //     dataIndex: "status",
  //     width: "5%",
  //     sorter: (a, b) => a.status - b.status,
  //     render: (value, record) => {
  //       if(record.status === true )
  //         return (
  //           <span style={{color: "#39A939"}}>Active</span>
  //         );

  //         return (
  //           <span style={{color: "#FF3234"}}>Inactive</span>
  //         );
  //     },
  //     filters: [
  //       { text: "Active", value: true },
  //       { text: "Inactive", value: false },
  //     ],
  //     filterIcon: () => {
  //       return <SearchOutlined />;
  //     },
  //     onFilter: (value, record) => {
  //       console.log(typeof value)
  //       return record.status === value;
  //     },
  //   },
  //   {
  //     title: "Acções",
  //     dataIndex: "type",
  //     width: "5%",
  //     render: (type, record) => {
  //       // if(type === "Income" )
  //       return (
  //         <div className="mx-4">
  //           <Space size="middle">
  //           <EditOutlined
  //             onClick={() => {
  //               setEditable(record);
  //               setAccountNameUpdate(record.accountName);
  //               setAccountDescriptionUpdate(record.accountDescription);
  //               setAccountIBANUpdate(record.accountIBAN);
  //               setAccountTypeUpdate(record.accountType);
  //               setStatusUpdate(record.status);
  //               setShowModal(true);
  //             }}
  //           />
  //           <UnorderedListOutlined
  //             onClick={() => {
  //               console.log(record);
  //               navigate('/Transactions',
  //               {
  //                 state:{
  //                   accountId: record.id,
  //                   accountDescription: record.accountName           
  //                 }
  //               });
  //             }}
  //           />
  //           </Space>
  //         </div>
  //       );
  //     },
  //   }
  // ];

  // useEffect(() => {
  //   const getInvestorProfileStats = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/investorProfiles/get-youinvestorprofile', {
  //         userid:user._id,
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }       
  //       );
  //       setLoading(false);
  //       setAllInvestorProfileStats(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With account');
  //     }
  //   };  
  //   getInvestorProfileStats();
  // },[])


  useEffect(() => {
    const getallInvestmentStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/wallet-transactions/get-userwalletbalance', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllInvestmentStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    

    // Clean up the interval when the component unmounts
    return () => getallInvestmentStats();
  },[])

  //Handlers User  **************************************************************************************************************************
  // const handleSubmitAccount = async (values) => {

  // try{
  
  //   const user = JSON.parse(localStorage.getItem('user'))

  //   setLoading(true);
  //   const res = await axios.post('/wallets/edit-wallet', {
  //     payload:{
  //       ...values,
  //       userid: user._id,
  //     },
  //     _id: editable._id.accountStats,
  //   },
  //   {
  //     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //   }
  //   );
  //   setLoading(false);
  //   message.success('Wallet Actualizada com sucesso!');
  //   setShowModal(false);
  //   setTimeout(function () {
  //     window.location.reload(true);
  //   }, 1000);
  //   console.log(values)

  //   }catch (error){
  //     setLoading(false);
  //     // message.error('Failed to industry');
  //     //message.error('You are not Admin User');
  //   }
  // }

  const [weightSubject, setWeightSubject]= useState([]);
  const [weightMarks, setWeightMarks]= useState([]);

  useEffect(() => {

    const sSubject=[];
    const sMarks=[];
    const getAllCategoryWeights = async (values) => {
      try{
        setLoading(true);
        const user = JSON.parse(localStorage.getItem('user'))
        const res = await axios.post('/portfolio-wallets/get-userwalletaccountbalance',
        {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        // setAllWalletsWeights(res.data);
        console.log(res.data);

        for(let i=0; i< res.data.length; i++)
        {
         sSubject.push(res.data[i].walletPortfolioName);
         sMarks.push(res.data[i].balance);
        }
        setWeightSubject(sSubject);
        setWeightMarks(sMarks);
      }catch (error){
        setLoading(false);
        // message.error('Fetch Issue With Sector');
        message.error('You are not Admin User');
      }
    };  
    getAllCategoryWeights();
  },[])

  const [weightAssetSubject, setWeightAssetSubject]= useState([]);
  const [weightAssetMarks, setWeightAssetMarks]= useState([]);

  useEffect(() => {

    const sSubject=[];
    const sMarks=[];
    const getAllCategoryIncomeWeights = async (values) => {
      try{
        setLoading(true);
        const user = JSON.parse(localStorage.getItem('user'))
        const res = await axios.post('/portfolio-wallets/get-userwalletcryptoassetbalance',
        {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        // setAllWalletsAssetsWeights(res.data);
        console.log(res.data);

        for(let i=0; i< res.data.length; i++)
        {
         sSubject.push(res.data[i].cryptoAssetDescription);
         sMarks.push(res.data[i].balance);
        }
        setWeightAssetSubject(sSubject);
        setWeightAssetMarks(sMarks);
      }catch (error){
        setLoading(false);
        // message.error('Fetch Issue With Sector');
        message.error('You are not Admin User');
      }
    };  
    getAllCategoryIncomeWeights();
  },[])

  // const reload=()=>window.location.reload();

  // const handleClose = () => {
  //   setShowModal(false)
  //   setAccountNameUpdate(null);
  //   setAccountDescriptionUpdate(null);
  //   setAccountIBANUpdate(null);
  //   setAccountTypeUpdate(null);
  //   setStatusUpdate(null);
  //   reload();
  // };

  let labelInvestorProfile;
  if (user.investorProfile==="null" && investor===null) {
    labelInvestorProfile = "";
  }
  else if (investor!==null){
    labelInvestorProfile = 
    <div className="accountpage">
      <div></div>
      <div>
      <h3>O seu perfil de investidor é : {investor.investorProfile}</h3>
      </div>
      <div></div>
    </div>;
  }
  else{
    labelInvestorProfile = 
      <div className="accountpage">
        <div></div>
        <div>
        <h3>O seu perfil de investidor é : {user.investorProfile}</h3>
        </div>
        <div></div>
      </div>;
  }

  console.log("user.investorProfile: ",user.investorProfile);
  console.log("investor: ",investor);

    if((user.investorProfile===null && investor===null) || (user.investorProfile==="null" && investor===null)){
    //if(user.investorProfile==="null"){
    return (
      <Layout>
        {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          style={{ background: "green", borderColor: "green" }}
          onClick={() => navigate('/')}
          >Voltar
        </Button>
        </div>
        <div><h1>Minhas Contas Wallets</h1></div>
        <div>
        </div>
        </div>
        <div>
        <div className="accountpage" style={{ marginTop: "100px" }}>
            <div>
            </div>
            <div>
              <h3>Para que comece a utilizar o modulo de "Wallets", preencha o questionário para identificar o seu perfil de investidor</h3>
              <br/>
              <Button
              onClick={() => navigate('/InvestorProfiles')} 
              style={{ width: "100%" }}  
              type="primary">
                COMEÇA JÁ O QUESTIONÁRIO
              </Button>
            </div>
            <div>
            </div>
        </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      {loading && <Spinner/>}
                        {/* Start Header */}
                        <div className="accountpage">
        <div></div>
        <div><h1>Minhas Wallets</h1></div>
        <div></div>
      </div>

      {labelInvestorProfile}
      
      <div className="accountpagebuttonsAll">
            <Button
              shape="round"
              className="btn btn-primary btn-block"
              style={{ background: "green", borderColor: "green" }}
              onClick={() => navigate('/NewWallets')}
            >
              + Nova Wallet
            </Button>
      </div>

      <div className="accountpagebuttonsAll">
        <Button
          shape="round"
          className="btn btn-primary btn-block"
          style={{ background: "blue", borderColor: "blue" }}
          onClick={() => navigate('/TransactionsWalletAll')}
        >
          Todos os Movimentos
        </Button>
      </div>
        {/* Final Header */}

      <hr></hr>
      {/* Start Graphs */}
      <div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
          <div className="chart-container">
          <Chart
              type="donut"
              width="100%"
              series={weightMarks}
              options={{
                responsive: [
                  {
                    breakpoint: 281, // iPhone SE width
                    options: {
                      chart: {
                        width: '100%',
                        height: 150 // Adjust height for iPhone SE
                      }
                    }
                  },
                  {
                    breakpoint: 361, // iPhone SE width
                    options: {
                      chart: {
                        width: '100%',
                        height: 230 // Adjust height for iPhone SE
                      }
                    }
                  },
                  {
                    breakpoint: 481, // iPhone SE width
                    options: {
                      chart: {
                        width: '100%',
                        height: 250 // Adjust height for iPhone SE
                      }
                    }
                  },
                  {
                    breakpoint: 769, // iPhone 12 Mini width
                    options: {
                      chart: {
                        width: '100%',
                        height: 275 // Adjust height for iPhone 12 Mini
                      }
                    }
                  },
                  {
                    breakpoint: 768, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  },
                  {
                    breakpoint: 1024, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  },
                  {
                    breakpoint: 1200, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  },
                  {
                    breakpoint: 2500, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  }
                ],
                labels: weightSubject,
                noData: { text: "No Data" }, 
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          formatter: function(val) {
                            return val
                          }
                        }
                      }
                    }
                  }
                },
                dataLabels: { enabled: true },
                legend: { show: false } // Hide legends
              }}
            />

          </div>
          <h4 align="center">Wallets</h4>
        </div>
        <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
          <div className="chart-container">
            <Chart
              align="center" 
              type="donut"
              width="100%"
              series={weightAssetMarks}                
              options={{
                responsive: [
                  {
                    breakpoint: 281, // iPhone SE width
                    options: {
                      chart: {
                        width: '100%',
                        height: 150 // Adjust height for iPhone SE
                      }
                    }
                  },
                  {
                    breakpoint: 361, // iPhone SE width
                    options: {
                      chart: {
                        width: '100%',
                        height: 230 // Adjust height for iPhone SE
                      }
                    }
                  },
                  {
                    breakpoint: 481, // iPhone SE width
                    options: {
                      chart: {
                        width: '100%',
                        height: 250 // Adjust height for iPhone SE
                      }
                    }
                  },
                  {
                    breakpoint: 769, // iPhone 12 Mini width
                    options: {
                      chart: {
                        width: '100%',
                        height: 275 // Adjust height for iPhone 12 Mini
                      }
                    }
                  },
                  {
                    breakpoint: 768, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  },
                  {
                    breakpoint: 1024, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  },
                  {
                    breakpoint: 1200, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  },
                  {
                    breakpoint: 2000, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  },
                  {
                    breakpoint: 2000, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  }
                ],
                labels: weightAssetSubject,
                noData: { text: "No Data" }, 
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          formatter: function(val) {
                            return val
                          }
                        }
                      }
                    }
                  }
                },
                dataLabels: { 
                  enabled: true,
                },
                legend: { show: false } // Hide legends
              }}
            />
          </div>
          <h4 align="center">Activos</h4>
        </div>
      </div>
        {/* Final Graphs */}

      {/* <hr></hr> */}
      <div className="content" align="center">
        {/* <Table 
        columns={columns}
        dataSource={allAccount}
        pagination={false} 
        /> */}
            <Row gutter={[8,24]}     
                style={{
                // height: 200,
                justifyContent: "center"
              }}>
                  {
                  allInvestmentStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    hoverable
                    style={{ width: 350 , marginTop: 16, marginRight: "10px"}}
                    cover={[
                      <div align="center">
                        <br></br>
                        <div>
                          <div align="right" style={{marginRight: "20px"}}>
                              {(() => {
                                    return (
                                      <div>
                                        <Space>
                                        <Tooltip title="Reforçar">
                                      <PlusOutlined
                                      style={{color: "green"}}
                                        onClick={() => {
                                          console.log(opts);
                                          // navigate('/NewWalletsReforces',
                                          // {
                                          //   state:{
                                          //     userID: opts._id.userid,
                                          //     walletId:opts._id.walletID,
                                          //     walletName:opts._id.walletName,
                                          //     walletDescription:opts._id.walletDescription,
                                          //     brokerName:opts._id.brokerName,
                                          //     currency:opts._id.currency,
                                          //     walletStartDate:opts._id.walletStartDate,
                                          //     walletEndDate:opts._id.walletEndDate,
                                          //     frequency:opts._id.frequency,
                                          //     goalName:opts._id.goalName,
                                          //     codeSquare:opts._id.codeSquare,
                                          //     status:opts._id.status,
                                          //     balance:opts.balance
                                          //   }
                                          // });
                                        }}
                                        />
                                        </Tooltip>,
                                        <Tooltip title="Levantamento">
                                     <MinusOutlined
                                      style={{color: "red" }}
                                        onClick={() => {
                                          console.log(opts);
                                          // navigate('/NewWalletsWithdraws',
                                          // {
                                          //   state:{
                                          //     userID: opts._id.userid,
                                          //     walletId:opts._id.walletID,
                                          //     walletName:opts._id.walletName,
                                          //     walletDescription:opts._id.walletDescription,
                                          //     brokerName:opts._id.brokerName,
                                          //     currency:opts._id.currency,
                                          //     walletStartDate:opts._id.walletStartDate,
                                          //     walletEndDate:opts._id.walletEndDate,
                                          //     frequency:opts._id.frequency,
                                          //     goalName:opts._id.goalName,
                                          //     codeSquare:opts._id.codeSquare,
                                          //     status:opts._id.status,
                                          //     balance:opts.balance
                                          //   }
                                          // });
                                        }}
                                        />
                                        </Tooltip>
                                        </Space>
                                        </div>   
                                    )
                              })()}
                          </div>
                        </div>
                        <div>
                          <br></br>
                          <img alt="example" src={MyWallet}/>
                        </div>
                        <div>
                          <br></br>
                          <h4 className="price">{opts._id.walletName}</h4>
                        </div>
                      </div>
                    ]}
                    actions={[
                      <Tooltip title="Editar">
                      <SettingOutlined
                      key="setting" 
                      onClick={() => {
                        console.log(opts);
                        navigate('/EditWallets',
                        {
                          state:{
                            userID: opts._id.userid,
                            walletId:opts._id.walletID,
                            walletName:opts._id.walletName,
                            walletDescription:opts._id.walletDescription,
                            brokerName:opts._id.brokerName,
                            currency_ctr:opts._id.currency_ctr,
                            protocol:opts._id.protocol,
                            walletStartDate:opts._id.walletStartDate,
                            walletEndDate:opts._id.walletEndDate,
                            frequency:opts._id.frequency,
                            goalName:opts._id.goalName,
                            codeSquare:opts._id.codeSquare,
                            status:opts._id.status,
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                      <Tooltip title="Transacções">
                      <UnorderedListOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/TransactionsWallet',
                        {
                          state:{
                            walletId: opts._id.walletID,
                            walletName: opts._id.walletName,
                            codeSquare:opts._id.codeSquare,
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                      <Tooltip title="Detalhe Portfolio">
                      <TableOutlined
                        onClick={() => {
                          navigate('/PortfolioWallets',
                          {
                            state:{
                              walletNameId: opts.walletNameId,
                              walletName: opts._id.walletName,
                              codeSquare:opts._id.codeSquare,
                              currency:opts._id.currency,
                            }
                          });
                        }}
                      />
                      </Tooltip>,
                        <Tooltip title="Refrescar Portfolio">
                        <ReloadOutlined
                        onClick={() => {
                          console.log(opts);
                          navigate('/ProcessingRefreshWallets',
                          {
                            state:{
                              walletNameId: opts.walletNameId,
                              walletName: opts._id.walletName,
                            }
                          }
                        );
                        }}
                        />
                        </Tooltip>,
                      <Tooltip title="Arquivar">
                      <HddOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/ArchiveWallets',
                        {
                          state:{
                            userID: opts._id.userid,
                            walletId:opts._id.walletID,
                            walletName:opts._id.walletName,
                            walletDescription:opts._id.walletDescription,
                            brokerName:opts._id.brokerName,
                            currency_ctr:opts._id.currency_ctr,
                            protocol:opts._id.protocol,
                            walletStartDate:opts._id.walletStartDate,
                            walletEndDate:opts._id.walletEndDate,
                            frequency:opts._id.frequency,
                            goalName:opts._id.goalName,
                            codeSquare:opts._id.codeSquare,
                            status:opts._id.status,
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                    ]}
                    >
                    <Meta
                      description={[
                            <div align="center" marginRight="500px">
                                <h4 className="price">{opts._id.brokerName}</h4>
                                <h4 className="price">{opts._id.protocol}</h4>
                            </div>
                            ]}
                          />
                      <br></br>
                      <div className="additional"  align="center">
                      {/* <h4>{opts._id.isProtectedCapital[0] ? <span style={{color:"green"}}>Capital Garantido</span>: <span style={{color:"red"}}>Capital Não Garantido</span>}</h4> */}
                      {/* <h4 className="price">Capital Garantido: <span className="quantity">{opts._id.isProtectedCapital.toLocaleString('pt-BR')}</span></h4> */}
                      <h4 className="price" >Saldo: <span className="quantity">{opts.balance.toLocaleString('pt-BR')} {opts._id.currency}</span></h4>
                      <h4 className="price" >Saldo: <span className="quantity">{opts.balance_ctr.toLocaleString('pt-BR')} {opts._id.currency_ctr}</span></h4>
                      <hr></hr>
                      <h5>Porfolio actualizado<br></br><span className="quantity">{opts.date_refreshed}</span></h5>
                        {/* <p>Author: <span className="quantity">John Doe</span></p> */}
                      </div>
                    </Card>)
                  }
            </Row>

      </div>
      {/* <div><pre>{JSON.stringify(allNoInvestmentStats, null, 2) }</pre></div> */}

      {/* <Modal
        destroyOnClose={true} 
        title="Editar Conta"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleSubmitAccount} style={{padding:"32px 16px"}}>
            <Form.Item label="Nome da Conta ou do seu Banco" name="accountName">
              <Input 
              type="text"
              defaultValue={accountNameUpdate}
              />
            </Form.Item>
            <Form.Item label="Descrição" name="accountDescription">
              <TextArea
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                defaultValue={accountDescriptionUpdate}
                />
            </Form.Item>
            <Form.Item label="IBAN" name="accountIBAN">
              <Input 
              type="text"
              defaultValue={accountIBANUpdate}
              />
            </Form.Item>
            <Form.Item label="Account Type" name="accountType">
              <Select defaultValue={accountTypeUpdate}>
                <Select.Option value="Conta-Corrente">Conta-Corrente</Select.Option>
                <Select.Option value="Conta-Despesas">Conta-Despesas</Select.Option>
                <Select.Option value="Cartão Alimentação">Cartão Alimentação</Select.Option>
                <Select.Option value="Cartão de Crédito">Cartão de Crédito</Select.Option>
                <Select.Option value="Conta Pagamentos">Outra</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Estado da Conta" name="status" valuePropName="checked">
              <Switch defaultChecked={statusUpdate}/>
            </Form.Item>
            <div>
              <button 
              type="submit" 
              className="btn btn-primary" 
              onClick={handleClose}
               style={{
                  width: '100%',
                }}>
                {" "}
                GRAVAR
              </button>
            </div>
          </Form>
      </Modal> */}

    </Layout>
  );
};

export default Investments;