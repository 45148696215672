import React,{useState,useEffect} from "react";
import {Progress} from 'antd';
import {useNavigate,useLocation} from "react-router-dom";

import Layout from "../components/Layout/Layout";

const ProcessingExchanges = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const exchangeNameUpdate = data.exchangeName;
  const processingUpdate = data.processing;
  const navigateLinkUpdate = data.navigateLink;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const duration = 60 * 1000; // 60 seconds in milliseconds
    const interval = 100; // Update the progress every 100 milliseconds

    const steps = duration / interval;
    let currentStep = 0;

    const updateProgress = () => {
      currentStep += 1;
      const newProgress = (currentStep / steps) * 100;
      setProgress(newProgress);

      if (currentStep < steps) {
        // Continue updating the progress
        setTimeout(updateProgress, interval);
      }else {
        // Navigate to another page after the progress is complete
        navigate(navigateLinkUpdate);
      }
    };

    // Start updating the progress
    updateProgress();

    // Clean up the interval when the component unmounts
    return () => clearTimeout(updateProgress);
  }, [navigate,navigateLinkUpdate]);

  return (
    <Layout>
    <div>
      <h1 align="center">A criar {processingUpdate} - {exchangeNameUpdate}</h1>
      <br/>
      <Progress
        percent={parseFloat(progress).toFixed(0)} 
        status="active"
      />
    </div>

    </Layout>
  );
};

export default ProcessingExchanges;