import React,{useState,useEffect}  from 'react'
import {Form, Input, message} from 'antd'
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Spinner from '../components/Spinner';
import bgImg from "./../components/Assets/LoginPhoto.jpg";

const Login = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate();

  //from submit
  const submitHandler = async (values) => {
    try{
      setLoading(true);
      const {data} = await axios.post('/users/login', values);
      setLoading(false);
      message.success('Login sucess');
      localStorage.setItem(
        'user', 
        JSON.stringify({...data,password:""})
        );
      navigate('/');
    }catch (error){
      setLoading(false);
      message.error('Email or Password are wrong or User Blocked!');
    }
  }

  useEffect(() => {
    if(localStorage.getItem('user')){
      navigate("/");
    }
  },[navigate])

  return (
    <>
        {/* <div className='login-page'>
          {loading && <Spinner/>}
          <div className="col-1">
          <Form layout="vertical" onFinish={submitHandler}>
            <h1 align="center">SMARTBUDGET4U</h1>
            <br></br>
            <Form.Item label="Email" name="email">
              <Input type="email"/>
            </Form.Item>
            <Form.Item label="Password" name="password">
              <Input type="password"/>
            </Form.Item>
            <div className='d-grid'>
              <button className='btn btn-primary'>Login</button>
            </div>
            <p className="forgot-password text-right">
              <Link to="/register">Sign Up</Link>
            </p>
          </Form>
          </div>
          </div> */}

          <div className="login-container">
            <div className="login-image">
              <img src={bgImg} alt="Login" />
            </div>
            <div className="login-form"align="center">
              <h2>SMARTBUDGET4U</h2>
              <h2>Login</h2>
              {loading && <Spinner/>}
              <Form layout="vertical" onFinish={submitHandler}>
                <Form.Item label="Email" name="email">
                  <Input type="email"/>
                </Form.Item>
                <Form.Item label="Password" name="password">
                  <Input type="password"/>
                </Form.Item>
                <div className='d-grid'>
                  <button className='btn btn-primary'>Login</button>
                </div>
              </Form>
              {/* <div className="signup-link">
                Don't have an account? <a href="/register">Sign Up</a>
              </div> */}
            </div>
          </div>

    </>
  )
}

export default Login