import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Select, Table, Switch, Button, Space,Tooltip,Tag,DatePicker,Card,Row,Col,Typography} from 'antd';

import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { EditOutlined, SearchOutlined, CheckCircleFilled,ExclamationCircleFilled,DeleteOutlined } from "@ant-design/icons";

import moment from 'moment';

const TransactionsWalletAll = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // const categoryName= "Savings and Investments";

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  // const [allAccount, setAllAccount] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const [getAllWallet, setAllWallet] = useState([]);

  const { TextArea } = Input;
  const { Title } = Typography;
  const {RangePicker} = DatePicker;

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Add Tags
  const [tags, setTags] = useState([]);

  //Fields from table to View
  const [amountUpdate, setAmountUpdate] = useState();
  const [dateUpdate, setDateUpdate] = useState();
  const [currencyUpdate, setCurrencyUpdate] = useState();
  const [isPayedReceivedUpdate, setIsPayedReceivedUpdate] = useState(false);
  const [walletNameUpdate, setWalletNameUpdate] = useState();
  const [goalNameUpdate, setGoalNameUpdate] = useState();
  const [commentsUpdate, setCommentsUpdate] = useState();
  const [tagsUpdate, setTagsUpdate] = useState();
  const [codeSquareUpdate, setCodeSquareUpdate] = useState();
  const [squareNameUpdate, setSquareNameUpdate] = useState();
  const [idUpdate, setIdUpdate] = useState();
  const [isReforceOrWithdrawUpdate, setIsReforceOrWithdrawUpdate] = useState(false);
  const [deleteOneTransactionUpdate, setDeleteOneTransactionUpdate] = useState(false);

  //Filters
  // const [accountName, setAccountFilter] = useState('all');
  const [isPayedReceived, setIsPayedReceivedFilter] = useState('all');
  const [walletName, setWalletFilter] = useState('all');
  const [isReforceOrWithdraw, setIsReforceOrWithdrawFilter] = useState('all');
  const [goalName, setGoalNameFilter] = useState('all');
  const [frequency, setFrequency] = useState('month');
  const [selectedDate, setSelectedDate] = useState([]);

  //table accounts
  const columns =[
    //column ispayed
    {
      key:"isPayedReceived",
      title: "",
      dataIndex: "isPayedReceived",
      defaultSortOrder: 'descend',
      align: 'center',
      render: (value, record) => {
        if(record.isPayedReceived === true )
        
          return (
          <Tooltip title="Confirmado">
            <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
          </Tooltip>
          );

          return (
          <Tooltip title="Pendente">
            <ExclamationCircleFilled className="mx-2" style={{color: "#FF3234"}}/>
          </Tooltip>
          );
      },
      filters: [
        { text: "Pagamento Confirmado", value: true },
        { text: "Pagamento Não Confirmado", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.isPayed === value;
      },
    },
    //column date
    {
      key: "date",
      title: "Data",
      dataIndex: "date",
      defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD")}</span>,
      sorter: (a, b) => new Date(a.date) - new Date(b.date)
    },
    //column account
    {
      key:"goalName",
      title: "Objectivo do Investimento",
      dataIndex: ["goalName"],
      align: 'center',
      sorter: (a, b) => a.goalName.localeCompare(b.goalName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.goalName.toLowerCase().includes(value.toLowerCase());
      },
    },
      //column account
      // {
      //   key:"typeInvestment",
      //   title: "Tipo de Conta",
      //   width:"7%",
      //   dataIndex: ["typeInvestment"],
      //   align: 'center',
      //   sorter: (a, b) => a.typeInvestment.localeCompare(b.typeInvestment),
      //   filterDropdown: ({
      //     setSelectedKeys,
      //     selectedKeys,
      //     confirm,
      //     clearFilters,
      //   }) => {
      //     return (
      //       <>
      //         <Input
      //           autoFocus
      //           placeholder="Type text here"
      //           value={selectedKeys[0]}
      //           onChange={(e) => {
      //             setSelectedKeys(e.target.value ? [e.target.value] : []);
      //             confirm({ closeDropdown: false });
      //           }}
      //           onPressEnter={() => {
      //             confirm();
      //           }}
      //           onBlur={() => {
      //             confirm();
      //           }}
      //         ></Input>
      //         <Button
      //           onClick={() => {
      //             confirm();
      //           }}
      //           type="primary"
      //         >
      //           Search
      //         </Button>
      //         <Button
      //           onClick={() => {
      //             clearFilters();
      //           }}
      //           type="danger"
      //         >
      //           Reset
      //         </Button>
      //       </>
      //     );
      //   },
      //   filterIcon: () => {
      //     return <SearchOutlined />;
      //   },
      //   onFilter: (value, record) => {
      //     return record.typeInvestment.toLowerCase().includes(value.toLowerCase());
      //   },
      // },
      //column category
      {
      key:"walletName",
      title: "Conta Wallet",
      dataIndex: ["walletName"],
      align: 'center',
      sorter: (a, b) => a.walletName.localeCompare(b.walletName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.walletName.toLowerCase().includes(value.toLowerCase());
      },
    },
          //column category
          {
            key:"brokerName",
            title: "Broker",
            dataIndex: ["brokerName"],
            align: 'center',
            sorter: (a, b) => a.brokerName.localeCompare(b.brokerName),
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => {
              return (
                <>
                  <Input
                    autoFocus
                    placeholder="Type text here"
                    value={selectedKeys[0]}
                    onChange={(e) => {
                      setSelectedKeys(e.target.value ? [e.target.value] : []);
                      confirm({ closeDropdown: false });
                    }}
                    onPressEnter={() => {
                      confirm();
                    }}
                    onBlur={() => {
                      confirm();
                    }}
                  ></Input>
                  <Button
                    onClick={() => {
                      confirm();
                    }}
                    type="primary"
                  >
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      clearFilters();
                    }}
                    type="danger"
                  >
                    Reset
                  </Button>
                </>
              );
            },
            filterIcon: () => {
              return <SearchOutlined />;
            },
            onFilter: (value, record) => {
              return record.brokerName.toLowerCase().includes(value.toLowerCase());
            },
          },
    //column amount
    {
        key:"amount",
        title: "Montante",
        dataIndex: "amount",
        align: 'right',
        sorter: (a, b) => a.amount - b.amount,
        render: (amount, record) => {
          if(record.isReforceOrWithdraw === true )
            return (
            <span style={{color: "#39A939"}}>{amount.toLocaleString('pt-BR')} €</span>
            );
            return (
            <span style={{color: "#FF3234"}}>{amount.toLocaleString('pt-BR')} €</span>
            );
        },
    },
    {
        key:"currency",
        title: "",
        dataIndex: "currency",
        align: 'center',
    },
    //column tags
    {
      key:"tags",
      title: "Tags",
      align: 'center',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 15 ? 'geekblue' : 'green';
            if (tag === 'loser') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Acções",
      dataIndex: "type",
      width: "5%",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-4">
            <Space size="middle">
            <EditOutlined
              onClick={() => {
                setEditable(record);
                setDateUpdate(record.date);
                setAmountUpdate(record.amount);
                setCurrencyUpdate(record.currency);
                setIsPayedReceivedUpdate(record.isPayedReceived);
                setWalletNameUpdate(record.walletName);
                setGoalNameUpdate(record.goalName);
                setIsReforceOrWithdrawUpdate(record.isReforceOrWithdraw);
                setCommentsUpdate(record.comments);
                setTagsUpdate(record.tags);
                setShowModal(true);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                setEditable(record);
                setDateUpdate(record.date);
                setAmountUpdate(record.amount);
                setCurrencyUpdate(record.currency);
                setIsPayedReceivedUpdate(record.isPayedReceived);
                setWalletNameUpdate(record.walletName);
                setGoalNameUpdate(record.goalName);
                setIsReforceOrWithdrawUpdate(record.isReforceOrWithdraw);
                setCodeSquareUpdate(record.codeSquare);
                setSquareNameUpdate(record.squareName);
                setIdUpdate(record._id);
                setShowDeleteModal(true);
              }}
            />
            </Space>
          </div>
        );
      },
    }
  ];

  //useEffect Hook
  useEffect(() => {
    const getAllWallet = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/wallets/get-wallet', {
          userid:user._id,
          status: true
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllWallet(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllWallet();
  },[])

  useEffect(() => {
    const getAllTransactions = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/wallet-transactions/get-wallet-transactions',
        {
          userid:user._id,
          frequency,
          selectedDate,
          isReforceOrWithdraw: isReforceOrWithdraw,
          // categoryName: categoryID,
          // typeInvestment: typeInvestment,
          isPayedReceived: isPayedReceived,
          goalName: goalName,
          walletName:walletName,
          isArchive: false
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllTransactions(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllTransactions();

    // // Clean up the interval when the component unmounts
    // return () => getAllTransactions();
  },[frequency,selectedDate,isReforceOrWithdraw,isPayedReceived,goalName,walletName])


  // useEffect(() => {
  //   const getAllCategories = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/categories/get-categoryName', {
  //         userid:user._id,
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllCategory(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With sector');
  //       //message.error('You are not Admin User');
  //     }
  //   };  
  //   getAllCategories();
  // },[])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitTransaction = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/wallet-transactions/edit-wallet-transaction', {
      payload:{
        ...values,
        userid: user._id,
      },
      _id: editable._id,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Transacção Actualizada com sucesso!');
    setShowModal(false);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      //message.error('You are not Admin User');
    }
  }

  //delete handler
  const handleDelete = async (record) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'))

        setLoading(true);

        if(record.deleteOnlyOneTransaction===true){
          await axios.post("/wallet-transactions/delete-wallet-alltransaction", {
            userid:user._id,
            isReforceOrWithdraw: isReforceOrWithdrawUpdate,
            selectedDate: dateUpdate,
            codeSquare: codeSquareUpdate
          }
          )
          message.success("Transacções eliminada!");
        }
        else{
          await axios.post("/wallet-transactions/delete-wallet-transaction", {
            _id: idUpdate,
            });
          message.success("Transacção eliminada!");
        };
        setLoading(false);
        setShowDeleteModal(false);
        setTimeout(function () {
        window.location.reload(true);
        message.success('reload 1 segundo');
        }, 1000);
    } catch (error) {
        setLoading(false);
        console.log(error);
        message.error("unable to delete");
    }
    };

  //tags******************************************************************************************************

  const addTag = (e) => {
    if (e.key === "Tab") {
      if (e.target.value.length > 0) {
        setTags([...tags, e.target.value]);
        e.target.value = "";
      }
    }
  };

  // const reload=()=>window.location.reload();

  const handleClose = () => {
    setShowModal(false)
    setEditable(null);
    setDateUpdate(null);
    setAmountUpdate(null);
    setCurrencyUpdate(null);
    setIsPayedReceivedUpdate(null);
    setWalletNameUpdate(null);
    setCommentsUpdate(null);
    setGoalNameUpdate(null);
    setTagsUpdate(null);
    setShowDeleteModal(false);
    setCodeSquareUpdate(null);
    setDeleteOneTransactionUpdate(null);
    setIsReforceOrWithdrawFilter(null);
    setSquareNameUpdate(null);
    //reload();
  };

  const onChangeAccount = ({ target: { value } }) => {
    console.log('choose: ', value);
    setDeleteOneTransactionUpdate(value);
  };

  // const onFinish = (values) => {
  //   console.log({values
  //   });
  // };

  //Analytics
  const totalIncomesTurnover = allTransactions
  .filter((transaction) => transaction.isReforceOrWithdraw === true)
  .reduce((acc, transaction) => acc + transaction.amount, 0);

  const totalExpensesTurnover = allTransactions
  .filter((transaction) => transaction.isReforceOrWithdraw === false)
  .reduce((acc, transaction) => acc + transaction.amount, 0);

  // console.log("Confirmed:",totalAllTransactionsPayedTurnover);

  const totalAllTransactionsNotPayedTurnover = allTransactions
  .filter((transaction) => transaction.isPayedReceived === false)
  .reduce((acc, transaction) => acc + transaction.amount, 0);

  // console.log("Pending:",totalAllTransactionsNotPayedTurnover);

  const totalTransactions = totalIncomesTurnover-totalExpensesTurnover;

  // console.log("Total:",totalTransactions);

  // const totalNumberTransaction = allTransactions.length;

  // console.log("Transacções:",totalNumberTransaction);

  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>Todas as Contas Wallets - Transacções</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            align="right"
            shape="round"
            className="btn btn-primary"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
            >Voltar
          </Button>
        </div>
      <div className="accountpage">
      <div className="vertical-select">
            <h6>Escolha Conta</h6>
            <Select 
              name="accounts"
              style={{
                width: '200px',
              }}  
              value={walletName} 
              onChange={(values) => setWalletFilter(values)}
              >
                  <option value='all'>Todos</option>
                  {
                    getAllWallet.map( (getcon, index)=>(
                      <option key={index} value={getcon.walletName}>{getcon.walletName} </option>
                    ))
                  }
            </Select>
        </div>
        <div className="vertical-select">
          <h6>Escolha o Objectivo de Investimento</h6>
          <Select 
          style={{
            width: '200px',
          }} 
          value={goalName} onChange={(values) => setGoalNameFilter(values)}>
            <Select.Option value='all'>Todos</Select.Option>
            <Select.Option value='Fundo de Emergência'>Fundo de Emergência</Select.Option>
            <Select.Option value='Reforma'>Reforma</Select.Option>
            <Select.Option value='Habitação'>Habitação</Select.Option>
            <Select.Option value='Educação'>Educação</Select.Option>
            <Select.Option value='Saúde'>Saúde</Select.Option>
            <Select.Option value='Viagem'>Viagem</Select.Option>
            <Select.Option value='Automóvel'>Automóvel</Select.Option>
            <Select.Option value='Mota'>Mota</Select.Option>
            <Select.Option value='Filantropia'>Filantropia</Select.Option>
            <Select.Option value='Outro'>Outro</Select.Option>
          </Select>
        </div>
        <div className="vertical-select">
          <h6>Compras/Vendas</h6>
          <Select 
          style={{
            width: '250px',
          }} 
          value={isReforceOrWithdraw} onChange={(values) => setIsReforceOrWithdrawFilter(values)}>
            <Select.Option value='all'>Todos</Select.Option>
            <Select.Option value='true'>Compras</Select.Option>
            <Select.Option value='false'>Vendas</Select.Option>
          </Select>
        </div>
        <div className="vertical-select">
          <h6>Confirmados/Pendentes</h6>
          <Select 
          style={{
            width: '150px',
          }} 
          value={isPayedReceived} onChange={(values) => setIsPayedReceivedFilter(values)}>
            <Select.Option value='all'>Todos</Select.Option>
            <Select.Option value='true'>Confirmado</Select.Option>
            <Select.Option value='false'>Pendente</Select.Option>
          </Select>
        </div>
        <div></div>
        <div>
        </div>
        <div></div>
        <div>
        <h6 className="vertical-select">Escolha a Frequência</h6>
          <Select 
          style={{
            width: '150px',
          }} 
          value={frequency} onChange={(values) => setFrequency(values)}>
            <Select.Option value='7'>Última semana</Select.Option>
            <Select.Option value='month'>Mês Actual</Select.Option>
            <Select.Option value='nextmonth'>Próximo Mês</Select.Option>
            <Select.Option value='lastmonth'>Último Mês</Select.Option>
            <Select.Option value='yeartodate'>YTD</Select.Option>
            <Select.Option value='thisyear'>Este Ano</Select.Option>
            <Select.Option value='custom'>Personalizado</Select.Option>
          </Select>
          {frequency === 'custom' && (
          <RangePicker 
            value={selectedDate} 
            onChange={(values) => { setSelectedDate(values)}}
          />
          )}
        </div>
      </div>
      <div >

      <Row gutter={[16,16]}
              style={{
              justifyContent: "center"
            }}>
              <Col xs={24} sm={12} md={12} lg={6}>
              <Card
              style={{ width: "100%" , marginTop: 16}}
              ><div>
                <Row align="middle" gutter={[24, 0]}>
                <Col
                >
                  {/* <span>{c.today}</span> */}
                  <h4>Compras</h4>
                  <Title 
                  level={1}
                  style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
                  >
                    {/* {c.title} <small className={c.bnb}>{c.persent}</small> */}
                    € {totalIncomesTurnover.toLocaleString("pt-BR")} 
                  </Title>
                </Col>
                <Col>
                  {/* <div className="icon-box">{c.icon}</div> */}
                  {/* <div className="icon-box">ICON</div> */}
                </Col> 
                </Row>
              </div>
              </Card>
              </Col>

              <Col xs={24} sm={12} md={12} lg={6}>
              <Card
              style={{ width: "100%" , marginTop: 16}}
              ><div>
                <Row align="middle" gutter={[24, 0]}>
                <Col 
                >
                  {/* <span>{c.today}</span> */}
                  <h4>Vendas</h4>
                  <Title 
                  level={1}
                  style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
                  >
                    {/* {c.title} <small className={c.bnb}>{c.persent}</small> */}
                    € {totalExpensesTurnover.toLocaleString("pt-BR")} 
                  </Title>
                </Col>
                <Col>
                  {/* <div className="icon-box">{c.icon}</div> */}
                  {/* <div className="icon-box">ICON</div> */}
                </Col> 
                </Row>
              </div>
              </Card>
              </Col>

              <Col xs={24} sm={12} md={12} lg={6}>
              <Card
              style={{ width: "100%" , marginTop: 16}}
              ><div>
                <Row align="middle" gutter={[24, 0]}>
                <Col
                >
                  {/* <span>{c.today}</span> */}
                  <h4>Pendentes</h4>
                  <Title 
                  level={1}
                  style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
                  >
                    {/* {c.title} <small className={c.bnb}>{c.persent}</small> */}
                    € {totalAllTransactionsNotPayedTurnover.toLocaleString("pt-BR")} 
                  </Title>
                </Col>
                <Col>
                  {/* <div className="icon-box">{c.icon}</div> */}
                  {/* <div className="icon-box">ICON</div> */}
                </Col> 
                </Row>
              </div>
              </Card>
              </Col>

              <Col xs={24} sm={12} md={12} lg={6}>
              <Card
              style={{ width: "100%" , marginTop: 16}}
              ><div>
                <Row align="middle" gutter={[24, 0]}>
                <Col 
                >
                  {/* <span>{c.today}</span> */}
                  <h4>Saldo</h4>
                  <Title 
                  level={1}
                  style={{ width: "100%" , marginTop: 16, fontSize: "25px"}}
                  >
                    {/* {c.title} <small className={c.bnb}>{c.persent}</small> */}
                    {totalTransactions.toLocaleString("pt-BR")} 
                  </Title>
                </Col>
                <Col xs={6}>
                  {/* <div className="icon-box">{c.icon}</div> */}
                  {/* <div className="icon-box">ICON</div> */}
                </Col> 
                </Row>
              </div>
              </Card>
              </Col>
              </Row>
      </div>
      <hr></hr>
      <div className="content">
        <Table 
            // size="small"
            columns={columns}
            dataSource={allTransactions}
            pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
            scroll={{ x: 'max-content'}}
            className="custom-table" // Apply custom CSS class for styling
        />
      </div>
      {/* <div><pre>{JSON.stringify(allTransactions, null, 2) }</pre></div> */}

      <Modal
       destroyOnClose={true} 
       title="Editar Transacção"
       open={showModal}
       onCancel={() => setShowModal(false)}
       footer={false}
       style={{padding:"32px 16px"}}
       >
         <Form layout="vertical" onFinish={handleSubmitTransaction} style={{padding:"32px 16px"}}>
           <Space direction="horizontal">
             <Form.Item style={{ marginTop:10 }} name="amount">
               <Input
                 defaultValue={amountUpdate}
                 placeholder='0.00'
                 suffix="€"
                 type="text" 
                 size="large" 
                 style={{fontSize:"32px", color:"black"}}
               />
             </Form.Item>
             <Form.Item style={{ marginTop:10 }} name="currency">
                 <Input style={{fontSize:"32px", width: "100px"}} defaultValue={currencyUpdate} disabled="true"/>
             </Form.Item>
           </Space>
           <Form.Item label="Reforço ou Aquisição confirmada" name="isPayedReceived" valuePropName="checked">
             <Switch defaultChecked={isPayedReceivedUpdate} checkedChildren="Sim" unCheckedChildren="Não"/>
           </Form.Item>
           <Form.Item label="Data" name="date">
               <Input
                   type="text"
                   defaultValue={moment(dateUpdate).format("DD/MM/YYYY")}
                   disabled="true"
               />
           </Form.Item>
           <Form.Item label="Nome da Conta Investimentos" name="walletName">
               <Input defaultValue={walletNameUpdate} disabled="true"/>
           </Form.Item>
           <Form.Item label="Objectivo do Investimento" name="goalName">
             <Input defaultValue={goalNameUpdate} disabled="true"/>
           </Form.Item>
           <Form.Item label="Comentários" name="comments">
             <TextArea
               defaultValue={commentsUpdate}
               showCount
               maxLength={500}
               style={{
                 height: 100,
                 marginBottom: 24,
               }}
               type="text"
               />
           </Form.Item>
           <Form.Item label="Tags" name="tags">
             <Select
               mode="tags"
               style={{
                 width: '100%',
               }}
               placeholder="Tags Mode"
               defaultValue={tagsUpdate}
               onChange={addTag}
               >
             </Select>
           </Form.Item>
           <div>
             <button 
             type="submit" 
             className="btn btn-primary" 
             onClick={handleClose}
              style={{
                 width: '100%',
               }}>
               {" "}
               GRAVAR
             </button>
           </div>
         </Form>
      </Modal>

      <Modal
        destroyOnClose={true} 
        title="Apagar Transacção"
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleDelete} 
          style={{padding:"32px 16px"}}>
            <Space direction="horizontal">
              <Form.Item style={{ marginTop:10 }} name="amount">
                <Input
                  defaultValue={amountUpdate}
                  placeholder='€ 0.00'
                  type="text" 
                  size="large" 
                  style={{fontSize:"32px", color:"black"}}
                  disabled="true"
                />
              </Form.Item>
              <Form.Item style={{ marginTop:10 }} name="currency">
                  <Input style={{fontSize:"32px", width: "100px"}} defaultValue={currencyUpdate} disabled="true"/>
              </Form.Item>
            </Space>
            <Form.Item label="Reforço ou Levantamento confirmado" name="isPayedReceived" valuePropName="checked">
              <Switch defaultChecked={isPayedReceivedUpdate} checkedChildren="Sim" unCheckedChildren="Não" disabled="true"/>
            </Form.Item>
            <Form.Item label="Data" name="date">
                <Input
                    type="text"
                    defaultValue={moment(dateUpdate).format("DD/MM/YYYY")}
                    disabled="true"
                />
            </Form.Item>
            <Form.Item label="Conta" name="walletName">
                <Input defaultValue={walletNameUpdate} disabled="true"/>
            </Form.Item>
            <Form.Item label="Categoria" name="goalName">
              <Input defaultValue={goalNameUpdate} disabled="true"/>
            </Form.Item>
            <Form.Item label="Obs" name="squareName">
              <Input defaultValue={squareNameUpdate} />
            </Form.Item>
            <Form.Item onChange={onChangeAccount} label="Esta transacção e futuras?" name="deleteOnlyOneTransaction" valuePropName="checked">
              <Switch defaultChecked={deleteOneTransactionUpdate} checkedChildren="Sim" unCheckedChildren="Não"/>
            </Form.Item>
            <div>
              <button 
              type="submit" 
              className="btn btn-primary" 
              onClick={handleClose}
               style={{
                  width: '100%',
                }}>
                {" "}
                APAGAR
              </button>
            </div>
          </Form>
      </Modal>

    </Layout>
  );
};


export default TransactionsWalletAll;