import React,{useState,useEffect} from "react";
import {Input,Table, Select, Button,Tag} from 'antd';
import {useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {
  SearchOutlined,
} from "@ant-design/icons";

import moment from 'moment';

const StockSmarts = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  // const { TextArea } = Input;

  // const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allStockAsset, setAllStockAsset] = useState([]);

  //DropList Market
  const [allMarket, setAllMarket] = useState([]);
  //DropList Sectors
  const [allSector, setAllSector] = useState([]);
  //DropList Industry
  const [allIndustry, setAllIndustry] = useState([]);

  //Filters
  const [marketName, setMarketNameFilter] = useState('all');
  const [sectorName, setSectorNameFilter] = useState('all');
  const [industryName, setIndustryNameFilter] = useState('all');
  const [riskCategory, setRiskCategory] = useState('all');
  

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "date",
      title: "Data",
      dataIndex: "date",
      align: 'center',
      defaultSortOrder: 'descend',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD")}</span>,
      sorter: (a, b) => new Date(a.date) - new Date(b.date)
    },
    {
      key: "stockAssetName",
      title: "Activo",
      dataIndex: ["stockAssetName","stockAssetName"],
      align: 'center',
      // defaultSortOrder: 'ascend',
      sorter: (a, b) => a.stockAssetName.stockAssetName.localeCompare(b.stockAssetName.stockAssetName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.stockAssetName.stockAssetName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "yahooTicker",
      title: "Yahoo Ticker",
      dataIndex: "yahooTicker",
      align: 'center',
      sorter: (a, b) => a.yahooTicker.localeCompare(b.yahooTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.yahooTicker.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "marketName",
      title: "Mercado",
      dataIndex: ["marketName", "marketNamePT"],
      align: 'center',
      sorter: (a, b) => a.marketName.marketNamePT.localeCompare(b.marketName.marketNamePT),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.marketName.marketNamePT.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "sectorName",
      title: "Sector",
      dataIndex: ["sectorName", "sectorNamePT"],
      align: 'center',
      sorter: (a, b) => a.sectorName.sectorNamePT.localeCompare(b.sectorName.sectorNamePT),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.sectorName.sectorNamePT.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "industryName",
      title: "Industria",
      dataIndex: ["industryName", "industryNamePT"],
      align: 'center',
      sorter: (a, b) => a.industryName.industryNamePT.localeCompare(b.industryName.industryNamePT),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.industryName.industryNamePT.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key:"close_day",
      title: "Preço Compra",
      dataIndex: "close_day",
      align: 'center',
      sorter: (a, b) => a.close_day - b.close_day,
      render: (close_day, record) => {
        if(record.close_day >= 0 )
          return (
          <span>{close_day.toLocaleString('pt-BR')}</span>
          );
          return (
          <span style={{color: "#FF3234"}}>{close_day.toLocaleString('pt-BR')} %</span>
          );
      },
    },
    {
      key: "currency",
      title: "",
      dataIndex: ["currency"],
      align: 'center',
      sorter: (a, b) => a.currency.localeCompare(b.currency),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.currency.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key:"pl",
      title: "Retorno Smart %",
      dataIndex: "pl",
      align: 'center',
      sorter: (a, b) => a.pl - b.pl,
      render: (pl, record) => {
        if(record.pl >= 0 )
          return (
          <span style={{color: "#39A939"}}>{pl.toLocaleString('pt-BR')} %</span>
          );
          return (
          <span style={{color: "#FF3234"}}>{pl.toLocaleString('pt-BR')} %</span>
          );
      },
    },
    {
      key:"VaR_95",
      title: "VaR_95",
      dataIndex: "VaR_95",
      align: 'center',
      sorter: (a, b) => a.VaR_95 - b.VaR_95,
      render: (VaR_95, record) => {
        if(record.VaR_95 >= 0 )
          return (
          <span>{VaR_95.toLocaleString('pt-BR')} %</span>
          );
          return (
          <span>{VaR_95.toLocaleString('pt-BR')} %</span>
          );
      },
    },
    {
      key:"VaR_99",
      title: "VaR_99",
      dataIndex: "VaR_99",
      align: 'center',
      sorter: (a, b) => a.VaR_99 - b.VaR_99,
      render: (VaR_99, record) => {
        if(record.VaR_99 >= 0 )
          return (
          <span>{VaR_99.toLocaleString('pt-BR')} %</span>
          );
          return (
          <span>{VaR_99.toLocaleString('pt-BR')} %</span>
          );
      },
    },
    {
      key: "SRRI",
      title: "SRRI",
      dataIndex: "SRRI",
      align: 'center',
      sorter: (a, b) => a.SRRI - b.SRRI,
    },
    {
      key: "riskCategory",
      title: "Risk Category",
      dataIndex: "riskCategory",
      align: 'center',
      sorter: (a, b) => a.riskCategory.localeCompare(b.riskCategory),
      render: (value, record) => 
      {
        if(record.riskCategory === "High") {
            return (
              <Tag style={{fontSize: "9px"}} color="#F2003C">Elevado</Tag>
            )
        } else if (record.riskCategory === "Medium") {
            return (
              <Tag style={{fontSize: "9px"}} color="#FF6000">Médio</Tag>
            )
        } else {
            return (
              <Tag style={{fontSize: "9px"}} color="#177245">Baixo</Tag>
            )
        }
      }
    },
    // {
    //   key:"week_volatility",
    //   title: "Risco Semanal %",
    //   dataIndex: "week_volatility",
    //   align: 'right',
    //   sorter: (a, b) => a.week_volatility - b.week_volatility,
    //   render: (week_volatility, record) => {
    //     if(record.week_volatility >= 0 )
    //       return (
    //       <span>{week_volatility.toLocaleString('pt-BR')} %</span>
    //       );
    //       return (
    //       <span style={{color: "#FF3234"}}>{week_volatility.toLocaleString('pt-BR')} %</span>
    //       );
    //   },
    // },
  ];

  // const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllStockSmart = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/stockSmarts/get-stockSmart',
        {
          marketName: marketName,
          sectorName: sectorName,
          industryName: industryName,
          riskCategory: riskCategory,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllStockAsset(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllStockSmart();

    // // Clean up the interval when the component unmounts
    // return () => getAllStockAsset();
  },[marketName,sectorName,industryName,riskCategory])

  //Handler Markets DropList ****************************************************************************************
  useEffect(() => {
    const getAllMarkets = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/markets/get-stockmarket', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllMarket(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllMarkets();

    // Clean up the interval when the component unmounts
    //return () => 
  },[])

  //Handler Sectors DropList ****************************************************************************************
  useEffect(() => {
    const getAllSectors = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/sectors/get-sector', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllSector(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllSectors();

    // Clean up the interval when the component unmounts
    //return () => getAllSectors();
  },[])

  //Handler Sectors DropList ****************************************************************************************
  useEffect(() => {
    const getAllIndustries = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/industries/get-industry', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllIndustry(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllIndustries();

    // Clean up the interval when the component unmounts
    //return () => getAllIndustries();
  },[])

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>SMART-ADVISOR - Acções</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            align="right"
            shape="round"
            className="btn btn-primary"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
            >Voltar
          </Button>
        </div>
      <div className="accountpage">
        <div className="vertical-select">
                    {/* <Form.Item> */}
          <h6>Escolha Mercado Financeiro</h6>
            <Select
              placeholder="Please select Stock Markets"
              name="accounts"
              style={{
                width: '100%',
              }}  
              defaultValue={marketName} 
              onChange={(values) => setMarketNameFilter(values)}
              >
                  <option value='all'>Todos</option>
                  {
                    allMarket.map( (getcon, index)=>(
                      <option key={index} value={getcon.id}>{getcon.marketName} </option>
                    ))
                  }
            </Select>
          {/* </Form.Item> */}
          </div>

        <div className="vertical-select">
            <h6>Escolha Sector Financeiro</h6>
              <Select
                // mode="multiple"
                placeholder="Please select Sector"
                name="accounts"
                // size="small"
                style={{
                  width: '100%',
                }}  
                defaultValue={sectorName} 
                onChange={(values) => setSectorNameFilter(values)}
                >
                    <option value='all'>Todos</option>
                    {
                      allSector.map( (getcon, index)=>(
                        <option key={index} value={getcon.id}>{getcon.sectorNamePT} </option>
                      ))
                    }
              </Select>
            {/* </Form.Item> */}
        </div>

        <div className="vertical-select">
            <h6><h6>Escolha Industria Financeiro</h6></h6>
              <Select
                // mode="multiple"
                placeholder="Please select Sector"
                name="accounts"
                // size="small"
                style={{
                  width: '100%',
                }}  
                defaultValue={industryName} 
                onChange={(values) => setIndustryNameFilter(values)}
                >
                    <option value='all'>Todas</option>
                    {
                      allIndustry.map( (getcon, index)=>(
                        <option key={index} value={getcon.id}>{getcon.industryNamePT} </option>
                      ))
                    }
              </Select>
            {/* </Form.Item> */}
        </div>
        <div className="vertical-select">
          <h6>Escolha o Risco da Acção</h6>
          <Select
          //mode="multiple" 
          style={{
            width: '100%',
          }} 
          value={riskCategory} onChange={(values) => setRiskCategory(values)}>
            <Select.Option value='all'>Todas</Select.Option>
            <Select.Option value='Low'>Baixo</Select.Option>
            <Select.Option value='Medium'>Médio</Select.Option>
            <Select.Option value='High'>Elevado</Select.Option>
          </Select>
        </div>  
      </div>
      <hr></hr>
      <div className="content">
        <Table 
          size="small"
          columns={columns}
          dataSource={allStockAsset}
          pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
          scroll={{ x: 'max-content'}}
          className="custom-table" // Apply custom CSS class for styling
        />
        
      </div>

      {/* <div><pre>{JSON.stringify(allStockAsset, null, 2) }</pre></div> */}
      
    </Layout>
  );
};

export default StockSmarts;