import React,{useState,useEffect} from 'react';
import {Form,Input,Button,Space,Radio,message,Select,Row,Switch} from 'antd';
import { Link } from 'react-router-dom';

import Layout from "../components/Layout/Layout";
import {useNavigate} from "react-router-dom";

import moment from 'moment';

import axios from "axios";
import Spinner from "../components/Spinner";

const NewUtilities = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const categoryName= "Housing (Utilities & Cable TV)";

  //loading Apis
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState('')

  //DropList Category
  const [allCategory, setAllCategory] = useState([]);
  //DropList Account
  const [allAccount, setAllAccount] = useState([]);
  //DropList Account
  const [getUser, setGetUser] = useState([]);

  //Add Tags
  const [tags, setTags] = useState([]);

  //Recursive Dates Array
  const [sdates, setsDates]= useState([]);
  const [sdatesEnd, setsDatesEnd]= useState([]);
  const [dateUpdate, setDateUpdate] = useState();

  const { TextArea } = Input;

  const [result, setResult] = useState();

  const [utilityDetails, setUtilityDetails] = useState(null);
  const [accountDetails, setAccountDetails] = useState(null);
  const [frequencyDetails, setFrequencyDetails] = useState(null);

  let now=(moment().format('YYYY-MM-DD'));

  const [dateShared, setDateShared] = useState(now);
  const [dateSharedDate, setDateSharedDate] = useState();
  const [sharedDetails, setSharedDetails] = useState(false);
  const [sharedPersonalDetails, setSharedPersonalDetails] = useState(false);
  const [sharedInformationDetails, setSharedInformationDetails] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user = JSON.parse(localStorage.getItem('user'))

  const addTag = (e) => {
    if (e.key === "Tab") {
      if (e.target.value.length > 0) {
        setTags([...tags, e.target.value]);
        e.target.value = "";
      }
    }
  };

       //***************************************MONTHS RECURSIVES**************************************************************/
    //Recursive Dates Array
    const [sMonth, setMonth]= useState('0');

    const handleMonths=(event)=>{
  
        setMonth([]);
    
        const getMonth= event.target.value;
    
        console.log(getMonth); //22
        console.log(sdates); //2023-01-06
    
        const dates = [];
        const currDate = moment(sdates);
    
        if (getMonth === "1" && frequencyDetails==="Mensal" ) {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else if (getMonth !== "1" && frequencyDetails==="Mensal" ){
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add(getMonth-1, 'month').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          //console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(1, "month").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else if (getMonth !== "1" && frequencyDetails==="Trimestral" ){
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add(getMonth-1, 'quarter').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          //console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(1, "quarter").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else if (getMonth !== "1" && frequencyDetails==="Semestral" ){
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add((getMonth*2)-2, 'quarter').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(2, "quarter").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else{
            //console.log(currDate); //2023-01-06

            let lastDate = moment(currDate).add(getMonth-1, 'year').toDate();
            //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
      
            //console.log(lastDate); //2028-04-06
      
            do {
            dates.push(moment(currDate).format('YYYY-MM-DD'));
            } while (currDate.add(1, "year").diff(lastDate) < 0);
            //dates.push(currDate.clone().toDate());
            dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
    
        message.success(dates);
        console.log(dates);
    
        setMonth(dates);
      }

    const worker = {
    dates: moment(dateUpdate),
    };

    //Handler Fund Family DropList ****************************************************************************************
    useEffect(() => {
      const getAllAccounts = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/accounts/get-account', {
            userid:user._id,
            status: true,
            isCreditCard: "all"
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllAccount(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          //message.error('You are not Admin User');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllAccounts();
    },[])
  
    useEffect(() => {
      const getUser = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/users/get-openfinanceoneuser', {
            _id:user._id,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setGetUser(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          //message.error('You are not Admin User');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getUser();
    },[])

    //Handler Sectors DropList ****************************************************************************************
    useEffect(() => {
      const getAllCategories = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/categories/get-findCategoryName', {
            userid:user._id,
            categoryName: categoryName
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllCategory(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          //message.error('You are not Admin User');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllCategories();
    },[categoryName])

var categoryID = allCategory[0];

let range = {min: 1, max: 999999999}
let delta = range.max - range.min

const codeSquare = Math.round(range.min + Math.random() * delta)

const handleDate=(event)=>{
  setsDates([]);
  const getDates= moment(event.target.value);
  setsDates(getDates);
  console.log(getDates);

  // novo
  setMonth([]);
  const dates = [];
  dates.push(moment(getDates).format('YYYY-MM-DD'));
  message.success(dates);
  setMonth(dates);
}

const handleDateEnd=(event)=>{
  setsDatesEnd([]);
  const getDates= moment(event.target.value);
  setsDatesEnd(getDates);
  console.log(getDates);
}

// SWITCH Final do Empréstimo
let utilityEndDateHTML;
if (utilityDetails==="TV+Internet+Voz" || utilityDetails==="TV+Internet+Móvel+Voz" || utilityDetails==="Telemóvel" || utilityDetails==="Internet Móvel") {
  utilityEndDateHTML =
  <Space direction='vertical'>
  <Form.Item 
    label="Data Fim da Fidelização" 
    name="utilityEndDate"
    style={{width: "200px"}}
    onChange={(e)=> {handleDateEnd(e); handleSubtraction(e)}}
    rules={[
        { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
        ]}>
        <Input style={{fontSize:"18px"}} type="date"/>
    </Form.Item>
  </Space>
      ;
} 
else {
  utilityEndDateHTML =
    <Form.Item hidden="true">
    <Input 
        type="text"
    />
</Form.Item>;
}

// SWITCH Shared
let sharedHTML;
if (sharedDetails===true) {
  sharedHTML =
  <Space direction='vertical'>
    
      <br></br>

      <Form.Item label="Dados Pessoais?" name="isSharedPersonalProfile" valuePropName="checked">
        <Switch checkedChildren="Sim" unCheckedChildren="Não"/>
      </Form.Item>
      <p><b><i>*Partilhe o seu Nome Completo, NIF e Data de Nascimento</i></b></p>

      <br></br>

      <Form.Item label="Dados da Utilty & Comunicação?" name="isSharedInformation" valuePropName="checked">
        <Switch checkedChildren="Sim" unCheckedChildren="Não" />
      </Form.Item>
      <p><b><i>*Partilhe o seu Tarifário e Empresa</i></b></p>

      <br></br>

      <Form.Item label="Prazo máximo de Partilha em número de meses?" name="monthsShared" onChange={(e)=>{handleDateShared(e)}}>
        <Input min={1} defaultValue={0} type="number" style={{width:"225px"}}/>
      </Form.Item>
      <br></br>

      <b style={{marginBottom:"50px"}}>Prazo de compartilhamento até: 
      <br/>
      <br/>
      {dateShared}</b>

  </Space>
      ;
} 
else {
  sharedHTML =
    <Form.Item hidden="true">
    <Input 
        type="text"
    />
</Form.Item>;
}

  const submitHandler = async (values) => {

    try{
      setLoading(true);

      for (const sdate of sMonth) {
        const res = axios.post('/transactions/add-transaction', {
          amount: values.amount, 
          isIncomeOrExpense:false,
          date: sdate,
          userid:user._id,
          months: values.months, 
          monthYear: moment(sdate).format("MMM YYYY"),
          monthYearNumber: moment(sdate).format("YYYYMM"),
          dayMonthYearNumber: moment(sdate).format("YYYY-MM-DD"),
          accountName:accountDetails,
          categoryName:categoryID.id,
          squareName: values.utilityName,
          codeSquare: codeSquare,
          tags: values.tags,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
      }
      const res2 = axios.post('/utilities/add-utility', {
        userid:user._id,
        utilityName:values.utilityName,
        utilityType:utilityDetails,
        utilityCompany:values.utilityCompany,
        utilityNumber:values.utilityNumber,
        utilityDescription:values.utilityDescription,
        utilityTariff:values.utilityTariff,
        utilityEndDate:values.utilityEndDate,
        frequency:frequencyDetails,
        months: values.months,
        accountName:accountDetails,
        categoryName:categoryID.id,
        codeSquare: codeSquare
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      )
      ;

      if (values.isShared===true){
        if (values.isSharedPersonalProfile===true & values.isSharedInformation=== true)
        {
          const res3 = axios.post('/openFinance/add-openfinanceutility', {
            userid:user._id,
            isShared: values.isShared,
            isSharedInformation: values.isSharedInformation,
            isSharedPersonalProfile: values.isSharedPersonalProfile,
            amount: values.amount,
            codeSquare: codeSquare,
            utilityType:utilityDetails,
            utilityCompany:values.utilityCompany,
            utilityNumber:values.utilityNumber,
            utilityTariff:values.utilityTariff,
            utilityEndDate:values.utilityEndDate,
            name: getUser[0].name,
            nif: getUser[0].nif,
            birthdayDate: getUser[0].birthdayDate,
            countryCitizenship: getUser[0].countryCitizenship,
            phoneNumber: getUser[0].phoneNumber,
            cityLiving: getUser[0].cityLiving,
            stateLiving: getUser[0].stateLiving,
            countryLiving: getUser[0].countryLiving,
            employmentStatus: getUser[0].employmentStatus,
            ocuppationWork: getUser[0].ocuppationWork,
            isPEP: getUser[0].isPEP,
            isCorporateControl: getUser[0].isCorporateControl,
            email: getUser[0].email,
            frequency: frequencyDetails,
            months: values.months,
            dateEndShared: dateShared,
            monthsShared: values.monthsShared,
            contador: 1
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
        } 
        if (values.isSharedInformation=== true & (values.isSharedPersonalProfile===false || values.isSharedPersonalProfile===undefined))
        {
          const res4 = axios.post('/openFinance/add-openfinanceutility', {
            userid:user._id,
            isShared: values.isShared,
            isSharedInformation: values.isSharedInformation,
            isSharedPersonalProfile: values.isSharedPersonalProfile,
            amount: values.amount,
            codeSquare: codeSquare,
            utilityType:utilityDetails,
            utilityCompany:values.utilityCompany,
            utilityNumber:values.utilityNumber,
            utilityTariff:values.utilityTariff,
            utilityEndDate:values.utilityEndDate,
            frequency: frequencyDetails,
            months: values.months,
            dateEndShared: dateShared,
            monthsShared: values.monthsShared,
          contador: 1
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
      }if (values.isSharedPersonalProfile=== true & (values.isSharedInformation===false || values.isSharedInformation===undefined))
      {
        const res5 = axios.post('/openFinance/add-openfinanceutility', {
          userid:user._id,
          isShared: values.isShared,
          isSharedInformation: values.isSharedInformation,
          isSharedPersonalProfile: values.isSharedPersonalProfile,
          amount: values.amount,
          codeSquare: codeSquare,
          utilityType:utilityDetails,
          name: getUser[0].name,
          nif: getUser[0].nif,
          birthdayDate: getUser[0].birthdayDate,
          countryCitizenship: getUser[0].countryCitizenship,
          phoneNumber: getUser[0].phoneNumber,
          cityLiving: getUser[0].cityLiving,
          stateLiving: getUser[0].stateLiving,
          countryLiving: getUser[0].countryLiving,
          employmentStatus: getUser[0].employmentStatus,
          ocuppationWork: getUser[0].ocuppationWork,
          isPEP: getUser[0].isPEP,
          isCorporateControl: getUser[0].isCorporateControl,
          email: getUser[0].email,
          frequency: frequencyDetails,
          months: values.months,
          dateEndShared: dateShared,
          monthsShared: values.monthsShared,
          contador: 1
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
      }
      }else{
        const res6 = axios.post('/openFinance/add-openfinanceutility', {
          userid:user._id,
          isShared: values.isShared,
          isSharedInformation: values.isSharedInformation,
          isSharedPersonalProfile: values.isSharedPersonalProfile,
          amount: values.amount,
          codeSquare: codeSquare,
          utilityType:utilityDetails,
          contador: 1
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
      }


      setLoading(false);
      message.success('Serviço Criado com Sucesso!');
      setTimeout(function () {
        window.location.reload(true);
      }, 1000);
      navigate('/Utilities');
    }catch (error){
      setLoading(false);
      message.error('Algo deu errado!');
    }
  }

  //Handler Test Console Log Values
  const onFinish = (values) => {
    if (values.isShared===true){
      if (values.isSharedPersonalProfile===true & values.isSharedInformation=== true){
      console.log({
        userid:user._id,
        isShared: values.isShared,
        isSharedInformation: values.isSharedInformation,
        isSharedPersonalProfile: values.isSharedPersonalProfile,
        amount: values.amount,
        codeSquare: codeSquare,
        utilityType:utilityDetails,
        utilityCompany:values.utilityCompany,
        utilityNumber:values.utilityNumber,
        utilityTariff:values.utilityTariff,
        utilityEndDate:values.utilityEndDate,
        name: getUser[0].name,
        nif: getUser[0].nif,
        birthdayDate: getUser[0].birthdayDate,
        countryCitizenship: getUser[0].countryCitizenship,
        phoneNumber: getUser[0].phoneNumber,
        cityLiving: getUser[0].cityLiving,
        stateLiving: getUser[0].stateLiving,
        countryLiving: getUser[0].countryLiving,
        employmentStatus: getUser[0].employmentStatus,
        ocuppationWork: getUser[0].ocuppationWork,
        isPEP: getUser[0].isPEP,
        isCorporateControl: getUser[0].isCorporateControl,
        email: getUser[0].email,
        frequency: frequencyDetails,
        months: values.months,
        dateEndShared: dateShared,
        monthsShared: values.monthsShared,
      });
    }
    if (values.isSharedInformation=== true & (values.isSharedPersonalProfile===false || values.isSharedPersonalProfile===undefined)){
      console.log({
        userid:user._id,
        isShared: values.isShared,
        isSharedInformation: values.isSharedInformation,
        isSharedPersonalProfile: values.isSharedPersonalProfile,
        amount: values.amount,
        codeSquare: codeSquare,
        utilityType:utilityDetails,
        utilityCompany:values.utilityCompany,
        utilityNumber:values.utilityNumber,
        utilityTariff:values.utilityTariff,
        utilityEndDate:values.utilityEndDate,
        frequency: frequencyDetails,
        months: values.months,
        dateEndShared: dateShared,
        monthsShared: values.monthsShared,
      });
    }
    if (values.isSharedPersonalProfile=== true & (values.isSharedInformation===false || values.isSharedInformation===undefined)){
      console.log({
        userid:user._id,
        isShared: values.isShared,
        isSharedInformation: values.isSharedInformation,
        isSharedPersonalProfile: values.isSharedPersonalProfile,
        amount: values.amount,
        codeSquare: codeSquare,
        utilityType:utilityDetails,
        name: getUser[0].name,
        nif: getUser[0].nif,
        birthdayDate: getUser[0].birthdayDate,
        countryCitizenship: getUser[0].countryCitizenship,
        phoneNumber: getUser[0].phoneNumber,
        cityLiving: getUser[0].cityLiving,
        stateLiving: getUser[0].stateLiving,
        countryLiving: getUser[0].countryLiving,
        employmentStatus: getUser[0].employmentStatus,
        ocuppationWork: getUser[0].ocuppationWork,
        isPEP: getUser[0].isPEP,
        isCorporateControl: getUser[0].isCorporateControl,
        email: getUser[0].email,
        frequency: frequencyDetails,
        months: values.months,
        dateEndShared: dateShared,
        monthsShared: values.monthsShared,
      });
    }
    }else{
      console.log({
        userid:user._id,
        isShared: values.isShared,
        isSharedInformation: values.isSharedInformation,
        isSharedPersonalProfile: values.isSharedPersonalProfile,
        amount: values.amount,
        codeSquare: codeSquare,
        utilityType:utilityDetails,
      });
    }
  };

  // Function to handle the subtraction
const handleSubtraction = () => {
  if ( frequencyDetails === "Trimestral") {
    const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'quarter', true)));
    setResult(subtractionResult);
    console.log(result)
  } else if (frequencyDetails === "Anual"){
      const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'year', true)));
      setResult(subtractionResult);
      console.log(result)
    } else if (frequencyDetails === "Semestral"){
      const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'quarter', true))/2);
      setResult(subtractionResult);
      console.log(result)
    }else {
      const subtractionResult = parseInt((moment(sdatesEnd).diff(sdates, 'month', true)));
      setResult(subtractionResult);
      console.log(result)
    }
  ;
};

    // Function to handle the subtraction
    const handleDateShared = (event) => {

    const getMonthShared= event.target.value;
    const todayShared = (moment().add(getMonthShared, 'month').format('YYYY-MM-DD'));
    setDateShared(todayShared);
    const todaySharedDate = (moment().add(getMonthShared, 'month').toDate());
    setDateSharedDate(todaySharedDate);
    console.log(dateShared)
  };
  
  const onChangeAccount = ({ target: { value } }) => {
    console.log('Account choose: ', value);
    setAccountDetails(value);
  };

  const onChangeUtility = ({ target: { value } }) => {
    console.log('Utility choose: ', value);
    setUtilityDetails(value);
  };

  const onChangeFrequency = state => {
    console.log('Frequency choose: ',state);
    setFrequencyDetails(state);
  };

  const onChangeShared = state => {
    console.log('Shared choose: ',state);
    setSharedDetails(state);
  };

  return (
    <Layout>
      {loading && <Spinner/>}
            <div className="accountpage">
        <div></div>
        <div><h1>Novo Utility & Comunicação </h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={submitHandler} 
          >
            <Space direction="horizontal"  className="horizontal-space">
            <Form.Item style={{ marginTop:10 }} label="Valor do Serviço" name="amount">
              <Input
                suffix="€"
                placeholder='0.00'
                type="text" 
                size="large" 
                style={{fontSize:"32px"}}
              />
            </Form.Item>
            <Form.Item style={{ marginTop:10 }} label="Moeda"  name="currency">
                  <Input style={{fontSize:"32px", width: "100px"}} defaultValue={"EUR"} disabled="true"/>
            </Form.Item>
            </Space>
            <Form.Item label="Data" name="date" onChange={(e)=>handleDate(e)}
                rules={[
                  { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                    ]}>
                <Input style={{fontSize:"18px"}} type="date"/>
            </Form.Item>

            <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da Utility & Comunicação</h5>

            <Form.Item label="" name="utilityName">
              <Input 
              type="text"
              />
            </Form.Item>

            <h5 style={{ marginBottom:30, marginTop:30 }}>Tipo de Serviço</h5>

            <Radio.Group onChange={onChangeUtility} style={{ display: "flex", justifyContent: "left"}} >
            <Space direction="vertical" align='center' size="middle">
              <Row gutter={[8,24]}>
              <Radio.Button value="Electricidade" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Electricidade</Radio.Button>
                  <Radio.Button value="Gás" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Gás</Radio.Button>
                  <Radio.Button value="Água" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Água</Radio.Button>
                  <Radio.Button value="Electricidade e Gás" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Electricidade e Gás</Radio.Button>
                  <Radio.Button value="TV + Internet + Voz" style={{display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>TV + Internet + Voz</Radio.Button>
                  <Radio.Button value="TV + Internet + Móvel + Voz" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>TV + Internet + Móvel + Voz</Radio.Button>
                  <Radio.Button value="Telemóvel" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>Telemóvel</Radio.Button>
                  <Radio.Button value="Internet Móvel" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>Internet Móvel</Radio.Button>
                  <Radio.Button value="Outro" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"}}>Outro</Radio.Button>
              </Row>
            </Space>
          </Radio.Group>

            <h5 style={{ marginBottom:30, marginTop:30 }}>Dados da Utility & Comunicação</h5>

            <Form.Item label="Nome da Empresa de Utility & Comunicação" name="utilityCompany">
              <Input 
              type="text"
              defaultValue={""}
              />
            </Form.Item>

            <Form.Item label="Numero do Contrato/Cliente" name="utilityNumber">
              <Input 
              type="text"
              defaultValue={""}
              />
            </Form.Item>

            <Form.Item label="Breve descrição do Tarifário Contratualizado" name="utilityTariff">
              <Input 
              type="text"
              defaultValue={""}
              />
            </Form.Item>

            <Space direction="vertical">
            {utilityEndDateHTML}
            </Space>

            <h5 style={{ marginBottom:30, marginTop:30 }}>Conta Utilizada</h5>

            <Radio.Group onChange={onChangeAccount} style={{ display: "flex", justifyContent: "left"}} >
              <Space direction="vertical" align='center' size="middle">
              <Row gutter={[8,24]}>
                      {
                      allAccount.map((opts,i)=>
                        <Radio.Button style={{ display: "flex", justifyContent: "center", width:"100%", marginTop: 5 }} key={i} value={opts._id}>
                          {opts.accountName}
                        </Radio.Button>)
                      }
              </Row>
              </Space>
            </Radio.Group>

            <Form.Item style={{ marginTop:30 }} label="Observações" name="utilityDescription">
              <TextArea
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                />
            </Form.Item>

            <Form.Item label="Tags" name="tags">
              <Select
                mode="tags"
                style={{
                  width: '100%',
                }}
                placeholder="Tags Mode"
                onChange={addTag}
                >
              </Select>
            </Form.Item>

            <Form.Item label="Frequência" name="frequency">
              <Select onChange={onChangeFrequency}>
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Número de frequências por ano" name="months" onChange={(e)=>handleMonths(e)} 
                rules={[
                  {
                    required: false, message: "Por favor, insira o número de frequências que vai ter com a sua subscrição!",
                    min: 1,
                    max: 999,
                  }
                    ]}>
                <Input min={1} defaultValue={1}/>
            </Form.Item>

            <br></br>
            <hr/>
            <br></br>
            <h5 style={{ marginBottom:30, marginTop:30 }}>Partilhe os dados do seu Empréstimo e receba ofertas mais atractivas que tem neste momento contratualizado!</h5>

            <Form.Item label="Quer Partilhar os dados do empréstimo?" name="isShared" valuePropName="checked" >
              <Switch 
              checkedChildren="Sim" 
              unCheckedChildren="Não" 
              //onChange={onChangeShared("Active!")}
              onChange={onChangeShared}
              />
            </Form.Item>

            <Space direction="vertical">
            {sharedHTML}
            </Space>
            
            
            <Button style={{ width: "100%" , background: "green", marginTop: 30, marginBottom: 30 }} type="primary" htmlType='submit'>
              CRIAR
              </Button>
              <Link to="/Utilities">
                <Button style={{ width: "100%"}} type="primary">
                  CANCELAR
                </Button>
                </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(allExpenses, null, 2) }</pre></div> */}
    </Layout>
  )
  };

export default NewUtilities;