import React,{useState,useEffect} from "react";
import {Input, Table, Select, Button,Form,Modal,Space} from 'antd';
import {useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {
  SearchOutlined,
  EyeOutlined
} from "@ant-design/icons";

import moment from 'moment';

const PPRPrices = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  // const { TextArea } = Input;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allStockAsset, setAllStockAsset] = useState([]);

  //Handle Close button Form
  // const [show, setShow] = useState(false);

  // //DropList Market
  // const [allMarket, setAllMarket] = useState([]);
  // //DropList Sectors
  // const [allSector, setAllSector] = useState([]);
  // //DropList Industry
  const [allCategory, setAllCategory] = useState([]);

  //Filters
  // const [marketName, setMarketNameFilter] = useState('all');
  // const [fundFamilyName, setFundFamilyNameFilter] = useState('all');
  const [categoryAssetName, setCategoryAssetNameFilter] = useState('all');

  //Fields from table to View
  const [descriptionUpdate, setDescriptionUpdate] = useState(null);
  const [websiteUpdate, setWebsiteUpdate] = useState(null);

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "dateRefresh",
      title: "Última Data Actualização",
      dataIndex: "dateRefresh",
      // defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.dateRefresh) - new Date(b.dateRefresh)
    },
    {
      key: "etfAssetName",
      title: "Activo",
      dataIndex: ["etfAssetName","etfAssetName"],
      // defaultSortOrder: 'ascend',
      sorter: (a, b) => a.etfAssetName.etfAssetName.localeCompare(b.etfAssetName.etfAssetName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.etfAssetName.etfAssetName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "ISIN",
      title: "ISIN",
      dataIndex: "ISIN",
      sorter: (a, b) => a.ISIN.localeCompare(b.ISIN),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.ISIN.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "bloombergTicker",
      title: "Bloomberg Ticker",
      dataIndex: "bloombergTicker",
      sorter: (a, b) => a.bloombergTicker.localeCompare(b.bloombergTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.bloombergTicker.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "fundFamilyName",
      title: "Família Fundo",
      dataIndex: ["fundFamilyName", "fundFamilyName"],
      sorter: (a, b) => a.fundFamilyName.fundFamilyName.localeCompare(b.fundFamilyName.fundFamilyName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.fundFamilyName.fundFamilyName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "categoryAssetName",
      title: "Classe Activos",
      dataIndex: ["categoryAssetName", "categoryAssetNamePT"],
      sorter: (a, b) => a.categoryAssetName.categoryAssetNamePT.localeCompare(b.categoryAssetName.categoryAssetNamePT),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.categoryAssetName.categoryAssetNamePT.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key:"price",
      title: "Preço",
      dataIndex: "price",
      align: 'right',
      sorter: (a, b) => a.price - b.price,
    },
    {
      key: "currency",
      title: "",
      dataIndex: ["currency"],
      sorter: (a, b) => a.currency.localeCompare(b.currency),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.currency.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key:"returnPercentage",
      title: "Retorno%",
      dataIndex: "returnPercentage",
      defaultSortOrder: 'descend',
      align: 'right',
      sorter: (a, b) => a.returnPercentage - b.returnPercentage,
      render: (returnPercentage, record) => {
        if(record.returnPercentage >= 0 )
          return (
          <span style={{color: "#39A939"}}>{returnPercentage.toLocaleString('pt-BR')} %</span>
          );
          return (
          <span style={{color: "#FF3234"}}>{returnPercentage.toLocaleString('pt-BR')} %</span>
          );
      },
  },
  {
    title: "Acções",
    dataIndex: "type",
    render: (type, record) => {
      // if(type === "Income" )
      return (
        <div className="mx-2">
          <EyeOutlined
            onClick={() => {
                  setDescriptionUpdate(record.etfAssetName.description);
                  setWebsiteUpdate(record.etfAssetName.website);
                  setShowModal(true);
            }}
          />
        </div>
      );
    },
  }
  ];

  // const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllStockAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/fundFamilyPrices/get-fundFamilyPrice',
        {
          fundFamilyName: "all",
          categoryAssetName: categoryAssetName,
          isPPR: true
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllStockAsset(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        // message.error('You are not Admin User');
      }
    };  
    
    getAllStockAsset();

    // // Clean up the interval when the component unmounts
    // return () => getAllStockAsset();
  },[categoryAssetName])

  // //Handler Markets DropList ****************************************************************************************
  // useEffect(() => {
  //   const getAllMarkets = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/markets/get-etfmarket', {
  //         userid:user._id,
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllMarket(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With sector');
  //       message.error('You are not Admin User');
  //     }
  //   };  
  //   getAllMarkets();
  // },[])

  // //Handler Sectors DropList ****************************************************************************************
  // useEffect(() => {
  //   const getAllSectors = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/FundFamilies/get-fundFamily', {
  //         userid:user._id,
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllSector(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With sector');
  //       // message.error('You are not Admin User');
  //     }
  //   };  
    
  //   // Clean up the interval when the component unmounts
  //   return () => getAllSectors();
  // },[])

  //Handler Sectors DropList ****************************************************************************************
  useEffect(() => {
    const getAllCategories = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/CategoryAssets/get-categoryAsset', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllCategory(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With sector');
        // message.error('You are not Admin User');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllCategories();
  },[])

    /************************************Website DISABLE BUTTON***************************/
    let webSiteInputDisable;

    if (!websiteUpdate) {
      webSiteInputDisable = 
      <Form.Item label="Morningstar Website Stats" name="website">
          <Input 
          type="text"
          />
      </Form.Item>
    } else {
      webSiteInputDisable = 
      <Form.Item label="" name="website">
        <Space.Compact style={{ width: '100%' }}>
          <Button style={{width: "100%" }} type="primary" onClick={() => window.open(websiteUpdate, "Popup","width=600, height=600")}>VER MAIS NO WEBSITE</Button>
        </Space.Compact>
      </Form.Item>
    }

      //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setDescriptionUpdate(null);
    setWebsiteUpdate(null);
  };

  // //Handler Close Modal
  // const handleClose = () => {
  //   setShow(false);
  // };

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>Cotações - PPRs</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            align="right"
            shape="round"
            className="btn btn-primary"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
            >Voltar
          </Button>
        </div>

      <div className="accountpage">
        <div className="vertical-select">
          <h6>Escolha Classe de Activos Financeiros</h6>
            <Select
              // mode="multiple"
              placeholder="Please select Asset Class"
              name="accounts"
              // size="small"
              style={{
                width: '100%',
              }}  
              defaultValue={categoryAssetName} 
              onChange={(values) => setCategoryAssetNameFilter(values)}
              >
                  <option value='all'>Todas</option>
                  {
                    allCategory.map( (getcon, index)=>(
                      <option key={index} value={getcon.id}>{getcon.categoryAssetNamePT} </option>
                    ))
                  }
            </Select>
          {/* </Form.Item> */}
        </div>
      </div>
      <hr></hr>
      <div className="content">
        <Table 
            size="small"
            columns={columns}
            dataSource={allStockAsset}
            pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
            scroll={{ x: 'max-content'}}
            className="custom-table" // Apply custom CSS class for styling
        />
        
      </div>

      {/* <div><pre>{JSON.stringify(allStockAsset, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title={"Algumas Informações sobre o ETF"}
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form 
            layout="vertical" 
            style={{padding:"32px 16px"}}
          >
            <Form.Item label="" name="description">
              <p>{descriptionUpdate}</p>
            </Form.Item>

            {webSiteInputDisable}
            
          </Form>
      </Modal>

    </Layout>
  );
};

export default PPRPrices;