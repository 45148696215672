import React,{useState,useEffect} from 'react';
import {Steps,Form,Input,Button,Space,Switch,message,Select } from 'antd';
import { CheckCircleOutlined, HomeOutlined, LikeOutlined, LoginOutlined, ProfileOutlined, ShopOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import moment from 'moment';
import axios from "axios";
import bgImg from "./../components/Assets/LoginPhoto.jpg";

import Spinner from "../components/Spinner";

import { FaCheckCircle } from 'react-icons/fa';

const RegisterAccountPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  //loading Apis
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState('')

  // const { TextArea } = Input;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const [current, setCurrent] = useState(0);
  const [profileDetails, setProfileDetails] = useState(null);
  const [addressDetails, setAddressDetails] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);
  const [reviewDetails, setReviewDetails] = useState(null);
  const [loginDetails, setLoginDetails] = useState(null);
 
  const onFinishProfileForm=(values) => {
    setProfileDetails(values);
    setCurrent(1);
  }

  const onFinishAddressForm=(values) => {
    setAddressDetails(values);
    setCurrent(2);
  }

  const onFinishJobForm=(values) => {
    setJobDetails(values);
    setCurrent(3);
  }

  const onFinishReviewForm=(values) => {
    setReviewDetails(values);
    setCurrent(4);
  }

  const onFinishLoginForm=(values) => {
    setLoginDetails(values);
    setCurrent(5);
  }

  const forms =[
    <ProfileForm 
      onFinish={onFinishProfileForm} 
      initialValues={profileDetails}/>,
    <AddressForm 
      onFinish={onFinishAddressForm} 
      initialValues={addressDetails}/>,
    <JobForm 
      onFinish={onFinishJobForm} 
      initialValues={jobDetails}/>,
    <ReviewForm
      onFinish={onFinishReviewForm}
      initialValues={reviewDetails}/>,
    <LoginForm 
      onFinish={onFinishLoginForm} 
      initialValues={loginDetails}/>,
    <FinishForm />
  ]

  const isStepDisabled=(stepnumber)=>{
    if(stepnumber===0){
      return false
    }
    if(stepnumber===1){
      return profileDetails === null
    }
    if(stepnumber===2){
      return profileDetails === null || addressDetails === null
    }
    if(stepnumber===3){
      return profileDetails === null || addressDetails === null || jobDetails === null
    }
    if(stepnumber===4){
      return profileDetails === null || addressDetails === null || jobDetails === null || reviewDetails === null
    }
    if(stepnumber===5){
      return profileDetails === null || addressDetails === null || jobDetails === null || reviewDetails === null || loginDetails === null
    }
  }

  // const user = JSON.parse(localStorage.getItem('user'))

  const submitHandler = async (values) => {
    try{
      setLoading(true);
      await axios.post('/users/register',
      {
      //STEP 1     
      name:profileDetails.name,
      nif:profileDetails.nif,
      currency:profileDetails.currency,
      birthdayDate:moment(profileDetails.birthdayYear+"-"+profileDetails.birthdayMonth+"-"+profileDetails.birthdayDay).format('YYYY-MM-DD'),
      countryCitizenship:profileDetails.countryCitizenship,
      phoneNumber:profileDetails.phoneNumber,
      isMobile:profileDetails.isMobile,
      
      //STEP 2 
      cityLiving:addressDetails.cityLiving,
      stateLiving:addressDetails.stateLiving,
      countryLiving:addressDetails.countryLiving,
      
      //STEP 3
      employmentStatus:jobDetails.employmentStatus,
      ocuppationWork:jobDetails.ocuppationWork,

      //STEP 5
      email:loginDetails.email,
      password:loginDetails.password,
      acceptPrivacy:loginDetails.acceptPrivacy,
      acceptMarketing:loginDetails.acceptMarketing,
      isBusiness:loginDetails.isBusiness
      })
      message.success('Registration Successfull');
      setLoading(false);
      navigate('/Login')
    }catch (error){
      setLoading(false);
      message.error('Something went wrong');
    }
  }

  function ProfileForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "100%", padding: "20px"}} onFinish={onFinish} initialValues={initialValues}>
        <h4 align="center">Fale-me de ti</h4>
        <hr/>
        <br/>
      <Form.Item label="Nome" name={"name"} 
        rules={[{
          required:true, 
          message:"Por favor, insira seu nome"
        }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="NIF" name={"nif"} 
        rules={[{
          required:true, 
          message:"Por favor, insira o seu número fiscal"
        }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Moeda" name="currency">
          <Select
              style={{
                width: '100%',
              }} 
          >
            <Select.Option value="EUR">EUR</Select.Option>
            <Select.Option value="USD">USD</Select.Option>
            {/* <Select.Option value="CHF">CHF</Select.Option>
            <Select.Option value="GBP">GBP</Select.Option>
            <Select.Option value="HKD">HKD</Select.Option>
            <Select.Option value="BRL">BRL</Select.Option>
            <Select.Option value="JPY">JPY</Select.Option>              
            <Select.Option value="Other">Other</Select.Option> */}
          </Select>
      </Form.Item>
      <Space direction="horizontal">
      <Form.Item label="Ano de nascimento" name={"birthdayYear"} 
        rules={[{
          required:true, 
          message:"Por favor, insira o ano do seu aniversário"
        }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Mês de nascimento" name={"birthdayMonth"} 
        rules={[{
          required:true, 
          message:"Por favor, insira o mês do seu aniversário"
        }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Dia de nascimento" name={"birthdayDay"} 
        rules={[{
          required:true, 
          message:"Por favor, insira o dia do seu aniversário"
        }]}
      >
        <Input />
      </Form.Item>
      </Space>
      <Form.Item label="Nacionalidade" name={"countryCitizenship"} 
        rules={[{
          required:true, 
          message:"Por favor, insira a sua nacionalidade"
        }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Número de Telefone" name={"phoneNumber"} 
        rules={[{
          required:true, 
          message:"Por favor, digite seu número de telefone"
        }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="É telemóvel" name="isMobile" valuePropName="checked">
        <Switch  checkedChildren="Sim" unCheckedChildren="Não"/>
      </Form.Item>
      <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
      Continuar
      </Button>
    </Form>)
  }

  function AddressForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "100%", padding: "20px"}} onFinish={onFinish} initialValues={initialValues}>
        <h4 align="center">Onde moras?</h4>
        <hr/>
        <br/>
      <Form.Item label="Cidade" name={"cityLiving"} 
        rules={[{
          required:true, 
          message:"Por favor, insira a cidade onde vive"
        }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Concelho" name={"stateLiving"} 
        rules={[{
          required:true, 
          message:"Por favor, insira o concelho onde vive"
        }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="País" name={"countryLiving"} 
        rules={[{
          required:true, 
          message:"Por favor, insira o país onde vive"
        }]}
      >
        <Input />
      </Form.Item>

      <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
      Continuar
      </Button>
    </Form>)
  }

  function JobForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "100%", padding: "20px"}} onFinish={onFinish} initialValues={initialValues}>
        <h4 align="center">Fala mais acerca do teu emprego</h4>
        <hr/>
        <br/>

        <h5 align="left">Estás empregado?</h5>
        <br/>
        <Form.Item label="Situação Profissional" name={"employmentStatus"} 
          rules={[{
            required:true, 
            message:"Por favor, insira situação laboral"
          }]}
        >
        <Select>
          <Select.Option value="Empregado">Empregado</Select.Option>
          <Select.Option value="Conta-Própria">Conta-Própria</Select.Option>
          <Select.Option value="Dono/a de casa">Dono/a de casa</Select.Option>
          <Select.Option value="Estudante">Estudante</Select.Option>
          <Select.Option value="Reformado">Reformado</Select.Option>
          <Select.Option value="Incapaz de trabalhar">Incapaz de trabalhar</Select.Option>
        </Select>
        </Form.Item>
      <hr/>

      <h5 align="left">Detalhe da tua profissão</h5>
      <br/>
      <Form.Item label="Ocupação profissional" name={"ocuppationWork"} 
        rules={[{
          required:true, 
          message:"Por favor, insira a ocupação profissional"
        }]}
      >
        <Input />
      </Form.Item>
   
      <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
        Continuar
      </Button>
    </Form>)
  }

  function ReviewForm({onFinish, initialValues}){
    return (
      <Form align="center" style={{ width: "100%", padding: "20px"}} onFinish={onFinish} initialValues={initialValues}>
        <h4 align="center">Reserve um momento para rever</h4>
        <h4 align="center"> e confirmar as suas informações</h4>
        <hr/>
        <br></br>
        <h5>Suas informações pessoais</h5>
        <br/>
        <p><b>Nome:</b> {profileDetails.name}</p>
        <br/>
        <p><b>Número Fiscal:</b> {profileDetails.nif}</p>
        <br/>
        <p><b>Data de Nascimento:</b> {profileDetails.birthdayYear}-{profileDetails.birthdayMonth}-{profileDetails.birthdayDay}</p>
        <br/>
        <p><b>Número de telefone:</b> {profileDetails.phoneNumber}</p>
        <br/>
        <p><b>Nacionalidade:</b> {profileDetails.countryCitizenship}</p>
        <hr/>
        <h5>Suas informações de onde vive</h5>
        <br/>
        <p><b>Morada da Residência:</b> {addressDetails.cityLiving}-{addressDetails.stateLiving}</p>
        <br/>
        <p><b>País de Residência:</b> {addressDetails.countryLiving}</p>
        <hr/>
        <h5>Suas informações de emprego</h5>
        <br/>
        <p><b>Situação laboral:</b> {jobDetails.employmentStatus}</p>
        <br/>
        <p ><b>Ocupação profissional:</b> {jobDetails.ocuppationWork}</p>
        <br/>
        <br/>
      <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
        Continuar
      </Button>
    </Form>)
  }

  function LoginForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "100%", padding: "20px"}} onFinish={onFinish} initialValues={initialValues}>
    <h4 align="center">Configure o utilizador e palavra-chave</h4>
    <br/>
    <h6>Antes de iniciar à sua conta, crie o seu utilizador e uma palavra-chave para aceder com segurança à sua conta on-line.</h6>
    <hr/>
    <br/>
    <h6 align="left">Crie um utilizador e palavra-chave</h6>
    <br/>
      <Form.Item style={{ width: "100%", padding: "20px"}} label="Email" name={"email"} 
      rules={[{
        required:true,
        type:"email",
        message:"Por favor, insira um endereço de e-mail válido"
      }]}>
        <Input />
      </Form.Item>
      <Form.Item style={{ width: "100%", padding: "20px"}} label="Palavra-Chave" name={"password"} 
      rules={[{
        required:true, 
        message:"Por favor, insira a sua palavra-chave"
      }]}>
        <Input.Password />
      </Form.Item>
      <br></br> 
      <h6 align="left">Tipo de Conta</h6>
      <br/>
      <Form.Item label="Que tipo de conta quer criar?" name="isBusiness" valuePropName="checked">
        <Switch  checkedChildren="EMPRESA/ENI" unCheckedChildren="PESSOA SINGULAR" />
      </Form.Item>
      <br/>
      <h6 align="left">Políticas de Privacidade e Tratamento de Dados</h6>
      <br/>
      <Form.Item label="Entendo e aceito as Políticas de Privacidade" name="acceptPrivacy" valuePropName="checked"
          rules={[{
            required:true, 
            message:"Por favor, indique se aceita as Políticas de Privacidade"
          }]}>
        <Switch checkedChildren="Aceito" unCheckedChildren="Não Aceito" />
      </Form.Item>
      <Form.Item label="Autorizo o tratamento de dados pessoais para comercialização de produtos e serviços" name="acceptMarketing" valuePropName="checked">
        <Switch  checkedChildren="Aceito" unCheckedChildren="Não Aceito" />
      </Form.Item>
      <br></br>
      <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
        Continuar
      </Button>
    </Form>)
  }

  function FinishForm(){
    return (<>
        <div>
          <div className="success-container">
              <div className="success-symbol">
              <FaCheckCircle className="success-icon" size={100} color="#00b85c" />
              </div>
              <br></br>
              <h1 style={{color:"green"}}>Registration Successful</h1>
              <p style={{color:"green"}}>Your registration has been completed successfully.</p>
          </div>
          <Button style={{ width: "100%" }}  type="primary" onClick={submitHandler}>
            Comece já
          </Button>
        </div>
    </>)
  }

  //***************************RENDER**************************************************************************/

  return (
      <>
          {/* <div aling="center" className='register-page-user'>
            {loading && <Spinner/>}
            <Steps 
              style={{ padding: "32px 16px"}}
              onChange={setCurrent}
              current={current}
              >
              <Steps.Step disabled={isStepDisabled(0)} title='Profile' icon={<ProfileOutlined />} />
              <Steps.Step disabled={isStepDisabled(1)} title='Address' icon={<HomeOutlined />} />
              <Steps.Step disabled={isStepDisabled(2)} title='Job' icon={<ShopOutlined />} />
              <Steps.Step disabled={isStepDisabled(3)} title='Review' icon={<LikeOutlined />} />
              <Steps.Step disabled={isStepDisabled(4)} title='Login' icon={<LoginOutlined />} />
              <Steps.Step disabled={isStepDisabled(5)} title='Finish' icon={<CheckCircleOutlined />} />
            </Steps>
            </div>
            <div className="registerAccount-page">
              {forms[current]}
            </div> */}
            {/* <div><pre>{JSON.stringify(addressDetails, null, 2) }</pre></div> */}


      <div className="registration-container">
      {loading && <Spinner/>}
      {/* <div className="registration-image">
        <img src={bgImg} alt="Registration" />
      </div> */}
      <div className="registration-form">
        <h1>Registration</h1>
        <Steps 
              style={{ padding: "32px 16px"}}
              onChange={setCurrent}
              current={current}
              >
              <Steps.Step disabled={isStepDisabled(0)} title='Profile' icon={<ProfileOutlined />} />
              <Steps.Step disabled={isStepDisabled(1)} title='Address' icon={<HomeOutlined />} />
              <Steps.Step disabled={isStepDisabled(2)} title='Job' icon={<ShopOutlined />} />
              <Steps.Step disabled={isStepDisabled(3)} title='Review' icon={<LikeOutlined />} />
              <Steps.Step disabled={isStepDisabled(4)} title='Login' icon={<LoginOutlined />} />
              <Steps.Step disabled={isStepDisabled(5)} title='Finish' icon={<CheckCircleOutlined />} />
            </Steps>
        <div className="steps-action">
            {forms[current]}
        </div>
      </div>
    </div>

      </>
  );
};

export default RegisterAccountPage;