import React,{useState,useEffect} from "react";
import {Form, Input, message, Select,Button,Card} from 'antd';
import {useNavigate,useLocation,Link} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';

const ArchiveAccounts = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const accountDataUpdate = data;
  const accountNameUpdate = data.accountName;
  const accountIDUpdate  = data.accountID;
  const accountDescriptionUpdate  = data.accountDescription;
  const accountIBANUpdate  = data.accountIBAN;
  const accountTypeUpdate  = data.accountType;

  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  const [allAccountStats, setAllAccountStats] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

  //Handlers User  **************************************************************************************************************************
  const handleSubmitAccount = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/accounts/edit-account', {
      payload:{
        status: false,
        userid: user._id,
      },
      _id: accountIDUpdate,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    const res2 = await axios.post('/transactions/archive-alltransaction', {
      payload:{
        isArchive: true,
      },
      accountName: accountIDUpdate[0],
      userid: user._id,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Conta Arquivada com sucesso!');
    navigate('/Accounts');
    console.log(values)

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  return (
    <Layout>
      <div className="accountpage">
        <div></div>
        <div><h1>Arquivar Conta - {accountNameUpdate}</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={handleSubmitAccount} 
          >
            <Form.Item label="Nome da Conta ou do seu Banco" name="accountName">
              <Input 
              type="text"
              defaultValue={accountNameUpdate}
              disabled="true"
              />
            </Form.Item>
            <Form.Item label="Descrição" name="accountDescription">
              <TextArea
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                defaultValue={accountDescriptionUpdate}
                disabled="true"
                />
            </Form.Item>
            <Form.Item label="IBAN" name="accountIBAN">
              <Input 
              type="text"
              defaultValue={accountIBANUpdate}
              disabled="true"
              />
            </Form.Item>
            <Form.Item label="Account Type" name="accountType">
              <Select defaultValue={accountTypeUpdate}               disabled="true">
                <Select.Option value="Conta-Corrente">Conta-Corrente</Select.Option>
                <Select.Option value="Conta-Despesas">Conta-Despesas</Select.Option>
                <Select.Option value="Cartão Alimentação">Cartão Alimentação</Select.Option>
                <Select.Option value="Cartão de Crédito">Cartão de Crédito</Select.Option>
                <Select.Option value="Conta Pagamentos">Outra</Select.Option>
              </Select>
            </Form.Item>
            <Button style={{ width: "100%" , background: "green",color:"white", marginTop: 30, marginBottom: 30}}  htmlType='submit'>
            ARQUIVAR
              </Button>
              <Link to="/Accounts">
                <Button style={{ width: "100%" }} type="primary">
                  CANCELAR
                </Button>
                </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(accountDataUpdate, null, 2) }</pre></div> */}
    </Layout>
  );
};

export default ArchiveAccounts;