import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Table, Switch,Button} from 'antd';

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {useNavigate} from "react-router-dom";

import {
  SearchOutlined,
  EditOutlined
} from "@ant-design/icons";

const FundFamily = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const { TextArea } = Input;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allFundFamily, setAllFundFamily] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Fields from table to View
  const [fundFamilyNameUpdate, setFundFamilyNameUpdate] = useState(null);
  const [descriptionUpdate, setDescriptionUpdate] = useState(null);
  const [statusUpdate, setStatusUpdate] = useState(null);

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "fundFamilyName",
      title: "Fund Family Name",
      dataIndex: "fundFamilyName",
      width: "10px",
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.fundFamilyName.localeCompare(b.fundFamilyName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.fundFamilyName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
      width: "50px",
      sorter: (a, b) => a.description.localeCompare(b.description),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.description.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "Status",
      width: "30px",
      align: "center",
      dataIndex: "status",
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Actions",
      width: "10px",
      dataIndex: "type",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
            <EditOutlined
              onClick={() => {
                    setEditable(record);
                    setFundFamilyNameUpdate(record.fundFamilyName);
                    setDescriptionUpdate(record.description);
                    setStatusUpdate(record.status);
                    setShowModal(true);
              }}
            />
          </div>
        );
      },
    }
  ];

  const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllCategory = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/fundFamilies/get-fundFamily',
        {
          // userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllFundFamily(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        // message.error('Fetch Issue With Sector');
        // message.error('You are not Admin User');
      }
    };  
  
    // Clean up the interval when the component unmounts
    return () => getAllCategory();
  },[])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitFundFamily = async (values) => {

  try{
    const promises = [];
  
    const user = JSON.parse(localStorage.getItem('user'))

    if(editable){
      setLoading(true);
      const result = axios.post('/fundFamilies/edit-fundFamily', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: editable._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Fund Family Updated Successfull');

    }else{
      setLoading(true);
      const result = axios.post('/fundFamilies/add-fundFamily', {
        ...values, 
        userid:user._id, 
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Fund Family Added Successfull');
    }

    setShowModal(false);
    setEditable(null);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);

    }catch (error){
      setLoading(false);
      // message.error('Failed to sector');
      // message.error('You are not Admin User');
    }
  }

  //Handler Test Console Log Values
  const onFinish = (values) => {
    console.log({values});
  };

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setEditable(null);
    setFundFamilyNameUpdate(null);
    setDescriptionUpdate(null);
    setStatusUpdate(null);
  };

  //Handler Close Modal
  const handleClose = () => {
    setShow(false);
  };
  

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Definições - Famílias de Fundos</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
          <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
          >+ Nova Família de Fundos
        </Button>
        </div>

      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
        
      <hr></hr>
      <div className="content">
        <Table 
        columns={columns}
        dataSource={allFundFamily}
        pagination={false}
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
        
      </div>
      {/* <div><pre>{JSON.stringify(allFundFamily, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title={editable ? "Edit Category" : "Add Category" }
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form 
            layout="vertical" 
            onFinish={handleSubmitFundFamily} 
            style={{padding:"32px 16px"}}
          >
            <Form.Item label="Fund Family Name" name="fundFamilyName">
              <Input 
                type="text"
                defaultValue={fundFamilyNameUpdate}
                />
            </Form.Item>

            <Form.Item label="Description" name="description">
              <TextArea
                showCount
                maxLength={2000}
                style={{
                  height: 400,
                  marginBottom: 24,
                }}
                type="text" 
                defaultValue={descriptionUpdate}
                />
            </Form.Item>

            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch 
                defaultChecked={statusUpdate}
              />
            </Form.Item>

            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" onClick={handleClose}>
                {" "}
                SAVE
              </button>
            </div>
            {/* <div><pre>{JSON.stringify(allCategory, null, 2) }</pre></div> */}
            
          </Form>
      </Modal>

    </Layout>
  );

};

export default FundFamily;