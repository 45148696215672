import React,{useState,useEffect} from "react";
import {Input, message,Button,Tooltip,Row,Card} from 'antd';
import MyInsurance from "./../components/Assets/insurance.png";
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { CheckCircleFilled, ClockCircleFilled, ExclamationCircleFilled, HddOutlined,SettingOutlined, UnorderedListOutlined } from "@ant-design/icons";

import  Chart  from "react-apexcharts";

const Insurances = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const categoryName= "Insurances";

  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  const [allCategory, setAllCategory] = useState([]);

  const [allInsurancesStats, setAllInsurancesStats] = useState([]);
  const [allNoInsurancesStats, setAllNoInsurancesStats] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Edit Transactions
    const [editable, setEditable] = useState(null);

    //Fields from Graphs to View
    const [allInsuranceNameWeights, setAllInsuranceNameWeights] = useState(null);
    const [allInsuranceTypeWeights, setAllInsuranceTypeWeights] = useState(null);

    //Fields from table to View
    const [accountNameUpdate, setAccountNameUpdate] = useState(null);
    const [accountDescriptionUpdate, setAccountDescriptionUpdate] = useState(null);
    const [accountIBANUpdate, setAccountIBANUpdate] = useState(null);
    const [accountTypeUpdate, setAccountTypeUpdate] = useState(null);
    const [statusUpdate, setStatusUpdate] = useState(null);

  useEffect(() => {
    const getAllUtilitiesStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-userinsurances', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllInsurancesStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllUtilitiesStats();
  },[])

  useEffect(() => {
    const getAllNoUtilitiesStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-usernoinsurances', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllNoInsurancesStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllNoUtilitiesStats();
  },[])

    //Handler Sectors DropList ****************************************************************************************
    useEffect(() => {
      const getAllCategories = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/categories/get-findCategoryName', {
            userid:user._id,
            categoryName: categoryName
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllCategory(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          ////message.error('You are not Admin User');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllCategories();
    },[categoryName])

  var categoryID = allCategory[0];

  const [weightSubject, setWeightSubject]= useState([]);
  const [weightMarks, setWeightMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllInsuranceNameWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/transactions/get-ytdinsurancename',
    {
      userid:user._id,
      categoryName:categoryID
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    setAllInsuranceNameWeights(res.data);
    console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].insuranceName);
    sMarks.push(res.data[i].amount);
    }
    setWeightSubject(sSubject);
    setWeightMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllInsuranceNameWeights();
  },[categoryID])

  const [weightInsuranceTypeSubject, setWeightInsuranceTypeSubject]= useState([]);
  const [weightInsuranceTypeMarks, setWeightInsuranceTypeMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllInsuranceTypeWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/transactions/get-ytdinsurancetype',
    {
      userid:user._id,
      categoryName:categoryID
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    setAllInsuranceTypeWeights(res.data);
    console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].insuranceType);
    sMarks.push(res.data[i].amount);
    }
    setWeightInsuranceTypeSubject(sSubject);
    setWeightInsuranceTypeMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllInsuranceTypeWeights();
  },[categoryID])

  const reload=()=>window.location.reload();

  const handleClose = () => {
    setShowModal(false)
    setAccountNameUpdate(null);
    setAccountDescriptionUpdate(null);
    setAccountIBANUpdate(null);
    setAccountTypeUpdate(null);
    setStatusUpdate(null);
    reload();
  };

  // const onFinish = (values) => {
  //   console.log({values
  //   });
  // };

  return (
    <Layout>
      {loading && <Spinner/>}
                  {/* Start Header */}
                  <div className="accountpage">
        <div></div>
        <div><h1>Meus Seguros</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
            <Button
              shape="round"
              className="btn btn-primary btn-block"
              style={{ background: "green", borderColor: "green" }}
              onClick={() => navigate('/NewInsurances')}
            >
              + Novo Seguro
            </Button>
      </div>

      <div className="accountpagebuttonsAll">
        <Button
          shape="round"
          className="btn btn-primary btn-block"
          style={{ background: "blue", borderColor: "blue" }}
          onClick={() => navigate('/TransactionsInsurancesAll')}
        >
          Todos os Movimentos
        </Button>
      </div>
        {/* Final Header */}
      <hr></hr>
      {/* Start Graphs */}
      <div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
      <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
        <div className="chart-container">
        <Chart
        align="center" 
        type="donut"
        series={weightMarks}                
        options={{
          responsive: [
            {
              breakpoint: 281, // iPhone SE width
              options: {
                chart: {
                  width: '100%',
                  height: 150 // Adjust height for iPhone SE
                }
              }
            },
            {
              breakpoint: 361, // iPhone SE width
              options: {
                chart: {
                  width: '100%',
                  height: 230 // Adjust height for iPhone SE
                }
              }
            },
            {
              breakpoint: 481, // iPhone SE width
              options: {
                chart: {
                  width: '100%',
                  height: 250 // Adjust height for iPhone SE
                }
              }
            },
            {
              breakpoint: 769, // iPhone 12 Mini width
              options: {
                chart: {
                  width: '100%',
                  height: 275 // Adjust height for iPhone 12 Mini
                }
              }
            },
            {
              breakpoint: 768, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            },
            {
              breakpoint: 1024, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            },
            {
              breakpoint: 1200, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            },
            {
              breakpoint: 2000, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            }
          ],
          labels: weightSubject,
          noData: { text: "No Data" }, 
          plotOptions: {
          pie: {
            donut: {
            labels: {
              show: true,
              name: {
              formatter: function(val) {
                return val
              }
              }
            }
            }
          }
          },
          dataLabels: { enabled: true },
          legend: { show: false } // Hide legends
        }}
        />
        </div>
        <h4 align="center">Seguros YTD</h4>
        </div>
        <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
        <div className="chart-container">
        <Chart
        align="center" 
        type="donut"
        series={weightInsuranceTypeMarks}                
        options={{
          responsive: [
            {
              breakpoint: 281, // iPhone SE width
              options: {
                chart: {
                  width: '100%',
                  height: 150 // Adjust height for iPhone SE
                }
              }
            },
            {
              breakpoint: 361, // iPhone SE width
              options: {
                chart: {
                  width: '100%',
                  height: 230 // Adjust height for iPhone SE
                }
              }
            },
            {
              breakpoint: 481, // iPhone SE width
              options: {
                chart: {
                  width: '100%',
                  height: 250 // Adjust height for iPhone SE
                }
              }
            },
            {
              breakpoint: 769, // iPhone 12 Mini width
              options: {
                chart: {
                  width: '100%',
                  height: 275 // Adjust height for iPhone 12 Mini
                }
              }
            },
            {
              breakpoint: 768, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            },
            {
              breakpoint: 1024, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            },
            {
              breakpoint: 1200, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            },
            {
              breakpoint: 2000, // iPhone 12, iPhone 14, and website width
              options: {
                chart: {
                  width: '100%',
                  height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                }
              }
            }
          ],
          labels: weightInsuranceTypeSubject,
          noData: { text: "No Data" }, 
          plotOptions: {
          pie: {
            donut: {
            labels: {
              show: true,
              name: {
              formatter: function(val) {
                return val
              }
              }
            }
            }
          }
          },
          dataLabels: { enabled: true },
          legend: { show: false } // Hide legends
        }}
        />
        </div>
        <h4 align="center">Tipo de Seguro YTD</h4>
        </div>
        </div>
      {/* Final Graphs */}
      <hr></hr>
      <div className="content">
        {/* <Table 
        columns={columns}
        dataSource={allAccount}
        pagination={false} 
        /> */}
            <Row gutter={[8,24]}     
                style={{
                // height: 200,
                justifyContent: "center"
              }}>
                  {
                  allInsurancesStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    hoverable
                    style={{ width: 350 , marginTop: 16, marginRight: "10px"}}
                    cover={[
                      <div align="center">
                        <br></br>
                        <div align="right" style={{marginRight: "20px"}}>
                        {opts.isPayedReceived
                          ? 
                          <Tooltip title="Pago">
                            <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
                          </Tooltip>
                          : 
                          <Tooltip title="Pendente">
                          <ExclamationCircleFilled className="mx-2" style={{color: "#FF3234"}}/>
                          </Tooltip>
                        }
                        </div>
                        <div>
                          <br></br>
                          <img alt="example" src={MyInsurance}/>
                        </div>
                        <div>
                          <br></br>
                          <h4 className="price">{opts._id.insuranceName}</h4>
                        </div>
                      </div>
                    ]}
                    actions={[
                      <Tooltip title="Editar">
                      <SettingOutlined key="setting" 
                      onClick={() => {
                        console.log(opts);
                        navigate('/EditInsurances',
                        {
                          state:{
                            insuranceId:opts._id.insuranceID,
                            insuranceName:opts._id.insuranceName,
                            insuranceType:opts._id.insuranceType,
                            insuranceDescription:opts._id.insuranceDescription,
                            insuranceCompany:opts._id.insuranceCompany,
                            insuranceNumber:opts._id.insuranceNumber,
                            insuranceObject:opts._id.insuranceObject,
                            insuranceObjectYear:opts._id.insuranceObjectYear,
                            frequency:opts._id.insuranceFrequency,
                            months: opts._id.insuranceMonths,
                            accountName:opts._id.accountName,
                            categoryName:opts._id.categoryName,
                            codeSquare: opts._id.codeSquare
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                      <Tooltip title="Transacções">
                      <UnorderedListOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/TransactionsProduct',
                        {
                          state:{
                            accountId:opts._id.accountName,
                            name:opts._id.insuranceName,
                            codeSquare: opts._id.codeSquare,
                            categoryName: opts._id.categoryName
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                      <Tooltip title="Arquivar">
                      <HddOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/ArchiveInsurances',
                        {
                          state:{
                            insuranceId:opts._id.insuranceID,
                            insuranceName:opts._id.insuranceName,
                            insuranceType:opts._id.insuranceType,
                            insuranceDescription:opts._id.insuranceDescription,
                            insuranceCompany:opts._id.insuranceCompany,
                            insuranceNumber:opts._id.insuranceNumber,
                            insuranceObject:opts._id.insuranceObject,
                            insuranceObjectYear:opts._id.insuranceObjectYear,
                            frequency:opts._id.insuranceFrequency,
                            months: opts._id.insuranceMonths,
                            accountName:opts._id.accountName,
                            categoryName:opts._id.categoryName,
                            codeSquare: opts._id.codeSquare
                          }
                        });
                      }}
                      />
                      </Tooltip>
                    ]}
                    >
                    <Meta
                      description={[
                            <div align="center">
                                <h4 className="price">{opts._id.insuranceType}</h4>
                            </div>
                            ]}
                          />
                      <br></br>
                      <div className="additional"  align="center">
                        <h4 className="price">Montante: <span className="quantity">{opts.amount.toLocaleString('pt-BR')} €</span></h4>
                        <hr></hr>
                        <h6 className="price">Data de Pagamento<br></br>{opts.dayMonthYearNumber}</h6>
                        {/* <p>Author: <span className="quantity">John Doe</span></p> */}
                      </div>
                    </Card>)
                  }
                  {
                  allNoInsurancesStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    hoverable
                    style={{ width: 350 , marginTop: 16, marginRight: "10px"}}
                    cover={[
                      <div align="center">
                        <br></br>
                        <div align="right" style={{marginRight: "20px"}}>
                        {opts.isPayedReceived
                          ? 
                          <Tooltip title="Pago">
                            <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
                          </Tooltip>
                          : 
                          <Tooltip title="Arquivar">
                          <ClockCircleFilled 
                          onClick={() => {
                            console.log(opts);
                            navigate('/ArchiveInsurances',
                            {
                              state:{
                                insuranceId:opts._id.insuranceID,
                                insuranceName:opts._id.insuranceName,
                                insuranceType:opts._id.insuranceType,
                                insuranceDescription:opts._id.insuranceDescription,
                                insuranceCompany:opts._id.insuranceCompany,
                                insuranceNumber:opts._id.insuranceNumber,
                                insuranceObject:opts._id.insuranceObject,
                                insuranceObjectYear:opts._id.insuranceObjectYear,
                                frequency:opts._id.insuranceFrequency,
                                months: opts._id.insuranceMonths,
                                accountName:opts._id.accountName,
                                categoryName:opts._id.categoryName,
                                codeSquare: opts._id.codeSquare
                              }
                            });
                          }}
                              />
                          </Tooltip>
                        }
                        </div>
                        <div>
                          <br></br>
                          <img alt="example" src={MyInsurance}/>
                        </div>
                        <div>
                          <br></br>
                          <h4 className="price">{opts._id.insuranceName}</h4>
                        </div>
                      </div>
                    ]}
                    actions={[
                      <Tooltip title="Arquivar">
                      <HddOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/ArchiveInsurances',
                        {
                          state:{
                            insuranceId:opts._id.insuranceID,
                            insuranceName:opts._id.insuranceName,
                            insuranceType:opts._id.insuranceType,
                            insuranceDescription:opts._id.insuranceDescription,
                            insuranceCompany:opts._id.insuranceCompany,
                            insuranceNumber:opts._id.insuranceNumber,
                            insuranceObject:opts._id.insuranceObject,
                            insuranceObjectYear:opts._id.insuranceObjectYear,
                            frequency:opts._id.insuranceFrequency,
                            months: opts._id.insuranceMonths,
                            accountName:opts._id.accountName,
                            categoryName:opts._id.categoryName,
                            codeSquare: opts._id.codeSquare
                          }
                        });
                      }}
                      />
                      </Tooltip>
                    ]}
                    >
                    <Meta
                      description={[
                            <div align="center">
                                <h4 className="price">{opts._id.insuranceType}</h4>
                            </div>
                            ]}
                          />
                      <br></br>
                      <div className="additional"  align="center">
                        <h4 className="price">Montante: <span className="quantity">0 €</span></h4>
                        <h6 className="price">Ultimo Pagamento: {opts.dayMonthYearNumber}</h6>
                        {/* <p>Author: <span className="quantity">John Doe</span></p> */}
                      </div>
                    </Card>)
                  }
            </Row>

      </div>
      {/* <div><pre>{JSON.stringify(allInsurancesStats, null, 2) }</pre></div> */}

    </Layout>
  );
};

export default Insurances;