import React,{useState,useEffect} from 'react';
import {Form,Input,Button,Space,Radio,message,Select,Row} from 'antd';

import Layout from "../components/Layout/Layout";
import {useNavigate,Link} from "react-router-dom";

import moment from 'moment';

import axios from "axios";
import Spinner from "../components/Spinner";

const NewTaxes = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const categoryName= "Taxes";

  //loading Apis
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState('')

  //DropList Category
  const [allCategory, setAllCategory] = useState([]);
  //DropList Account
  const [allAccount, setAllAccount] = useState([]);

  //Add Tags
  const [tags, setTags] = useState([]);

  //Recursive Dates Array
  const [sdates, setsDates]= useState([]);
  const [dateUpdate, setDateUpdate] = useState();

  const { TextArea } = Input;

  const [taxDetails, setTaxDetails] = useState(null);
  const [accountDetails, setAccountDetails] = useState(null);
  const [frequencyDetails, setFrequencyDetails] = useState(null);



  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user = JSON.parse(localStorage.getItem('user'))

  const addTag = (e) => {
    if (e.key === "Tab") {
      if (e.target.value.length > 0) {
        setTags([...tags, e.target.value]);
        e.target.value = "";
      }
    }
  };

       //***************************************MONTHS RECURSIVES**************************************************************/
    //Recursive Dates Array
    const [sMonth, setMonth]= useState('0');

    const handleMonths=(event)=>{
  
        setMonth([]);
    
        const getMonth= event.target.value;
    
        console.log(getMonth); //22
        console.log(sdates); //2023-01-06
    
        const dates = [];
        const currDate = moment(sdates);
    
        if (getMonth === "1" && frequencyDetails==="Mensal" ) {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else if (getMonth !== "1" && frequencyDetails==="Mensal" ){
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add(getMonth-1, 'month').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          //console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(1, "month").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else if (getMonth !== "1" && frequencyDetails==="Trimestral" ){
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add(getMonth-1, 'quarter').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          //console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(1, "quarter").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else if (getMonth !== "1" && frequencyDetails==="Semestral" ){
          //console.log(currDate); //2023-01-06
        
          let lastDate = moment(currDate).add((getMonth*2)-2, 'quarter').toDate();
          //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
          console.log(lastDate); //2028-04-06
    
          do {
          dates.push(moment(currDate).format('YYYY-MM-DD'));
          } while (currDate.add(2, "quarter").diff(lastDate) < 0);
          //dates.push(currDate.clone().toDate());
          dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
        else{
            //console.log(currDate); //2023-01-06

            let lastDate = moment(currDate).add(getMonth-1, 'year').toDate();
            //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
      
            //console.log(lastDate); //2028-04-06
      
            do {
            dates.push(moment(currDate).format('YYYY-MM-DD'));
            } while (currDate.add(1, "year").diff(lastDate) < 0);
            //dates.push(currDate.clone().toDate());
            dates.push(moment(currDate).format('YYYY-MM-DD'));
        }
    
        message.success(dates);
        console.log(dates);
    
        setMonth(dates);
      }

    const worker = {
    dates: moment(dateUpdate),
    };

    //Handler Fund Family DropList ****************************************************************************************
    useEffect(() => {
      const getAllAccounts = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/accounts/get-account', {
            userid:user._id,
            status: true,
            isCreditCard: "all"
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllAccount(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          //message.error('You are not Admin User');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllAccounts();
    },[])
  
    //Handler Sectors DropList ****************************************************************************************
    useEffect(() => {
      const getAllCategories = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/categories/get-findCategoryName', {
            userid:user._id,
            categoryName: categoryName
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllCategory(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          //message.error('You are not Admin User');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllCategories();
    },[categoryName])

var categoryID = allCategory[0];

let range = {min: 1, max: 999999999}
let delta = range.max - range.min

const codeSquare = Math.round(range.min + Math.random() * delta)

  const submitHandler = async (values) => {

    try{
      setLoading(true);

      for (const sdate of sMonth) {
        const res = axios.post('/transactions/add-transaction', {
          amount: values.amount, 
          isIncomeOrExpense:false,
          date: sdate,
          userid:user._id,
          months: values.months, 
          monthYear: moment(sdate).format("MMM YYYY"),
          monthYearNumber: moment(sdate).format("YYYYMM"),
          dayMonthYearNumber: moment(sdate).format("YYYY-MM-DD"),
          accountName:accountDetails,
          categoryName:categoryID.id,
          squareName: values.taxDescription,
          codeSquare: codeSquare,
          tags: values.tags,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
      }
      const res2 = axios.post('/taxes/add-tax', {
        userid:user._id,
        taxName:values.taxName,
        taxType:taxDetails,
        taxDescription:values.taxDescription,
        frequency:frequencyDetails,
        months: values.months,
        accountName:accountDetails,
        categoryName:categoryID.id,
        codeSquare: codeSquare
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Contribuição Fiscal Criada com Sucesso!');
      setTimeout(function () {
        window.location.reload(true);
      }, 1000);
      navigate('/Taxes');
    }catch (error){
      setLoading(false);
      message.error('Algo deu errado!');
    }
  }

  //Handler Test Console Log Values
  const onFinish = (values) => {
    console.log({
      userid:user._id,
      taxName:values.taxName,
      taxType:taxDetails,
      taxDescription:values.taxDescription,
      frequency:frequencyDetails,
      accountName:accountDetails,
      categoryName:categoryID.id,
      isIncomeOrExpense:false,
      date: values.date,
      amount: values.amount,
      monthyear: moment(values.date).format("MMM YYYY"),
      monthyearnumber: moment(values.date).format("YYYYMM"),
      tags: values.tags,
      codeSquare: codeSquare
    });
  };

  const handleDate=(event)=>{
    setsDates([]);
    const getDates= moment(event.target.value);
    setsDates(getDates);
    console.log(getDates);

    // novo
    setMonth([]);
    const dates = [];
    dates.push(moment(getDates).format('YYYY-MM-DD'));
    message.success(dates);
    setMonth(dates);
  }

  const onChangeAccount = ({ target: { value } }) => {
    console.log('Account choose: ', value);
    setAccountDetails(value);
  };

  const onChangeTax = ({ target: { value } }) => {
    console.log('Tax choose: ', value);
    setTaxDetails(value);
  };

  const onChangeFrequency = state => {
    console.log('Frequency choose: ',state);
    setFrequencyDetails(state);
  };

  return (
    <Layout>
      {loading && <Spinner/>}
                  <div className="accountpage">
        <div></div>
        <div><h1>Nova Contribuição Fiscal</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={submitHandler} 
          >
            <Space direction="horizontal">
            <Form.Item style={{ marginTop:10 }} label="Valor do imposto a pagar" name="amount">
              <Input
                suffix="€"
                placeholder='0.00'
                type="text" 
                size="large" 
                style={{fontSize:"32px"}}
              />
            </Form.Item>
            <Form.Item style={{ marginTop:10 }} label="Moeda"  name="currency">
                  <Input style={{fontSize:"32px", width: "100px"}} defaultValue={"EUR"} disabled="true"/>
            </Form.Item>
            </Space>
            <Form.Item label="Data" name="date" onChange={(e)=>handleDate(e)}
                rules={[
                  { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                    ]}>
                <Input style={{fontSize:"18px"}} type="date"/>
            </Form.Item>

            <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da Contribuição Fiscal</h5>

            <Form.Item label="" name="taxName">
              <Input 
              type="text"
              />
            </Form.Item>

            <h5 style={{ marginBottom:30, marginTop:30 }}>Tipo de Imposto</h5>

            <Radio.Group onChange={onChangeTax} style={{ display: "flex", justifyContent: "left"}} >
              <Space direction="vertical" align='center' size="middle">
                <Row gutter={[8,24]}>
                <Radio.Button value="IUC" style={{display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>IUC</Radio.Button>
                  <Radio.Button value="IMI" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>IMI</Radio.Button>
                  <Radio.Button value="IMT" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>IMT</Radio.Button>
                  <Radio.Button value="IVA" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>IVA</Radio.Button>
                  <Radio.Button value="IRS" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>IRS</Radio.Button>
                  <Radio.Button value="IRC" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>IRC</Radio.Button>
                  <Radio.Button value="Segurança Social" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%" }}>Segurança Social</Radio.Button>
                  <Radio.Button value="Outra" style={{ display: "flex", textAlign:"center", justifyContent: "center", width:"100%",height:"100%"  }}>Outra</Radio.Button>
                </Row>
              </Space>
            </Radio.Group>

            <h5 style={{ marginBottom:30, marginTop:30 }}>Conta Utilizada</h5>

            <Radio.Group onChange={onChangeAccount} style={{ display: "flex", justifyContent: "left"}} >
              <Space direction="vertical" align='center' size="middle">
              <Row gutter={[8,24]}>
              {
                      allAccount.map((opts,i)=>
                        <Radio.Button style={{ display: "flex", justifyContent: "center", width:"100%", marginTop: 5 }} 
                        key={i} value={`${opts._id}|${opts.isCreditCard}`}>
                          {opts.accountName}
                        </Radio.Button>)
                      }
              </Row>
              </Space>
            </Radio.Group>

            <Form.Item style={{ marginTop:30 }} label="Observações" name="taxDescription">
              <TextArea
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                />
            </Form.Item>

            <Form.Item label="Tags" name="tags">
              <Select
                mode="tags"
                style={{
                  width: '100%',
                }}
                placeholder="Tags Mode"
                onChange={addTag}
                >
              </Select>
            </Form.Item>

            <Form.Item label="Frequência" name="frequency">
              <Select onChange={onChangeFrequency}>
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Número de frequências" name="months" onChange={(e)=>handleMonths(e)} 
                rules={[
                  {
                    required: false, message: "Por favor, insira o número de frequências que vai ter com a sua contribuição fiscal!",
                    min: 1,
                    max: 999,
                  }
                    ]}>
                <Input min={1} defaultValue={1}/>
            </Form.Item>

            {/* <Form.Item label="Viagem?" name="isTravel" valuePropName="checked">
              <Switch checkedChildren="Sim" unCheckedChildren="Não" />
            </Form.Item> */}

                <Button style={{ width: "100%", background:"green" }} type="primary" htmlType='submit'>
                    CRIAR
                    </Button>
                <Link to="/Taxes">
                <Button style={{ width: "100%", marginTop: 30 }} type="primary">
                  CANCELAR
                </Button>
                </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(allExpenses, null, 2) }</pre></div> */}
    </Layout>
  )
  };

export default NewTaxes;