import React,{useState,useEffect} from "react";
import {message, Table, Button} from 'antd';
import {useNavigate,useLocation} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import moment from 'moment';

import  Chart  from "react-apexcharts";

const PortfolioExchanges = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // const { Meta } = Card;

  // const [showModal, setShowModal] = useState(false);

  //Fields from Graphs to View
  // const [allBalanceExchangeAccountWeights, setAllBalanceExchangeAccountWeights] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  // const exchangeDataUpdate = data;
  const exchangeIdUpdate = data.exchangeID;
  const exchangeNameUpdate = data.exchangeName;
  const currencyUpdate = data.currency;

  const [loading,setLoading] = useState(false);

  const [allSubscription, setAllSubscription] = useState([]);
  // const [allAccount, setAllAccount] = useState([]);

  // const [allAccountStats, setAllAccountStats] = useState([]);

  // const [show, setShow] = useState(false);

  // const { TextArea } = Input;

    //Edit Transactions
    // const [editable, setEditable] = useState(null);

  //table accounts
  const columns =[
    {
      key: "date_refreshed",
      title: "Data de Actualização",
      dataIndex: "date_refreshed",
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.date_refreshed) - new Date(b.date_refreshed)
    },
    {
      key: "symbol",
      title: "Símbolo",
      dataIndex: ["symbol"],
      sorter: (a, b) => a.symbol.localeCompare(b.symbol),
    },
    {
      key: "qty",
      title: "Quantidade",
      dataIndex: "qty",
      align: "right",
      sorter: (a, b) => a.qty-b.qty,
      render: (qty, record) => {
        if(record.qty >= 0 )
          return (
          <span style={{color: "black"}}>{qty.toLocaleString('pt-PT')}</span>
          );
      },
    },
    {
      key: "locked",
      title: "Quantidade Cativa",
      dataIndex: "locked",
      align: "right",
      sorter: (a, b) => a.locked-b.locked,
    },
    {
      key: "today_price",
      title: "Cotação Base",
      dataIndex: "today_price",
      align: "right",
      sorter: (a, b) => a.today_price -b.today_price,
      render: (today_price, record) => {
        if(record.today_price >= 0 )
          return (
          <span style={{color: "black"}}>{today_price.toLocaleString('pt-PT')}</span>
          );
      },
    },
    {
      key: "currency",
      title: "Moeda Base",
      dataIndex: ["currency"],
    },
    {
      key: "today_value",
      title: "Valor Base",
      dataIndex: "today_value",
      align: "right",
      sorter: (a, b) => a.today_value-b.today_value,
      render: (today_value, record) => {
        if(record.today_value >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(today_value).toFixed(2)}</span>
          );
    },
    },
    {
      key: "currency",
      title: "Moeda Base",
      dataIndex: ["currency"],
    },
    {
      key: "today_price_ctr",
      title: "Cotação",
      dataIndex: "today_price_ctr",
      align: "right",
      sorter: (a, b) => a.today_price_ctr -b.today_price_ctr,
      render: (today_price_ctr, record) => {
        if(record.today_price_ctr >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(today_price_ctr).toFixed(2)}</span>
          );
    },
    },
    {
      key: "currency_ctr",
      title: "Moeda",
      dataIndex: ["currency_ctr"],
    },
    {
      key: "today_value_ctr",
      title: "Valor",
      dataIndex: "today_value_ctr",
      align: "right",
      sorter: (a, b) => a.today_value_ctr-b.today_value_ctr,
      render: (today_value_ctr, record) => {
        if(record.today_value_ctr >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(today_value_ctr).toFixed(2)}</span>
          );
    },
    },
    {
      key: "currency_ctr",
      title: "Moeda",
      dataIndex: ["currency_ctr"],
    },
    {
      key:"weights_pct",
      title: "Peso%",
      dataIndex: "weights_pct",
      defaultSortOrder: 'descend',
      align: 'right',
      sorter: (a, b) => a.weights_pct - b.weights_pct,
      render: (weights_pct, record) => {
        if(record.weights_pct >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(weights_pct).toFixed(2)} %</span>
          );
      },
  },
    // {
    //   title: "Acções",
    //   dataIndex: "type",
    //   width: "5%",
    //   render: (type, record) => {
    //     // if(type === "Income" )
    //     return (
    //       <div className="mx-4">
    //         <Space size="middle">
    //         <EditOutlined
    //           onClick={() => {
    //             setEditable(record);
    //             setExchangeNameUpdate(record.exchangeName.exchangeName);
    //             setBrokerNameUpdate(record.exchangeName.brokerName);
    //             setFrequencyUpdate(record.exchangeName.frequency);
    //             setStatusUpdate(record.status);
    //             setExchangeDescriptionUpdate(record.exchangeName.exchangeDescription);
    //             setExchangeIdUpdate(record.exchangeName._id);
    //             setCashExchangeIdUpdate(record._id);
    //             setShowModal(true);
    //           }}
    //         />
    //         <DeleteOutlined
    //           onClick={() => {
    //             // console.log(record);
    //             // navigate('/Transactions',
    //             // {
    //             //   state:{
    //             //     accountId: record.id,
    //             //     accountDescription: record.accountName           
    //             //   }
    //             // });
    //           }}
    //         />
    //         </Space>
    //       </div>
    //     );
    //   },
    // }
  ];

  //useEffect Hook
  useEffect(() => {
    const getAllSubscription = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/portfolio-exchanges/get-portfolio-exchange', {
          userid:user._id,
          exchangeName: exchangeIdUpdate
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSubscription(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllSubscription();
  },[exchangeIdUpdate])

  const [weightSubject, setWeightSubject]= useState([]);
  const [weightMarks, setWeightMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllLoanNameWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/transactions/get-balanceexchangeaccount',
    {
      userid:user._id,
      exchangeName: exchangeIdUpdate
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    // setAllBalanceExchangeAccountWeights(res.data);
    console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].cryptoAssetName);
    sMarks.push(res.data[i].amount);
    }
    setWeightSubject(sSubject);
    setWeightMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllLoanNameWeights();
  },[exchangeIdUpdate])
    
  // //Handlers User  **************************************************************************************************************************
  // const handleSubmitAccount = async (values) => {

  // try{
  
  //   const user = JSON.parse(localStorage.getItem('user'))

  //   setLoading(true);
  //   const res = await axios.post('/exchanges/edit-exchange', {
  //     payload:{
  //       archive: false,
  //       status: true,
  //       userid: user._id,
  //     },
  //     _id: exchangeIdUpdate,
  //   },
  //   {
  //     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //   }
  //   );
  //   const res2 = await axios.post('/cash-exchanges/edit-cash-exchange', {
  //     payload:{
  //       archive: false,
  //       userid: user._id,
  //     },
  //     _id: cashExchangeIdUpdate,
  //   },
  //   {
  //     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //   }
  //   );
  //   setLoading(false);
  //   message.success('Conta Wallet Activada novamente com sucesso!');
  //   setShowModal(false);
  //   setTimeout(function () {
  //     window.location.reload(true);
  //   }, 1000);
  //   console.log(values)
  //   }catch (error){
  //     setLoading(false);
  //     // message.error('Failed to industry');
  //     //message.error('You are not Admin User');
  //   }
  // }

  return (
    <Layout>
      {loading && <Spinner/>}
            <div className="accountpage">
          <div>
          </div>
          <div><h1> Portfolio Exchange - {exchangeNameUpdate} - {currencyUpdate} </h1></div>
          <div></div>
        </div>
        <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
        <br></br>
      {/* Start Graphs */}
      <div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
      <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
        <div className="chart-container">
        <Chart
          align="center" 
          type="donut"
          width="100%"
          series={weightMarks}                
          options={{
            responsive: [
              {
                breakpoint: 281, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 150 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 361, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 230 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 481, // iPhone SE width
                options: {
                  chart: {
                    width: '100%',
                    height: 250 // Adjust height for iPhone SE
                  }
                }
              },
              {
                breakpoint: 769, // iPhone 12 Mini width
                options: {
                  chart: {
                    width: '100%',
                    height: 275 // Adjust height for iPhone 12 Mini
                  }
                }
              },
              {
                breakpoint: 768, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1024, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 1200, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              },
              {
                breakpoint: 2000, // iPhone 12, iPhone 14, and website width
                options: {
                  chart: {
                    width: '100%',
                    height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                  }
                }
              }
            ],
          labels: weightSubject,
          noData: { text: "No Data" }, 
          plotOptions: {
            pie: {
            donut: {
              labels: {
              show: true,
              name: {
                formatter: function(val) {
                return val
                }
              }
              }
            }
            }
          },
          dataLabels: { enabled: true },
          legend: { show: false } // Hide legends
          }}
        />
        </div>
        <h4 align="center">Exchanges Total</h4>
      </div>
      </div>
      {/* Final Graphs */}

      <div className="content">

        <Table
          size="small"
          columns={columns}
          dataSource={allSubscription}
          pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
          scroll={{ x: 'max-content'}}
          className="custom-table" // Apply custom CSS class for styling
        />
      </div>

      {/* <div><pre>{JSON.stringify(allSubscription, null, 2) }</pre></div> */}

      {/* <Modal
        destroyOnClose={true} 
        title="Activar Conta Exchange"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleSubmitAccount} style={{padding:"32px 16px"}}>
          <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da Conta Exchange</h5>

          <Form.Item label="Nome da Conta Exchange" name="exchangeName">
            <Input defaultValue={exchangeNameUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <h5 style={{ marginBottom:30, marginTop:30 }}>Broker</h5>

          <Form.Item label="" name="brokerName">
            <Input defaultValue={brokerNameUpdate} 
            disabled="true"
            type="text"
            />
          </Form.Item>

          <Form.Item style={{ marginTop:30 }} label="Observações" name="exchangeDescription">
            <TextArea defaultValue={exchangeDescriptionUpdate} 
            disabled="true"
              showCount
              maxLength={500}
              style={{
                height: 100,
                marginBottom: 24,
              }}
              type="text"
              />
          </Form.Item>

          <Form.Item label="Frequência" name="frequency">
            <Select defaultValue={frequencyUpdate} 
            disabled="true">
              <Select.Option value="Mensal">Mensal</Select.Option>
              <Select.Option value="Trimestral">Trimestral</Select.Option>
              <Select.Option value="Semestral">Semestral</Select.Option>
              <Select.Option value="Anual">Anual</Select.Option>
            </Select>
          </Form.Item>
          
            <div>
              <button 
              type="submit" 
              className="btn btn-primary" 
              onClick={handleClose}
               style={{
                  width: '100%',
                }}>
                {" "}
                ACTIVAR CONTA EXCHANGE NOVAMENTE
              </button>
            </div>
          </Form>
      </Modal> */}

    </Layout>
  );
};

export default PortfolioExchanges;