import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Select,Switch, Button, Space,Tooltip,Row,Card} from 'antd';
import MyCreditCard from "./../components/Assets/credit-card.png";
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { EditOutlined, HddOutlined, PieChartOutlined, SearchOutlined, SettingOutlined, UnorderedListOutlined } from "@ant-design/icons";

import moment from 'moment';

const CreditCards = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  const [allAccountStats, setAllAccountStats] = useState([]);
  const [allNoAccountStats, setAllNoAccountStats] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Edit Transactions
    const [editable, setEditable] = useState(null);

    //Fields from table to View
    const [accountNameUpdate, setAccountNameUpdate] = useState(null);
    const [accountDescriptionUpdate, setAccountDescriptionUpdate] = useState(null);
    const [accountIBANUpdate, setAccountIBANUpdate] = useState(null);
    const [accountTypeUpdate, setAccountTypeUpdate] = useState(null);
    const [statusUpdate, setStatusUpdate] = useState(null);

  //table accounts
  const columns =[
    {
      key: "createdAt",
      title: "Data de criação",
      dataIndex: "createdAt",
      width: "5%",
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      key: "accountName",
      title: "Conta",
      dataIndex: "accountName",
      width: "5%",
      sorter: (a, b) => a.accountName.localeCompare(b.accountName),
    },
    {
      key: "accountDescription",
      title: "Descrição",
      dataIndex: "accountDescription",
      width: "5%",
      sorter: (a, b) => a.accountDescription.localeCompare(b.accountDescription),
    },
    {
      key: "accountType",
      title: "Tipo de Conta",
      dataIndex: "accountType",
      width: "5%",
      sorter: (a, b) => a.accountType.localeCompare(b.accountType),
    },
    {
      key: "accountIBAN",
      title: "IBAN",
      dataIndex: "accountIBAN",
      width: "5%"
    },
    {
      key: "status",
      title: "Estado",
      dataIndex: "status",
      width: "5%",
      sorter: (a, b) => a.status - b.status,
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Acções",
      dataIndex: "type",
      width: "5%",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-4">
            <Space size="middle">
            <EditOutlined
              onClick={() => {
                setEditable(record);
                setAccountNameUpdate(record.accountName);
                setAccountDescriptionUpdate(record.accountDescription);
                setAccountIBANUpdate(record.accountIBAN);
                setAccountTypeUpdate(record.accountType);
                setStatusUpdate(record.status);
                setShowModal(true);
              }}
            />
            <UnorderedListOutlined
              onClick={() => {
                console.log(record);
                navigate('/Transactions',
                {
                  state:{
                    accountId: record.id,
                    accountDescription: record.accountName,
                    isCreditCard: "true",        
                  }
                });
              }}
            />
            </Space>
          </div>
        );
      },
    }
  ];

  useEffect(() => {
    const getAllAccountStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-userbalance', {
          userid:user._id,
          isCreditCard: true,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllAccountStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        // //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllAccountStats();
  },[])

  useEffect(() => {
    const getAllNoAccountStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/transactions/get-usernobalance', {
          userid:user._id,
          isCreditCard: true,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllNoAccountStats(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
        // //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllNoAccountStats();
  },[])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitAccount = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/accounts/edit-account', {
      payload:{
        ...values,
        userid: user._id,
      },
      _id: editable._id.accountStats,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Conta Actualizada com sucesso!');
    setShowModal(false);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);
    console.log(values)

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  const reload=()=>window.location.reload();

  const handleClose = () => {
    setShowModal(false)
    setAccountNameUpdate(null);
    setAccountDescriptionUpdate(null);
    setAccountIBANUpdate(null);
    setAccountTypeUpdate(null);
    setStatusUpdate(null);
    reload();
  };

  // const onFinish = (values) => {
  //   console.log({values
  //   });
  // };

  return (
    <Layout>
      {loading && <Spinner/>}
                  {/* Start Header */}
                  <div className="accountpage">
        <div></div>
        <div><h1>Meus Cartões de Crédito</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
            <Button
              shape="round"
              className="btn btn-primary btn-block"
              style={{ background: "green", borderColor: "green" }}
              onClick={() => navigate('/NewCreditCards')}
            >
              + Novo Cartão de Crédito
            </Button>
      </div>

      <div className="accountpagebuttonsAll">
        <Button
          shape="round"
          className="btn btn-primary btn-block"
          style={{ background: "blue", borderColor: "blue" }}
          onClick={() => navigate('/TransactionsAll', 
          {state:{
            isCreditCard: true}
          })}
        >
          Todos os Movimentos
        </Button>
      </div>
        {/* Final Header */}

      <hr></hr>
      <div className="content">
        {/* <Table 
        columns={columns}
        dataSource={allAccount}
        pagination={false} 
        /> */}
            <Row gutter={[8,24]}     
                style={{
                // height: 200,
                justifyContent: "center"
              }}>
                  {
                  allAccountStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    hoverable
                    style={{ width: 350 , marginTop: 16, marginRight: "10px"}}
                    cover={[
                      <div align="center">
                        <div>
                          <br></br>
                          <img alt="example" src={MyCreditCard}/>
                        </div>
                        <div>
                          <br></br>
                          <h4 className="price">{opts._id.accountName}</h4>
                        </div>
                      </div>
                    ]}
                    actions={[
                      <Tooltip title="Editar">
                      <SettingOutlined key="setting" 
                      onClick={() => {
                        console.log(opts);
                        navigate('/EditAccounts',
                        {
                          state:{
                            accountID: opts._id.accountID ,
                            accountName: opts._id.accountName,
                            accountDescription:  opts._id.accountDescription,
                            accountIBAN:  opts._id.accountIBAN,   
                            accountType:  opts._id.accountType,
                            userID: opts._id.userid
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                      <Tooltip title="Transacções">
                      <UnorderedListOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/Transactions',
                        {
                          state:{
                            accountId: opts._id.accountID,
                            accountDescription: opts._id.accountName,
                            isCreditCard: opts._id.isCreditCard,
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                        <Tooltip title="Estatísticas">
                        <PieChartOutlined
                        onClick={() => {
                          console.log(opts);
                          navigate('/TransactionsGraphs',
                          {
                            state:{
                              accountId: opts._id.accountID,
                              accountDescription: opts._id.accountName,
                              isCreditCard: opts._id.isCreditCard,
                            }
                          });
                        }}
                        />
                        </Tooltip>,
                      <Tooltip title="Arquivar">
                      <HddOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/ArchiveAccounts',
                        {
                          state:{
                            accountID: opts._id.accountID ,
                            accountName: opts._id.accountName,
                            accountDescription:  opts._id.accountDescription,
                            accountIBAN:  opts._id.accountIBAN,   
                            accountType:  opts._id.accountType,
                            userID: opts._id.userid
                          }
                        });
                      }}
                      />
                      </Tooltip>
                    ]}
                    >
                    <Meta
                      description={[
                            <div align="center">
                                <h4 className="price">{opts._id.accountType}</h4>
                            </div>
                            ]}
                          />
                      <br></br>
                      <div className="additional"  align="center">
                        <h4 className="price">Saldo: <span className="quantity">{opts.balance.toLocaleString('pt-BR')} €</span></h4>
                        {/* <p>Author: <span className="quantity">John Doe</span></p> */}
                      </div>
                    </Card>)
                  }

                  {
                  allNoAccountStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    hoverable
                    style={{ width: 350 , marginTop: 16, marginRight: "10px"}}
                    cover={[
                      <div align="center">
                        <div>
                          <br></br>
                          <img alt="example" src={MyCreditCard}/>
                        </div>
                        <div>
                          <br></br>
                          <h4 className="price">{opts._id.accountName}</h4>
                        </div>
                      </div>
                    ]}
                    actions={[
                      <Tooltip title="Arquivar">
                      <HddOutlined
                      onClick={() => {
                        console.log(opts);
                        navigate('/ArchiveAccounts',
                        {
                          state:{
                            accountID: opts._id.accountID ,
                            accountName: opts._id.accountName,
                            accountDescription:  opts._id.accountDescription,
                            accountIBAN:  opts._id.accountIBAN,   
                            accountType:  opts._id.accountType,
                            userID: opts._id.userid
                          }
                        });
                      }}
                      />
                      </Tooltip>
                    ]}
                    >
                    <Meta
                      description={[
                            <div align="center">
                              <h4 className="price">{opts._id.accountType}</h4>
                            </div>
                            ]}
                          />
                      <br></br>
                      <div className="additional"  align="center">
                        <h4 className="price">Saldo: <span className="quantity">0 €</span></h4>
                        {/* <p>Author: <span className="quantity">John Doe</span></p> */}
                      </div>
                    </Card>)
                  }
            </Row>

      </div>
      {/* <div><pre>{JSON.stringify(allAccountStats, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title="Editar Cartão de Crédito"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleSubmitAccount} style={{padding:"32px 16px"}}>
            <Form.Item label="Nome da Conta ou do seu Banco" name="accountName">
              <Input 
              type="text"
              defaultValue={accountNameUpdate}
              />
            </Form.Item>
            <Form.Item label="Descrição" name="accountDescription">
              <TextArea
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                defaultValue={accountDescriptionUpdate}
                />
            </Form.Item>
            <Form.Item label="IBAN" name="accountIBAN">
              <Input 
              type="text"
              defaultValue={accountIBANUpdate}
              />
            </Form.Item>
            <Form.Item label="Account Type" name="accountType">
              <Select defaultValue={accountTypeUpdate}>
                <Select.Option value="Conta-Corrente">Conta-Corrente</Select.Option>
                <Select.Option value="Conta-Despesas">Conta-Despesas</Select.Option>
                <Select.Option value="Cartão Alimentação">Cartão Alimentação</Select.Option>
                <Select.Option value="Cartão de Crédito">Cartão de Crédito</Select.Option>
                <Select.Option value="Conta Pagamentos">Outra</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Estado da Conta" name="status" valuePropName="checked">
              <Switch defaultChecked={statusUpdate}/>
            </Form.Item>
            <div>
              <button 
              type="submit" 
              className="btn btn-primary" 
              onClick={handleClose}
               style={{
                  width: '100%',
                }}>
                {" "}
                GRAVAR
              </button>
            </div>
          </Form>
          {/* <div><pre>{JSON.stringify(allAccountStats, null, 2) }</pre></div> */}
      </Modal>

    </Layout>
  );
};

export default CreditCards;