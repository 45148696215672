import React,{useState,useEffect} from "react";
import {Input, Table, Select, Button} from 'antd';
import {useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {
  SearchOutlined,
} from "@ant-design/icons";

import moment from 'moment';

const FundFamilyPrices = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  // const { TextArea } = Input;

  // const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allFuturePrices, settAllFuturePrices] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Filters
  const [futureType, setfutureTypeFilter] = useState('all');

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "date",
      title: "Última Data Actualização",
      dataIndex: "date",
      // defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.dateRefresh) - new Date(b.dateRefresh)
    },
    {
      key: "futureAssetName",
      title: "Activo",
      dataIndex: ["futureAssetName"],
      // defaultSortOrder: 'ascend',
      sorter: (a, b) => a.futureAssetName.localeCompare(b.futureAssetName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.futureAssetName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "yahooTicker",
      title: "Yahoo Ticker",
      dataIndex: "yahooTicker",
      sorter: (a, b) => a.yahooTicker.localeCompare(b.yahooTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.yahooTicker.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "futureType",
      title: "Classe Futuro",
      dataIndex: "futureType",
      sorter: (a, b) => a.futureType.localeCompare(b.futureType),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.futureType.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "expirationMonth",
      title: "Data Expiração",
      dataIndex: ["expirationMonth"],
      sorter: (a, b) => a.expirationMonth.localeCompare(b.expirationMonth),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.expirationMonth.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key:"value",
      title: "Preço",
      dataIndex: "value",
      align: 'right',
      sorter: (a, b) => a.value - b.value,
    },
    {
      key: "currency",
      title: "",
      dataIndex: ["currency"],
      sorter: (a, b) => a.currency.localeCompare(b.currency),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.currency.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key:"returnPercentage",
      title: "Retorno%",
      dataIndex: "returnPercentage",
      defaultSortOrder: 'descend',
      align: 'right',
      sorter: (a, b) => a.returnPercentage - b.returnPercentage,
      render: (returnPercentage, record) => {
        if(record.returnPercentage >= 0 )
          return (
          <span style={{color: "#39A939"}}>{returnPercentage.toLocaleString('pt-BR')} %</span>
          );
          return (
          <span style={{color: "#FF3234"}}>{returnPercentage.toLocaleString('pt-BR')} %</span>
          );
      },
  },
  ];

  const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllFuturePrices = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/futurePrices/get-futurePrice',
        {
          futureType: futureType,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        settAllFuturePrices(res.data);
        console.log(res.data);
      }catch (error){
        setLoading(false);
      }
    };  
    
    getAllFuturePrices();

  },[futureType])


  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>Cotações - Futuros</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            align="right"
            shape="round"
            className="btn btn-primary"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
            >Voltar
          </Button>
        </div>

      <div className="accountpage">
        <div className="vertical-select">
            <h6>Classe Activos</h6>
            <Select 
            style={{
              width: '180px',
            }} 
            value={futureType} onChange={(values) => setfutureTypeFilter(values)}>
              <Select.Option value='all'>Todos</Select.Option>
              <Select.Option value="Indices">Indices</Select.Option>
              <Select.Option value="Commodities">Commodities</Select.Option>
              <Select.Option value="Yields">Yields</Select.Option>
            </Select>
          </div>
      </div>
      <hr></hr>
      <div className="content">
        <Table
          // size="small"
          columns={columns}
          dataSource={allFuturePrices}
          pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
          scroll={{ x: 'max-content'}}
          className="custom-table" // Apply custom CSS class for styling
        />
        
      </div>

      {/* <div><pre>{JSON.stringify(allStockAsset, null, 2) }</pre></div> */}

    </Layout>
  );
};

export default FundFamilyPrices;