import React, { useState } from 'react';
import Navbar from "./Navbar";
import Header from "./Header";
import mainLogo from'./Logo.png';
import { BrowserRouter as Router, Route, Link, useNavigate } from "react-router-dom";

import {
  AlertOutlined,
  BankOutlined,
  CarOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  PieChartOutlined,
  EuroOutlined,
  InsuranceOutlined,
  LogoutOutlined,
  RocketOutlined,
  SaveOutlined,
  SearchOutlined,
  SettingOutlined,
  ShoppingOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme, message } from 'antd';

const { Sider, Content } = Layout;

const LayoutSide = ({ children, visible }) => {

  let now=('v.2024.2.2');

  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(true);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const DashboardHandler = () => {
    navigate('/');
  }

  const AccountsHandler = () => {
    navigate('/Accounts');
  }

  const TransactionsStatsHandler = () => {
    navigate('/TransactionsStats');
  }
  
  const SubscriptionsHandler = () => {
    navigate('/Subscriptions');
  }

  const UtilitiesHandler = () => {
    navigate('/Utilities');
  }

  const InsurancesHandler = () => {
    navigate('/Insurances');
  }

  const CreditCardsHandler = () => {
    navigate('/CreditCards');
  }

  const LoansHandler = () => {
    navigate('/Loans');
  }

  const TaxesHandler = () => {
    navigate('/Taxes');
  }

  const WalletsHandler = () => {
    navigate('/Wallets');
  }

  const ExchangesHandler = () => {
    navigate('/Exchanges');
  }

  const InvestmentsHandler = () => {
    navigate('/Investments');
  }
  
  const ExplorerHandler = () => {
    navigate('/Explorer');
  }

  const ExplorerSettingsHandler = () => {
    navigate('/ExplorerSettings');
  }

  const logoutHandler = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('investorProfile');
    message.success("Logout Successfully");
    navigate('/login');
  }

  return (
    <Layout
    style={{
      minHeight: '1000vh',
    }}
    >
      <Sider
        theme="light"
        width={280}
        collapsible 
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
          <div className="main-logo" style={{ height: '10px', margin: '25px'}}>
            {/* <h3 style={{color:"black"}}>FIONIC SYSTEMS</h3> */}
          </div>
          {/* <div align="right" style={{ paddingRight: '10px'}}>
            <Dropdown overlay={widgetMenu}>
              <PlusSquareOutlined style={{color: "black"}} />
            </Dropdown>
          </div> */}
          <Menu
            theme="light"
            // defaultSelectedKeys={['1']}
            // mode="inline"
          >
          <hr></hr>
          <Menu.Item key="1" icon={<DashboardOutlined/>} onClick={DashboardHandler}>
                Dashboard
          </Menu.Item>
          <Menu.Item key="100" icon={<BankOutlined/>} onClick={AccountsHandler}>
                Contas
          </Menu.Item>
          <Menu.Item key="1400" icon={<PieChartOutlined/>} onClick={TransactionsStatsHandler}>
                Estatísticas
          </Menu.Item>
          <Menu.Item key="200" icon={<SearchOutlined/>} onClick={ExplorerHandler}>
                Explorador
          </Menu.Item>
          <Menu.Item key="300" icon={<SaveOutlined/>} onClick={InvestmentsHandler}>
                Poupanças & Investimentos
          </Menu.Item>
          <Menu.Item key="400" icon={<CarOutlined/>} onClick={LoansHandler}>
                Empréstimos
          </Menu.Item>
          <Menu.Item key="500" icon={<CreditCardOutlined/>} onClick={CreditCardsHandler}>
                Cartões de Crédito
          </Menu.Item>
          <Menu.Item key="600" icon={<InsuranceOutlined/>} onClick={InsurancesHandler}>
                Seguros
          </Menu.Item>
          <Menu.Item key="700" icon={<ShoppingOutlined/>} onClick={UtilitiesHandler}>
                Serviços Utilities & Comunicações
          </Menu.Item>
          <Menu.Item key="800" icon={<RocketOutlined/>} onClick={SubscriptionsHandler}>
                Subscrições
          </Menu.Item>
          <Menu.Item key="1100" icon={<AlertOutlined/>} onClick={TaxesHandler}>
                Contribuições Fiscais
          </Menu.Item>
          <Menu.Item key="900" icon={<EuroOutlined/>} onClick={ExchangesHandler}>
                Exchanges
          </Menu.Item>
          {/* <Menu.Item  disabled="true" key="1000" icon={<HeartOutlined/>}>
                Caridade
          </Menu.Item> */}
          {/* {visible && ( */}
          <Menu.Item key="1200" icon={<WalletOutlined/>} onClick={WalletsHandler}>
                Wallets
          </Menu.Item>
          {/* )} */}
          <hr></hr>
          <Menu.Item key="1300" icon={<SettingOutlined/>} onClick={ExplorerSettingsHandler}>
                Settings
          </Menu.Item>
          <hr></hr>
          <Menu.Item key="99999" icon={<LogoutOutlined/>} onClick={logoutHandler}>
                Sign Out
          </Menu.Item>
        </Menu>
        <p
          style={{
            margin: '10px 10px',
            padding: 1,
            background: colorBgContainer,
          }}
          >
          {now}
        </p>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Header />
        </Header>
        <Navbar style={{ padding: 0, background: colorBgContainer }}>
          <Navbar />
        </Navbar>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 400,
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
        {/* <Footer>
          <Footer />
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default LayoutSide;