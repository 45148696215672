import React,{useState,useEffect} from "react";
import {Form, Input, message, Select, Button,Card} from 'antd';
import {useNavigate,useLocation,Link} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';

const EditTaxes = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const taxDataUpdate = data;
  const taxIdUpdate = data.taxID;
  const taxNameUpdate = data.taxName;
  const taxTypeUpdate = data.taxType; 
  const taxDescriptionUpdate  = data.taxDescription;
  const frequencyUpdate  = data.taxFrequency;
  const monthsUpdate  = data.months;
  const accountNameUpdate  = data.accountName;
  const codeSquareUpdate  = data.codeSquare;

  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

    //Handler Fund Family DropList ****************************************************************************************
    useEffect(() => {
      const getAllAccounts = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/accounts/get-oneaccount', {
            userid:user._id,
            _id: accountNameUpdate
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllAccount(res.data);
          console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With sector');
          // message.error('You are not Admin User');
        }
      };  
      getAllAccounts();
    },[accountNameUpdate])

  
  //Handlers User  **************************************************************************************************************************
  const handleSubmitSubscription = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/taxes/edit-tax', {
      payload:{
        ...values,
        userid: user._id,
      },
      _id: data.taxId,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Contribuição Fiscail Actualizada com sucesso!');
    navigate('/Taxes');
    console.log(values)

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  // const onFinish = (values) => {
  //   console.log({values
  //   });
  // };

  return (
    <Layout>
             <div className="accountpage">
        <div></div>
        <div><h1>Editar Contribuição Fiscal - {taxNameUpdate}</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={handleSubmitSubscription} 
          >

            <h5 style={{ marginBottom:30, marginTop:30 }}>Nome da Contribuição Fiscal</h5>

            <Form.Item label="" name="taxName">
              <Input defaultValue={taxNameUpdate}
              type="text"
              />
            </Form.Item>

            <h5 style={{ marginBottom:30, marginTop:30 }}>Tipo de Contribuição Fiscal</h5>

            <Form.Item label="" name="taxType">
              <Input defaultValue={taxTypeUpdate} disabled="true"
              type="text"
              />
            </Form.Item>

            <h5 style={{ marginBottom:30, marginTop:30 }}>Conta Utilizada</h5>

            <Form.Item label="" name="accountName">
                <Select defaultValue={accountNameUpdate} disabled="true">
                  {
                  allAccount.map((opts,i)=>
                    <Select.Option key={i} value={opts._id}>
                      {opts.accountName}
                    </Select.Option>)
                  }
                </Select>
            </Form.Item>

            <Form.Item style={{ marginTop:30 }} label="Observações" name="taxDescription">
              <TextArea defaultValue={taxDescriptionUpdate}
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text"
                />
            </Form.Item>

            <Form.Item label="Frequência" name="frequency">
              <Select defaultValue={frequencyUpdate} disabled="true">
                <Select.Option value="Mensal">Mensal</Select.Option>
                <Select.Option value="Trimestral">Trimestral</Select.Option>
                <Select.Option value="Semestral">Semestral</Select.Option>
                <Select.Option value="Anual">Anual</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Número de frequências" name="months">
                <Input defaultValue={monthsUpdate} disabled="true"/>
            </Form.Item>
            
            <Button style={{ width: "100%" , background: "green",color:"white", marginTop: 30, marginBottom: 30}}  htmlType='submit'>
                EDITAR
              </Button>
              <Link to="/Taxes">
                <Button style={{ width: "100%" }} type="primary">
                  CANCELAR
                </Button>
                </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(accountDataUpdate, null, 2) }</pre></div> */}
    </Layout>
  );
};

export default EditTaxes;