import React,{useState,useEffect} from 'react';
import {Card,Row,Col} from 'antd';
import Layout from "../components/Layout/Layout";
import MyBank from "./../components/Assets/bank.png";
import Spinner from "../components/Spinner";
import {useNavigate} from "react-router-dom";

const Explorer = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { Meta } = Card;

  // const [allAccountStats, setAllAccountStats] = useState([]);
  // const [allNoAccountStats, setAllNoAccountStats] = useState([]);
  // const [allSubscriptionStats, setAllSubscriptionStats] = useState([]);
  // const [allNoSubscriptionStats, setAllNoSubscriptionStats] = useState([]);
  // const [allUtilitiesStats, setAllUtilitiesStats] = useState([]);
  // const [allNoUtilitiesStats, setAllNoUtilitiesStats] = useState([]);
  // const [allInsurancesStats, setAllInsurancesStats] = useState([]);
  // const [allNoInsurancesStats, setAllNoInsurancesStats] = useState([]);
  // const [allLoansStats, setAllLoansStats] = useState([]);
  // const [allNoLoansStats, setAllNoLoansStats] = useState([]);
  // const [allInvestmentsStats, setAllInvestmentsStats] = useState([]);
  // const [allCreditCardsStats, setAllCreditCardsStats] = useState([]);
  // const [allTaxStats, setAllTaxStats] = useState([]);
  // const [allNoTaxStats, setAllNoTaxStats] = useState([]);  
  // const [allWalletsStats, setAllWalletsStats] = useState([]);

  //loading Apis
  const [loading,setLoading] = useState(false);

  const [loginUser, setLoginUser] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user = JSON.parse(localStorage.getItem('user'))
  const subscriptionPackage = user.subscriptionPackage

  //***************************RENDER**************************************************************************/

  return (
    
    <Layout>

      {loading && <Spinner/>}
      <div className="content">
        <Row gutter={[8,24]}
            style={{
            justifyContent: "center"
          }}>
            
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/StockPrices');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Cotações Acções</h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/ETFPrices');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Cotações ETFs</h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/FundFamilyPrices');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Cotações Fundos de Investimento</h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/PPRPrices');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Cotações PPRs</h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CryptoPrices');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%",marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Cotações Criptomoedas</h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/FuturePrices');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Cotações Futuros</h4>
              </div>
            </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CurrencyRates');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%",marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Cotações Cambiais</h4>
              </div>
            </Card>
            </Col>

            {/* subscription package view card */}
            {(subscriptionPackage === 1 || subscriptionPackage === 2) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/StockSmarts');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Smart-Advisor Acções</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(subscriptionPackage === 1 || subscriptionPackage === 2) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/ETFSmarts');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Smart-Advisor ETFs</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(subscriptionPackage === 1 || subscriptionPackage === 2) && (
             <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CryptoSmarts');
            }}
            size="large"
            hoverable
            style={{  width: "100%", height: "100%",marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Smart-Advisor Criptomoedas</h4>
              </div>
            </Card>
            </Col>
            )}

          </Row>
      </div>

    </Layout>
  );
};

export default Explorer;