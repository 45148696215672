import React,{useState,useEffect} from 'react';
import {Form,Input,Button,Space,message} from 'antd';

import Layout from "../components/Layout/Layout";
import {useNavigate,useLocation} from "react-router-dom";

import moment from 'moment';

import axios from "axios";
import Spinner from '../components/Spinner';

const NewBalanceInitial = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const location = useLocation();
  const data = location.state;

  const AccountName = data.codeSquare;
  const AccountNameDetail = data.accountName;
  const accountTypeDetail = data.accountType;
  
  //loading Apis
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState('')

  // //DropList Category
  // const [allCategory, setAllCategory] = useState([]);
  // //DropList Account
  // const [allAccount, setAllAccount] = useState([]);
  //DropList Account
  const [allAccountName, setAllAccountName] = useState([]);

  //Add Tags
  // const [tags, setTags] = useState([]);

  // //Recursive Dates Array
  // const [sdates, setsDates]= useState([]);
  // const [dateUpdate, setDateUpdate] = useState();

  // const { TextArea } = Input;

  // const [categoryDetails, setCategoryDetails] = useState(null);
  // const [accountDetails, setAccountDetails] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user = JSON.parse(localStorage.getItem('user'))

  // const addTag = (e) => {
  //   if (e.key === "Tab") {
  //     if (e.target.value.length > 0) {
  //       setTags([...tags, e.target.value]);
  //       e.target.value = "";
  //     }
  //   }
  // };

       //***************************************MONTHS RECURSIVES**************************************************************/
    // //Recursive Dates Array
    // const [sMonth, setMonth]= useState('0');

    // const handleMonths=(event)=>{
  
    //     setMonth([]);
    
    //     const getMonth= event.target.value;
    
    //     console.log(getMonth); //22
    //     console.log(sdates); //2023-01-06
    
    //     const dates = [];
    //     const currDate = moment(sdates);
    
    //     if (getMonth === "1") {
    //       dates.push(moment(currDate).format('YYYY-MM-DD'));
    //     }else{
    //       //console.log(currDate); //2023-01-06
        
    //       let lastDate = moment(currDate).add(getMonth-1, 'month').toDate();
    //       //let lastDate = moment.utc(new Date("12/31/2023")).startOf("day");
    
    //       //console.log(lastDate); //2028-04-06
    
    //       do {
    //       dates.push(moment(currDate).format('YYYY-MM-DD'));
    //       } while (currDate.add(1, "month").diff(lastDate) < 0);
    //       //dates.push(currDate.clone().toDate());
    //       dates.push(moment(currDate).format('YYYY-MM-DD'));
    //     }
    
    //     message.success(dates);
    //     console.log(dates);
    
    //     setMonth(dates);
    //   }

    // const worker = {
    // dates: moment(dateUpdate),
    // };

    // //Handler Fund Family DropList ****************************************************************************************
    // useEffect(() => {
    //   const getAllAccounts = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/accounts/get-account', {
    //         userid:user._id,
    //         status: true
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllAccount(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       //message.error('You are not Admin User');
    //     }
    //   };  
      
    //   // Clean up the interval when the component unmounts
    //   return () => getAllAccounts();
    // },[])
  
    // //Handler Sectors DropList ****************************************************************************************
    // useEffect(() => {
    //   const getAllCategories = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/categories/get-categoryName', {
    //         userid:user._id,
    //         isIncomeOrExpense: false,
    //         isBusiness: user.isBusiness,
    //         isCreditCard: "all"
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllCategory(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       //message.error('You are not Admin User');
    //     }
    //   };  
      
    //   // Clean up the interval when the component unmounts
    //   return () => getAllCategories();
    // },[])

        //Handler Sectors DropList ****************************************************************************************
        useEffect(() => {
          const getAccountName = async (values) => {
            try{
              const user = JSON.parse(localStorage.getItem('user'))
              setLoading(true);
              const res = await axios.post('/accounts/get-findAccountName', {
                userid:user._id,
                codeSquare: AccountName
              },
              {
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
              }
              );
              setLoading(false);
              setAllAccountName(res.data);
              console.log(res.data);
            }catch (error){
              setLoading(false);
              //message.error('Fetch Issue With sector');
              //message.error('You are not Admin User');
            }
          };  
          
          // Clean up the interval when the component unmounts
          return () => getAccountName();
        },[AccountName])

        var accountID = allAccountName[0]
        console.log(accountID);

let range = {min: 1, max: 999999999}
let delta = range.max - range.min

const codeSquare = Math.round(range.min + Math.random() * delta)

  const submitHandler = async (values) => {

    try{
      setLoading(true);

      if(accountTypeDetail!=="Cartão de Crédito"){
        const res = axios.post('/transactions/add-transaction', {
          amount:0, 
          isIncomeOrExpense:true,
          isPayedReceived:true,
          date: values.date,
          userid:user._id, 
          monthYear: moment(values.date).format("MMM YYYY"),
          monthYearNumber: moment(values.date).format("YYYYMM"),
          dayMonthYearNumber: moment(values.date).format("YYYY-MM-DD"),
          accountName:accountID._id,
          categoryName:"6527ceebd444c343b8561826",
          codeSquare: codeSquare,
          tags: values.tags
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
      }else{
        const res = axios.post('/transactions/add-transaction', {
          ...values, 
          isIncomeOrExpense:true,
          isPayedReceived:true,
          date: values.date,
          userid:user._id, 
          monthYear: moment(values.date).format("MMM YYYY"),
          monthYearNumber: moment(values.date).format("YYYYMM"),
          dayMonthYearNumber: moment(values.date).format("YYYY-MM-DD"),
          accountName:accountID._id,
          categoryName:"6527ceebd444c343b8561826",
          codeSquare: codeSquare,
          tags: values.tags,
          isCreditCard: true
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
      }
      setLoading(false);
      message.success('Saldo Actualizado com Sucesso!');
      if(accountTypeDetail!=="Cartão de Crédito"){
        navigate('/Accounts');
      }else{
        navigate('/CreditCards');
      }
    }catch (error){
      setLoading(false);
      message.error('Algo deu errado!');
    }
  }

  //Handler Test Console Log Values
  // const onFinish = (values) => {
  //   console.log({
  //     ...values, 
  //     isIncomeOrExpense:true,
  //     date: values.date,
  //     userid:user._id,
  //     monthyear: moment(values.date).format("MMM YYYY"),
  //     monthyearnumber: moment(values.date).format("YYYYMM"),
  //     accountName:accountDetails,
  //     categoryName:categoryDetails
  //   });
  // };

  // const handleDate=(event)=>{
  //   setsDates([]);
  //   const getDates= moment(event.target.value);
  //   setsDates(getDates);
  //   console.log(getDates);
  // }

  // const onChangeAccount = ({ target: { value } }) => {
  //   console.log('Account choose: ', value);
  //   setAccountDetails(value);
  // };

  // const onChangeCategory = ({ target: { value } }) => {
  //   console.log('Category choose: ', value);
  //   setCategoryDetails(value);
  // };

  // //Handler Test Console Log Values
  // const onFinish = (values) => {
  //   console.log({
  //     ...values, 
  //     isIncomeOrExpense:true,
  //     date: values.date,
  //     userid:user._id, 
  //     monthYear: moment(values.date).format("MMM YYYY"),
  //     monthYearNumber: moment(values.date).format("YYYYMM"),
  //     dayMonthYearNumber: moment(values.date).format("YYYY-MM-DD"),
  //     accountName:accountID,
  //     categoryName:"6527ceebd444c343b8561826",
  //     codeSquare: codeSquare,
  //     tags: values.tags,
  //   });
  // };

  if(accountTypeDetail!=="Cartão de Crédito"){
    return (
      <Layout>
        <div className="accountpage">
          <div></div>
          <div><h1>{AccountNameDetail} - Abertura de Conta</h1></div>
          <div>
          </div>
        </div>
        <br></br>
        <div>
          <div></div>
          <div aling="center" className='registerAccount'>
          <Form 
              layout="vertical"
              onFinish={submitHandler}
            >
              {/* <Space direction="horizontal">
              <Form.Item style={{ marginTop:10 }} label="Saldo Inicial" name="amount">
                <Input
                  placeholder='0.00'
                  suffix="€"
                  type="text" 
                  size="large" 
                  style={{fontSize:"32px", color:"blue"}}
                />
              </Form.Item>
              <Form.Item style={{ marginTop:10 }} label="Moeda"  name="currency">
                    <Input style={{fontSize:"32px", width: "100px"}} defaultValue={"EUR"} disabled="true"/>
              </Form.Item>
              </Space> */}
              <Form.Item label="Data da Abertura de Conta" name="date"
                  rules={[
                    { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                      ]}>
                  <Input style={{fontSize:"18px"}} type="date"/>
              </Form.Item>
  
              {/* <Form.Item label="Já recebida?" name="isPayed" valuePropName="checked">
                <Switch checkedChildren="Sim" unCheckedChildren="Não"/>
              </Form.Item> */}
  
              {/* <h5 style={{ marginBottom:30, marginTop:30 }}>Conta</h5>
  
              <Radio.Group onChange={onChangeAccount} style={{ display: "flex", justifyContent: "left"}} >
                <Space direction="vertical" align='center' size="middle">
                <Row gutter={[8,24]}>
                        {
                        allAccount.map((opts,i)=>
                          <Radio.Button style={{ display: "flex", justifyContent: "center", width:300, marginTop: 5 }} key={i} value={opts._id}>
                            {opts.accountName}
                          </Radio.Button>)
                        }
                </Row>
                </Space>
              </Radio.Group> */}
  
              {/* <h5 style={{ marginBottom:30, marginTop:30 }}>Tipo de Receita - Inicial</h5>
  
              <Radio.Group onChange={onChangeCategory} style={{ display: "flex", justifyContent: "left"}} >
                <Space direction="vertical" align='center' size="middle">
                <Row gutter={[8,24]}>
                        {
                        allCategory.map((opts,i)=>
                          <Radio.Button style={{ display: "flex", justifyContent: "center", width:300, marginTop: 5 }} key={i} value={opts._id}>
                            {opts.categoryNamePT}
                          </Radio.Button>)
                        }
                </Row>
                </Space>
              </Radio.Group> */}
  
              {/* <Form.Item label="Viagem?" name="isTravel" valuePropName="checked">
                <Switch checkedChildren="Sim" unCheckedChildren="Não" />
              </Form.Item> */}
  
              <Button style={{ width: "100%", marginTop: "10px" }} type="primary" htmlType='submit'>
              CRIAR
              </Button>
                </Form>
          </div>
          <div></div>
  
              </div>
              {/* <div><pre>{JSON.stringify(allExpenses, null, 2) }</pre></div> */}
      </Layout>
    )
  }else{
    return (
      <Layout>
        {loading && <Spinner/>}
        <div className="accountpage">
          <div></div>
          <div><h1>{AccountNameDetail} - Abertura de Conta</h1></div>
          <div>
          </div>
        </div>
        <br></br>
        <div>
          <div></div>
          <div aling="center" className='registerAccount'>
          <Form 
              layout="vertical"
              onFinish={submitHandler}
            >
              <Space direction="horizontal" className="horizontal-space">
              <Form.Item style={{ marginTop:10 }} label="Saldo Inicial" name="amount">
                <Input
                  placeholder='0.00'
                  suffix="€"
                  type="text" 
                  size="large" 
                  style={{fontSize:"32px", color:"blue"}}
                />
              </Form.Item>
              <Form.Item style={{ marginTop:10 }} label="Moeda"  name="currency">
                    <Input style={{fontSize:"32px", width: "100px"}} defaultValue={"EUR"} disabled="true"/>
              </Form.Item>
              </Space>
              <Form.Item label="Data" name="date"
                  rules={[
                    { required: true, whitespace:true, message: 'Por favor, insira uma data!' },
                      ]}>
                  <Input style={{fontSize:"18px"}} type="date"/>
              </Form.Item>
  
              <Button style={{ width: "100%", marginTop: "10px" }} type="primary" htmlType='submit'>
              CRIAR
              </Button>
                </Form>
          </div>
          <div></div>
  
              </div>
              {/* <div><pre>{JSON.stringify(allExpenses, null, 2) }</pre></div> */}
      </Layout>
    )
  }
  
  };

export default NewBalanceInitial;