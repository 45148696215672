import React,{useState,useEffect} from "react";
import { message, Button} from 'antd';

import {useNavigate,useLocation} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import  Chart  from "react-apexcharts";

const Transactions = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // const [showModal, setShowModal] = useState(false);
  // const [showDeleteModal, setShowDeleteModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const accountDescriptionPass = data.accountDescription
  const isCreditCardPass = data.isCreditCard[0]
  const accountIdPass = data.accountId.toString()

  console.log(data);

  // const { Title, Text } = Typography;

  const [loading,setLoading] = useState(false);

  // const [allAccount, setAllAccount] = useState([]);
  // const [allTransactions, setAllTransactions] = useState([]);
  // const [allCategory, setAllCategory] = useState([]);

  // const { TextArea } = Input;
  // const {RangePicker} = DatePicker;

  // //Edit Transactions
  // const [editable, setEditable] = useState(null);

  // //Add Tags
  // const [tags, setTags] = useState([]);

  //Fields from table to View
  // const [amountUpdate, setAmountUpdate] = useState();
  // const [dateUpdate, setDateUpdate] = useState();
  // const [currencyUpdate, setCurrencyUpdate] = useState();
  // const [isPayedReceivedUpdate, setIsPayedReceivedUpdate] = useState(false);
  // const [accountNameUpdate, setAccountNameUpdate] = useState();
  // const [accountTypeUpdate, setAccountTypeUpdate] = useState();
  // const [categoryNameUpdate, setCategoryNameUpdate] = useState();
  // const [commentsUpdate, setCommentsUpdate] = useState();
  // const [isTravelUpdate, setIsTravelUpdate] = useState(false);
  // const [tagsUpdate, setTagsUpdate] = useState();
  // const [monthsUpdate, setMonthsUpdate] = useState();
  // const [isIncomeOrExpenseUpdate, setIsIncomeOrExpenseUpdate] = useState();
  // const [codeSquareUpdate, setCodeSquareUpdate] = useState();
  // const [squareNameUpdate, setSquareNameUpdate] = useState();
  // const [idUpdate, setIdUpdate] = useState();
  // const [deleteOneTransactionUpdate, setDeleteOneTransactionUpdate] = useState(false);

  //Fields from Graphs to View
  // const [allCategoriesWeights, setAllCategoriesWeights] = useState(null);
  // const [allCategoriesIncomeWeights, setAllCategoriesIncomeWeights] = useState(null);

  //Filters
  // const [accountName, setAccountFilter] = useState('all');
  // const [isPayedReceived, setIsPayedReceivedFilter] = useState('all');
  // const [isIncomeOrExpense, setIsIncomeOrExpenseFilter] = useState('all');
  // const [frequency, setFrequency] = useState('month');
  // const [selectedDate, setSelectedDate] = useState([]);

  // useEffect(() => {
  //   const getAllTransactions = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/transactions/get-transaction',
  //       {
  //         userid:user._id,
  //         accountName: accountNamePass,
  //         isIncomeOrExpense: isIncomeOrExpense,
  //         frequency,
  //         selectedDate,
  //         isPayedReceived: isPayedReceived,
  //         isArchive: "all"
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllTransactions(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With Industry');
  //       //message.error('You are not Admin User');
  //     }
  //   };  

  //   getAllTransactions();
    
  //   // // Clean up the interval when the component unmounts
  //   // return () => getAllTransactions();

  // },[accountNamePass,isPayedReceived,isIncomeOrExpense,frequency,selectedDate])

  // //useEffect Hook
  // useEffect(() => {
  //   const getAllAccount = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/accounts/get-account', {
  //         userid:user._id,
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }       
  //       );
  //       setLoading(false);
  //       setAllAccount(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With account');
  //     }
  //   };  
    
  //   // Clean up the interval when the component unmounts
  //   return () => getAllAccount();
  // },[])

  const [weightSubject, setWeightSubject]= useState([]);
  const [weightMarks, setWeightMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllCategoryWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/transactions/get-accountcategotyall',
    {
      userid:user._id,
      accountName:accountIdPass,
      isIncomeOrExpense:false
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    // setAllCategoriesWeights(res.data);
    console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].categoryNamePT);
    sMarks.push(res.data[i].amount);
    }
    setWeightSubject(sSubject);
    setWeightMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllCategoryWeights();
  },[accountIdPass])

  const [weightIncomeSubject, setWeightIncomeSubject]= useState([]);
  const [weightIncomeMarks, setWeightIncomeMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllCategoryIncomeWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/transactions/get-accountcategotyall',
    {
      userid:user._id,
      accountName:accountIdPass,
      isIncomeOrExpense:true
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    // setAllCategoriesIncomeWeights(res.data);
    console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].categoryNamePT);
    sMarks.push(res.data[i].amount);
    }
    setWeightIncomeSubject(sSubject);
    setWeightIncomeMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllCategoryIncomeWeights();
  },[accountIdPass])

  //Handlers User  **************************************************************************************************************************
  // const handleSubmitTransaction = async (values) => {

  // try{
  
  //   const user = JSON.parse(localStorage.getItem('user'))

  //   setLoading(true);
  //   const res = await axios.post('/transactions/edit-transaction', {
  //     payload:{
  //       ...values,
  //       userid: user._id,
  //     },
  //     _id: editable._id,
  //   },
  //   {
  //     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //   }
  //   );
  //   setLoading(false);
  //   message.success('Transacção Actualizada com sucesso!');
  //   setShowModal(false);
  //   setTimeout(function () {
  //     window.location.reload(true);
  //   }, 1000);

  //   }catch (error){
  //     setLoading(false);
  //     // message.error('Failed to industry');
  //     //message.error('You are not Admin User');
  //   }
  // }

  //delete handler
  // const handleDelete = async (record) => {
  //   try {
  //       const user = JSON.parse(localStorage.getItem('user'))

  //       setLoading(true);

  //       if(record.deleteOnlyOneTransaction===true){
  //         await axios.post("/transactions/delete-alltransaction", {
  //           userid:user._id,
  //           isIncomeOrExpense: isIncomeOrExpenseUpdate,
  //           selectedDate: dateUpdate,
  //           codeSquare: codeSquareUpdate
  //         }
  //         )
  //         message.success("Transacções eliminada!");
  //       }
  //       else{
  //         await axios.post("/transactions/delete-transaction", {
  //           _id: idUpdate,
  //           });
  //         message.success("Transacção eliminada!");
  //       };
  //       setLoading(false);
  //       setShowDeleteModal(false);
  //       setTimeout(function () {
  //       window.location.reload(true);
  //       message.success('reload 1 segundo');
  //       }, 1000);
  //   } catch (error) {
  //       setLoading(false);
  //       console.log(error);
  //       message.error("unable to delete");
  //   }
  //   };

  //tags******************************************************************************************************

  // const addTag = (e) => {
  //   if (e.key === "Tab") {
  //     if (e.target.value.length > 0) {
  //       setTags([...tags, e.target.value]);
  //       e.target.value = "";
  //     }
  //   }
  // };

  // const reload=()=>window.location.reload();

  // const handleClose = () => {
  //   setShowModal(false)
  //   setEditable(null);
  //   setDateUpdate(null);
  //   setAmountUpdate(null);
  //   setCurrencyUpdate(null);
  //   setIsPayedReceivedUpdate(null);
  //   setAccountNameUpdate(null);
  //   setCategoryNameUpdate(null);
  //   setCommentsUpdate(null);
  //   setIsTravelUpdate(null);
  //   setTagsUpdate(null);
  //   setMonthsUpdate(null);
  //   setShowDeleteModal(false);
  //   setCodeSquareUpdate(null);
  //   setDeleteOneTransactionUpdate(null);
  //   setIsIncomeOrExpenseUpdate(null);
  //   setSquareNameUpdate(null);
  //   //reload();
  // };

  // const onChangeAccount = ({ target: { value } }) => {
  //   console.log('choose: ', value);
  //   setDeleteOneTransactionUpdate(value);
  // };

  // const onFinish = (values) => {
  //   console.log({values
  //   });
  // };

  // //Analytics
  // const totalIncomesTurnover = allTransactions
  // .filter((transaction) => transaction.isIncomeOrExpense === true)
  // .reduce((acc, transaction) => acc + transaction.amount, 0);

  // const totalExpensesTurnover = allTransactions
  // .filter((transaction) => transaction.isIncomeOrExpense === false)
  // .reduce((acc, transaction) => acc + transaction.amount, 0);

  // console.log("Confirmed:",totalAllTransactionsPayedTurnover);

  // const totalAllTransactionsNotPayedTurnover = allTransactions
  // .filter((transaction) => transaction.isPayedReceived === false)
  // .reduce((acc, transaction) => acc + transaction.amount, 0);

  // console.log("Pending:",totalAllTransactionsNotPayedTurnover);

  // const totalTransactions = totalIncomesTurnover-totalExpensesTurnover;

  // console.log("Total:",totalTransactions);

  // const totalNumberTransaction = allTransactions.length;

  // console.log("Transacções:",totalNumberTransaction);

  console.log("isCreditCardPass",isCreditCardPass)

  // ACCOUNT TRANSACTIONS
    return (
      <Layout>
        {loading && <Spinner/>}
        <div className="accountpage">
        <div></div>
        <div><h1>Dashboard - {accountDescriptionPass}</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
        <hr></hr>
        {/* Start Graphs */}
        <div className="row">
        <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
          <div className="chart-container">
          <Chart
            align="center" 
            type="donut"
            width="100%"
            series={weightMarks}                
            options={{
              responsive: [
                {
                  breakpoint: 281, // iPhone SE width
                  options: {
                    chart: {
                      width: '100%',
                      height: 150 // Adjust height for iPhone SE
                    }
                  }
                },
                {
                  breakpoint: 361, // iPhone SE width
                  options: {
                    chart: {
                      width: '100%',
                      height: 230 // Adjust height for iPhone SE
                    }
                  }
                },
                {
                  breakpoint: 481, // iPhone SE width
                  options: {
                    chart: {
                      width: '100%',
                      height: 250 // Adjust height for iPhone SE
                    }
                  }
                },
                {
                  breakpoint: 769, // iPhone 12 Mini width
                  options: {
                    chart: {
                      width: '100%',
                      height: 275 // Adjust height for iPhone 12 Mini
                    }
                  }
                },
                {
                  breakpoint: 768, // iPhone 12, iPhone 14, and website width
                  options: {
                    chart: {
                      width: '100%',
                      height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                    }
                  }
                },
                {
                  breakpoint: 1024, // iPhone 12, iPhone 14, and website width
                  options: {
                    chart: {
                      width: '100%',
                      height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                    }
                  }
                },
                {
                  breakpoint: 1200, // iPhone 12, iPhone 14, and website width
                  options: {
                    chart: {
                      width: '100%',
                      height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                    }
                  }
                },
                {
                  breakpoint: 2000, // iPhone 12, iPhone 14, and website width
                  options: {
                    chart: {
                      width: '100%',
                      height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                    }
                  }
                }
              ],
            labels: weightSubject,
            noData: { text: "No Data" }, 
            plotOptions: {
              pie: {
              donut: {
                labels: {
                show: true,
                name: {
                  formatter: function(val) {
                  return val
                  }
                }
                }
              }
              }
            },
            dataLabels: { enabled: true },
            legend: { show: false } // Hide legends
            }}
          />
          </div>
          <h4 align="center">Despesas YTD</h4>
        </div>
        <div className="col-xs-12 col-md-6"> {/* Each chart takes half of the width on medium screens and full width on extra small screens */}
          <div className="chart-container">
          <Chart
            align="center" 
            type="donut"
            width="100%"
            series={weightIncomeMarks}                
            options={{
              responsive: [
                {
                  breakpoint: 281, // iPhone SE width
                  options: {
                    chart: {
                      width: '100%',
                      height: 150 // Adjust height for iPhone SE
                    }
                  }
                },
                {
                  breakpoint: 361, // iPhone SE width
                  options: {
                    chart: {
                      width: '100%',
                      height: 230 // Adjust height for iPhone SE
                    }
                  }
                },
                {
                  breakpoint: 481, // iPhone SE width
                  options: {
                    chart: {
                      width: '100%',
                      height: 250 // Adjust height for iPhone SE
                    }
                  }
                },
                {
                  breakpoint: 769, // iPhone 12 Mini width
                  options: {
                    chart: {
                      width: '100%',
                      height: 275 // Adjust height for iPhone 12 Mini
                    }
                  }
                },
                {
                  breakpoint: 768, // iPhone 12, iPhone 14, and website width
                  options: {
                    chart: {
                      width: '100%',
                      height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                    }
                  }
                },
                {
                  breakpoint: 1024, // iPhone 12, iPhone 14, and website width
                  options: {
                    chart: {
                      width: '100%',
                      height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                    }
                  }
                },
                {
                  breakpoint: 1200, // iPhone 12, iPhone 14, and website width
                  options: {
                    chart: {
                      width: '100%',
                      height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                    }
                  }
                },
                {
                  breakpoint: 2000, // iPhone 12, iPhone 14, and website width
                  options: {
                    chart: {
                      width: '100%',
                      height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                    }
                  }
                }
              ],
            labels: weightIncomeSubject,
            noData: { text: "No Data" }, 
            plotOptions: {
              pie: {
              donut: {
                labels: {
                show: true,
                name: {
                  formatter: function(val) {
                  return val
                  }
                }
                }
              }
              }
            },
            dataLabels: { 
              enabled: true,
            },
            legend: { show: false } // Hide legends
            }}
          />
          </div>
          <h4 align="center">Receitas YTD</h4>
        </div>
        </div>
        {/* Final Graphs */}

        {/* <div><pre>{JSON.stringify(allTransactions, null, 2) }</pre></div> */}
  
      </Layout>
    );
  };

export default Transactions;